import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RtcService } from 'src/app/shared/services/rtc.service';
import { version } from '../../../environments/version';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { LiveGameCommunicatorService } from 'src/app/shared/services/live-game-communicator.service';

@Component({
  selector: 'app-progress',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userDetails;
  version = version;
  sub: any;
  latestSetsSub: any;
  finishedSetsSub: any;
  unfinishedSetsSub: any;
  customSetsSub: any;
  userSetsSub: any;

  private calculateDiamondsFromSets(sets: any[]) {
    if (!sets || !this.userDetails || this.userDetails.userData?.diamondsCalculated) return;

    const setsDiamonds = sets.reduce((total, set) => {
      if (set?.stats?.quizParams?.multiQuizParams?.multiModeNumber) {
        return total + set.stats.quizParams.multiQuizParams.multiModeNumber;
      }
      return total;
    }, 0);

    if (setsDiamonds > 0) {
      const currentTotal = this.userDetails.userData?.totalDiamonds || 0;
      const newTotal = currentTotal + setsDiamonds;
      this.userDetailsService.setUsersProp(this.userDetails._id, 'totalDiamonds', newTotal);
      this.userDetailsService.setUsersProp(this.userDetails._id, 'diamondsCalculated', true);
    }
  }
  constructor(
    private router: Router,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    private rtcService: RtcService,
    private auth: AuthenticationService,
    private liveCommunicationService: LiveGameCommunicatorService
  ) { }

  ngOnInit() {
    // Subscribe to user details
    this.sub = this.userDetailsService
      .userDetailsListener()
      .subscribe((userDetails) => {
        if (userDetails) {
          const previousDetails = this.userDetails;
          this.userDetails = userDetails;

          // Only load sets if this is the first time receiving user details
          if (!previousDetails && userDetails._id) {
            this.userDetailsService.getUsersSets(userDetails._id);
          }
        }
        if (this.userDetails?.userData?.ageStatus === 'smallChild') {
          const token = this.helpers.clone(localStorage.getItem('mean-token'));
          console.log(
            '🚀 ~ file: profile.component.ts:40 ~ ProfileComponent ~ .subscribe ~ token:',
            token
          );
          if (token) {
            if (
              confirm('Wykryto konto dziecka. Przejść do aplikacji dla dzieci?')
            ) {
              window.location.href =
                'https://int2.linget.it/login?token=' + token;
            } else {
              this.auth.logout();
            }
          }
        }
      });

    // Subscribe to sets updates to calculate diamonds only for new sets
    this.latestSetsSub = this.userDetailsService.latestSetsUpdatedListener()
      .subscribe(sets => {
        if (sets && this.userDetails) {
          // Only calculate diamonds for the newly loaded sets
          this.calculateDiamondsFromSets(sets);
        }
      });

    this.userDetailsService.getUserDetails();
  }

  getLearningLevelColor(time: number): string {
    const level = this.userDetailsService.getLearningLevel(time);
    switch (level) {
      case 'advanced': return 'danger';  // > 15 mins (red)
      case 'intermediate': return 'warning';  // 10-15 mins (yellow)
      case 'basic': return 'tertiary';  // 5-10 mins (purple)
      default: return 'medium';  // < 5 mins (gray)
    }
  }

  getLearningTimeText(time: number): string {
    if (!time) return '0m';
    const minutes = Math.round(time / 60);
    return `${minutes}m`;
  }

  getDayLabels(): string[] {
    const days = ['Ndz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'];
    const today = new Date().getDay();
    const result = [];

    // Start from 6 days ago up to today
    for (let i = 6; i >= 0; i--) {
      const index = (today - i + 7) % 7;
      result.push(days[index]);
    }

    return result;
  }

  getOrderedStreak(): number[] {
    if (!this.userDetails?.userData?.learningStreak) return Array(7).fill(0);

    const streak = this.userDetails.userData.learningStreak;
    const today = new Date().getDay();
    const result = [];

    // Start from 6 days ago up to today
    for (let i = 6; i >= 0; i--) {
      const index = (today - i + 7) % 7;
      result.push(streak[index]);
    }

    return result;
  }

  getLatestBadge() {
    const path = 'assets/kowersacjebadges/';
    if (this.userDetails?.userData?.conversationsStats?.length > 0) {
      let finishedCategories = this.userDetails.userData.conversationsStats.filter(category =>
        category?.conversations?.every(conversation => conversation?.finished)
      );
      if (finishedCategories?.length > 0) {
        const latestCategory = finishedCategories[finishedCategories.length - 1];
        return path + latestCategory.id + '.png';
      } else {
        return path + '0.png';
      }
    }
    return path + '0.png';
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub?.unsubscribe();
    this.latestSetsSub?.unsubscribe();
  }
  resetProgress() {
    this.userDetailsService.resetProgress();
  }
}
