import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chatbots-conversations-topics-card',
  templateUrl: './chatbots-conversations-topics-card.component.html',
  styleUrls: ['./chatbots-conversations-topics-card.component.css']
})
export class ChatbotsConversationsTopicsCardComponent implements OnInit {
  @Input() item
  @Input() disabled
  @Input() finished
  constructor() { }


  ngOnInit() {

  }

}
