import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit, OnDestroy, ElementRef, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject, fromEvent, merge, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TextManipulationService } from '../../services/text-manipulation.service';
import { KeyboardWorkerService, KeyboardPrediction } from '../../services/keyboard-worker.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

interface KeyboardKey {
  label: string;
  disabled: boolean;
  action?: string;
  predicted?: boolean;
  size?: string;
}

interface KeyPressPool {
  [key: string]: HTMLElement[];
}

const PREDICTION_DEBOUNCE = 150; // ms
const EFFECT_POOL_SIZE = 10;
const TOUCH_FEEDBACK_DURATION = 100;

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) suggestionsViewport: CdkVirtualScrollViewport;

  isShiftMode = false;
  isSpecialMode = false;
  @Input() isExpandedMode = false;
  @Input() showSuggestions = false; // Control suggestion box visibility
  @Input() hideEnterButton = false; // Control enter button visibility
  currentText: string = '';
  private keyboardHeight: number = 0;
  private resizeObserver: ResizeObserver;
  private keyPressPool: KeyPressPool = {};
  private touchStartTime: number = 0;
  private readonly LONG_PRESS_DURATION = 500;
  private lastKeys: string[] = [];
  private destroy$ = new Subject<void>();
  private layoutsLoaded = false;

  suggestions: string[] = [];
  selectedSuggestion: string = '';
  readonly itemSize = 40;

  private readonly alphabeticRows: KeyboardKey[][] = [
    [
      { label: 'q', disabled: false },
      { label: 'w', disabled: false },
      { label: 'e', disabled: false },
      { label: 'r', disabled: false },
      { label: 't', disabled: false },
      { label: 'y', disabled: false },
      { label: 'u', disabled: false },
      { label: 'i', disabled: false },
      { label: 'o', disabled: false },
      { label: 'p', disabled: false },
    ],
    [
      { label: 'a', disabled: false },
      { label: 's', disabled: false },
      { label: 'd', disabled: false },
      { label: 'f', disabled: false },
      { label: 'g', disabled: false },
      { label: 'h', disabled: false },
      { label: 'j', disabled: false },
      { label: 'k', disabled: false },
      { label: 'l', disabled: false },
    ],
    [
      { label: 'z', disabled: false },
      { label: 'x', disabled: false },
      { label: 'c', disabled: false },
      { label: 'v', disabled: false },
      { label: 'b', disabled: false },
      { label: 'n', disabled: false },
      { label: 'm', disabled: false },
      { label: '⌫', disabled: false, action: 'backspace' },
    ],
  ];

  private readonly expandedAlphabeticRows: KeyboardKey[][] = [
    ...this.alphabeticRows,
    [
      { label: '?123', disabled: false, action: 'special' },
      { label: ',', disabled: false, size: 'half' },
      { label: '\'', disabled: false, size: 'half' },
      { label: '␣', disabled: false, action: 'space' },
      { label: '.', disabled: false },
      { label: '→', disabled: false, action: 'enter' },
    ],
  ];

  private readonly specialRows: KeyboardKey[][] = [
    [
      { label: '1', disabled: false },
      { label: '2', disabled: false },
      { label: '3', disabled: false },
      { label: '4', disabled: false },
      { label: '5', disabled: false },
      { label: '6', disabled: false },
      { label: '7', disabled: false },
      { label: '8', disabled: false },
      { label: '9', disabled: false },
      { label: '0', disabled: false },
    ],
    [
      { label: '!', disabled: false },
      { label: '@', disabled: false },
      { label: '\'', disabled: false },
      { label: '$', disabled: false },
      { label: '%', disabled: false },
      { label: '?', disabled: false },
      { label: '&', disabled: false },
      { label: '*', disabled: false },
      { label: '(', disabled: false },
      { label: ')', disabled: false },
    ],
    [
      { label: '¿', disabled: false },
      { label: '¡', disabled: false },
      { label: 'á', disabled: false },
      { label: 'é', disabled: false },
      { label: 'í', disabled: false },
      { label: 'ó', disabled: false },
      { label: 'ú', disabled: false },
      { label: 'ñ', disabled: false },
      { label: '⌫', disabled: false, action: 'backspace' },
    ],
    [
      { label: '?123', disabled: false, action: 'special' },
      { label: ',', disabled: false, size: 'half' },
      { label: '\'', disabled: false, size: 'half' },
      { label: '␣', disabled: false, action: 'space' },
      { label: '.', disabled: false },
      { label: '→', disabled: false },
    ],
  ];

  private cachedShiftRows: KeyboardKey[][] | null = null;
  private suggestionsSub: any;
  private activeKey: HTMLElement | null = null;
  private touchListeners: { element: HTMLElement; listener: EventListener }[] = [];
  private predictedKeys: Set<string> = new Set();
  private keyClickSubject = new Subject<KeyboardKey>();

  @Output() keyClick = new EventEmitter<string>();
  @Output() backspaceClick = new EventEmitter<void>();
  @Output() enterClick = new EventEmitter<void>();
  @Output() textChange = new EventEmitter<string>();

  constructor(
    private textManipulationService: TextManipulationService,
    private keyboardWorker: KeyboardWorkerService,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    this.resizeObserver = new ResizeObserver(entries => {
      this.ngZone.run(() => {
        const height = entries[0].contentRect.height;
        if (height !== this.keyboardHeight) {
          this.keyboardHeight = height;
          document.documentElement.style.setProperty('--keyboard-height', `${height}px`);
        }
      });
    });
  }

  get rows(): KeyboardKey[][] {
    const currentRows = this.getCurrentRows();

    // If hideEnterButton is true and we're in expanded mode, remove the enter button
    if (this.hideEnterButton && this.isExpandedMode) {
      return currentRows.map((row, rowIndex) => {
        if (rowIndex === currentRows.length - 1) {
          // Filter out the enter button from the last row
          return row.filter(key => key.label !== '→');
        }
        return row;
      });
    }

    if (this.predictedKeys.size > 0) {
      return currentRows.map(row =>
        row.map(key => ({
          ...key,
          predicted: this.predictedKeys.has(key.label)
        }))
      );
    }
    return currentRows;
  }
  onSuggestionSelected(suggestion) {
    this.textManipulationService.suggestionSelected(suggestion);
  }
  private getCurrentRows(): KeyboardKey[][] {
    if (this.isSpecialMode) {
      return this.specialRows;
    }

    if (this.isShiftMode) {
      if (!this.cachedShiftRows) {
        this.cachedShiftRows = this.alphabeticRows.map(row =>
          row.map(key => ({ ...key, label: key.label.toUpperCase() }))
        );
      }
      return this.isExpandedMode ? [...this.cachedShiftRows, this.expandedAlphabeticRows[3]] : this.cachedShiftRows;
    }

    return this.isExpandedMode ? this.expandedAlphabeticRows : this.alphabeticRows;
  }

  ngOnInit(): void {
    // Preload layouts
    this.keyboardWorker.getPreloadedLayouts()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.layoutsLoaded = true;
        this.cdr.markForCheck();
      });

    // Setup suggestions
    this.suggestionsSub = this.textManipulationService.getSuggestionsObservable()
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe(suggestions => {
        this.suggestions = suggestions;
        this.cdr.markForCheck();
      });

    // Setup key predictions
    this.setupPredictions();

    this.resizeObserver.observe(this.elementRef.nativeElement);
    this.initializeKeyPressPool();
    this.setupTouchListeners();
  }

  private setupPredictions(): void {
    merge(
      this.keyClickSubject.pipe(
        tap(key => {
          if (!key.action) {
            this.lastKeys.push(key.label);
            if (this.lastKeys.length > 5) {
              this.lastKeys.shift();
            }
          }
        })
      ),
      this.textManipulationService.getCorrectedText()
    ).pipe(
      debounceTime(PREDICTION_DEBOUNCE),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.updatePredictions();
    });
  }

  private updatePredictions(): void {
    if (this.lastKeys.length < 2) return;

    this.keyboardWorker.getPredictions(this.currentText, this.lastKeys)
      .pipe(takeUntil(this.destroy$))
      .subscribe((prediction: KeyboardPrediction) => {
        this.predictedKeys = new Set(prediction.predictions);
        this.cdr.markForCheck();
      });
  }

  private initializeKeyPressPool(): void {
    const allKeys = this.getCurrentRows().reduce((acc, row) => [...acc, ...row], [] as KeyboardKey[]);
    allKeys.forEach(key => {
      this.keyPressPool[key.label] = Array(EFFECT_POOL_SIZE)
        .fill(null)
        .map(() => {
          const element = document.createElement('div');
          element.className = 'key-press-effect';
          return element;
        });
    });
  }

  private setupTouchListeners(): void {
    this.ngZone.runOutsideAngular(() => {
      const keyboard = this.elementRef.nativeElement;

      const touchStartListener = (event: TouchEvent) => {
        this.touchStartTime = Date.now();
        const target = event.target as HTMLElement;
        if (target.classList.contains('keyboard-key')) {
          this.handleTouchStart(target);
        }
      };

      const touchEndListener = (event: TouchEvent) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains('keyboard-key')) {
          this.handleTouchEnd(target);
        }
      };

      keyboard.addEventListener('touchstart', touchStartListener, { passive: true });
      keyboard.addEventListener('touchend', touchEndListener, { passive: true });

      this.touchListeners.push(
        { element: keyboard, listener: touchStartListener as EventListener },
        { element: keyboard, listener: touchEndListener as EventListener }
      );
    });
  }

  private handleTouchStart(target: HTMLElement): void {
    const keyEffect = this.getKeyPressEffect(target.textContent || '');
    if (keyEffect) {
      target.appendChild(keyEffect);

      if ('vibrate' in navigator) {
        navigator.vibrate(10);
      }
    }
  }

  private handleTouchEnd(target: HTMLElement): void {
    const pressDuration = Date.now() - this.touchStartTime;
    const keyEffect = target.querySelector('.key-press-effect') as HTMLElement;

    if (keyEffect) {
      keyEffect.classList.add('fade-out');
      setTimeout(() => {
        if (keyEffect.parentElement === target) {
          target.removeChild(keyEffect);
        }
        keyEffect.classList.remove('fade-out');
        this.returnKeyPressEffectToPool(keyEffect, target.textContent || '');
      }, TOUCH_FEEDBACK_DURATION);
    }

    // Handle long press for special characters
    if (pressDuration >= this.LONG_PRESS_DURATION) {
      this.handleLongPress(target);
    } else {
      this.ngZone.run(() => {
        const key = this.findKeyByLabel(target.textContent || '');
        if (key) {
          this.handleKeyClick(key);
        }
      });
    }
  }

  private getKeyPressEffect(label: string): HTMLElement | null {
    const pool = this.keyPressPool[label];
    return pool && pool.length > 0 ? pool.pop() || null : null;
  }

  private returnKeyPressEffectToPool(element: HTMLElement, label: string): void {
    if (this.keyPressPool[label]) {
      this.keyPressPool[label].push(element);
    }
  }

  private findKeyByLabel(label: string): KeyboardKey | null {
    return this.getCurrentRows().reduce((acc, row) => [...acc, ...row], [] as KeyboardKey[])
      .find(key => key.label === label) || null;
  }

  private handleLongPress(target: HTMLElement): void {
    const key = this.findKeyByLabel(target.textContent || '');
    if (!key) return;

    // Handle special characters based on the key
    const specialChars: { [key: string]: string[] } = {
      'a': ['á', 'à', 'ä', 'â', 'ã', 'å'],
      'e': ['é', 'è', 'ë', 'ê'],
      'i': ['í', 'ì', 'ï', 'î'],
      'o': ['ó', 'ò', 'ö', 'ô', 'õ'],
      'u': ['ú', 'ù', 'ü', 'û'],
      'n': ['ñ'],
      's': ['ß'],
      'y': ['ý', 'ÿ'],
    };

    const chars = specialChars[key.label.toLowerCase()];
    if (chars) {
      this.ngZone.run(() => {
        // TODO: Implement special characters popup
      });
    }
  }

  private handleKeyClick(key: KeyboardKey): void {
    if (key.disabled) return;

    this.keyClickSubject.next(key);

    switch (key.action) {
      case 'shift':
        this.toggleShiftMode();
        break;
      case 'special':
        this.toggleSpecialMode();
        break;
      case 'space':
        this.keyClick.emit('␣');
        this.textManipulationService.addCharacter(' ');
        this.suggestions = [];
        break;
      case 'backspace':
        this.keyClick.emit('⌫');
        this.textManipulationService.removeCharacter();
        break;
      default:
        if (key.label !== '→') {
          this.textManipulationService.addCharacter(key.label);
          this.keyClick.emit(key.label);
        } else if (!this.hideEnterButton) {
          this.enterClick.emit();
          this.textManipulationService.resetTextState();
        }
        if (!key.action) {
          this.isSpecialMode = false;
        }
    }

    this.cdr.markForCheck();
  }

  toggleShiftMode(): void {
    this.isShiftMode = !this.isShiftMode;
    this.cdr.markForCheck();
  }

  toggleSpecialMode(): void {
    this.isSpecialMode = !this.isSpecialMode;
    this.cdr.markForCheck();
  }

  trackByFn(index: number, item: KeyboardKey): string {
    return `${item.label}-${item.predicted ? '1' : '0'}`;
  }

  trackByRowFn(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.keyClickSubject.complete();
    if (this.suggestionsSub) {
      this.suggestionsSub.unsubscribe();
    }
    this.resizeObserver.disconnect();

    this.touchListeners.forEach(({ element, listener }) => {
      element.removeEventListener('touchstart', listener);
      element.removeEventListener('touchend', listener);
    });

    // Clear key press pool
    Object.values(this.keyPressPool).forEach(pool => {
      pool.length = 0;
    });
  }
}
