import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EncouragementService {
    private messages: string[] = [
        "Wypróbuj konwersacje sprawdź, jak szybko poprawisz swoją płynność mówienia!",
        "Konwersacje to najszybszy sposób nauki języka - zacznij rozmawiać już dziś!",
        "Odkryj ponad 40 różnych tematów konwersacji w różnych poziomach trudności!",
        "Nowe odznaki czekają na Ciebie - zdobywaj je kończąc konwersacje z chatbotami!",
        "Zbieraj diamenty za ukończone zestawy i odblokuj kolejne destynacje podróży!",
        "Dzięki konwersacjom nauczysz się praktycznych zwrotów przydatnych w podróży!",
        "Sprawdź swoje umiejętności w prawdziwej rozmowie z naszymi chatbotami!",
        "Zestawy słów + konwersacje = sukces w nauce języka! Wypróbuj obie funkcje!",
        "Codzienne konwersacje to klucz do płynności językowej - zacznij już teraz!",
        "Rozwijaj swoje umiejętności językowe poprzez realistyczne konwersacje!",
        "Pobij rekord i zostań Mistrzem Rozmów na najwyższym poziomie konwersacji!",
        "Awansuj na wyższy poziom konwersacji i zdobądź ekskluzywne odznaki!",
        "Konwersacje + zestawy słówek to połączenie, które znacząco przyspiesza naukę!",
        "Wyzwania konwersacyjne czekają na Ciebie - sprawdź, czy podołasz trudniejszym tematom!",
        "Ćwicz język w codziennych sytuacjach z naszymi realistycznymi konwersacjami!",
        "Korzystaj z chatbotów, aby przygotować się do rzeczywistych rozmów za granicą!",
        "Odkryj nowe funkcje aplikacji - wypróbuj konwersacje z inteligentnymi chatbotami!",
        "Potrzebujesz pomocy językowej w podróży? Nasze konwersacje Cię przygotują!",
        "Twój wirtualny rozmówca czeka - rozpocznij konwersację i ćwicz język w praktyce!",
        "Odznaki za konwersacje pokazują Twój postęp - zbieraj je wszystkie!",
        "Codzienne konwersacje pomagają budować pamięć długotrwałą - sprawdź to!",
        "Poznaj kulturę innych krajów poprzez tematyczne konwersacje z botami!",
        "Funkcja konwersacji pomaga przełamać barierę językową - zacznij rozmawiać!",
        "Śledź swój postęp w konwersacjach i obserwuj, jak stajesz się coraz lepszy!",
        "Lęk przed mówieniem? Pokonaj go z naszymi przyjaznymi chatbotami!",
        "Każda ukończona konwersacja to nowy poziom umiejętności - sprawdź, jak daleko zajdziesz!",
        "Konwersacje + zestawy = pełne doświadczenie nauki języka. Korzystaj z obu funkcji!",
        "Nasi użytkownicy rozwijają się najszybciej łącząc naukę zestawów z konwersacjami!",
        "Podróżuj wirtualnie rozmawiając z botami z różnych krajów i kultur!",
        "Awansuj na wyższy poziom konwersacyjny i odkryj nowe, trudniejsze tematy!",
        "Zdobądź odznakę Eksperta Konwersacji kończąc wszystkie dostępne rozmowy!",
        "Funkcja konwersacji pozwala ćwiczyć mówienie bez stresu i oceniania!",
        "Nowe tematy konwersacji są regularnie dodawane - odkrywaj je wszystkie!",
        "Przygotuj się do podróży ćwicząc praktyczne konwersacje w różnych sytuacjach!",
        "Każda konwersacja to nowa przygoda językowa - rozpocznij już dziś!",
        "Połącz naukę zestawów z praktyką w konwersacjach i podnieś swoje umiejętności!",
        "Sprawdź swój poziom zaawansowania w prawdziwych konwersacjach z botami!",
        "Funkcja mapy podróży pokazuje Twój postęp - odblokuj nowe destynacje ucząc się regularnie!",
        "Nasze zestawy słów zawierają praktyczne zwroty używane w codziennych sytuacjach!",
        "Ucz się gramatyki w kontekście realnych zdań dzięki naszym zaawansowanym zestawom!",
        "Ćwicz wymowę z zestawami audio i popraw swoją płynność mówienia!",
        "Zestawy tematyczne przygotują Cię do rozmów w restauracji, hotelu czy na lotnisku!",
        "15 minut nauki zestawów dziennie zwiększa Twoje słownictwo o 200 słów miesięcznie!",
        "Nasz system inteligentnych powtórek pomaga zapamiętać 90% nowego słownictwa!",
        "Regularne ćwiczenie zestawów buduje pewność siebie w mówieniu - zauważysz różnicę!",
        "Zestawy z prawdziwymi wyrażeniami idiomatycznymi wzbogacą Twój język o naturalne zwroty!",
        "Nasze zestawy gramatyczne pomagają zrozumieć trudne konstrukcje w prosty sposób!",
        "Zestawy dla podróżujących zawierają kluczowe zwroty niezbędne podczas wyjazdów!",
        "Praktykuj słuchanie ze zrozumieniem dzięki zestawom z nagraniami!",
        "Interaktywne ćwiczenia w zestawach rozwijają wszystkie umiejętności językowe jednocześnie!",
        "Nasze zestawy są tworzone przez ekspertów językowych!"
    ];

    constructor() { }

    getRandomMessage(): string {
        const randomIndex = Math.floor(Math.random() * this.messages.length);
        return this.messages[randomIndex];
    }

    getShortMessage(): string {
        // Filter for shorter messages (less than 60 characters)
        const shortMessages = this.messages.filter(msg => msg.length < 60);
        if (shortMessages.length === 0) return this.getRandomMessage();

        const randomIndex = Math.floor(Math.random() * shortMessages.length);
        return shortMessages[randomIndex];
    }

    getLongMessage(): string {
        // Filter for longer messages (more than 100 characters)
        const longMessages = this.messages.filter(msg => msg.length > 100);
        if (longMessages.length === 0) return this.getRandomMessage();

        const randomIndex = Math.floor(Math.random() * longMessages.length);
        return longMessages[randomIndex];
    }
} 