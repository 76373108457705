import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { wordLists } from './autocorrect-dictionary';

@Injectable({
    providedIn: 'root'
})
export class TextManipulationService {
    private correctedTextSubject: BehaviorSubject<string>;
    private suggestionsSubject: BehaviorSubject<string[]>;
    private debouncedTextSubject: BehaviorSubject<string>;
    private textState: string;
    cursorPosition: number;
    private suggestionCache: Map<string, string[]> = new Map();

    constructor() {
        this.correctedTextSubject = new BehaviorSubject<string>('');
        this.suggestionsSubject = new BehaviorSubject<string[]>([]);
        this.debouncedTextSubject = new BehaviorSubject<string>('');
        this.textState = '';
        this.cursorPosition = 0;

        this.debouncedTextSubject.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(text => this.generateSuggestions(text))
        ).subscribe(suggestions => {
            this.suggestionsSubject.next(suggestions);
        });
    }

    autocorrectText(text: string): string {
        return null;
    }

    // text-manipulation.service.ts

    suggestionSelected(text: string) {
        let words = this.textState.split(' ');
        let correctedWord = text;
        let currentPos = 0;
        let wordIndex = -1;

        // Find the word at the cursor position
        for (let i = 0; i < words.length; i++) {
            currentPos += words[i].length + 1; // +1 for the space
            if (this.cursorPosition < currentPos) {
                wordIndex = i;
                break;
            }
        }

        if (wordIndex !== -1) {
            let wordToReplace = words[wordIndex];
            if (wordToReplace.charAt(0) === wordToReplace.charAt(0).toUpperCase()) {
                correctedWord = correctedWord.charAt(0).toUpperCase() + correctedWord.slice(1);
            }
            words[wordIndex] = correctedWord;
            // Removed the extra space at the end
            this.textState = words.join(' ').trim() + ' ';
        }

        this.suggestionsSubject.next([]);
        this.correctedTextSubject.next(this.textState);
        this.cursorPosition = this.textState.length;
    }

    resetTextState() {
        this.textState = '';
        this.suggestionsSubject.next([]);
        this.correctedTextSubject.next('');
    }

    getCorrectedText(): Observable<string> {
        return this.correctedTextSubject.asObservable();
    }

    getSuggestions(text: string): void {
        this.debouncedTextSubject.next(text);
    }

    private generateSuggestions(text: string): Observable<string[]> {
        return new Observable(observer => {
            if (!text) {
                observer.next([]);
                observer.complete();
                return;
            }

            const cacheKey = text.toLowerCase();
            if (this.suggestionCache.has(cacheKey)) {
                observer.next(this.suggestionCache.get(cacheKey)!);
                observer.complete();
                return;
            }

            const words = text.split(' ');
            const lastWord = words[words.length - 1].toLowerCase();
            if (!lastWord) {
                observer.next([]);
                observer.complete();
                return;
            }

            const suggestionsMap = new Map<string, number>();
            const maxDistance = Math.max(2, Math.floor(lastWord.length / 3));

            wordLists.forEach(entry => {
                const variants = [entry.word.toLowerCase(), ...entry.misspellings.map(m => m.toLowerCase())];
                for (const variant of variants) {
                    const distance = this.levenshteinDistanceOptimized(lastWord, variant, maxDistance);
                    if (distance <= maxDistance && (!suggestionsMap.has(entry.word) || distance < suggestionsMap.get(entry.word)!)) {
                        suggestionsMap.set(entry.word, distance);
                    }
                }
            });

            const suggestions = Array.from(suggestionsMap.entries())
                .sort((a, b) => a[1] - b[1])
                .map(entry => entry[0])
                .slice(0, 4);

            this.suggestionCache.set(cacheKey, suggestions);
            observer.next(suggestions);
            observer.complete();
        });
    }

    getSuggestionsObservable(): Observable<string[]> {
        return this.suggestionsSubject.asObservable();
    }

    removeExtraSpaces(text: string): string {
        return text.replace(/\s+/g, ' ').trim();
    }

    capitalizeFirstLetter(text: string): string {
        if (!text) return text;
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    addCharacter(char: string, cursorPosition?: number): void {
        if (cursorPosition !== undefined && cursorPosition >= 0 && cursorPosition <= this.textState.length) {
            this.cursorPosition = cursorPosition;
        }

        this.textState = this.textState.slice(0, this.cursorPosition) + char + this.textState.slice(this.cursorPosition);
        this.cursorPosition += char.length;

        const wordAtCursor = this.getWordAtCursor();
        this.getSuggestions(wordAtCursor);
        this.correctedTextSubject.next(this.textState);
    }

    removeCharacter(): void {
        if (this.cursorPosition > 0) {
            this.textState = this.textState.slice(0, this.cursorPosition - 1) + this.textState.slice(this.cursorPosition);
            this.cursorPosition--;

            const wordAtCursor = this.getWordAtCursor();
            if (this.textState === '') {
                this.suggestionsSubject.next([]);
            } else {
                this.getSuggestions(wordAtCursor);
            }

            this.correctedTextSubject.next(this.textState);
        }
    }

    setCursorPosition(position: number): void {
        if (position >= 0 && position <= this.textState.length) {
            this.cursorPosition = position;
            const currentWord = this.getCurrentWord(position);
            this.getSuggestions(currentWord);
        }
    }

    public getCurrentWord(position: number): string {
        const textBeforeCursor = this.textState.slice(0, position);
        const textAfterCursor = this.textState.slice(position);
        const start = textBeforeCursor.lastIndexOf(' ') + 1;
        const end = textAfterCursor.indexOf(' ') === -1 ? textAfterCursor.length : textAfterCursor.indexOf(' ');
        return this.textState.slice(start, position + end);
    }

    private getWordAtCursor(): string {
        const words = this.textState.split(/\s+/);
        let cursorIndex = 0;
        for (const word of words) {
            cursorIndex += word.length;
            if (cursorIndex >= this.cursorPosition) {
                return word;
            }
            cursorIndex++; // for the space
        }
        return '';
    }

    private levenshteinDistanceOptimized(a: string, b: string, maxDistance: number): number {
        if (Math.abs(a.length - b.length) > maxDistance) return maxDistance + 1;

        const matrix = Array(2).fill(null).map(() => Array(b.length + 1).fill(0));
        for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

        for (let i = 1; i <= a.length; i++) {
            const curr = i % 2;
            const prev = (i - 1) % 2;
            matrix[curr][0] = i;

            let minDistance = maxDistance + 1;
            for (let j = 1; j <= b.length; j++) {
                const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[curr][j] = Math.min(
                    matrix[prev][j] + 1,
                    matrix[curr][j - 1] + 1,
                    matrix[prev][j - 1] + cost
                );
                minDistance = Math.min(minDistance, matrix[curr][j]);
            }
            if (minDistance > maxDistance) return maxDistance + 1;
        }

        return matrix[a.length % 2][b.length];
    }
}
