import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, from, shareReplay, of } from 'rxjs';

export interface KeyboardPrediction {
    predictions: string[];
}

export interface KeyboardSuggestions {
    suggestions: string[];
}

@Injectable({
    providedIn: 'root'
})
export class KeyboardWorkerService implements OnDestroy {
    private worker: Worker | null = null;
    private readonly suggestionCache = new Map<string, string[]>();
    private readonly predictionCache = new Map<string, string[]>();
    private readonly MAX_CACHE_SIZE = 100;
    private readonly CACHE_EXPIRY_TIME = 5 * 60 * 1000; // 5 minutes
    private readonly cacheTimestamps = new Map<string, number>();

    private readonly suggestionSubject = new Subject<KeyboardSuggestions>();
    private readonly predictionSubject = new Subject<KeyboardPrediction>();

    // Preload keyboard layouts
    private readonly layouts$ = from(this.preloadLayouts()).pipe(
        shareReplay(1)
    );

    constructor() {
        this.initializeWorker();
    }

    private initializeWorker(): void {
        if (typeof Worker !== 'undefined') {
            try {
                // Use dynamic import for the worker
                this.worker = new Worker(new URL('../workers/keyboard.worker', import.meta.url), { type: 'module' });
                this.setupWorkerListeners();
            } catch (error) {
                console.error('Failed to initialize Web Worker:', error);
                this.worker = null;
            }
        } else {
            console.warn('Web Workers are not supported in this environment');
        }
    }

    private setupWorkerListeners(): void {
        if (!this.worker) return;

        this.worker.onmessage = ({ data }) => {
            switch (data.type) {
                case 'suggestions':
                    this.suggestionSubject.next(data);
                    this.cacheResults('suggestions', data.text, data.suggestions);
                    break;
                case 'predictions':
                    this.predictionSubject.next(data);
                    this.cacheResults('predictions', data.currentText, data.predictions);
                    break;
            }
        };

        this.worker.onerror = (error) => {
            console.error('Web Worker error:', error);
            // Attempt to reinitialize worker on error
            this.worker?.terminate();
            this.initializeWorker();
        };
    }

    getSuggestions(text: string, maxDistance: number = 2): Observable<KeyboardSuggestions> {
        const cached = this.getCachedResult('suggestions', text);
        if (cached) {
            return of({ suggestions: cached });
        }

        if (this.worker) {
            this.worker.postMessage({
                type: 'getSuggestions',
                text,
                maxDistance
            });
            return this.suggestionSubject.asObservable();
        }

        // Fallback when worker is not available
        return of({ suggestions: [] });
    }

    getPredictions(currentText: string, lastKeys: string[]): Observable<KeyboardPrediction> {
        const cacheKey = `${currentText}-${lastKeys.join('')}`;
        const cached = this.getCachedResult('predictions', cacheKey);
        if (cached) {
            return of({ predictions: cached });
        }

        if (this.worker) {
            this.worker.postMessage({
                type: 'predictNextKey',
                currentText,
                lastKeys
            });
            return this.predictionSubject.asObservable();
        }

        // Fallback when worker is not available
        return of({ predictions: [] });
    }

    getPreloadedLayouts(): Observable<any> {
        return this.layouts$;
    }

    private async preloadLayouts(): Promise<any> {
        const layouts = {
            default: [/* default layout */],
            special: [/* special characters */],
            emoji: [/* emoji layout */]
        };
        return layouts;
    }

    private cacheResults(type: 'suggestions' | 'predictions', key: string, results: string[]): void {
        const cache = type === 'suggestions' ? this.suggestionCache : this.predictionCache;

        if (cache.size >= this.MAX_CACHE_SIZE) {
            let oldestKey = '';
            let oldestTime = Date.now();

            this.cacheTimestamps.forEach((time, k) => {
                if (time < oldestTime) {
                    oldestTime = time;
                    oldestKey = k;
                }
            });

            cache.delete(oldestKey);
            this.cacheTimestamps.delete(oldestKey);
        }

        cache.set(key, results);
        this.cacheTimestamps.set(key, Date.now());

        this.cleanExpiredCache();
    }

    private getCachedResult(type: 'suggestions' | 'predictions', key: string): string[] | null {
        const cache = type === 'suggestions' ? this.suggestionCache : this.predictionCache;
        const timestamp = this.cacheTimestamps.get(key);

        if (timestamp && Date.now() - timestamp < this.CACHE_EXPIRY_TIME) {
            return cache.get(key) || null;
        }

        if (timestamp) {
            cache.delete(key);
            this.cacheTimestamps.delete(key);
        }

        return null;
    }

    private cleanExpiredCache(): void {
        const now = Date.now();
        this.cacheTimestamps.forEach((timestamp, key) => {
            if (now - timestamp >= this.CACHE_EXPIRY_TIME) {
                this.suggestionCache.delete(key);
                this.predictionCache.delete(key);
                this.cacheTimestamps.delete(key);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.worker) {
            this.worker.terminate();
        }
        this.suggestionSubject.complete();
        this.predictionSubject.complete();
    }
}
