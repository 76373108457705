import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { SetsService } from '../../services/sets.service';

@Component({
  selector: 'app-choose-custom-set',
  templateUrl: './choose-custom-set.component.html',
  styleUrls: ['./choose-custom-set.component.css']
})
export class ChooseCustomSetComponent implements OnInit {
  interval
  usersSets = []
  sub: any;
  constructor(public modalController: ModalController,
    private userDetailsService: UserDetailsService,
    private setsService: SetsService) {

  }

  ngOnInit() {
    this.sub = this.userDetailsService.usersCustomSetsListener().subscribe((sets) => {
      if (this.usersSets.length !== sets.length) {
        clearInterval(this.interval)
      }
      this.usersSets = sets;

    }
    )
    this.userDetailsService.getUsersCustomSets(this.userDetailsService.getSimpleUserDetails()?.id);


  }

  addUsersSet() {
    if (this.usersSets.length < 12) {
      let name = prompt('Podaj nazwę zestawu');
      if (name) {

        this.setsService.addUsersSet(name, {
          name: this.userDetailsService.getSimpleUserDetails().name,
          id: this.userDetailsService.getSimpleUserDetails().id,
          role: this.userDetailsService.getSimpleUserDetails().role,
        },);
        this.interval = setInterval(() => {
          this.userDetailsService.getUsersCustomSets(this.userDetailsService.getSimpleUserDetails()?.id);
        }, 500);
      }
      else {
        alert("Nie podano nazwy zestawu")
      }
    }
    else {
      alert("Maksymalna liczba własnych zestawów to 12")
    }
  }
  chooseSet(set) {

    if (set.items.length < 87) {
      this.modalController.dismiss(set.id);
    }
    else {
      alert("Maksymalna liczba słów w zestawie to 86")
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
