import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { DragulaService } from 'ng2-dragula';
import { DisassembledTextConstructorComponent } from 'src/app/shared/components/disassembled-text-constructor/disassembled-text-constructor.component';
import { ChatService } from 'src/app/shared/services/chat.service';
import { QuizParamsService } from 'src/app/shared/services/quiz-params.service';
import { ReadTextService } from 'src/app/shared/services/read-text.service';
import { SpeechToTextService } from 'src/app/shared/services/speech-recognition.service';
import { ChatQuestionComponent } from './chat-question/chat-question.component';

@Component({
  selector: 'app-answer-summary-modal',
  templateUrl: './answer-summary-modal.component.html',
  styleUrls: ['./answer-summary-modal.component.scss'],
})
export class AnswerSummaryModalComponent implements OnInit {
  @ViewChild('area') area: ElementRef;
  data;
  dismissPossible = false;
  lang;
  textToRead = '';
  readCount = 0;
  sub: any;
  sub2: any;
  rateLettersPerSecond = 5;
  estimatedReadingTime = 0;
  translatingDisabled = false;
  translating = false;
  chosenAnswer: string = null;
  timeout;
  showPolishTranslation: boolean;
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private readTextService: ReadTextService,
    private chatService: ChatService,
    private dragulaService: DragulaService,
    private speechToTextService: SpeechToTextService,
    private router: Router,
    private quizParamsService: QuizParamsService
  ) { }
  ngAfterViewInit(): void { }
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {

        this.modalCtrl.dismiss(null, 'next');
      }
    });
    this.textToRead = this.data.filledQuestion
      ? this.data.filledQuestion
      : this.data.question?.word1
        ? this.data.question.word1 + ' ' + this.data.question.word2
        : this.data.question?.correctAnswer;
    if (this.textToRead.length > 7) {
      this.rateLettersPerSecond = 7;
    }
    if (this.textToRead.length > 13) {
      this.rateLettersPerSecond = 10;
    }
    this.estimatedReadingTime =
      this.textToRead.length / this.rateLettersPerSecond;



    // Only set auto-dismiss timeout if not coming from speech recognition
    if (!this.data.fromSpeaking) {
      this.timeout = setTimeout(() => {
        this.dismissPossible = true;
      }, this.estimatedReadingTime * 1000);
    } else {
      // For speech recognition, enable dismiss immediately
      this.dismissPossible = true;
    }

    this.sub = this.chatService.chatMessagesListener().subscribe((message: any) => {
      if (message) {
        this.translating = false;
        this.translatingDisabled = true;
        this.showPolishTranslation = true;

        // Extract the translation from the chat service response
        if (message.res && message.res.choices && message.res.choices[0] && message.res.choices[0].message) {
          try {
            // Try to parse the response as JSON if it's in JSON format
            const content = message.res.choices[0].message.content;
            const jsonMatch = content.match(/{.*?}/);
            if (jsonMatch) {
              const jsonContent = JSON.parse(jsonMatch[0]);
              if (jsonContent.translation) {
                this.data.question.sentenceTranslation = jsonContent.translation;
              } else {
                this.data.question.sentenceTranslation = content;
              }
            } else {
              // If not in JSON format, use the raw content
              this.data.question.sentenceTranslation = content;
            }
          } catch (e) {
            // If parsing fails, use the raw content
            this.data.question.sentenceTranslation = message.res.choices[0].message.content;
          }
        }
      }
    });
    if (this.data.autoDismiss) {
      setTimeout(() => {
        return this.modalCtrl.dismiss(null, 'next');
      }, 100);
    }
    this.sub2 = this.readTextService
      .readingFinishedListener()
      .subscribe((finished) => {
        if (finished) {
          this.readCount++;
          if (this.readCount == 1) {
            // this.dismissPossible = true;
            // this.readTranslation();
          }
        }
      });
    this.data = this.navParams.get('data');
    this.lang = this.data.question?.sentenceAudioES?.length > 0 ? 'es' : 'en';

    this.readTextService.stopReading();
    if (!this.data.question.freeSetItem) {
      this.readWord();
    } else {
      this.data.question?.termAndDefinitionAudioUS.length > 0
        ? this.readTermAndDefinition()
        : this.readDefinition();
    }
  }

  next() {
    if (this.dismissPossible) {
      this.speechToTextService.speakingTry = 0;
      return this.modalCtrl.dismiss(null, 'next');
    }
  }

  again() {
    this.speechToTextService.speakingTry++;
    return this.modalCtrl.dismiss(null, 'again');
  }

  readWord() {
    this.readTextService.stopReading();
    this.readTextService.readTextFromBlob(
      this.lang == 'es'
        ? this.data?.question?.wordAudioES
        : this.data?.question?.wordAudio,
      this.lang == 'es'
        ? this.data?.question?.wordAudioESDownloaded
        : this.data?.question?.wordAudioDownloaded,

      this.data.question.word1 + ' ' + this.data.question.word2,
      null
    );
  }
  readTranslation() {
    this.readTextService.stopReading();
    this.readTextService.readTextFromBlob(
      this.data.question.polishAudio,
      this.data.question.polishAudioDownloaded,
      this.data.question.translation,
      this.data.question.translation
    );
  }
  translateSentence() {
    // Check if we have a translation in the database
    if (this.data.question.sentenceTranslation) {
      this.showPolishTranslation = true;
    } else {
      // If no translation in database, use the chat service
      this.translating = true;
      this.chatService.translateToPolishWithChat(
        this.data.question.sentence
          ? this.data.question.sentence
          : this.data.question.filledQuestion
            ? this.data.question.filledQuestion
            : this.data.question.correctAnswer
      );
      setTimeout(() => {
        if (this.translatingDisabled && this.translating) {
          this.translating = false;
          this.translatingDisabled = false;
          alert('Ups, straciliśmy na chwilę połączenie. Spróbuj ponownie.');
        }
      }, 5000);
    }
  }
  readTerm() {
    this.readTextService.stopReading();
    this.readTextService.readTextFromBlob(
      this.data.question.termAudioUs,
      this.data.question.termAudioUsDownloaded,
      this.data.question.question,
      null
    );
  }
  readDefinition() {
    this.readTextService.stopReading();
    this.readTextService.readTextFromBlob(
      this.data.question.definitionAudioUS,
      this.data.question.definitionAudioUSDownloaded,
      this.data.question.correctAnswer,
      null
    );
  }
  readTermAndDefinition() {
    this.readTextService.stopReading();
    // this.readTextService.readTextFromBlob(
    //   this.data.question.termAndDefinitionAudioUS,
    //   this.data.question.termAndDefinitionAudioUSDownloaded,
    //   this.data.question.correctAnswer,
    //   null
    // );
  }
  readSentence() {
    this.readTextService.stopReading();
    this.readTextService.readTextFromBlob(
      this.lang == 'es'
        ? this.data?.question?.sentenceAudioES
        : this.data?.question?.sentenceAudio,
      this.lang == 'es'
        ? this.data?.question?.sentenceAudioESDownloaded
        : this.data?.question?.sentenceAudioDownloaded,

      this.data.question.word1 + ' ' + this.data.question.word2,
      null
    );
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
    this.dragulaService.destroy('TRANSLATION_GROUP');
    clearTimeout(this.timeout);
  }

  async openChatQuestionModal() {
    const sentence = this.data.question.sentence
      ? this.data.question.sentence
      : this.data.question.filledQuestion
        ? this.data.question.filledQuestion
        : this.data.question.correctAnswer;

    const modal = await this.modalCtrl.create({
      component: ChatQuestionComponent,
      componentProps: {
        sentence: sentence,
        question: this.data.question.word1 + ' ' + this.data.question.word2
      }
    });

    return await modal.present();
  }
}
