import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HelpersService } from '../../shared/services/helpers.service';
import { SetsService } from '../../shared/services/sets.service';
import { datadogRum } from '@datadog/browser-rum';
import { RtcService } from 'src/app/shared/services/rtc.service';
import { Router } from '@angular/router';
import { version } from '../../../environments/version';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { QuizParamsService } from 'src/app/shared/services/quiz-params.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  smallChild = false;
  mediumChild = false;
  largeChild = false;
  customer = false;
  userDetails: any;
  getSetsAllowed = true;
  userDetailsAvailable = false;
  userDetailsBearer = new Subject<any>();
  private lastEmittedDetails: any = null;
  currentSet;
  usersSets = []
  interval;
  private url: string;
  version = version;
  private profileSub: Subscription;
  currentSetStats: any;
  asd: any;
  alsoStudent: boolean;
  timer;
  sub: Subscription;
  latestSetsUpdated = new Subject<any>();
  finishedSetsUpdated = new Subject<any>();
  unfinishedSetsUpdated = new Subject<any>();
  usersCustomSets = new Subject<any>();
  ageStatus: string;

  difficultyLevel: number;
  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private authService: AuthenticationService,
    private setsService: SetsService,
    private spinnerService: SpinnerService,
    private helpers: HelpersService,
  ) {
    document.addEventListener('visibilitychange', () =>
      this.handleVisibilityChange()
    );

    this.url = platform.url;
    if (!this.profileSub) {
      this.profileSub = this.authService.profile().subscribe((user) => {


        if (user.userData) {


          // if (user.role == 'customer' && !user.userData?.alsoStudent) {
          //   this.router.navigateByUrl('/customer-panel');
          //   return;
          // } else {
          // this.router.navigateByUrl('/home/profile');
          // }

        }
        if (user?.role === 'customer') {
          this.customer = true;
        }
        if (user?.userData?.alsoStudent) {
          this.alsoStudent = true;
        }

        this.storeUserDetails(user);

        datadogRum.setUser({
          name: user.name,
        });
        if (this.platform.env !== 'dev') {
          datadogRum.init({
            applicationId: '200e3c6b-26ff-4b39-9bbe-c9d2f308daaf',
            clientToken: 'pub00436916c5ed35c4e66876f11b0bc130',
            site: 'datadoghq.eu',
            service: 'ionic',
            env: '<ENV_NAME>',
            // Specify a version number to identify the deployed version of your application in Datadog
            version: this.version,

            sessionSampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
          });
          datadogRum.startSessionReplayRecording();
        }
        this.checkAgeStatus();
        this.checkDifficultyLevel();
      });
    }
    this.setsService.setContent.asObservable().subscribe((setContent: any) => {
      if (this.userDetails?.userData?.sets) {
        let setToReplace = this.userDetails.userData.sets.find(
          (set) => set.parentSet === setContent.parentSet
        );
        if (setToReplace) {
          setToReplace = setContent;
          // Only emit if details have changed
          if (JSON.stringify(this.userDetails) !== JSON.stringify(this.lastEmittedDetails)) {
            this.lastEmittedDetails = { ...this.userDetails };
            this.userDetailsBearer.next(this.userDetails);
          }
        }
      }
    });
  }
  private handleVisibilityChange(): void {
    const currentTime = new Date().getTime();

    if (document.hidden) {
      // Store the current time when the tab goes inactive
      localStorage.setItem('inactiveTime', currentTime.toString());

    } else {
      // Retrieve the stored time and calculate the duration of inactivity
      const inactiveTime = localStorage.getItem('inactiveTime');

      if (inactiveTime) {
        const duration = currentTime - Number(inactiveTime);

        // Check if the tab has been inactive for more than 5 seconds (5000 milliseconds)
        if (duration > 300000) {

          if (window.location.pathname !== '/home/chatbots') {
            window.location.reload();
          }
        }

        // Remove the stored time
        localStorage.removeItem('inactiveTime');
      }
    }
  }

  async getUsersGroups() {
    let usersGroups = this.userDetails.userData.groups;
    try {
      const response: any = await this.http
        .post(
          this.url + '/api/groups/get-users-groups',
          { groupsIds: usersGroups },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .toPromise();

      this.userDetails.userData.groupsNames = response?.groups.map(
        (group) => group.data.name
      );

      this.userDetailsBearer.next(this.userDetails);
      return response.groups.map(
        (group) => group.data.name
      );
    } catch (error) {
      console.error(error);
    }
  }
  getUsersProgress(usersIds) {
    this.http
      .post(this.url + '/api/users/get-users-progress/', usersIds, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {

      });
  }

  resetProgress() {
    let numberMilisecondsFromLastReset =
      Date.now() - this.userDetails.userData.lastResetProgress;


    if (numberMilisecondsFromLastReset < 0) {
      numberMilisecondsFromLastReset = -numberMilisecondsFromLastReset;
    }
    if (numberMilisecondsFromLastReset > 604800000 || !this.userDetails.userData.lastResetProgress) {
      this.spinnerService.showSpinner();
      console.error('resetting progress');
      this.http
        .put(
          this.url + '/api/users/reset-weekly-progress/' + this.userDetails._id,
          {
            headers: {
              Authorization: `Bearer ${this.authService.getToken()}`,
            },
          }
        )
        .subscribe((response) => {

        }),
        (error) => {

        };

      setTimeout(() => {
        window.location.reload();
        this.spinnerService.hideSpinner();
      }, 3000);
    }
  }
  addusersCollectableItems(userId, collectableItem) {
    const url = this.url + '/api/users/adduserscollectableitems/' + userId;

    this.http
      .put(
        this.url + '/api/users/adduserscollectableitems/' + userId,
        { collectableItem: collectableItem },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => { });
  }
  storeUserDetails(userDetails) {
    this.userDetails = userDetails;
    this.userDetailsAvailable = true;
    this.smallChild = userDetails?.userData?.ageStatus === 'smallChild';
    this.mediumChild = userDetails?.userData?.ageStatus === 'mediumChild';
    this.largeChild = userDetails?.userData?.ageStatus === 'largeChild';
    this.ageStatus = userDetails?.userData?.ageStatus;
    this.difficultyLevel = userDetails?.userData?.difficultyLevel;

    // Ensure userData object exists
    if (!this.userDetails.userData) {
      this.userDetails.userData = {};
    }

    // Initialize conversationTime if it doesn't exist
    if (this.userDetails.userData.conversationTime === undefined) {
      this.userDetails.userData.conversationTime = 0;
      // Update the backend if we have a user ID
      if (this.userDetails._id) {
        this.setUsersProp(this.userDetails._id, 'conversationTime', 0);
      }
    }

    // Ensure learningStreak is initialized correctly
    if (!this.userDetails.userData.learningStreak) {

      this.userDetails.userData.learningStreak = Array(7).fill(0);
      // Update the backend if we have a user ID
      if (this.userDetails._id) {
        this.setUsersProp(this.userDetails._id, 'learningStreak', this.userDetails.userData.learningStreak);
      }
    } else if (!Array.isArray(this.userDetails.userData.learningStreak) ||
      this.userDetails.userData.learningStreak.length !== 7) {
      console.warn('Invalid learningStreak data format', this.userDetails.userData.learningStreak);
      this.userDetails.userData.learningStreak = Array(7).fill(0);
      // Update the backend if we have a user ID
      if (this.userDetails._id) {
        this.setUsersProp(this.userDetails._id, 'learningStreak', this.userDetails.userData.learningStreak);

      }
    } else {
      // Check if we need to shift the data based on days elapsed since last update
      if (this.userDetails.userData.lastStreakUpdate) {
        const today = new Date();
        const lastUpdateDate = new Date(this.userDetails.userData.lastStreakUpdate);

        // Calculate days difference
        const daysDiff = Math.floor((today.getTime() - lastUpdateDate.getTime()) / (1000 * 60 * 60 * 24));

        // If it's been more than 0 days but less than 7, shift the data
        if (daysDiff > 0 && daysDiff < 7) {


          // Create a new array with shifted data
          const newStreak = Array(7).fill(0);
          for (let i = 0; i < 7; i++) {
            // Calculate the old index, accounting for the shift
            const oldIndex = (i - daysDiff + 7) % 7;
            if (oldIndex >= 0 && oldIndex < 7) {
              newStreak[i] = this.userDetails.userData.learningStreak[oldIndex];
            }
          }

          // Update the learningStreak array
          this.userDetails.userData.learningStreak = newStreak;

          // Update the lastStreakUpdate to today
          this.userDetails.userData.lastStreakUpdate = today.toISOString();

          // Update in backend
          if (this.userDetails._id) {
            this.setUsersProp(this.userDetails._id, 'learningStreak', newStreak);
            this.setUsersProp(this.userDetails._id, 'lastStreakUpdate', today.toISOString());

          }
        } else if (daysDiff >= 7) {
          // If it's been 7 or more days, reset the streak
          this.userDetails.userData.learningStreak = Array(7).fill(0);
          this.userDetails.userData.lastStreakUpdate = today.toISOString();

          // Update in backend
          if (this.userDetails._id) {
            this.setUsersProp(this.userDetails._id, 'learningStreak', this.userDetails.userData.learningStreak);
            this.setUsersProp(this.userDetails._id, 'lastStreakUpdate', today.toISOString());

          }
        }
      }
    }

    // If lastStreakUpdate is missing but weeklyProgress exists, initialize lastStreakUpdate
    if (!this.userDetails.userData.lastStreakUpdate && this.userDetails.userData.weeklyProgress > 0) {
      // Initialize with a more accurate timestamp if possible, otherwise use current time
      const latestActivityTimestamp = this.determineLatestActivityTimestamp();
      this.userDetails.userData.lastStreakUpdate = latestActivityTimestamp?.toISOString() || new Date().toISOString();
      // Update the backend if we have a user ID
      if (this.userDetails._id) {
        this.setUsersProp(this.userDetails._id, 'lastStreakUpdate', this.userDetails.userData.lastStreakUpdate);
      }
    }

    // Only update streak if we have a valid lastStreakUpdate
    if (this.userDetails.userData.weeklyProgress > 0 &&
      this.userDetails.userData.learningStreak.every(val => val === 0) &&
      this.userDetails.userData.lastStreakUpdate) {

      // Use the lastStreakUpdate to determine when the learning occurred
      const lastUpdateDate = new Date(this.userDetails.userData.lastStreakUpdate);
      const standardDayIndex = lastUpdateDate.getDay(); // Standard day index (0 = Sunday)

      // Convert to the UI display order: 0 = Wednesday, 1 = Thursday, ..., 6 = Tuesday
      const daysSinceWednesday = (standardDayIndex - 3 + 7) % 7;
      const uiOrderIndex = daysSinceWednesday;

      // Check if lastStreakUpdate is recent
      const currentDate = new Date();
      const hoursDiff = Math.floor((currentDate.getTime() - lastUpdateDate.getTime()) / (1000 * 60 * 60));

      if (hoursDiff < 24 * 7) { // If update was within the last week
        const updatedStreak = [...this.userDetails.userData.learningStreak];
        updatedStreak[uiOrderIndex] = this.userDetails.userData.weeklyProgress;



        // Update locally
        this.userDetails.userData.learningStreak = updatedStreak;

        // Update on server (only if we have an ID)
        if (this.userDetails._id) {
          this.setUsersProp(this.userDetails._id, 'learningStreak', updatedStreak);

        }
      }
    }

    // Save a copy of the current state for comparison in subsequent updates
    this.lastEmittedDetails = JSON.parse(JSON.stringify(userDetails));
    this.userDetailsBearer.next(userDetails);

    // Only proceed with additional operations for non-customer users
    if (!(this.userDetails.role == 'customer' && !this.userDetails.userData?.alsoStudent)) {
      // Only get latest sets if this is a new user details object
      if (JSON.stringify(userDetails) !== JSON.stringify(this.lastEmittedDetails)) {
        this.getLatestSets(userDetails._id ? userDetails._id : userDetails.id);
      }

      if (userDetails?.role === 'student' || userDetails?.userData?.alsoStudent) {
        this.resetProgress();
      }
    }
  }

  // Helper method to determine when the latest learning activity occurred
  private determineLatestActivityTimestamp(): Date | null {
    if (!this.userDetails?.userData?.sets) {
      return null;
    }

    // Try to find the most recent activity timestamp from the sets
    let latestTimestamp = 0;

    this.userDetails.userData.sets.forEach(set => {
      if (set?.stats?.lastQuestionTime && set.stats.lastQuestionTime > latestTimestamp) {
        latestTimestamp = set.stats.lastQuestionTime;
      }
    });

    if (latestTimestamp > 0) {
      return new Date(latestTimestamp);
    }

    return null;
  }

  clearUserDetails() {
    this.userDetails = null;
    this.userDetailsAvailable = false;
    this.userDetailsBearer.next(null);
    this.currentSet = null;
  }
  getUserDetailsPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userDetails) {
        this.userDetailsBearer.next(this.userDetails);
        resolve(this.userDetails);
      }
      if (!this.userDetails) {
        if (!this.sub) {
          this.sub = this.authService.profile().subscribe((user) => {
            this.userDetails = user;
            this.userDetailsBearer.next(this.userDetails);
            this.sub.unsubscribe();
            resolve(this.userDetails);
          }, error => {
            reject(error);
          });
        }
      }
    });
  }
  getUserDetails() {
    if (this.userDetails) {
      // Only emit if details have changed
      if (JSON.stringify(this.userDetails) !== JSON.stringify(this.lastEmittedDetails)) {
        this.lastEmittedDetails = { ...this.userDetails };
        this.userDetailsBearer.next(this.userDetails);
      }
      return this.userDetails;
    }

    // Fetch new details only if we don't have an active subscription
    if (!this.sub) {
      this.sub = this.authService.profile().subscribe((user) => {
        this.storeUserDetails(user);
        this.sub.unsubscribe();
      });
    }

    return null;
  }

  checkAgeStatus() {
    if (this.userDetails?.userData?.ageStatus) {
      switch (this.userDetails.userData.ageStatus) {
        case 'smallChild':
          this.smallChild = true;
          this.ageStatus = 'smallChild';
          break;
        case 'mediumChild':
          this.mediumChild = true;
          this.ageStatus = 'mediumChild';
          break;
        case 'largeChild':
          this.largeChild = true;
          this.ageStatus = 'largeChild';
          break;
        default:
          break;
      }
      return this.userDetails.userData.ageStatus;
    }
    return null;
  }

  checkDifficultyLevel() {
    if (this.userDetails?.userData?.difficultyLevel) {
      this.difficultyLevel = Number(this.userDetails.userData.difficultyLevel);
      return this.difficultyLevel;
    }
    return null;
  }
  getSimpleUserDetails() {
    if (this.userDetails) {
      return {
        id: this.userDetails.id ? this.userDetails.id : this.userDetails._id,
        name: this.userDetails.name,
        role: this.userDetails.role,
        avatar: this.userDetails.avatar ? this.userDetails.avatar : null,
      };
    }
    return null;
  }

  storeCurrentSet(set) {
    this.currentSet = set;
    let currentSetInUserSets = this.userDetails.userData.sets.find(
      (_set) => _set?.parentSet === set.parentSet || _set?.id === set.id
    );
    if (currentSetInUserSets) {
      currentSetInUserSets = set;
    } else {
      this.userDetails.userData.sets.push(set);
    }
    this.userDetailsBearer.next(this.userDetails);
  }
  storeCurrentSetsStats(stats) {
    this.currentSetStats = stats;
    this.userDetailsBearer.next(this.userDetails);
  }
  getCurrentSetsStats() {
    return this.currentSetStats;
  }

  getCurrentSet() {
    return this.currentSet;
  }

  getUsersSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/getuserssetswithgroupssets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe((response: any) => {
          response.sets.forEach((set) => {
            if (set?.stats?.quizParams?.lastLearned) {
              set.lastLearned = set?.stats?.quizParams?.lastLearned;
            } else {
              set.lastLearned = 0
            }
          });

          // Store the current sets for comparison
          const currentSets = this.userDetails.userData.sets || [];

          // Update user details with all sets
          this.userDetails.userData.sets = response.sets;

          // Only emit new sets that weren't in the previous set
          const newSets = response.sets.filter(newSet =>
            !currentSets.some(currentSet =>
              currentSet.parentSet === newSet.parentSet || currentSet.id === newSet.id
            )
          );

          if (newSets.length > 0) {
            this.latestSetsUpdated.next(newSets);
          }

          this.userDetails.userData.newSets =
            this.userDetails.userData.sets.filter((set) => set.new);

          // Only emit if details have changed
          if (JSON.stringify(this.userDetails) !== JSON.stringify(this.lastEmittedDetails)) {
            this.lastEmittedDetails = { ...this.userDetails };
            this.userDetailsBearer.next(this.userDetails);
          }
        });
    }
  }
  getLatestSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-latest-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe({
          next: (response: any) => {
            response = response?.filter((set) => set);
            response.forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                if (set) {
                  set.lastLearned = 0
                }
              }
            });
            this.latestSetsUpdated.next(response);
            this.spinnerService.hideSpinner();
          },
          error: (error) => {
            console.error('Error fetching latest sets:', error);
            this.latestSetsUpdated.next([]);
            this.spinnerService.hideSpinner();
          }
        });
    } else {
      this.spinnerService.hideSpinner();
    }
  }
  getFinishedSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-finished-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe({
          next: (response: any) => {
            response.forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                set.lastLearned = 0
              }
            });
            this.finishedSetsUpdated.next(response);
            this.spinnerService.hideSpinner();
          },
          error: (error) => {
            console.error('Error fetching finished sets:', error);
            this.finishedSetsUpdated.next([]);
            this.spinnerService.hideSpinner();
          }
        });
    } else {
      this.spinnerService.hideSpinner();
    }
  }
  getUsersCustomSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-users-custom-sets',
          { id: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe({
          next: (response: any) => {

            response.forEach((set) => {
              if (set?.stats?.quizParams?.lastLearned) {
                set.lastLearned = set?.stats?.quizParams?.lastLearned;
              } else {
                set.lastLearned = 0
              }
            });
            response = response.filter(set => set.name !== null)
            this.helpers.usersSets = response;
            this.usersCustomSets.next(response);
            this.spinnerService.hideSpinner();
          },
          error: (error) => {
            console.error('Error fetching user custom sets:', error);
            this.usersCustomSets.next([]);
            this.spinnerService.hideSpinner();
          }
        });
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  getUnfinishedSets(id) {
    if (id) {
      this.http
        .post(
          this.url + '/api/users/get-unfinished-sets',
          { studentId: id },
          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          }
        )
        .subscribe({
          next: (response: any) => {
            response.

              forEach((set) => {
                if (set?.stats?.quizParams?.lastLearned) {
                  set.lastLearned = set?.stats?.quizParams?.lastLearned;
                } else {
                  set.lastLearned = 0
                }
              });
            this.unfinishedSetsUpdated.next(response);
            this.spinnerService.hideSpinner();
          },
          error: (error) => {
            console.error('Error fetching unfinished sets:', error);
            this.unfinishedSetsUpdated.next([]);
            this.spinnerService.hideSpinner();
          }
        });
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  updateLearningStreak(learningTime: number) {
    if (!learningTime || learningTime <= 0) {
      console.warn('Attempted to update learning streak with invalid time:', learningTime);
      return;
    }

    // Ensure userDetails and userData exist
    if (!this.userDetails || !this.userDetails.userData) {
      console.warn('Cannot update learning streak: userDetails or userData not available');
      return;
    }

    // Initialize streak array if it doesn't exist
    if (!this.userDetails.userData.learningStreak ||
      !Array.isArray(this.userDetails.userData.learningStreak) ||
      this.userDetails.userData.learningStreak.length !== 7) {

      this.userDetails.userData.learningStreak = Array(7).fill(0);
    }

    const today = new Date();
    // Standard JavaScript day mapping: 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const standardDayIndex = today.getDay();



    // Always update the lastStreakUpdate to today to ensure it's current
    this.userDetails.userData.lastStreakUpdate = today.toISOString();

    // Update today's learning time
    const currentStreak = this.userDetails.userData.learningStreak[standardDayIndex] || 0;
    const newTime = currentStreak + learningTime;

    // Create a new array to trigger change detection
    const newStreak = [...this.userDetails.userData.learningStreak];
    newStreak[standardDayIndex] = newTime;



    // Update locally
    this.userDetails.userData.learningStreak = newStreak;

    // Update in backend
    if (this.userDetails._id) {
      // Update both properties together to maintain consistency
      this.setUsersProp(this.userDetails._id, 'learningStreak', newStreak);
      this.setUsersProp(this.userDetails._id, 'lastStreakUpdate', today.toISOString());
    }

    // Emit updated user details immediately
    this.lastEmittedDetails = JSON.parse(JSON.stringify(this.userDetails));
    this.userDetailsBearer.next(this.userDetails);
  }

  getLearningLevel(time: number): 'none' | 'basic' | 'intermediate' | 'advanced' {
    if (!time || time < 300) return 'none'; // < 5 mins
    if (time < 600) return 'basic'; // 5-10 mins
    if (time < 900) return 'intermediate'; // 10-15 mins
    return 'advanced'; // > 15 mins
  }

  setUsersProp(userAppId: string, prop: string, value: any) {
    // Update locally first for immediate UI feedback
    if (this.userDetails && this.userDetails.userData) {
      this.userDetails.userData[prop] = value;

      // Emit updated user details immediately for UI update
      this.lastEmittedDetails = JSON.parse(JSON.stringify(this.userDetails));
      this.userDetailsBearer.next(this.userDetails);
    }

    // Then update in backend
    this.http
      .put(
        `${this.url}/api/users/setusersprop/${userAppId}`,
        { prop: prop, value: value },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe({
        next: (response) => {

        },
        error: (error) => {
          console.error(`Error updating ${prop}:`, error);
        }
      });
  }
  userDetailsListener() {
    return this.userDetailsBearer.asObservable();
  }

  latestSetsUpdatedListener() {
    return this.latestSetsUpdated.asObservable();

  }
  finishedSetsUpdatedListener() {
    return this.finishedSetsUpdated.asObservable();
  }
  unfinishedSetsUpdatedListener() {
    return this.unfinishedSetsUpdated.asObservable();
  }
  usersCustomSetsListener() {
    return this.usersCustomSets.asObservable();
  }

}
