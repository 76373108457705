import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { ChatbotsService } from './chatbots.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TextManipulationService } from '../../services/text-manipulation.service';
import { HelpersService } from '../../services/helpers.service';
import { PlatformService } from '../../services/platform.service';
import { IonMenu, IonModal, IonSelect, IonTextarea, MenuController, ModalController } from '@ionic/angular';
import { ChatbotsConversationsTopicsService } from './chatbots-conversations-topics/chatbots-conversations-topics.service';
import { SpeechToTextService } from '../../services/speech-recognition.service';
import { ReadTextService } from '../../services/read-text.service';
import { ChatbotsCostComponent } from './chatbots-cost/chatbots-cost.component';
import { SpinnerService } from '../../services/spinner.service';
import { ToolbarService } from '../../services/toolbar.service';

@Component({
  selector: 'app-chatbots',
  templateUrl: './chatbots.component.html',
  styleUrls: ['./chatbots.component.scss'],
})
export class ChatbotsComponent implements OnInit {
  @ViewChild('popover') popover!: HTMLIonPopoverElement;
  @ViewChild(IonModal) modal!: IonModal;
  @ViewChild(IonMenu) menu: IonMenu;
  @ViewChild('visualizerCanvas') visualizerCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('messageInput', { static: false }) messageInput: IonTextarea;
  @ViewChild('messageInput', { static: false, read: ElementRef }) messageInputElement: ElementRef;

  private mediaRecorder!: MediaRecorder;
  private recordedChunks: Blob[] = [];
  searchTerm: string = '';
  conversationsStats = [];
  englishLevels = [
    "Nigdy nie uczyłem się języka (help me with the basics)",
    "Znam tylko kilka słów (help me construct basic sentences)",
    "Umiem powiedzieć kilka zdań (help me with grammar)",
    "Porozumiewam się na podstawowym poziomie (help me with vocabulary)",
    "Swobodnie rozmawiam (challenge me lightly)",
    "Uzywam zaawanosowanego języka (challenge me)",
    "Rozmawiam jak Native Speaker (challenge me hard)",
  ];

  name!: string;
  message = '';
  selectedModel = 'gpt-4o-mini';
  isMobile = false;
  silence = false;
  isOpen = false;
  stopSpeakingPossible = false;
  canChangeTutor = false;
  showHints = false;
  readingMode = true;
  activeSpeakingMs = 0;
  voiceInteractionsToday = 0;
  maxAllowedVoiceInteractions = 3;
  voiceInteractionsLocked = false;
  readingNow = false;
  selectedTopic = '';
  selectedLevel = '';
  waitingForTranscription = false;
  chatbots: any;
  mode = 'writing';
  interval
  usersAvatar: any;
  files: File[] = [];
  private silenceThreshold = 0.09;
  private silentFrames = 0;
  private maxSilentFrames = 120;
  conversation: any[] = [];
  audioContext!: AudioContext;
  analyser!: AnalyserNode;
  source!: MediaStreamAudioSourceNode;
  animationId?: number;
  selectedChatbot: any;
  previousChatbotName = 'Matt';
  usersFirstName = 'User';
  conversationsTopics: any;
  recentConversations: any[] = [];
  @ViewChild('chatMessages') private chatMessages!: ElementRef;
  @ViewChild('chatbotSelect') chatbotSelect!: IonSelect;
  @ViewChild('selectRef', { static: false }) selectRef!: IonSelect;

  trackByMessage: any;
  userMessageCount = 0;
  speaking: boolean = false;
  expandedStates: boolean[] = [];
  sub: any;
  speakingSeconds = 15;
  showThinking: boolean = false;
  cursorPosition = 0;
  interval2: NodeJS.Timeout;

  constructor(
    private userDetailsService: UserDetailsService,
    private aiService: ChatbotsService,
    private chatbotsService: ChatbotsService,
    private sanitizer: DomSanitizer,
    private helpers: HelpersService,
    public platformService: PlatformService,
    private ref: ChangeDetectorRef,
    private menuController: MenuController,
    private conversationsTopicsService: ChatbotsConversationsTopicsService,
    private speechRecognitionService: SpeechToTextService,
    public readTextService: ReadTextService,
    private modalController: ModalController,
    private spinnerService: SpinnerService,
    private toolbarService: ToolbarService,
    private textManipulationService: TextManipulationService,
  ) { }

  suggestions: string[] = [];

  ngOnInit(): void {
    this.textManipulationService.getCorrectedText().subscribe((correctedText: any) => {
      this.message = correctedText;

      if (this.message === '') {

      }
    });

    this.textManipulationService.getSuggestionsObservable().subscribe(suggestions => {
      this.suggestions = suggestions;
    });

    this.sub = this.readTextService.readingFinishedListener().subscribe((data) => {
      console.log("🚀 ~ ChatbotsComponent ~ this.sub=this.readTextService.readingFinishedListener ~ data:", data);
      this.readingNow = false;
      if (this.checkLastMessage() && this.mode === 'speaking') {
        this.finishConversation();
      }
    });

    this.conversationsTopics = this.conversationsTopicsService.topics;
    this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === 'Matt');
    setTimeout(() => {
      this.loadRecentConversations();
      this.previousChatbotName = this.userDetailsService.getUserDetails().userData.selectedChatbot || 'Matt';
      this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === this.previousChatbotName);
      this.refreshConversationsView();
      this.chatbots = this.chatbotsService.chatbots;
      this.checkVoiceInteractions();
    }, 1200);

    this.conversation.forEach((message: { role: string; content: string; splitContent?: any }) => {
      message.splitContent = this.splitContent(this.helpers.clone(message.content));
    });

    // Initialize component
    this.isMobile = window.innerWidth < 700;

    this.updateUserDetails();
  }

  // Prevent default keyboard behavior
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) { }

  removeBrackets(level: string): string {
    if (!level) return '';
    return level.replace(/\(.*?\)/g, "");
  }

  openSelectLevel() {
    this.selectRef.open();
  }

  ionViewWillEnter() {
    this.updateUserDetails();
    this.spinnerService.showSpinner();
    setTimeout(() => {
      this.spinnerService.hideSpinner();
    }, 1500);
  }

  onAccordionChange(event: any, index: number) {
    this.expandedStates[index] = event.detail.value === 'first';
  }

  // Method to check if an accordion is expanded
  isExpanded(index: number): boolean {
    return this.expandedStates[index] || false;
  }

  refreshConversationsView() {
    this.selectedLevel = this.userDetailsService.getUserDetails().userData.selectedConversationsLevel;
    if (this.conversationsStats?.length > 0) {
      this.conversationsTopics.forEach((category: any) => {
        category.finished = this.checkCategoryFinishedStats(category);
        category.nextCategoryUnlocked = this.checkNextCategoryUnlocked(category);
        category.topics.forEach((topic: any) => {
          topic.finished = this.checkConversationFinishedStats(topic);
        });
      });
    }
  }

  onTopicClick(event: any) {
    this.selectedTopic = event.topicEn;
    if (event.type === 'situation') {
      this.selectedTopic += ' in the form of a roleplay';
    }
    const user = this.userDetailsService.getSimpleUserDetails();
    if (
      user.name.includes('test') ||
      this.checkNumberConversationsToday() < 55 ||
      user.role === 'owner'
    ) {
      if (!this.selectedLevel) {
        alert('Wybierz poziom');
      } else {
        this.selectedChatbot.systemMsg = this.selectedChatbot.systemMsg.split('one question within the topic of:')[0] + this.selectedTopic;
        this.sendFirstMessage();
      }
    } else {
      alert('Wykorzystałeś dzisiaj limit rozmów. Spróbuj jutro');
    }
  }

  searchConversations() {
    if (!this.searchTerm.trim()) {
      this.loadRecentConversations();
      return;
    }

    const userId = this.userDetailsService.getSimpleUserDetails().id;
    this.chatbotsService.searchConversations(userId, this.searchTerm).subscribe(
      response => {
        this.recentConversations = response.conversations;
      },
      error => {
        console.error('Error searching conversations:', error);
      }
    );
  }

  checkConversationFinishedStats(topic: any): boolean {
    const userDetails = this.userDetailsService.getUserDetails();
    const category = userDetails.userData.conversationsStats.find(
      (_topic: any) => _topic.conversations.some(conversation => conversation.id === topic.id)
    );
    if (!category) {
      return false;
    }
    const conversationStats = category.conversations.find((conv: any) => conv.id === topic.id);
    return conversationStats ? conversationStats.finished : false;
  }

  checkCategoryFinishedStats(category: any): boolean {
    const userDetails = this.userDetailsService.getUserDetails();
    const categoryStats = userDetails?.userData?.conversationsStats.find((topic: any) => topic.id === category.id);
    return categoryStats?.categoryFinished || false;
  }

  checkNextCategoryUnlocked(category: any): boolean {
    const userDetails = this.userDetailsService.getUserDetails();
    const categoryStats = userDetails.userData.conversationsStats.find((topic: any) => topic.id === category.id);
    return categoryStats?.nextCategoryUnlocked || false;
  }

  resetConversationsStats() {
    const userDetails = this.userDetailsService.getUserDetails();
    userDetails.userData.conversationsStats = null;
    this.userDetailsService.setUsersProp(userDetails._id, 'conversationsStats', userDetails.userData.conversationsStats);
    this.refreshConversationsView();
  }

  saveConversationsStats(
    categoryId: string,
    categoryName: string,
    conversationId: string,
    conversationName: string,
    userMessagesNumber: number
  ) {
    const userDetails = this.userDetailsService.getUserDetails();
    if (!userDetails.userData.conversationsStats) {
      userDetails.userData.conversationsStats = [];
    }

    const currentCategory = userDetails.userData.conversationsStats.find(
      (topic: any) => topic.id === categoryId
    );

    if (!currentCategory) {
      // Create category if not found
      const newCategory = {
        id: categoryId,
        name: categoryName,
        categoryFinished: false,
        nextCategoryUnlocked: false,
        conversations: [
          {
            id: conversationId,
            name: conversationName,
            finished: false
          }
        ]
      };
      userDetails.userData.conversationsStats.push(newCategory);
    } else {
      const categoryFinished = currentCategory.conversations.every(
        (conv: any) => conv.finished
      );
      const currentConversationFinished = userMessagesNumber > 11;
      const nextCategoryUnlocked = currentCategory.conversations.filter(conv => conv.finished).length > 2;

      // Find conversation within existing category
      const existingConversation = currentCategory.conversations.find(
        (c: any) => c.id === conversationId
      );
      if (existingConversation) {
        existingConversation.finished = existingConversation.finished ? true : currentConversationFinished;
      } else {
        currentCategory.conversations.push({
          id: conversationId,
          name: conversationName,
          finished: currentConversationFinished
        });
        const finishedCount = currentCategory.conversations.filter(c => c.finished).length;
        if (finishedCount >= 6) {
          currentCategory.categoryFinished = true;
          if (!currentCategory.categoryFinishedDate) {
            currentCategory.categoryFinishedDate = Date.now();
          }
          currentCategory.nextCategoryUnlocked = true;
        }
      }
    }
    this.userDetailsService.setUsersProp(userDetails._id, 'conversationsStats', userDetails.userData.conversationsStats);
  }

  checkConversationFinished(): boolean {
    return this.userMessageCount > 11;
  }

  checkLastMessage(): boolean {
    return this.userMessageCount >= 12;
  }

  async openStatsComponent() {
    if (!this.conversation) {
      console.error('Conversation is not defined');
      return;
    }

    const modal = await this.modalController.create({
      component: ChatbotsCostComponent,
      componentProps: { conversation: this.conversation }
    });

    return await modal.present();
  }
  onInputChange(event: Event) {
    console.log('focusing the input...');
    this.messageInputElement.nativeElement.focus();
    // Assuming this.messageInput is a reference to the input element
    this.messageInput.setFocus().then(() => {
      const inputElement = this.messageInput.getInputElement();
      inputElement.then((element) => {
        const length = this.textManipulationService.cursorPosition;
        element.setSelectionRange(length, length);
      });
      // this.messageInput.getInputElement().then(input => {
      //   input.style.height = 'auto';
      //   input.style.height = input.scrollHeight + 'px';
      //   console.log("🚀 ~ ChatbotsComponent ~ this.messageInput.getInputElement ~ input.scrollHeight:", input.scrollHeight)
      // });
    });
    // const currentWord = this.textManipulationService.getCurrentWord(this.cursorPosition);
    // this.textManipulationService.getSuggestions(currentWord);
  }

  async sendFirstMessage() {
    if (window.innerWidth <= 768) {
      this.interval2 = setInterval(() => {
        console.log('focusing the input...')
        // Assuming this.messageInput is a reference to the input element
        this.messageInput.setFocus().then(() => {
          const inputElement = this.messageInput.getInputElement();
          inputElement.then((element) => {

            const length = this.textManipulationService.cursorPosition
            element.setSelectionRange(length, length);
          });
        });
        if (this.suggestions.length == 0) {
          this.textManipulationService.getSuggestions(this.message);
        }
      }

        , 1000);
    }

    if (!this.voiceInteractionsLocked) {
      if (await this.presentAlert() === 'ok') {
        this.mode = 'speaking';
      } else {
        this.mode = 'writing';
        setTimeout(() => {
          console.log("🚀 ~ ChatbotsComponent ~ this.messageInput.getInputElement ~  this.messageInput:", this.messageInputElement);

          this.messageInputElement.nativeElement.addEventListener('input', () => {
            console.log('Input event fired');
          });
          this.messageInput.getInputElement().then((textarea) => {
            console.log('🚀 ~ ChatbotsComponent ~ ngAfterViewInit ~ textarea:', textarea);

            textarea.addEventListener('click', () => {
              const start = textarea.selectionStart;
              const end = textarea.selectionEnd;
              console.log(`Cursor starts at: ${start}, ends at: ${end}`);
              this.cursorPosition = start;
              this.textManipulationService.setCursorPosition(start);

            });

            // Utility function to debounce a function
            function debounce(func: Function, wait: number) {
              let timeout: number;
              return function (...args: any[]) {
                clearTimeout(timeout);
                timeout = window.setTimeout(() => func.apply(this, args), wait);
              };
            }

            // Event handler function
            const handleInput = () => {
              this.messageInputElement.nativeElement.focus();
              console.log("🚀 ~ ChatbotsComponent ~ textarea.addEventListener ~ messageInputElement:", this.messageInputElement);

              this.cursorPosition = textarea.selectionStart;
              this.textManipulationService.setCursorPosition(this.cursorPosition);
              this.textManipulationService.getSuggestions(this.message);
              console.log("🚀 ~ ChatbotsComponent ~ textarea.addEventListener ~ textarea:", textarea);
              console.log("🚀 ~ ChatbotsComponent ~ textarea.addEventListener ~ this.messageInput:", this.messageInput);

              // Ensure the textarea is focused
            };

            // Add event listener with debounced handler
            textarea.addEventListener('input', debounce(handleInput, 300));


          }).catch((error) => {
            console.error('Error getting input element:', error);
          });
        }, 1000);
      }
    }


    if (this.selectedChatbot.lang === 'spanish') {
      this.message = `Hola, mi nombre es ${this.usersFirstName} y me gustaría practicar mi espanol hablando sobre el tema: ${this.selectedTopic}. ¿Puedes ayudarme con eso? Mi nivel de idioma es ${this.selectedLevel}.`;
    } else {
      this.message = `Hi, my name is ${this.usersFirstName} and I would like to practice my English by talking about the topic of: ${this.selectedTopic}. Can you help me with that? My language level is ${this.selectedLevel}.`;
    }

    this.sendMessage();
    this.textManipulationService.resetTextState();

    if (this.mode === 'speaking') {
      setTimeout(() => {
        this.drawStraightLine();
      }, 10);
    }
    if (this.mode === 'writing') {
      this.toolbarService.setToolbarVisibility(false);
    }
  }

  async presentAlert(): Promise<string> {
    return new Promise((resolve) => {
      const alert = document.createElement('ion-alert');
      alert.header = 'Rozmowa głosowa';
      alert.message = 'Masz możliwość przeprowadzenia rozmowy głosowej. Czy chcesz skorzystać z tej opcji?';
      alert.buttons = [
        {
          text: 'Mówienie',
          handler: () => {
            resolve('ok');
          }
        },
        {
          text: 'Pisanie',
          handler: () => {
            resolve('cancel');
          }
        }
      ];

      document.body.appendChild(alert);
      alert.present();
    });
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  private updateUserDetails(): void {
    const userDetails = this.userDetailsService.getUserDetails();
    if (userDetails) {
      const newFirstName = userDetails.name.split(' ')[0];
      this.canChangeTutor = userDetails.userData.canChangeTutor === 'true';
      if (newFirstName !== this.usersFirstName) {
        this.usersFirstName = newFirstName;
      }
    }
  }

  checkVoiceInteractions() {
    const userDetails = this.userDetailsService.getUserDetails();
    this.maxAllowedVoiceInteractions = Number(userDetails?.userData?.maxAllowedVoiceInteractions) || 20;
    const today = new Date();
    const interactionDate = new Date(userDetails?.userData?.voiceInteractionsToday?.date);

    const isSameDay = interactionDate.getFullYear() === today.getFullYear() &&
      interactionDate.getMonth() === today.getMonth() &&
      interactionDate.getDate() === today.getDate();

    this.voiceInteractionsLocked = isSameDay &&
      (userDetails?.userData?.voiceInteractionsToday?.number || 0) >= this.maxAllowedVoiceInteractions;
    if (this.voiceInteractionsLocked) {
      this.mode = 'writing';
    }
  }

  onFileSelected(event: any) {
    this.files = event.target.files;
  }

  openMenu() {
    this.menu.open();
  }

  openSelect() {
    this.chatbotSelect.open();
  }

  checkNumberConversationsToday(): number {
    if (!this.recentConversations || !Array.isArray(this.recentConversations)) {
      return 0;
    }
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth();
    const d = today.getDate();

    const conversationsToday = this.recentConversations.filter((conversation: any) => {
      const convDate = new Date(conversation.lastUpdated);
      return (
        convDate.getFullYear() === y &&
        convDate.getMonth() === m &&
        convDate.getDate() === d
      );
    });

    return conversationsToday.length;
  }

  sendMessage(voice?: boolean) {
    this.userMessageCount += 1;
    if (voice) {
      const userDetails = this.userDetailsService.getUserDetails().userData;
      const currentDate = new Date().toDateString();
      const storedDate = userDetails.voiceInteractionsToday?.date ? new Date(userDetails.voiceInteractionsToday.date).toDateString() : null;

      if (currentDate !== storedDate) {
        this.voiceInteractionsToday = 1; // Reset to 1 for the first interaction of the day
      } else {
        this.voiceInteractionsToday = (userDetails.voiceInteractionsToday?.number || 0) + 1;
      }

      this.userDetailsService.setUsersProp(this.userDetailsService.getSimpleUserDetails().id, 'voiceInteractionsToday', { date: new Date(), number: this.voiceInteractionsToday });
    }

    if (!voice) {
      if (this.message.length === 0) {
        alert('Wpisz wiadomość');
        return;
      }
      // Apply text manipulation before sending
      if (this.message.length > 0) {
        this.message = this.textManipulationService.capitalizeFirstLetter(this.message);
      }
    }

    this.checkNumberConversationsToday();

    let lastSummary = this.conversation.find((message) => message.summary);
    let conversationUpToLastSummary = lastSummary ? this.conversation.slice(this.conversation.indexOf(lastSummary)) : this.conversation;
    console.log("🚀 ~ ChatbotsComponent ~ sendMessage ~ lastSummary:", lastSummary);

    if (!this.message.trim() && this.files.length === 0) return;

    this.conversation.push({ role: 'user', content: this.message, splitContent: this.splitContent(this.message), voice: voice } as { role: string; content: string; splitContent: any; voice: any });
    if (lastSummary) {
      conversationUpToLastSummary.push({ role: 'user', content: this.message, splitContent: this.splitContent(this.message) } as { role: string; content: string; splitContent: any });
    }
    const systemMsgLength = 455;
    if (!this.conversation[this.conversation.length - 1]?.tokensInTokensStats) {
      this.conversation[this.conversation.length - 1].tokensInTokensStats = (this.message.length + systemMsgLength) / 4;
    } else {
      this.conversation[this.conversation.length - 1].tokensInTokensStats = (this.conversation[this.conversation.length - 1].tokensInTokensStats + this.message.length) / 4;
    }
    this.scrollToBottom();

    if (this.checkLastMessage()) {
      this.selectedChatbot.systemMsg = this.selectedChatbot.systemMsg.replace(
        'You must ask only one question. The question must be within the topic of:',
        'Dont ask any question, relate to what user said and then politely say that you have to go and goodbye.'
      );
      console.log("🚀 ~ ChatbotsComponent ~ sendMessage ~ this.selectedChatbot.systemMsg:", this.selectedChatbot.systemMsg);
    }

    const data = {
      messages: this.conversation,
      aiProvider: this.selectedChatbot.aiProvider,
      model: this.selectedModel,
      maxTokens: this.selectedChatbot.maxTokens,
      maxMessages: this.selectedChatbot.maxMessages,
      systemMsg: this.selectedChatbot.systemMsg,
      files: this.files
    };

    this.message = '';

    this.checkVoiceInteractions();

    this.aiService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        this.conversation = this.conversation.filter(message => !(message.role === 'thinking' && message.content === 'Thinking...'));
        res.res.content = this.helpers.fixJSONFormat(res.res.content);
        this.conversation.push(res.res);

        this.conversation.forEach(message => {
          if (!message.translatedText) {
            message.translatedText = '';
          }
        });

        this.conversation.forEach((message: { role: string; content: string; splitContent?: any }) => {
          message.splitContent = this.splitContent(this.helpers.clone(message.content));
        });

        if (!this.conversation[this.conversation.length - 1].tokensOutTokensStats) {
          this.conversation[this.conversation.length - 1].tokensOutTokensStats = this.conversation[this.conversation.length - 1].content.length / 4;
        } else {
          this.conversation[this.conversation.length - 1].tokensOutTokensStats = (this.conversation[this.conversation.length - 1].tokensOutTokensStats + this.conversation[this.conversation.length - 1].content.length) / 4;
        }

        this.files = [];

        this.scrollToBottom();
        const textToRead = res.res.content.split('<hr>')[0];
        if (this.mode === 'speaking') {
          this.readingNow = true;
          this.readText(textToRead);
        }
        if (this.selectedModel === 'claude-3-5-sonnet-latest') {
          this.selectedModel = 'deepseek-chat';
        }

        if (this.messageInput) {


          setTimeout(() => {
            this.messageInput.getInputElement().then((textarea) => {
              this.messageInput.setFocus();


              textarea.setSelectionRange(this.cursorPosition, this.cursorPosition);
              // this.textManipulationService.getSuggestions(this.message);
            });
          }, 200);
        }

        this.updateConversation();

        const categoryObj = this.conversationsTopics.find((topic: any) => topic.topics.some(_topic => _topic.topicEn === this.selectedTopic.replace(' in the form of a roleplay', '')));
        const conversationObj = categoryObj.topics.find((topic: any) => topic.topicEn === this.selectedTopic.replace(' in the form of a roleplay', ''));
        this.saveConversationsStats(
          categoryObj.id,
          categoryObj.title,
          conversationObj.id,
          conversationObj.topic,
          this.userMessageCount
        );
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    this.message = '';
    this.textManipulationService.resetTextState();
    this.conversation.push({ role: 'thinking', content: 'Thinking...', splitContent: this.splitContent('Thinking...') } as { role: string; content: string; splitContent: any });
    this.scrollToBottom();
  }

  finishConversation(): void {
    // Implement the logic for finishing the conversation
    // For example, send a goodbye message or perform cleanup
    // Placeholder implementation:
    this.message = this.selectedChatbot.lang === 'spanish'
      ? 'Me encantaría quedarme y charlar, pero tengo que irme ahora. ¡Hablaremos más tarde!'
      : 'I would love to stay and chat but I have to go now. I will talk to you later!';
    this.sendMessage();
  }

  checkLang(): string {
    if (this.selectedChatbot.lang === 'spanish') {
      return 'es';
    }
    if (this.selectedChatbot.lang === 'english') {
      return 'en';
    }
    throw new Error('Language not supported');
  }

  checkWidth(): boolean {
    return window.innerWidth <= 768;
  }

  getDisplayText(): string {
    if (this.showThinking || (!this.silence && this.conversation[this.conversation.length - 1]?.role === 'thinking')) {
      return 'Myślę...';
    }
    if (!this.silence && this.readingNow) {
      return 'Mówię...';
    }
    if (!this.showThinking && !this.silence && !this.speaking && !this.readingNow && this.mode === 'speaking' && !this.checkConversationFinished()) {
      return 'Aby mówić wciśnij mikrofon';
    }
    if (this.silence) {
      return 'Nic nie słyszę. Jeśli skończyłeś mowić wciśnij mikrofon';
    }
    if (!this.silence && this.checkConversationFinished()) {
      return 'Ta rozmowa się zakończyła, ale zawsze możesz przećwiczyć ją ponownie wybierając kartę rozmowy.';
    }
    return '';
  }

  replaceLanguageLevel(input: string): string {
    const regex = /My language level is.*/;
    return input.replace(regex, '');
  }

  readText(text: string): void {
    if (!this.conversation[this.conversation.length - 1]?.readTextStats) {
      this.conversation[this.conversation.length - 1].readTextStats = text.length;
    } else {
      this.conversation[this.conversation.length - 1].readTextStats += text.length;
    }

    this.readTextService.readTextFromMs(text, null, null, 1, null, null, this.selectedChatbot.lang === 'english' ? false : true);
  }

  startSpeaking(): void {
    this.stopSpeakingPossible = false;
    setTimeout(() => {
      this.stopSpeakingPossible = true;
    }, 1000);
    let speakingSeconds = 0;
    this.waitingForTranscription = true;

    let speakingInterval = setInterval(() => {
      speakingSeconds += 1;
      this.speakingSeconds = 10 - speakingSeconds;
      if (speakingSeconds >= 10) {
        clearInterval(speakingInterval);
        this.stopSpeaking();
        this.sendMessage(true);
      }
    }, 1000);

    this.speaking = true;
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.ondataavailable = event => {
        this.recordedChunks.push(event.data);
      };
      this.mediaRecorder.onstop = () => {
        clearInterval(speakingInterval);
        this.speakingSeconds = 10;
        if (!this.conversation[this.conversation.length - 1]?.speakingSecondsStats) {
          this.conversation[this.conversation.length - 1].speakingSecondsStats = speakingSeconds;
        } else {
          this.conversation[this.conversation.length - 1].speakingSecondsStats += speakingSeconds;
        }
        const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
        const audioFile = new File([audioBlob], 'recordedAudio.wav', { type: 'audio/wav' });
        const lang = this.selectedChatbot.lang === 'spanish' ? 'es' : 'en';
        console.log("🚀 ~ ChatbotsComponent ~ navigator.mediaDevices.getUserMedia ~ this.activeSpeakingMs:", this.activeSpeakingMs);
        if (this.activeSpeakingMs > 50) {
          this.speechRecognitionService.transcribe(audioFile, lang).subscribe(response => {
            this.waitingForTranscription = false;
            this.message = response.text;
            this.sendMessage(true);
          });
        } else {
          this.waitingForTranscription = false;
          alert('Nie nagrałeś żadnej wiadomości');
        }
      };

      this.recordedChunks = [];
      this.mediaRecorder.start();

      // Create audio context + analyser
      this.audioContext = new AudioContext();
      this.source = this.audioContext.createMediaStreamSource(stream);
      this.analyser = this.audioContext.createAnalyser();
      this.source.connect(this.analyser);
      this.drawWaveform();
    });
  }

  drawWaveform(): void {
    this.activeSpeakingMs = 0;
    const canvas = this.visualizerCanvas.nativeElement;
    const container = canvas.parentElement;
    const canvasCtx = canvas.getContext('2d')!;
    const dataArray = new Uint8Array(this.analyser.fftSize);
    let speakingStartTime: number | null = null;
    this.silentFrames = 0;

    const setCanvasSize = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      canvas.width = containerWidth * 2;
      canvas.height = containerHeight * 2;
      canvas.style.width = `${containerWidth}px`;
      canvas.style.height = `${containerHeight}px`;
      canvasCtx.scale(2, 2);
    };
    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    const draw = () => {
      this.animationId = requestAnimationFrame(draw);
      this.analyser.getByteTimeDomainData(dataArray);

      let sum = 0;
      for (const sample of dataArray) sum += Math.abs(sample - 128);
      const avg = sum / dataArray.length / 128;

      // Silence detection and active speaking time
      if (avg < this.silenceThreshold) {
        this.silentFrames++;
        if (speakingStartTime !== null) speakingStartTime = null;
        if (this.silentFrames > this.maxSilentFrames) {
          this.silence = true;
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
          canvasCtx.beginPath();
          canvasCtx.moveTo(canvas.width / 2, 0);
          canvasCtx.lineTo(canvas.width / 2, canvas.height);
          canvasCtx.strokeStyle = '#007bff';
          canvasCtx.stroke();
        }
      } else {
        this.silentFrames = 0;
        this.silence = false;
        if (speakingStartTime === null) {
          speakingStartTime = Date.now();
        } else {
          this.activeSpeakingMs += Date.now() - speakingStartTime;
          speakingStartTime = Date.now();
        }
      }

      // Waveform drawing (smaller amplitude)
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      canvasCtx.beginPath();
      const sliceWidth = canvas.width / dataArray.length / 2;
      let x = 0;
      for (let i = 0; i < dataArray.length; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2 / 2; // dividing by 4
        i === 0 ? canvasCtx.moveTo(x, y) : canvasCtx.lineTo(x, y);
        x += sliceWidth;
      }
      canvasCtx.strokeStyle = '#0163aa'; // Ionic default blue primary color
      canvasCtx.lineWidth = 2;
      canvasCtx.stroke();
    };
    draw();
  }

  drawStraightLine(): void {
    const canvas = this.visualizerCanvas.nativeElement;
    const container = canvas.parentElement;
    const canvasCtx = canvas.getContext('2d')!;

    // Set canvas size to match the container size
    const setCanvasSize = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      canvas.width = containerWidth * 2; // Higher resolution
      canvas.height = containerHeight * 2; // Higher resolution
      canvas.style.width = `${containerWidth}px`;
      canvas.style.height = `${containerHeight}px`;
      canvasCtx.scale(2, 2); // Scale context to match higher resolution
      canvasCtx.strokeStyle = '#0163aa';
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    canvasCtx.beginPath();
    canvasCtx.moveTo(0, canvas.height / 2 / 2); // Adjust for higher resolution
    canvasCtx.lineTo(canvas.width / 2, canvas.height / 2 / 2); // Adjust for higher resolution
    canvasCtx.strokeStyle = '#000';
    canvasCtx.lineWidth = 2;
    canvasCtx.stroke();
  }

  clearWaveform(): void {
    const canvas = this.visualizerCanvas.nativeElement;
    const canvasCtx = canvas.getContext('2d')!;
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    this.analyser.disconnect();
    this.source.disconnect();
  }

  stopSpeaking(): void {
    this.showThinking = true;
    this.speaking = false;
    this.mediaRecorder.stop();
    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
    }
    this.drawStraightLine();
    this.silence = false;
    this.clearWaveform();
    // Optionally close or suspend context
    this.audioContext.close();
    setTimeout(() => {
      this.showThinking = false;
    }, 3000);
  }

  private processAudio(file: File): Promise<Blob> {
    // Example placeholder for audio processing
    return Promise.resolve(file);
  }

  updateConversation(): void {
    const conversationData = {
      userId: this.userDetailsService.getSimpleUserDetails().id,
      chatbotName: this.selectedChatbot.name,
      conversation: this.conversation,
      topic: this.selectedTopic,
      englishLevel: this.selectedLevel
    };

    this.chatbotsService.saveOrUpdateConversation(conversationData).subscribe(
      response => {
        if (!this.chatbotsService.currentConversationId) {
          this.chatbotsService.setCurrentConversationId(response.chatId);
        }
      },
      error => {
        console.error('Error updating conversation:', error);
      }
    );
  }

  getChatbotAvatar(chatbotName: string): string {
    const chatbot = this.chatbotsService.chatbots.find((bot: any) => bot.name === chatbotName);
    return chatbot ? chatbot.avatar : '/assets/people-speaking-plain/default.png';
  }

  selectChatbot(event: any) {
    this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === event.detail.value.name);
    this.previousChatbotName = this.selectedChatbot.name;
    this.userDetailsService.setUsersProp(this.userDetailsService.getSimpleUserDetails().id, 'selectedChatbot', event.detail.value.name);
    this.startNewConversation();
  }

  assignTranslatedText(event: any) {
    console.log("🚀 ~ ChatbotsComponent ~ assignTranslatedText ~ event:", event);
    console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ conversation:", this.conversation);
    this.conversation.forEach((message, index) => {
      const messageContent = message.content;
      console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ messageContent:", messageContent);
      if (messageContent === event.originalText) {
        if (!this.conversation[index]?.translatedTextTokensStats) {
          this.conversation[index].translatedTextTokensStats = Math.ceil((event.translatedText.length + event.originalText.length + 160) / 4);
        } else {
          this.conversation[index].translatedTextTokensStats = (this.conversation[index].translatedTextTokensStats + (event.translatedText.length + event.originalText.length + 160)) / 4;
        }
        this.conversation[index].translatedText = event.translatedText;
        this.updateConversation();
        console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ conversation:", this.conversation);
      }
    });
  }

  assignHintText(event: any, message: any) {
    console.log("🚀 ~ ChatbotsComponent ~ assignHintText ~ message:", message);
    console.log("🚀 ~ ChatbotsComponent ~ assignHintText ~ event:", event);
    console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ conversation:", this.conversation);
    this.conversation.forEach((_message, index) => {
      const messageHint = _message;
      console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ messageHints:", messageHint);
      if (messageHint.content === message.content) {
        this.conversation[index].hint = event.hint;
        if (!this.conversation[index]?.hintsTokenStats) {
          this.conversation[index].hintsTokenStats = Math.ceil((this.conversation[index].hint.length + event.msgToSend.content.length + 676) / 4);
        } else {
          this.conversation[index].hintsTokenStats = (this.conversation[index].hintsTokenStats + this.conversation[index].hint.length + 676) / 4;
        }
        this.updateConversation();
      }
    });
  }

  assignCorrectionText(event: any, message: any) {
    console.log("🚀 ~ ChatbotsComponent ~ assignCorrectionText ~ message:", message);
    console.log("🚀 ~ ChatbotsComponent ~ assignCorrectionText ~ event:", event);
    this.conversation.forEach((_message, index) => {
      const messageCorrection = _message;
      if (messageCorrection.content === message.content) {
        this.conversation[index].correction = event.correction;
        if (!this.conversation[index]?.correctionTokenStats) {
          this.conversation[index].correctionTokenStats = Math.ceil((this.conversation[index].correction.length + event.msgToSend.content.length + 1452) / 4);
        } else {
          this.conversation[index].correctionTokenStats = (this.conversation[index].correctionTokenStats + (this.conversation[index].correction.length + event.msgToSend.length + 1452)) / 4;
        }
        this.updateConversation();
      }

      console.log("🚀 ~ ChatbotsComponent ~ this.conversation.forEach ~ messageCorrections:", messageCorrection);
    });
  }

  splitContent(content: string): any[] {
    const parts: { type: 'text'; value: string }[] = [];

    if (!content) {
      return parts;
    }

    try {
      const jsonValue = JSON.parse(content);
      parts.push({ type: 'text', value: String(jsonValue) });
    } catch (e) {
      if (!/\s/.test(content) && !/[a-zA-Z]{2,}/.test(content)) {
        for (const char of content) {
          parts.push({ type: 'text', value: char });
        }
      } else {
        parts.push({ type: 'text', value: String(content) });
      }
    }

    return parts;
  }

  levelChange(event: any) {
    this.selectedLevel = event.detail.value;
    this.userDetailsService.setUsersProp(this.userDetailsService.getSimpleUserDetails().id, 'selectedConversationsLevel', this.selectedLevel);
  }

  async loadConversation(id: string) {
    this.chatbotsService.getConversationById(id).subscribe(
      response => {
        this.conversation = response.conversation.conversation;
        this.userMessageCount = this.conversation.filter(message => message.role === 'user').length;
        this.conversation.forEach((message: { role: string; content: string; splitContent?: any }) => {
          message.splitContent = this.splitContent(this.helpers.clone(message.content));
        });
        console.log("🚀 ~ ChatbotsComponent ~ loadConversation ~ conversation:", this.conversation);
        this.selectedTopic = response.conversation.topic;
        this.selectedLevel = response.conversation.englishLevel;
        this.selectedChatbot = this.chatbotsService.chatbots.find(
          (chatbot: any) => chatbot.name === response.conversation.chatbotName
        );
        this.previousChatbotName = this.selectedChatbot.name;
        this.chatbotsService.setCurrentConversationId(id);
        this.menuController.close('chat-history');
        this.scrollToBottom();
      },
      error => {
        console.error('Error loading conversation:', error);
      }
    );
  }

  startNewConversation(reload?: boolean) {
    // window.location.reload();
    this.chatbotsService.clearCurrentConversation();
    this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === this.userDetailsService.getUserDetails().userData.selectedChatbot);
    if (!this.selectedChatbot) {
      this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name === 'Matt');
    }
    this.conversation = [];
    this.selectedTopic = '';
    this.selectedLevel = this.userDetailsService.getUserDetails().userData.selectedConversationsLevel;
    this.ref.detectChanges();
    this.loadRecentConversations();
    this.refreshConversationsView();
    this.userMessageCount = 0;
    this.readTextService.stopReading();
    this.toolbarService.setToolbarVisibility(true);
    clearInterval(this.interval);
  }

  startNewConversationForOwnTopic() {
    const usersTopic = prompt('Wpisz temat rozmowy');
    if (usersTopic) {
      this.selectedTopic = usersTopic;
      this.selectedChatbot.systemMsg = this.selectedChatbot.systemMsg.split('one question within the topic of:')[0] + this.selectedTopic;
      this.sendFirstMessage();
    }
  }

  loadRecentConversations() {
    const userId = this.userDetailsService.getSimpleUserDetails().id;
    this.conversationsStats = this.userDetailsService.getUserDetails().userData.conversationsStats;
    this.chatbotsService.getRecentConversations(userId, 10).subscribe(
      response => {
        this.recentConversations = response.conversations;
      },
      error => {
        console.error('Error loading recent conversations:', error);
      }
    );
  }

  private scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
        this.ref.detectChanges();
      }, 10);
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    clearInterval(this.interval);
    clearInterval(this.interval2);
    if (this.sub) {
      this.sub.unsubscribe();

    }
  }
}
