import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsService } from '../chatbots.service';

@Component({
  selector: 'app-chatbot-hint',
  templateUrl: './chatbot-hint.component.html',
  styleUrls: ['./chatbot-hint.component.css']
})
export class ChatbotHintComponent implements OnInit {
  @Input() messages;
  @Input() messageIndex;
  msgToSend
  @Input() hintText = ''
  @Output() hintTextChange = new EventEmitter();
  showSpinner = false;
  @Input() lang: 'en' | 'es' = 'en'
  selectedChatbot
  currentMessage: any;
  previousUsersMessage: any;




  constructor(
    private aiService: ChatbotsService
  ) { }

  ngOnInit(): void {
    // Initialization logic
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes?.messages?.currentValue && changes?.messages?.firstChange) {
      if (this.lang === 'en') {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Eduardo')
      }
      else {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Gustavo')
      }
      this.currentMessage = this.messages[this.messageIndex].content || ''
    }
  }
}
