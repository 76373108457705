import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RtcService } from 'src/app/shared/services/rtc.service';
import { version } from '../../../environments/version';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { LiveGameCommunicatorService } from 'src/app/shared/services/live-game-communicator.service';
import { TravelDestinationsService, TravelDestination } from 'src/app/shared/services/travel-destinations.service';
import { ModalController, ToastController } from '@ionic/angular';
import { TravelDestinationsModalComponent } from './travel-destinations-modal/travel-destinations-modal.component';
import { ProgressHistoryModalComponent } from './progress-history-modal/progress-history-modal.component';
import { ConversationsLevelModalComponent } from 'src/app/shared/components/chatbots/conversations-level-modal/conversations-level-modal.component';
import { ChatbotsConversationsTopicsService } from 'src/app/shared/components/chatbots/chatbots-conversations-topics/chatbots-conversations-topics.service';
import { EncouragementService } from 'src/app/services/encouragement.service';
import { ChoosePrizeComponent } from 'src/app/shared/components/collectables/choose-prize/choose-prize.component';

@Component({
  selector: 'app-progress',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userDetails;
  version = version;
  sub: any;
  finishedSetsSub: any;
  unfinishedSetsSub: any;
  customSetsSub: any;
  userSetsSub: any;
  currentDestination: TravelDestination | undefined;
  currentLevel: number = 1;
  previousLevel: number = 1; // Track previous level to detect changes
  currentDiamonds: number = 15; // Example value, should come from your service
  nextDestination: string = 'Paris'; // Example value, should be dynamic based on level
  encouragementMessage: string = '';
  private messageInterval: any;
  isMessageTransitioning = false;
  currentMessage = '';
  nextMessage = '';
  transitionStyles = [
    { in: 'fade-in', out: 'fade-out' },
    { in: 'slide-left-in', out: 'slide-right-out' },
    { in: 'zoom-in', out: 'zoom-out' },
    { in: 'cross-fade-in', out: 'cross-fade-out' },
    // Removed rotate and flip transitions as they were too dramatic
  ];
  currentTransition = this.transitionStyles[0];

  constructor(
    private router: Router,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    private rtcService: RtcService,
    private auth: AuthenticationService,
    private liveCommunicationService: LiveGameCommunicatorService,
    private travelDestinationsService: TravelDestinationsService,
    private modalController: ModalController,
    private chatbotsConversationsTopicsService: ChatbotsConversationsTopicsService,
    private encouragementService: EncouragementService,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    // Subscribe to user details
    this.sub = this.userDetailsService
      .userDetailsListener()
      .subscribe((userDetails) => {
        if (userDetails) {
          const previousDetails = this.userDetails;
          this.userDetails = userDetails;

          // Only load sets if this is the first time receiving user details
          if (!previousDetails && userDetails._id) {
            this.userDetailsService.getUsersSets(userDetails._id);
          }

          // Store previous level before updating
          if (previousDetails) {
            this.previousLevel = this.getTravelLevel(previousDetails.userData?.totalDiamonds || 0);
          } else {
            this.previousLevel = this.getTravelLevel(userDetails.userData?.totalDiamonds || 0);
          }

          // Update current destination based on travel level
          this.updateCurrentDestination();

          // Check if level has increased
          const currentLevel = this.getTravelLevel();
          if (previousDetails && currentLevel > this.previousLevel) {
            this.presentLevelUpToast(currentLevel);
          }

          // Check if weekly progress needs to be reset (Monday)
          this.checkAndResetWeeklyProgress();

          // Update streak with current progress
          this.updateStreakWithCurrentProgress();
        }
        if (this.userDetails?.userData?.ageStatus === 'smallChild') {
          const token = this.helpers.clone(localStorage.getItem('mean-token'));

          if (token) {
            if (
              confirm('Wykryto konto dziecka. Przejść do aplikacji dla dzieci?')
            ) {
              window.location.href =
                'https://int2.linget.it/login?token=' + token;
            } else {
              this.auth.logout();
            }
          }
        }
      });

    // Force refresh user details from server
    this.userDetailsService.getUserDetails();

    // Set up encouragement message update interval
    this.updateEncouragementMessage();
    this.messageInterval = setInterval(() => {
      this.updateEncouragementMessage();
    }, 15000); // Increased from 10000 to 15000 ms (15 seconds) to reduce update frequency
  }

  /**
   * Present a toast notification when user levels up
   */
  async presentLevelUpToast(newLevel: number) {


    const destination = this.travelDestinationsService.getDestinationForLevel(newLevel);


    const destinationName = destination?.place['pl'] || 'nowego celu';


    try {
      const toast = await this.toastController.create({
        header: 'Nowy poziom podróży!',
        message: `Gratulacje! Osiągnąłeś poziom ${newLevel} i odblokowałeś ${destinationName}!`,
        position: 'top',
        duration: 5000,
        color: 'success',
        buttons: [
          {
            text: 'Zobacz',
            role: 'info',
            handler: () => {
              this.openTravelDestinationsModal();
            }
          },
          {
            text: 'OK',
            role: 'cancel'
          }
        ]
      });


      await toast.present();

    } catch (error) {
      console.error('Error presenting toast:', error);
    }
  }

  getTravelLevel(diamonds?: number): number {
    const diamondCount = diamonds !== undefined ? diamonds : (this.userDetails?.userData?.totalDiamonds || 0);
    return Math.floor(diamondCount / 10) || 1; // Ensure minimum level 1
  }

  getLearningLevelColor(time: number): string {
    const level = this.userDetailsService.getLearningLevel(time);
    switch (level) {
      case 'advanced': return 'danger';  // > 15 mins (red)
      case 'intermediate': return 'warning';  // 10-15 mins (yellow)
      case 'basic': return 'tertiary';  // 5-10 mins (purple)
      default: return 'medium';  // < 5 mins (gray)
    }
  }

  getLearningTimeText(time: number): string {
    // Return empty string since we no longer display time under flames
    return '';
  }

  getTotalMinutesLearned(): number {
    return this.getTotalProgressMinutes();
  }

  getMinutesText(minutes: number): string {
    // Polish grammar rules for numbers
    if (minutes === 1) {
      return 'minuta';
    } else if (minutes % 10 >= 2 && minutes % 10 <= 4 && (minutes % 100 < 10 || minutes % 100 >= 20)) {
      return 'minuty';
    } else {
      return 'minut';
    }
  }

  /**
   * Get the time spent on conversations in minutes
   */
  getConversationMinutes(): number {
    const conversationTime = this.userDetailsService.userDetails?.userData?.conversationTime || 0;
    return Math.round(conversationTime / 60);
  }

  /**
   * Get the time spent on sets in minutes
   */
  getSetsMinutes(): number {
    // Calculate from sets if available
    let totalSetSeconds = 0;

    // Check if we have sets data available
    if (this.userDetailsService.userDetails?.userData?.sets &&
      Array.isArray(this.userDetailsService.userDetails.userData.sets) &&
      this.userDetailsService.userDetails.userData.sets.length > 0) {

      // Sum up weeklyProgress from all sets
      this.userDetailsService.userDetails.userData.sets.forEach(set => {
        if (set?.stats?.weeklyProgress && typeof set.stats.weeklyProgress === 'number') {
          totalSetSeconds += set.stats.weeklyProgress;
        }
      });

      // If we have valid data from sets, return it
      if (totalSetSeconds > 0) {
        return Math.round(totalSetSeconds / 60);
      }
    }

    // Fallback calculation
    const weeklyProgress = this.userDetailsService.userDetails?.userData?.weeklyProgress || 0;
    const conversationTime = this.userDetailsService.userDetails?.userData?.conversationTime || 0;

    // Ensure we don't return negative values
    return Math.round(Math.max(0, weeklyProgress - conversationTime) / 60);
  }

  getRemainingMinutes(): number {
    const totalProgressMinutes = this.getTotalProgressMinutes();
    const remainingMinutes = Math.max(0, 45 - totalProgressMinutes);
    return Math.round(remainingMinutes);
  }

  getRecommendedDailyMinutes(): number {
    const remainingMinutes = this.getRemainingMinutes();

    // Use the corrected remaining days calculation
    const remainingDays = this.getRemainingDays();

    // If no days left or no minutes left, return 0
    if (remainingDays <= 0 || remainingMinutes <= 0) {
      return 0;
    }

    // Calculate minutes per day, rounded up to ensure goal is met
    return Math.ceil(remainingMinutes / remainingDays);
  }

  getRemainingDays(): number {
    const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    // If today is Sunday (0), only 1 day left in the week
    // If today is Monday (1), 7 days left in the week
    // If today is Tuesday (2), 6 days left, etc.
    return today === 0 ? 1 : 8 - today;
  }

  getDaysText(days: number): string {
    // Polish grammar rules for days
    if (days === 1) {
      return 'dzień';
    } else {
      return 'dni';
    }
  }

  getDayLabels(): string[] {
    const days = ['Ndz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'];
    const result = [];

    // Get current date
    const today = new Date();

    // Generate labels for the last 7 days (not week-based but exact previous 7 days)
    for (let i = 6; i >= 0; i--) {
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - i);
      const dayIndex = pastDate.getDay();
      result.push(days[dayIndex]);
    }

    return result;
  }

  getOrderedStreak(): number[] {
    if (!this.userDetails?.userData?.learningStreak) return Array(7).fill(0);

    // Create a default array with zeros
    const result = Array(7).fill(0);

    // Get the raw streak data
    const rawStreak = [...this.userDetails.userData.learningStreak];

    // Get today's date
    const today = new Date();

    // Check if lastStreakUpdate exists and is valid
    if (!this.userDetails.userData.lastStreakUpdate) {
      console.warn('No lastStreakUpdate found, using empty streak data');
      return result;
    }

    // Calculate the last 7 days (regardless of week boundaries)
    for (let i = 0; i < 7; i++) {
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - i);
      const pastDay = pastDate.getDay(); // 0-6 (Sunday-Saturday)

      // Map the last 7 days to the result array (most recent day last)
      // 6-i ensures most recent day is at the end of the array
      result[6 - i] = rawStreak[pastDay] || 0;
    }

    // Update today's streak with current progress if needed
    this.updateStreakWithCurrentProgress();

    return result;
  }

  getTravelImagePath(): string {
    const level = this.getTravelLevel();

    // If level is greater than 99, we use level 99 image
    // If level is less than 1, we use level 1 image
    const normalizedLevel = level > 500 ? 500 : (level < 1 ? 1 : level);

    // Return the path to the image
    return `assets/travel-levels/${normalizedLevel}.jpg`;
  }

  /**
   * Update the current destination based on the user's travel level
   */
  updateCurrentDestination(): void {
    const level = this.getTravelLevel();
    this.currentDestination = this.travelDestinationsService.getDestinationForLevel(level);
  }

  /**
   * Get the current destination description in the specified language
   * @param lang Language code ('pl', 'en', or 'es')
   */
  getDestinationDescription(lang: 'pl' | 'en' | 'es' = 'pl'): string {
    return this.currentDestination?.description[lang] || '';
  }

  /**
   * Get the current destination place name in the specified language
   * @param lang Language code ('pl', 'en', or 'es')
   */
  getDestinationPlace(lang: 'pl' | 'en' | 'es' = 'pl'): string {
    return this.currentDestination?.place[lang] || '';
  }

  /**
   * Get the current destination country in the specified language
   * @param lang Language code ('pl', 'en', or 'es')
   */
  getDestinationCountry(lang: 'pl' | 'en' | 'es' = 'pl'): string {
    return this.currentDestination?.country[lang] || '';
  }

  getLatestBadge() {
    const path = 'assets/kowersacjebadges/';
    if (this.userDetails?.userData?.conversationsStats?.length > 0) {
      let finishedCategories = this.userDetails.userData.conversationsStats.filter(category =>
        category?.conversations?.every(conversation => conversation?.finished)
      );
      if (finishedCategories?.length > 0) {
        const latestCategory = finishedCategories[finishedCategories.length - 1];
        return path + latestCategory.id + '.png';
      } else {
        return path + '0.png';
      }
    }
    return path + '0.png';
  }

  /**
   * Gets the name of the next conversation level
   * @returns The name of the next level or a default string if not found
   */
  getNextLevelName(): string {
    // Get the current level number
    const currentLevelNum = parseInt(this.getLatestBadge()?.split("/").pop()?.split(".")[0] || '0', 10);
    const nextLevelNum = currentLevelNum + 1;

    // Get the level information from the conversations topics service
    const nextLevel = this.chatbotsConversationsTopicsService.topics.find(
      level => level.id === nextLevelNum
    );

    // Return the level title if found, otherwise a default message
    return nextLevel?.title || `Poziom ${nextLevelNum}`;
  }

  /**
   * Calculates how many conversations are left to unlock the next level
   * @returns The number of conversations left to unlock the next level
   */
  getConversationsLeftToNextLevel(): number {
    // Based on the UI text, we need 3 completed conversations to level up
    const requiredConversations = 3;

    // Get the current completed conversations count
    let completedConversations = 0;

    if (this.userDetails?.userData?.conversationsStats?.length > 0) {
      // Count all finished conversations in the latest category
      const latestCategoryIndex = this.userDetails.userData.conversationsStats.length - 1;
      const latestCategory = this.userDetails.userData.conversationsStats[latestCategoryIndex];

      if (latestCategory?.conversations) {
        completedConversations = latestCategory.conversations.filter(
          conversation => conversation?.finished
        ).length;
      }
    }

    // Calculate how many are left
    const conversationsLeft = Math.max(0, requiredConversations - completedConversations);
    return conversationsLeft;
  }

  getNextDestination(): string {
    const nextLevel = this.getTravelLevel() + 1;
    const destination = this.travelDestinationsService.getDestinationForLevel(nextLevel);
    return destination?.place['pl'] || 'następnego celu';
  }

  getNextDestinationDiamonds(): number {
    const currentLevel = this.getTravelLevel();
    const nextLevelDiamonds = (currentLevel + 1) * 10;
    const currentDiamonds = this.userDetails?.userData?.totalDiamonds || 0;
    return Math.max(0, nextLevelDiamonds - currentDiamonds);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub?.unsubscribe();
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
  }
  resetProgress() {
    this.userDetailsService.resetProgress();
  }

  /**
   * Opens the travel destinations modal
   */
  async openTravelDestinationsModal() {
    const modal = await this.modalController.create({
      component: TravelDestinationsModalComponent,
      cssClass: 'conversations-level-modal',
      backdropDismiss: true
    });

    await modal.present();
  }

  /**
   * Opens the progress history modal
   */
  async openProgressHistoryModal() {
    const modal = await this.modalController.create({
      component: ProgressHistoryModalComponent,
      cssClass: 'progress-history-modal',
      backdropDismiss: true
    });

    await modal.present();
  }

  async openConversationsLevelModal() {
    const modal = await this.modalController.create({
      component: ConversationsLevelModalComponent,
      cssClass: 'conversations-level-modal'
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data && data.topic) {
      // Navigate to the chatbots page with the selected topic
      this.router.navigate(['/home/chatbots'], {
        queryParams: {
          topic: data.topic.id,
          topicName: data.topic.topic,
          level: data.topic.levelId || data.topic.level
        }
      });
    }
  }

  updateEncouragementMessage() {
    if (this.isMessageTransitioning) return;

    // Select a random transition style
    const randomIndex = Math.floor(Math.random() * this.transitionStyles.length);
    this.currentTransition = this.transitionStyles[randomIndex];

    this.isMessageTransitioning = true;

    // Alternate between short and long messages for better visual effect
    const useShortMessage = Math.random() > 0.7; // 30% chance for short messages

    if (useShortMessage) {
      this.nextMessage = this.encouragementService.getShortMessage();
    } else {
      // For longer messages, occasionally use multi-line messages
      if (Math.random() > 0.5) {
        this.nextMessage = this.encouragementService.getLongMessage();
      } else {
        this.nextMessage = this.encouragementService.getRandomMessage();
      }
    }

    // Simplified DOM handling - only one operation
    setTimeout(() => {
      this.currentMessage = this.nextMessage;
      this.isMessageTransitioning = false;
    }, 700); // Match this with the new animation duration (0.7s)
  }

  /**
   * Opens the choose prize modal for testing purposes
   */
  async openChoosePrizeModal() {
    const modal = await this.modalController.create({
      component: ChoosePrizeComponent,
      cssClass: 'choose-prize-modal fullscreen-modal',
      componentProps: {
        isTestMode: true // Flag to indicate it's opened from test button
      },
      backdropDismiss: true,
      showBackdrop: true
    });

    // Store previous level before modal is dismissed
    const previousDiamonds = this.userDetails?.userData?.totalDiamonds || 0;
    const previousLevel = Math.floor(previousDiamonds / 10) || 1;


    await modal.present();

    // Refresh user details after modal is dismissed to update diamond count
    const { data } = await modal.onDidDismiss();


    if (data && data.updated) {
      // If we received updated data from the modal
      if (data.totalDiamonds !== undefined) {


        // Calculate new level directly from the diamond count
        const newDiamonds = data.totalDiamonds;
        const currentLevel = Math.floor(newDiamonds / 10) || 1;


        // Update the local user details with the new diamond count
        if (this.userDetails && this.userDetails.userData) {
          this.userDetails.userData.totalDiamonds = newDiamonds;

          // Also update the current destination based on the new diamond count
          this.updateCurrentDestination();

          // Check if level has increased
          if (currentLevel > previousLevel) {

            // Use setTimeout to ensure the toast appears after the modal is fully dismissed
            setTimeout(() => {
              this.presentLevelUpToast(currentLevel);
            }, 300);
          } else {

          }
        }
      }

      // Force refresh the user details from the server
      this.userDetailsService.getUserDetails();
    }
  }

  /**
   * Refreshes the user details from the server
   */
  refreshUserDetails() {

    // Get updated user details
    this.userDetailsService.getUserDetails();

    // Also explicitly refresh sets data to get accurate set learning statistics
    if (this.userDetailsService.userDetails?._id) {
      this.userDetailsService.getUsersSets(this.userDetailsService.userDetails._id);
    }

    // Check if weekly progress needs to be reset (Monday)
    this.checkAndResetWeeklyProgress();

    // Update the streak with current progress
    this.updateStreakWithCurrentProgress();
  }

  /**
   * Get total progress minutes combining both sets and conversations 
   * for progress calculation purposes
   */
  getTotalProgressMinutes(): number {
    return this.getSetsMinutes() + this.getConversationMinutes();
  }

  /**
   * Updates today's streak entry with the combined learning time from sets and conversations
   */
  updateStreakWithCurrentProgress(): void {
    if (!this.userDetails?.userData?.learningStreak ||
      !Array.isArray(this.userDetails.userData.learningStreak) ||
      this.userDetails.userData.learningStreak.length !== 7) {

      // Initialize the streak array if it doesn't exist or is invalid
      const newStreak = Array(7).fill(0);

      if (this.userDetailsService && this.userDetails?._id) {
        this.userDetailsService.setUsersProp(this.userDetails._id, 'learningStreak', newStreak);
        this.userDetailsService.setUsersProp(this.userDetails._id, 'lastStreakUpdate', new Date().toISOString());
      }


      return;
    }

    const today = new Date();
    const todayIndex = today.getDay(); // 0-6 (Sunday-Saturday)
    const totalProgressSeconds = this.getTotalProgressMinutes() * 60; // Convert minutes to seconds

    // Check if we need to update today's streak value
    if (totalProgressSeconds > 0 &&
      this.userDetails.userData.learningStreak[todayIndex] < totalProgressSeconds) {



      // Create a copy of the streak array
      const updatedStreak = [...this.userDetails.userData.learningStreak];

      // Update today's entry
      updatedStreak[todayIndex] = totalProgressSeconds;

      // Update streak in the backend
      if (this.userDetailsService && this.userDetails?._id) {
        this.userDetailsService.setUsersProp(this.userDetails._id, 'learningStreak', updatedStreak);
        this.userDetailsService.setUsersProp(this.userDetails._id, 'lastStreakUpdate', new Date().toISOString());
      }
    }
  }

  /**
   * Check if it's a new week and reset weekly progress if needed
   * The week starts on Monday and ends on Sunday
   */
  checkAndResetWeeklyProgress(): void {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, etc.

    // Get the last reset date or default to a past date
    const lastResetDate = this.userDetails?.userData?.lastWeeklyReset
      ? new Date(this.userDetails.userData.lastWeeklyReset)
      : new Date(0);

    // Check if today is Monday (1) and the last reset was not today
    if (dayOfWeek === 1 &&
      (lastResetDate.getDate() !== today.getDate() ||
        lastResetDate.getMonth() !== today.getMonth() ||
        lastResetDate.getFullYear() !== today.getFullYear())) {



      // Reset weekly progress
      if (this.userDetailsService && this.userDetails?._id) {
        // Reset weeklyProgress to 0
        this.userDetailsService.setUsersProp(this.userDetails._id, 'weeklyProgress', 0);

        // Reset conversationTime for the week to 0
        this.userDetailsService.setUsersProp(this.userDetails._id, 'conversationTime', 0);

        // Update last reset date
        this.userDetailsService.setUsersProp(this.userDetails._id, 'lastWeeklyReset', today.toISOString());


      }
    }
  }
}
