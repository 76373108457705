import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DictionaryService } from '../../services/dictionary.service';
import { HelpersService } from '../../services/helpers.service';
import { QuizService } from 'src/app/home/learn/quiz-wrapper/quizzes/multi-quiz/questions-quiz/quiz.service';
import { ChatService } from '../../services/chat.service';
import { ReadTextService } from '../../services/read-text.service';

@Component({
  selector: 'app-disassembled-text-constructor',
  templateUrl: './disassembled-text-constructor.component.html',
  styleUrls: ['./disassembled-text-constructor.component.scss'],
})
export class DisassembledTextConstructorComponent implements OnInit, OnDestroy {
  @Input() meaningId: string;
  @Input() wordId: string;
  @Input() onRead = false;
  @Input() translationPossible = false;
  @Input() dontMarkWordsForTranslation = false;
  @Input() blurPercentage: number = 0;
  @Input() itemToProcess: string | Array<object>;
  @Input() currentIdToProcess;
  @Input() readSentencePossible = false;
  @Input() onQuiz = false;
  @Input() lang = 'en';
  @Input() question;
  @Input() showTranslation = false;
  @Input() polishTranslation = '';
  @Input() markBlank = true;
  @Input() blank = false;

  @Input() onAnswerSummary = false;
  @Input() deviceHeight = 800;
  @Input() findTranslations = false;
  processedItem;
  originalBlurredPercentage = 0;
  text;
  help = false;
  textSize;
  private sub: any;
  public itemToDisplay;

  constructor(
    private dictionaryService: DictionaryService,
    private helpers: HelpersService,
    private quizService: QuizService,
    private chatService: ChatService,
    private readTextService: ReadTextService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.blurPercentage) {
      this.applyBlur();

    }

    if (changes.currentIdToProcess) {
      // this.polishTranslation = '';
      if (typeof this.itemToProcess === 'string') {
        const text = this.itemToProcess;
        this.textSize = this.helpers.calculateTextSize(text);
        this.text = this.itemToProcess;
      } else {
        const text = this.itemToProcess.map((item: any) => item.word).join('');
        this.textSize = this.helpers.calculateTextSize(text);
        this.text = this.itemToProcess.map((item: any) => item.word).join(' ');
      }
    }
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.currentIdToProcess) {
      if (changes.currentIdToProcess.currentValue) {
        if (this.meaningId === changes.currentIdToProcess.currentValue) {
          this.processedItem =
            this.dictionaryService.sentencesCheckedForTranslation.asObservable();
          // this.dictionaryService.checkTextForTranslations(
          //   this.itemToProcess,
          //   this.meaningId
          // );
        }
      }
    }
  }
  readSentence() {
    this.readTextService.stopReading();
    if (!this.onRead) {
      this.readTextService.readTextFromBlob(
        this.lang == 'es'
          ? this.question?.sentenceAudioES
          : this.question?.sentenceAudio,
        this.lang == 'es'
          ? this.question?.sentenceAudioESDownloaded
          : this.question?.sentenceAudioDownloaded,

        this.question.word1 + ' ' + this.question.word2,
        null
      );
    } else {
      this.readTextService.readTextFromMs(
        this.text,
        this.currentIdToProcess,
        null,
        null,
        null,
        null,
        this.lang == 'es'
      );
    }
  }
  checkTranslation() {
    this.chatService.translateToPolishWithChat(this.text);
  }
  helpClicked() {
    this.help = true;
    if (this.blurPercentage > 0) {
      this.originalBlurredPercentage = this.helpers.clone(this.blurPercentage);
    }
    this.blurPercentage = 0;
    this.applyBlur();
  }
  helpRelased() {
    this.help = false;

    this.blurPercentage = this.originalBlurredPercentage;
    this.applyBlur();
  }
  ngOnInit() {
    this.sub = this.chatService
      .chatMessagesListener()
      .subscribe((message: any) => {
        // this.polishTranslation = message.res.choices[0].message.content;
      });

    console.log
    if (!this.itemToProcess) return;

    if (typeof this.itemToProcess === 'string') {
      this.itemToDisplay = this.dictionaryService.disassembleObject(
        this.itemToProcess
      );

      // this.sub = this.processedItem.subscribe((data) => {
      //   if (data.meaningId === this.meaningId) {
      //     data.sentence.forEach((item) => {

      //     });
      //     this.itemToDisplay = data.sentence;
      //   }
      // });
    } else {
      this.itemToDisplay = this.itemToProcess;
      this.itemToDisplay.forEach((item: any) => {
        if (item.connectionColor === '#ffcdd2') {
          item.connectionColor = '#e57373';
        }
        if (item.connectionColor === '#e1bee7') {
          item.connectionColor = '#9575cd';
        }
        if (item.connectionColor === '#d1c4e9') {
          item.connectionColor = '#7e57c2';
        }
        if (item.connectionColor === '#c5cae9') {
          item.connectionColor = '#42a5f5';
        }
        if (item.connectionColor === '#b3e5fc') {
          item.connectionColor = '#1565c0';
        }
        if (item.connectionColor === '#b2ebf2') {
          item.connectionColor = '#00838f';
        }
        if (item.connectionColor === '#b2dfdb') {
          item.connectionColor = '#26a69a'; // Changed to a lighter green
        }
        if (item.connectionColor === '#c8e6c9') {
          item.connectionColor = '#66bb6a'; // Changed to a lighter green
        }
        if (item.connectionColor === '#dcedc8') {
          item.connectionColor = '#7cb342';
        }
        if (item.connectionColor === '#f0f4c3') {
          item.connectionColor = '#afb42b';
        }
        if (item.connectionColor === '#fff9c4') {
          item.connectionColor = '#fbc02d';
        }
        if (item.connectionColor === '#ffecb3') {
          item.connectionColor = '#ff9800'; // Changed to a darker orange
        }
        if (item.connectionColor === '#ffe0b2') {
          item.connectionColor = '#ff7043';
        }
      });
    }
    if (this.findTranslations) {
      this.processedItem =
        this.dictionaryService.sentencesCheckedForTranslation.asObservable();
      this.dictionaryService.checkTextForTranslations(
        this.itemToDisplay,
        this.meaningId
      );
    }
    this.applyBlur();
  }

  findWord(word) {
    if (word.connectedWords) {
      this.dictionaryService.findWordsForStudents(
        word.connectedWords,
        false,
        'exact',
        false,
        false,
        true,
        word.word,
        null,
        this.quizService.currentQuestion?.sentenceAudioES?.length > 0 ||
        this.lang == 'es'
      );
    } else {
      this.dictionaryService.findWordsForStudents(
        word,
        false,
        'exact',
        true,
        false,
        true,
        word,
        null,
        this.quizService.currentQuestion?.sentenceAudioES?.length > 0 ||
        this.lang == 'es'
      );
    }
  }
  applyBlur() {
    const totalWords = this.itemToDisplay?.length;
    if (!totalWords) {
      return;
    }
    const wordsToBlur = Math.round(
      Number(this.blurPercentage / 100) * totalWords
    );

    // Reset all words blur to false
    this.itemToDisplay.forEach((word) => {
      word.blur = false;
    });

    // Shuffle word indices of non-blank words
    const shuffledIndices = this.shuffleArray([
      ...Array(this.itemToDisplay.length).keys(),
    ]);

    // Apply blur to the first 'wordsToBlur' indices
    for (let i = 0; i < wordsToBlur; i++) {
      const wordIndex = shuffledIndices[i];
      this.itemToDisplay[wordIndex].blur = true;
    }


  }
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  isMultipleWordBlank(word: any): boolean {
    return word.blank && /\s/.test(word.word);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
