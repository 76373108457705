import { Injectable } from '@angular/core';
import uniqid from 'uniqid';
import clone from 'clone-deep';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  usersSets = [];
  colors400 = [
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#78909c',
  ];
  accentMap = {
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
  };
  constructor() { }
  removeSpecialCharsAndSpaces(str) {
    return this.removeAccentFromString(str)
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  }
  generateRandomString(length: number): string {
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const values = crypto.getRandomValues(new Uint32Array(length));
    values.forEach((value) => {
      result += charset[value % charset.length];
    });
    return result;
  }

  getLongestString(array) {
    let longestString = '';
    for (const item of array) {
      if (item.length > longestString.length) {
        longestString = item;
      }
    }
    return longestString;
  }

  translateObjectFromBackend(object) {
    let result;
    result = object.data;
    result.id = object._id;
    return result;
  }
  removeAccentFromString(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  convertGoogleDriveLink(link) {
    let id = link.replace('https://drive.google.com/file/d/', '');
    id = id
      .replace('/view?usp=share_link', '')
      .replace('/view?usp=sharing', '')
      .replace('/view?usp=drivesdk', '');

    const directLink =
      'https://drive.google.com/uc?id=' + id + '&export=download';
    console.log(
      '🚀 ~ file: helpers.service.ts ~ line 125 ~ HelpersService ~ convertGoogleDriveLink ~ directLink',
      directLink
    );
    return directLink;
  }
  getUniqueRandomItemsFromArray(array, numberOfItems) {
    let result = [];
    let tempArray = [...array]; // clone the original array

    for (let i = 0; i < numberOfItems; i++) {
      if (tempArray.length === 0) {
        break; // break if we've exhausted all elements
      }

      let randomIndex = Math.floor(Math.random() * tempArray.length); // get a random index
      result.push(tempArray[randomIndex]); // push the random item from tempArray to result
      tempArray.splice(randomIndex, 1); // remove the used item from tempArray
    }

    return result;
  }
  getRandomObjectFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }
  clone(item) {
    return clone(item);
  }
  removeDuplicatesObjbyId(array) {
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item.id == itemToCheck.id)) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }
  calculateTextSize(text: string): string {
    const deviceHeight = window.innerHeight;
    const textLength = text.toString().length;
    // Calculate the ideal font size based on the device height and text length
    let heightFactor = 1.4;
    let lengthFactor = 3.8;
    if (deviceHeight > 700) {
      heightFactor = 1.1;
      lengthFactor = 3.7;
    }

    const idealFontSize =
      (deviceHeight * heightFactor) / (textLength * lengthFactor);

    // Limit the font size to a maximum of 3vh and a minimum of 2vh
    const fontSize = Math.max(Math.min(idealFontSize, 3), 1.5);

    // Convert the font size to a string with "vh" units
    console.log(
      "🚀 ~ file: helpers.service.ts:55 ~ HelpersService ~ calculateTextSize ~ fontSize.toFixed(2) + 'vh':",
      fontSize.toFixed(2) + 'vh'
    );
    return fontSize.toFixed(2) + 'vh';
  }
  sortArrayByProperty(array, property, direction = 1) {
    array.sort(function compare(a, b) {
      // Check for nullish values
      const aIsNullish = a[property] === null || a[property] === undefined;
      const bIsNullish = b[property] === null || b[property] === undefined;

      // If both are nullish, they are equal in terms of sorting
      if (aIsNullish && bIsNullish) return 0;

      // If a is nullish but b is not, a should come first
      if (aIsNullish && !bIsNullish) return -1;

      // If b is nullish but a is not, b should come first
      if (!aIsNullish && bIsNullish) return 1;

      // Regular comparison
      let comparison = 0;
      if (a[property] > b[property]) {
        comparison = 1 * direction;
      } else if (a[property] < b[property]) {
        comparison = -1 * direction;
      }
      return comparison;
    });

    return array; // Chainable
  }

  get nativeWindow(): Window {
    return window;
  }

  fixJSONFormat(json: string): string {
    let originalJson = this.clone(json);



    return originalJson

  }









  chunk(arr, chunkSize) {
    if (chunkSize <= 0) {
      throw new Error('Invalid chunk size');
    }
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  shuffleArrayOfObjects(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  makeid(length?) {
    return uniqid();
  }
  getRandomNumber(min, max) {
    let result;
    result = Math.random() * (max - min) + min;
    return Math.round(result);
  }
}
