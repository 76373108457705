import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, from, switchMap, tap } from 'rxjs';
import { HelpersService } from './helpers.service';
import * as toWav from 'audiobuffer-to-wav';
import { PlatformService } from './platform.service';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { QuizService } from 'src/app/home/learn/quiz-wrapper/quizzes/multi-quiz/questions-quiz/quiz.service';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

@Injectable({
  providedIn: 'root',
})
export class SpeechToTextService {
  private url;
  private headers = new HttpHeaders({ 'Content-Type': 'audio/wav' });
  recognitionData = new Subject();
  recognitionPending = false;
  browserRecognitionSupported: any;
  ratioToCorrectAnswer: number = 0;
  rec = null;
  recResult = null;
  recognizedText = null;
  internalRecResult = null;
  understood: boolean;
  difficulty: any;
  expectedInput: any;
  lang: any;
  speakingTry = 0;

  constructor(
    private http: HttpClient,
    private helper: HelpersService,
    public quizService: QuizService,
    private platform: PlatformService,
    private userDetailsService: UserDetailsService
  ) {
    var { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    var recognition = new webkitSpeechRecognition();
    if (recognition) {
      this.browserRecognitionSupported = true;
    } else {
      this.browserRecognitionSupported = false;
    }
    this.url = this.platform.url;
    // this.url = 'https://lingking.com.pl:3001';
  }
  // Helper function to convert Float32Array to WAV Blob
  float32ArrayToWavBlob = (
    float32Array: Float32Array,
    sampleRate: number
  ): Blob => {
    const buffer = new ArrayBuffer(44 + float32Array.length * 2);
    const view = new DataView(buffer);

    // Write WAV header
    view.setUint32(0, 0x52494646, true); // "RIFF"
    view.setUint32(4, 36 + float32Array.length * 2, true);
    view.setUint32(8, 0x57415645, true); // "WAVE"
    view.setUint32(12, 0x666d7420, true); // "fmt "
    view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
    view.setUint16(20, 1, true); // AudioFormat (1 for PCM)
    view.setUint16(22, 1, true); // NumChannels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, sampleRate * 2, true); // ByteRate
    view.setUint16(32, 2, true); // BlockAlign
    view.setUint16(34, 16, true); // BitsPerSample
    view.setUint32(36, 0x64617461, true); // "data"
    view.setUint32(40, float32Array.length * 2, true);

    // Write audio data
    for (let i = 0, offset = 44; i < float32Array.length; i++, offset += 2) {
      const sample = Math.max(-1, Math.min(1, float32Array[i]));
      view.setInt16(
        offset,
        sample < 0 ? sample * 0x8000 : sample * 0x7fff,
        true
      );
    }

    return new Blob([view], { type: 'audio/wav' });
  };

  private async processAudio(audioFile: File): Promise<Blob> {
    const audioContext = new AudioContext();

    // Helper function to decode the audio data
    const decodeAudioData = (audioFile: File) =>
      new Promise<AudioBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          audioContext.decodeAudioData(
            reader.result as ArrayBuffer,
            resolve,
            reject
          );
        reader.readAsArrayBuffer(audioFile);
      });

    // Helper function to convert AudioBuffer to Blob
    // Helper function to convert AudioBuffer to Blob
    const audioBufferToBlob = (audioBuffer: AudioBuffer): Blob => {
      const wavBuffer = toWav(audioBuffer);
      return new Blob([wavBuffer], { type: 'audio/wav' });
    };

    // Load the audio data into an AudioBuffer
    const audioBuffer = await decodeAudioData(audioFile);

    // Create an OfflineAudioContext to process the audio data
    const offlineContext = new OfflineAudioContext({
      numberOfChannels: audioBuffer.numberOfChannels,
      length: audioBuffer.length,
      sampleRate: audioBuffer.sampleRate,
    });

    // Create a gain node for volume boost
    const gainNode = offlineContext.createGain();
    gainNode.gain.value = 1.2; // Adjust this value for volume boost

    // Create a biquad filter for noise reduction
    const filterNode = offlineContext.createBiquadFilter();
    filterNode.type = 'lowpass';
    filterNode.frequency.value = 1200;
    // Adjust this value for noise reduction

    // Create a dynamics compressor node for automatic gain control
    const compressorNode = offlineContext.createDynamicsCompressor();
    compressorNode.threshold.value = -45;
    compressorNode.knee.value = 30;
    compressorNode.ratio.value = 8;
    compressorNode.attack.value = 0.003;
    compressorNode.release.value = 0.1;

    // Process the audio data
    const sourceNode = offlineContext.createBufferSource();
    sourceNode.buffer = audioBuffer;
    sourceNode
      .connect(gainNode)
      .connect(filterNode)
      .connect(compressorNode) // Add the compressor node to the chain
      .connect(offlineContext.destination);

    sourceNode.start(0);
    const updatedAudioBuffer = await offlineContext.startRendering();
    const updatedAudioBlob = audioBufferToBlob(updatedAudioBuffer);
    return updatedAudioBlob;
  }

  // transcribe(audioFile: File) {
  //   this.http
  //     .post(this.url + '/api/chat/transcribe', {
  //       file: audioFile,
  //     })
  //     .subscribe((response: any) => {
  //       
  //     });
  // }
  transcribe(audioFile: File, lang: string = 'en'): Observable<any> {

    const headers = new HttpHeaders();
    const options = { headers };

    return from(this.processAudio(audioFile)).pipe(
      switchMap((updatedAudioBlob) => {

        const formData = new FormData();
        formData.append('file', updatedAudioBlob, 'audio.wav');
        formData.append('language', lang);

        return this.http.post<any>(
          `${this.url}/api/chat/transcribe`,
          formData,
          options
        );
      }),
      tap(response => {

      })
    );
  }

  startBrowserRecognition(expectedInput, possibilities, lang?, difficulty?) {
    this.difficulty = difficulty;
    this.expectedInput = expectedInput;
    this.lang = lang;

    let language = null;
    switch (lang) {
      case 'english':
        language = 'en-US';
        break;
      case 'german':
        language = 'de-DE';
        break;
      case 'french':
        language = 'fr-FR';
        break;
      case 'spanish':
        language = 'es-ES';
        break;

      default:
        break;
    }
    var { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    var recognition = new webkitSpeechRecognition();
    this.rec = recognition;
    this.rec.continuous = true;
    if (!lang) {
      recognition.lang = 'en-US';
    } else {
      recognition.lang = language;
    }
    recognition.interimResults = false;
    recognition.maxAlternatives = 10;
    recognition.start();

    recognition.onstart = (e) => {
      this.recognitionData.next({ listening: true });
    };

    recognition.onend = (e) => {
      this.internalRecResult = e;
    };
    recognition.onerror = (e) => {

      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });

      recognition = null;
    };
    recognition.onnomatch = (e) => {

      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });
      recognition = null;
    };

    recognition.onresult = (e) => {
      let result = e.results;

      if (expectedInput) {
        this.recResult = result;
      }
    };
  }
  stopBrowserRecognition() {
    this.rec.stop();
    this.checkSimilarity(this.expectedInput, this.recResult, this.difficulty);
    this.recognitionPending = false;
  }
  checkSimilarity(expectedInput, result, difficulty?, fromExt?, noSummary?) {
    this.ratioToCorrectAnswer = 0;
    let similaritiesArray = [];

    let resultsArray = [];
    if (this.browserRecognitionSupported && !fromExt) {
      resultsArray = Array.from(result?.[0] ?? []);
    } else {
      resultsArray = [{ transcript: result }];
    }

    const transcript = resultsArray[0].transcript;

    // Calculate similarity using our enhanced methods
    const similarity = this.compareStrings(
      this.normalizeString(expectedInput),
      this.normalizeString(transcript)
    );

    similaritiesArray.push({
      word: transcript.toLowerCase().trim().replace('.', ''),
      similarity: similarity,
    });

    similaritiesArray = this.helper.sortArrayByProperty(
      similaritiesArray,
      'similarity',
      -1
    );


    let selectedOption = similaritiesArray[0];
    this.recognizedText = selectedOption.word;

    // Adjust difficulty thresholds based on level
    let difficultyRate = 0;
    if (difficulty == 'easy') {
      difficultyRate = this.browserRecognitionSupported && !fromExt ? 0.6 : 0.65;
    } else if (difficulty == 'medium') {
      difficultyRate = this.browserRecognitionSupported && !fromExt ? 0.7 : 0.75;
    } else if (difficulty == 'hard') {
      difficultyRate = this.browserRecognitionSupported && !fromExt ? 0.8 : 0.85;
    }

    // Small decrease for each try to encourage progress
    difficultyRate = difficultyRate - (this.speakingTry * 0.03);

    // Add feedback mechanism for learners
    let feedbackMessage = '';
    if (similarity > 0.9) {
      feedbackMessage = 'Doskonale! Twoja wymowa jest bardzo dobra.';
    } else if (similarity > 0.7) {
      feedbackMessage = 'Dobrze! Twoja wymowa jest zrozumiała.';
    } else if (similarity > difficultyRate) {
      feedbackMessage = 'W porządku. Kontynuuj ćwiczenia, aby poprawić wymowę.';
    } else {
      feedbackMessage = 'Spróbuj jeszcze raz, zwracając uwagę na wymowę.';
    }

    this.ratioToCorrectAnswer = similarity;

    if (similarity > difficultyRate) {
      if (!noSummary) {
        this.quizService.checkAnswer(
          'correct',
          this.ratioToCorrectAnswer,
          feedbackMessage,
          this.recognizedText,
          null,
          null,
          { fromSpeaking: true, preventAutoDismiss: true }
        );
      }
      this.outputResult(expectedInput);
    } else {
      if (!noSummary) {
        this.quizService.presentActionSheet(
          'wrong',
          this.ratioToCorrectAnswer > 0 ? this.ratioToCorrectAnswer : 0.01,
          feedbackMessage,
          this.recognizedText,
          null,
          null,
          { fromSpeaking: true, preventAutoDismiss: true }
        );
      }
      this.outputResult(null);
    }
    this.ratioToCorrectAnswer = 0;
  }
  outputResult(result) {
    this.understood = true;
    this.recognitionData.next(result);

    this.recognitionPending = false;
  }
  stopRecognition() {
    this.recognitionPending = false;
    this.recognitionData.next({ listening: false });
    this.recognitionData.next({ understood: false });
    if (this.rec) {
      this.rec.abort();
    }

  }
  compareStrings(transcribedAudio: string, expectedAnswer: string): number {
    // Normalize strings: remove special characters, extra spaces, and convert to lowercase
    const normalizedTranscribed = this.normalizeString(transcribedAudio);
    const normalizedExpected = this.normalizeString(expectedAnswer);

    // For empty strings, return appropriate values
    if (normalizedTranscribed === normalizedExpected) return 1;
    if (!normalizedTranscribed || !normalizedExpected) return 0;

    // Use multiple comparison strategies and weight them
    const bigramSimilarity = this.compareBigrams(normalizedTranscribed, normalizedExpected);
    const phoneticalSimilarity = this.comparePhonetically(normalizedTranscribed, normalizedExpected);
    const wordOrderSimilarity = this.compareWordOrder(normalizedTranscribed, normalizedExpected);
    const keywordSimilarity = this.compareKeywords(normalizedTranscribed, normalizedExpected);

    // Weight the different similarity measures (can be adjusted based on difficulty level)
    // Phonetic similarity is given higher weight for language learners
    return (bigramSimilarity * 0.3) +
      (phoneticalSimilarity * 0.4) +
      (wordOrderSimilarity * 0.15) +
      (keywordSimilarity * 0.15);
  }

  /**
   * Normalize string by removing special characters, extra spaces, and converting to lowercase
   */
  normalizeString(text: string): string {
    if (!text) return '';
    return this.helper
      .removeSpecialCharsAndSpaces(text)
      .replace('A: ', '')
      .replace('B: ', '')
      .trim()
      .toLowerCase();
  }

  /**
   * Compare strings using bigrams - good for detecting spelling/typing errors
   */
  compareBigrams(first: string, second: string): number {
    if (first === second) return 1;
    if (first.length < 2 || second.length < 2) return 0;

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
      const bigram = first.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
      firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
      const bigram = second.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

      if (count > 0) {
        firstBigrams.set(bigram, count - 1);
        intersectionSize++;
      }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
  }

  /**
   * Compare strings phonetically - crucial for language learners
   * Uses a simplified phonetic algorithm to compare how words sound
   */
  comparePhonetically(first: string, second: string): number {
    const firstWords = first.split(/\s+/);
    const secondWords = second.split(/\s+/);

    // If different number of words, penalize but don't reject outright
    const lengthPenalty = Math.min(firstWords.length, secondWords.length) /
      Math.max(firstWords.length, secondWords.length);

    // Compare phonetic codes of each word and get similarity
    let phoneticalMatches = 0;
    const maxPossibleMatches = Math.max(firstWords.length, secondWords.length);

    // Create phonetic maps for both strings
    const firstPhonetics = firstWords.map(word => this.getPhoneticCode(word));
    const secondPhonetics = secondWords.map(word => this.getPhoneticCode(word));

    // Compare phonetic representations
    for (const firstCode of firstPhonetics) {
      // Find best match in second list
      let bestMatch = 0;
      for (const secondCode of secondPhonetics) {
        const similarity = this.phoneticalCodeSimilarity(firstCode, secondCode);
        bestMatch = Math.max(bestMatch, similarity);
      }
      phoneticalMatches += bestMatch;
    }

    return (phoneticalMatches / maxPossibleMatches) * lengthPenalty;
  }

  /**
   * Calculate similarity between two phonetic codes
   */
  phoneticalCodeSimilarity(code1: string, code2: string): number {
    if (code1 === code2) return 1;
    if (!code1 || !code2) return 0;

    // Allow for some variance in the codes (handles similar sounds)
    let matches = 0;
    const maxLength = Math.max(code1.length, code2.length);
    const minLength = Math.min(code1.length, code2.length);

    for (let i = 0; i < minLength; i++) {
      // Exact match
      if (code1[i] === code2[i]) {
        matches += 1;
      }
      // Similar consonant groups
      else if (this.areSimilarSounds(code1[i], code2[i])) {
        matches += 0.7;
      }
    }

    return matches / maxLength;
  }

  /**
   * Check if two phonetic sounds are similar (common pronunciation errors for learners)
   */
  areSimilarSounds(sound1: string, sound2: string): boolean {
    // Groups of sounds that are commonly confused by language learners
    const similarSoundsGroups = [
      ['v', 'f', 'w'],  // v/f/w confusion
      ['t', 'd'],       // t/d confusion
      ['p', 'b'],       // p/b confusion
      ['s', 'z'],       // s/z confusion
      ['m', 'n'],       // m/n confusion
      ['r', 'l'],       // r/l confusion (especially for Asian speakers)
      ['i', 'e'],       // i/e confusion
      ['u', 'o'],       // u/o confusion
      ['θ', 's', 't'],  // th/s/t confusion (th as in "think")
      ['ð', 'z', 'd'],  // th/z/d confusion (th as in "this")
    ];

    return similarSoundsGroups.some(group =>
      group.includes(sound1) && group.includes(sound2)
    );
  }

  /**
   * Create a simplified phonetic code for a word
   * This is a very simplified version of the Soundex/Metaphone algorithms
   */
  getPhoneticCode(word: string): string {
    if (!word) return '';

    // Convert to lowercase and remove non-alphabetic characters
    word = word.toLowerCase().replace(/[^a-z]/g, '');
    if (!word) return '';

    // Keep first letter
    let code = word[0];

    // Replace consonants with phonetic codes
    const replacements = {
      'b': 'b', 'p': 'b', 'f': 'f', 'v': 'f', 'w': 'f',
      'c': 'k', 'k': 'k', 'q': 'k', 'g': 'k', 'j': 'j',
      'd': 'd', 't': 'd', 'l': 'l', 'r': 'r',
      'm': 'm', 'n': 'n',
      's': 's', 'z': 's', 'x': 's',
      'th': 'θ', 'ch': 'č', 'sh': 'š', 'ph': 'f',
      'wh': 'w', 'zh': 'ž'
    };

    // Process rest of the word
    for (let i = 1; i < word.length; i++) {
      // Check for digraphs (th, ch, etc.)
      const digraph = word.substring(i, i + 2).toLowerCase();
      if (replacements[digraph]) {
        code += replacements[digraph];
        i++; // Skip next letter
        continue;
      }

      // Single consonants
      const char = word[i];
      if (replacements[char]) {
        // Avoid repeating the same phonetic code
        if (code.charAt(code.length - 1) !== replacements[char]) {
          code += replacements[char];
        }
      }
      // Keep vowels but compress repeated ones
      else if ('aeiou'.includes(char)) {
        if (code.charAt(code.length - 1) !== char) {
          code += char;
        }
      }
    }

    return code;
  }

  /**
   * Compare word order between strings
   * Important for grammar evaluation
   */
  compareWordOrder(first: string, second: string): number {
    const firstWords = first.split(/\s+/);
    const secondWords = second.split(/\s+/);

    const totalWords = Math.max(firstWords.length, secondWords.length);
    if (totalWords === 0) return 1;

    let correctPositions = 0;
    const minLength = Math.min(firstWords.length, secondWords.length);

    // Check words in the same position
    for (let i = 0; i < minLength; i++) {
      if (firstWords[i] === secondWords[i]) {
        correctPositions++;
      } else {
        // Check for similar words with Levenshtein distance
        const similarity = this.levenshteinSimilarity(firstWords[i], secondWords[i]);
        if (similarity > 0.7) {
          correctPositions += similarity;
        }
      }
    }

    return correctPositions / totalWords;
  }

  /**
   * Compare similarity of key content words (nouns, verbs, adjectives)
   * This is important for meaning preservation
   */
  compareKeywords(first: string, second: string): number {
    // Simple implementation that treats all words as potentially important
    const firstWords = first.split(/\s+/);
    const secondWords = second.split(/\s+/);

    if (firstWords.length === 0 || secondWords.length === 0) {
      return 0;
    }

    // Count matching words regardless of position
    let matches = 0;
    const firstWordsCopy = [...firstWords];

    // Try to match each word from the second string
    for (const word of secondWords) {
      const index = firstWordsCopy.findIndex(w =>
        w === word || this.levenshteinSimilarity(w, word) > 0.7
      );

      if (index !== -1) {
        matches++;
        firstWordsCopy.splice(index, 1); // Remove the matched word
      }
    }

    return matches / Math.max(firstWords.length, secondWords.length);
  }

  /**
   * Calculate Levenshtein similarity between two words
   * Useful for catching spelling errors and minor pronunciation differences
   */
  levenshteinSimilarity(a: string, b: string): number {
    if (a === b) return 1;
    if (!a || !b) return 0;

    const matrix = [];

    // Initialize matrix
    for (let i = 0; i <= a.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }

    // Fill matrix
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,      // deletion
          matrix[i][j - 1] + 1,      // insertion
          matrix[i - 1][j - 1] + cost  // substitution
        );
      }
    }

    // Calculate similarity as 1 - normalized distance
    const maxDistance = Math.max(a.length, b.length);
    return 1 - (matrix[a.length][b.length] / maxDistance);
  }
  recognitionResultListener() {
    return this.recognitionData.asObservable();
  }
}
