import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatbots-cost',
  templateUrl: './chatbots-cost.component.html',
  styleUrls: ['./chatbots-cost.component.css']
})
export class ChatbotsCostComponent implements OnInit {
  @Input() conversation: any[];

  stats: { name: string, value: number }[] = [];
  totalCost: number = 0;

  ngOnInit() {
    console.log('Received conversation:', this.conversation);
    this.calculateCosts();
  }

  calculateCosts() {
    let tokensIn = 0;
    let tokensOut = 0;
    let speakingSeconds = 0;
    let readText = 0;
    let correctionTokens = 0;
    let hintsTokens = 0;
    let translatedTextTokens = 0;

    this.conversation.forEach(message => {
      if (message.tokensInTokensStats) tokensIn += parseFloat(message.tokensInTokensStats);
      if (message.tokensOutTokensStats) tokensOut += parseFloat(message.tokensOutTokensStats);
      if (message.speakingSecondsStats) speakingSeconds += parseFloat(message.speakingSecondsStats);
      if (message.readTextStats) readText += parseFloat(message.readTextStats);
      if (message.correctionTokenStats) correctionTokens += parseFloat(message.correctionTokenStats);
      if (message.hintsTokenStats) hintsTokens += parseFloat(message.hintsTokenStats);
      if (message.translatedTextTokensStats) translatedTextTokens += parseFloat(message.translatedTextTokensStats);
    });

    const costPerTokenIn = 0.8 / 1000000;
    const costPerTokenOut = 4 / 1000000;
    const costPerSpeakingSecond = 0.006 / 60;
    const costPerReadText = 16 / 1000000;
    const costPerCorrectionToken = (0.8 + 4) / 2 / 1000000; // Rough average cost per token
    const costPerHintToken = (0.8 + 4) / 2 / 1000000; // Rough average cost per token
    const costPerTranslatedTextToken = (0.8 + 4) / 2 / 1000000; // Rough average cost per token

    const tokensInCost = parseFloat((tokensIn * costPerTokenIn).toFixed(6));
    const tokensOutCost = parseFloat((tokensOut * costPerTokenOut).toFixed(6));
    const speakingSecondsCost = parseFloat((speakingSeconds * costPerSpeakingSecond).toFixed(6));
    const readTextCost = parseFloat((readText * costPerReadText).toFixed(6));
    const correctionTokensCost = parseFloat((correctionTokens * costPerCorrectionToken).toFixed(6));
    const hintsTokensCost = parseFloat((hintsTokens * costPerHintToken).toFixed(6));
    const translatedTextTokensCost = parseFloat((translatedTextTokens * costPerTranslatedTextToken).toFixed(6));

    this.stats = [
      { name: 'Tokens In', value: tokensIn },
      { name: 'Tokens Out', value: tokensOut },
      { name: 'Speaking Seconds', value: speakingSeconds },
      { name: 'Read Text', value: readText },
      { name: 'Correction Tokens', value: correctionTokens },
      { name: 'Hints Tokens', value: hintsTokens },
      { name: 'Translated Text Tokens', value: translatedTextTokens },
      { name: 'Tokens In Cost', value: tokensInCost },
      { name: 'Tokens Out Cost', value: tokensOutCost },
      { name: 'Speaking Seconds Cost', value: speakingSecondsCost },
      { name: 'Read Text Cost', value: readTextCost },
      { name: 'Correction Tokens Cost', value: correctionTokensCost },
      { name: 'Hints Tokens Cost', value: hintsTokensCost },
      { name: 'Translated Text Tokens Cost', value: translatedTextTokensCost }
    ];

    this.totalCost = parseFloat((tokensInCost + tokensOutCost + speakingSecondsCost + readTextCost + correctionTokensCost + hintsTokensCost + translatedTextTokensCost).toFixed(6));
  }
}