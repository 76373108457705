import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-correction-bar',
    template: `
        <div class="correction-bar">
            <ng-container *ngIf="suggestions.length > 0; else noSuggestions">
                <button 
                    *ngFor="let suggestion of suggestions" 
                    (click)="onSuggestionClick(suggestion)"
                    class="suggestion-button">
                    {{ suggestion }}
                </button>
            </ng-container>
            <ng-template #noSuggestions>
                <span class="no-suggestions">Tutaj pojawią się sugestie</span>
            </ng-template>
        </div>
    `,
    styles: [`
        .correction-bar {
            min-height: 40px;
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            background-color: #f5f5f5;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            white-space: nowrap;
            border-radius: 15px;
            margin-bottom: 8px;
            justify-content: space-evenly;
        }
        
        .suggestion-button {
            margin: 0 4px;
            padding: 4px 12px;
            border: 1px solid #ddd;
            border-radius: 16px;
            background-color: white;
            font-size: 14px;
            color: #333;
            cursor: pointer;
        }
        
        .suggestion-button:hover {
            background-color: #eee;
        }

        .no-suggestions {
            font-size: 14px;
            color: #999;
        }
    `]
})
export class CorrectionBarComponent {
    @Input() suggestions: string[] = [];
    @Output() suggestionSelected = new EventEmitter<string>();

    onSuggestionClick(suggestion: string): void {
        this.suggestionSelected.emit(suggestion);
    }
}