import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsService } from '../chatbots.service';

@Component({
  selector: 'app-chatbot-correction',
  templateUrl: './chatbot-correction.component.html',
  styleUrls: ['./chatbot-correction.component.css']
})
export class ChatbotCorrectionComponent implements OnInit {
  @Input() messages;
  @Input() messageIndex;
  msgToSend
  showSpinner = false;
  @Input() correctionText = ''
  @Input() lang: 'en' | 'es' = 'en'
  selectedChatbot
  previousTeacherMessage: any;
  previousUsersMessage: any;



  @Output() correctionTextChange = new EventEmitter();

  constructor(
    private aiService: ChatbotsService
  ) { }

  ngOnInit(): void {
    // Initialization logic
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.messages?.currentValue && changes?.messages?.firstChange) {
      if (this.lang === 'en') {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Ron')
      }
      else {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Leslie')
      }
      const previousTeachersMessageExists = this.messages[this.messageIndex - 2]?.role
      if (previousTeachersMessageExists) {
        this.previousTeacherMessage = this.messages[this.messageIndex - 2]?.content
      }
      else {
        this.previousTeacherMessage = ''
      }
    }
  }
}
