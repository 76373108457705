import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private isToolbarVisible: boolean = true;
  toolbarVisibilityChange: EventEmitter<boolean> = new EventEmitter();

  toggleToolbar() {
    this.isToolbarVisible = !this.isToolbarVisible;
    this.toolbarVisibilityChange.emit(this.isToolbarVisible);
  }

  setToolbarVisibility(isVisible: boolean) {
    this.isToolbarVisible = isVisible;
    this.toolbarVisibilityChange.emit(this.isToolbarVisible);
  }

  getToolbarVisibility(): boolean {
    return this.isToolbarVisible;
  }
}