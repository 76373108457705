import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsService } from '../chatbots.service';

@Component({
  selector: 'app-chatbot-correction',
  templateUrl: './chatbot-correction.component.html',
  styleUrls: ['./chatbot-correction.component.css']
})
export class ChatbotCorrectionComponent implements OnInit {
  @Input() messages;
  @Input() messageIndex;
  msgToSend
  showSpinner = false;
  @Input() correctionText = ''
  @Input() lang: 'en' | 'es' = 'en'
  selectedChatbot
  previousTeacherMessage: any;
  previousUsersMessage: any;



  @Output() correctionTextChange = new EventEmitter();

  constructor(
    private aiService: ChatbotsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.messages?.currentValue && changes?.messages?.firstChange) {
      if (this.lang === 'en') {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Ron')
      }
      else {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Leslie')
      }
      const previousTeachersMessageExists = this.messages[this.messageIndex - 2]?.role
      if (previousTeachersMessageExists) {
        this.previousTeacherMessage = this.messages[this.messageIndex - 2]?.content
      }
      else {
        this.previousTeacherMessage = ''
      }
      console.log("🚀 ~ ChatbotHintComponent ~ ngOnInit ~ currentMessage", this.previousTeacherMessage
      )
      this.previousUsersMessage = this.messages[this.messageIndex - 1].content || ''
      console.log("🚀 ~ ChatbotHintComponent ~ ngOnInit ~ previousUsersMessage:", this.previousUsersMessage)
      if (this.correctionText === '' || !this.correctionText) {
        this.sendMessage()
      }
    }
  }
  ngOnInit() {

  }

  sendMessage() {
    console.log('generating corrections...')
    this.showSpinner = true;
    this.msgToSend = {
      "role": "user",
      "content": " teacher: " +
        this.previousTeacherMessage + " student: " + this.previousUsersMessage


    }
    const data = {
      messages: [this.msgToSend

      ],
      aiProvider: this.selectedChatbot.aiProvider,
      model: this.selectedChatbot.model,
      maxTokens: this.selectedChatbot.maxTokens,
      maxMessages: this.selectedChatbot.maxMessages,
      systemMsg: this.selectedChatbot.systemMsg,
      files: null
    };
    console.log("🚀 ~ ChatbotCorrectionComponent ~ sendMessage ~ data //correction", data.messages[0].content)
    this.aiService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        console.log("🚀 ~ hintComponent ~ sendMessage ~ res:", res)
        this.correctionText = res.res.content
        this.correctionTextChange.emit({ correction: this.correctionText, messageIndex: this.messageIndex, msgToSend: this.msgToSend })
        this.showSpinner = false;

      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
}
