import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private activeSpinner: HTMLIonLoadingElement | null = null;
  private spinnerTimeout: any = null;
  private spinnerMessage = 'Trwa wczytywanie...';
  private isSpinnerVisible = false;
  private readonly MAX_SPINNER_DURATION = 5000; // 5 seconds maximum spinner duration

  constructor(private loadingCtrl: LoadingController, private router: Router) {
    // this.router.events.subscribe((event) => {
    //   
    //   if (event instanceof NavigationStart) {
    //     this.showSpinner();
    //   }
    //   if (
    //     event instanceof NavigationEnd ||
    //     event instanceof NavigationCancel ||
    //     event instanceof NavigationError
    //   ) {
    //      // Debug log
    //     this.hideSpinner();
    //   }
    // });
  }

  async showSpinner() {
    if (this.isSpinnerVisible) {
      return;
    }

    try {
      if (this.spinnerTimeout) {
        clearTimeout(this.spinnerTimeout);
        this.spinnerTimeout = null;
      }

      if (this.activeSpinner) {
        await this.activeSpinner.dismiss();
        this.activeSpinner = null;
      }

      this.activeSpinner = await this.loadingCtrl.create({
        message: this.spinnerMessage,
        cssClass: 'dark-backdrop',
        spinner: 'bubbles',
      });

      await this.activeSpinner.present();
      this.isSpinnerVisible = true;

      // Always set a safety timeout to ensure spinner is hidden
      this.spinnerTimeout = setTimeout(() => {
        this.hideSpinner();
        console.warn('Spinner dismissed after 5 seconds due to safety timeout.');
      }, this.MAX_SPINNER_DURATION);
    } catch (error) {
      console.error('Error showing spinner:', error);
      this.isSpinnerVisible = false;
    }
  }

  async hideSpinner() {
    try {
      if (this.spinnerTimeout) {
        clearTimeout(this.spinnerTimeout);
        this.spinnerTimeout = null;
      }

      if (this.activeSpinner) {
        await this.activeSpinner.dismiss();
        this.activeSpinner = null;
        this.isSpinnerVisible = false;
      }
    } catch (error) {
      console.error('Error hiding spinner:', error);
      // Reset state in case of error
      this.activeSpinner = null;
      this.isSpinnerVisible = false;
    }
  }
}
