import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from './platform.service';
import { HelpersService } from './helpers.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { StudentDictionaryDialogComponent } from '../components/student-dictionary-dialog/student-dictionary-dialog.component';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  sentencesCheckedForTranslation = new Subject();
  url;
  checkingTranslationForStudents = false;
  constructor(
    private http: HttpClient,
    private platform: PlatformService,
    private helpers: HelpersService,
    private modalCtrl: ModalController,
    private chatService: ChatService
  ) {
    this.url = this.platform.url;
  }

  checkTextForTranslations(itemToDisassemble, meaningId?) {
    let disassembledSentence = [];
    if (itemToDisassemble) {
      disassembledSentence =
        typeof itemToDisassemble == 'string'
          ? this.disassembleObject(itemToDisassemble)
          : itemToDisassemble;

      const words = disassembledSentence.map((word: any) => {
        const result = {
          word: word.word.replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
          id: word.id,
          meaningId2: meaningId,
        };
        return result;
      });

      this.http
        .post<{ message: string }>(
          this.url + '/api/dictionary/checksentencefortranslations/',
          { words }
        )
        .subscribe((response: any) => {
          const _meaningId = response?.foundWords[0].meaningId;
          disassembledSentence.forEach((word) => {
            const wordInResponse = response.foundWords.find(
              (responseWord) => word.id === responseWord.id
            );
            if (wordInResponse.exists) {
              word.hasTranslation = true;
            }
          });
          if (_meaningId === meaningId) {
            this.sentencesCheckedForTranslation.next({
              sentence: disassembledSentence,
              meaningId,
            });
          }
        });
    }
  }

  disassembleObject(object: string, mode?) {
    let singleWords;
    if (mode !== undefined) {
      singleWords = object.split("'");
    } else {
      singleWords = object
        .replace('/', ' / ')
        .replace('(', '( ')
        .replace(')', ' )')
        .replace(/\s\s+/g, ' ')

        .split(' ');
    }

    const disassembledObject = [];
    singleWords.forEach((element) => {
      disassembledObject.push({
        id: this.helpers.makeid(6),
        word: element.trim(),
        connectedWith: [],
        connectedWord: '',
        connectionColor: '',
        verb: false,
      });
    });
    return disassembledObject;
  }
  findWords(
    wordToFind: any,
    searchThruSentence: any,
    searchMode: any,
    translation: boolean,
    polish: boolean,
    spanish?: boolean,
    onlySpanish = false
  ) {
    if (wordToFind === '') {
      // this.getWordsList()
      return;
    }

    // Normalize the word to find by removing special characters but preserving spaces
    const normalizedWord = wordToFind
      .toLowerCase()
      .trim()
      .replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

    // If onlySpanish is true, we force spanish to be true
    if (onlySpanish) {
      spanish = true;
    }



    this.http
      .get<{ words: any }>(
        this.url +
        '/api/dictionary/findWords?wordtofind=' +
        normalizedWord +
        '&searchthrusentence=' +
        searchThruSentence +
        '&searchmode=' +
        searchMode +
        '&polish=' +
        polish +
        '&spanish=' +
        spanish
      )
      .pipe(
        map((words: any) =>
          words.words.map((word) => ({
            id: word._id,
            word: word.data.word,
            added: word.data.added,
            meanings: word.data.meanings,
          }))
        )
      )
      .subscribe(async (words: any) => {
        let finalMeanings = [];
        words.forEach((singleWord) => {
          singleWord.meanings?.forEach((meaning) => {
            // Filter out Spanish words by default (when spanish flag is false)
            // If only Spanish is requested, filter for only Spanish words (with lang:spanish tag)
            const isSpanishWord = meaning.tags && meaning.tags.some((tag) => tag.tag === 'lang:spanish');

            // Include the word if:
            // 1. Looking for Spanish words and this is a Spanish word (onlySpanish is true and word is Spanish)
            // 2. Looking for all words including Spanish (spanish flag is true and onlySpanish is false) - no filtering
            // 3. Looking for non-Spanish words (spanish flag is false and word is not Spanish)
            if ((onlySpanish && isSpanishWord) || (spanish && !onlySpanish) || (!spanish && !isSpanishWord)) {
              finalMeanings.push(meaning);
            }
          });
        });



        // Only translate to Polish if we're searching in English and found nothing
        if (finalMeanings.length === 0 && !polish && !spanish) {


          try {
            // Use the new method that returns properly formatted dictionary entries
            const translatedEntries = await this.chatService.translateWordToDictionaryFormat(normalizedWord);


            // Display the translated results in the dictionary dialog
            this.openStudentsDictionaryResultsDialog(
              translatedEntries,
              normalizedWord,
              'angielskim'
            );
          } catch (error) {
            console.error('Błąd podczas tłumaczenia:', error);
            // If there's an error, still show the dialog but with empty results
            this.openStudentsDictionaryResultsDialog(
              [],
              normalizedWord,
              'angielskim'
            );
          }
        } else {
          // Word found in dictionary, show results as usual
          this.openStudentsDictionaryResultsDialog(
            finalMeanings,
            wordToFind,
            polish ? 'polskim' : spanish ? 'hiszpańskim' : 'angielskim'
          );
        }
      });
  }
  findWordsForStudents(
    wordToFind,
    searchThruSentence,
    searchMode,
    object?,
    fromButton?,
    connected?,
    optionalWordForConnected?,
    connectedNotFound?,
    spanish?,
    onlySpanish = false
  ) {
    this.checkingTranslationForStudents = true;

    // Ensure wordToFind is a string
    const wordToFindStr = typeof wordToFind === 'string' ? wordToFind :
      (wordToFind && typeof wordToFind === 'object' && wordToFind.word ? wordToFind.word : '');

    // Normalize the word to find by removing special characters but preserving spaces
    const normalizedWord = wordToFindStr
      .toLowerCase()
      .trim()
      .replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');

    // If onlySpanish is true, we force spanish to be true
    if (onlySpanish) {
      spanish = true;
    }



    this.http
      .get<{ words: any }>(
        this.url +
        '/api/dictionary/findWords?wordtofind=' +
        normalizedWord +
        '&searchthrusentence=' +
        searchThruSentence +
        '&searchmode=' +
        searchMode +
        '&polish=' +
        false +
        '&spanish=' +
        spanish
      )
      .pipe(
        map((words: any) => {
          return words.words.map((word) => ({
            id: word._id,
            word: word.data.word,
            added: word.data.added,
            meanings: word.data.meanings,
          }));
        })
      )
      .subscribe(async (words: any) => {
        let finalMeanings = [];
        words.forEach((singleWord) => {
          singleWord.meanings?.forEach((meaning) => {
            // Filter out Spanish words by default (when spanish flag is false)
            // If only Spanish is requested, filter for only Spanish words (with lang:spanish tag)
            const isSpanishWord = meaning.tags && meaning.tags.some((tag) => tag.tag === 'lang:spanish');

            // Include the word if:
            // 1. Looking for Spanish words and this is a Spanish word (onlySpanish is true and word is Spanish)
            // 2. Looking for all words including Spanish (spanish flag is true and onlySpanish is false) - no filtering
            // 3. Looking for non-Spanish words (spanish flag is false and word is not Spanish)
            if ((onlySpanish && isSpanishWord) || (spanish && !onlySpanish) || (!spanish && !isSpanishWord)) {
              finalMeanings.push(meaning);
            }
          });
        });



        // If no words found and not searching for Spanish, try with AI translation
        if (finalMeanings.length === 0 && !spanish) {


          try {
            // Use the translateWordToDictionaryFormat method to get a properly formatted entry
            const translatedEntries = await this.chatService.translateWordToDictionaryFormat(normalizedWord);


            // Show the translation in the dialog
            this.openStudentsDictionaryResultsDialog(
              translatedEntries,
              normalizedWord,
              spanish ? 'hiszpańskim' : 'angielskim'
            );
          } catch (error) {
            console.error('Błąd podczas tłumaczenia dla uczniów:', error);
            // If there's an error, show the dialog with empty results
            this.openStudentsDictionaryResultsDialog(
              [],
              normalizedWord,
              spanish ? 'hiszpańskim' : 'angielskim'
            );
          }
        } else if (finalMeanings.length === 0 && spanish) {

          // Show the dialog with empty results
          this.openStudentsDictionaryResultsDialog(
            [],
            normalizedWord,
            'hiszpańskim'
          );
        } else {
          // Words found in dictionary or Spanish search, show as usual
          this.openStudentsDictionaryResultsDialog(
            finalMeanings,
            normalizedWord,
            spanish ? 'hiszpańskim' : 'angielskim'
          );
        }

        this.checkingTranslationForStudents = false;
      });
  }

  async openStudentsDictionaryResultsDialog(foundWords, searchedWord, lang?) {
    const modal = await this.modalCtrl.create({
      component: StudentDictionaryDialogComponent,
      cssClass: 'full-height',
      backdropDismiss: false,

      componentProps: {
        foundWords,
        searchedWord,
        lang,
      },
    });

    modal.present();
    this.checkingTranslationForStudents = false;
  }
}
