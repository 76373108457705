import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatbotsService } from 'src/app/shared/components/chatbots/chatbots.service';

interface ChatMessage {
    role: 'user' | 'assistant';
    content: string;
}

@Component({
    selector: 'app-chat-question',
    templateUrl: './chat-question.component.html',
    styleUrls: ['./chat-question.component.scss']
})
export class ChatQuestionComponent implements OnInit {
    selectedChatbot: any;
    checking = false;

    @Input() sentence: string = '';
    @Input() question: string = '';
    @Input() answer: string = '';
    @Input() showFeedback: boolean = false;
    @Input() extended: boolean = false;

    usersQuestion = '';
    aiResponse: string = '';
    conversationHistory: ChatMessage[] = [];

    // Modify scroll behavior to not constantly force scroll to bottom
    private shouldScrollToBottom = false;
    private lastMessageIndex = -1;
    // Offset to position new messages slightly below the top (in pixels)
    private newMessageTopOffset = 10;

    constructor(
        private chatbotsService: ChatbotsService,
        private modalController: ModalController
    ) { }

    ngOnInit() {
        this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name == 'Joe');

        // Adjust top offset based on screen size
        this.adjustTopOffsetForScreenSize();

        // Initialize conversation with context message
        this.conversationHistory.push({
            role: 'assistant',
            content: `Kontekst: "${this.sentence}". Zadaj pytanie o to zdanie.`
        });

        // Give UI time to render before scrolling to top
        setTimeout(() => {
            this.scrollToTop();
        }, 100);
    }

    // For first load, scroll to top instead of bottom
    ngAfterViewInit() {
        this.scrollToTop();
    }

    ngAfterViewChecked() {
        if (this.shouldScrollToBottom) {
            // Instead of always scrolling to the bottom, scroll the newest message into view
            this.scrollNewMessageIntoView();
            this.shouldScrollToBottom = false;
        }
    }

    // Scroll the newest message into view
    private scrollNewMessageIntoView() {
        try {
            if (this.lastMessageIndex >= 0 && this.lastMessageIndex < this.conversationHistory.length) {
                const chatMessages = document.querySelector('.chat-messages');
                if (!chatMessages) return;

                const messageElements = chatMessages.querySelectorAll('.chat-message');
                if (messageElements && messageElements.length > this.lastMessageIndex) {
                    // Get the target message element
                    const targetMessage = messageElements[this.lastMessageIndex];

                    // Get its position relative to the chat container
                    const containerRect = chatMessages.getBoundingClientRect();
                    const messageRect = targetMessage.getBoundingClientRect();

                    // Calculate the scroll position to put the message at the top with an offset
                    const scrollPosition = chatMessages.scrollTop +
                        (messageRect.top - containerRect.top) -
                        this.newMessageTopOffset;

                    // Scroll the container to this position
                    chatMessages.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth'
                    });

                    this.lastMessageIndex = -1;
                }
            } else {
                // Fallback to scrollToTop if we can't find the specific message
                this.scrollToTop();
            }
        } catch (err) {
            console.error('Error scrolling new message into view:', err);
            // Fallback to normal scrollToTop
            this.scrollToTop();
        }
    }

    public scrollToTop() {
        try {
            const chatMessages = document.querySelector('.chat-messages');
            if (chatMessages) {
                chatMessages.scrollTop = 0;
            }
        } catch (err) {
            console.error('Error scrolling to top:', err);
        }
    }

    public scrollToBottom() {
        try {
            const chatMessages = document.querySelector('.chat-messages');
            if (chatMessages) {
                chatMessages.scrollTop = chatMessages.scrollHeight;
            }
        } catch (err) {
            console.error('Error scrolling to bottom:', err);
        }
    }

    sendMessage(checkOnly: boolean = false) {
        if (!this.usersQuestion.trim() || (this.extended && !checkOnly)) return;

        // Add user's question to conversation history
        this.conversationHistory.push({
            role: 'user',
            content: this.usersQuestion
        });

        // Remember the index of this new message for scrolling
        this.lastMessageIndex = this.conversationHistory.length - 1;
        // Set flag to scroll the new message into view
        this.shouldScrollToBottom = true;

        this.checking = true;
        const data = {
            messages: [
                // Include initial context about the sentence
                {
                    "role": "system",
                    "content": `The context is a sentence: "${this.sentence}". Help the user explore and understand this sentence.`
                },
                // Include full conversation history
                ...this.conversationHistory.map(msg => ({
                    "role": msg.role,
                    "content": msg.content
                }))
            ],
            aiProvider: this.selectedChatbot.aiProvider,
            model: this.selectedChatbot.model,
            maxTokens: this.selectedChatbot.maxTokens,
            maxMessages: this.selectedChatbot.maxMessages,
            systemMsg: this.selectedChatbot.systemMsg,
            files: null
        };

        this.chatbotsService.sendPromptToChosenAi(data).subscribe(
            (res) => {

                this.checking = false;

                // Add AI's response to conversation history
                const aiResponse = res.res.content;
                this.conversationHistory.push({
                    role: 'assistant',
                    content: aiResponse
                });

                // Remember the index of this new message for scrolling
                this.lastMessageIndex = this.conversationHistory.length - 1;
                // Set flag to scroll the new message into view
                this.shouldScrollToBottom = true;

                // Clear user's input
                this.usersQuestion = '';
            },
            (error) => {
                console.error('Error:', error);
                this.checking = false;

                // Add error message to conversation history
                this.conversationHistory.push({
                    role: 'assistant',
                    content: 'Sorry, there was an error processing your question.'
                });

                // Remember the index of this new message for scrolling
                this.lastMessageIndex = this.conversationHistory.length - 1;
                // Set flag to scroll the new message into view
                this.shouldScrollToBottom = true;
            }
        );
    }

    closeModal() {
        this.modalController.dismiss();
    }

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
    }

    // Adjust the top offset based on screen size
    private adjustTopOffsetForScreenSize() {
        const screenHeight = window.innerHeight;
        if (screenHeight < 600) {
            // Smaller screens need less offset
            this.newMessageTopOffset = 5;
        } else if (screenHeight > 1000) {
            // Larger screens can have more offset
            this.newMessageTopOffset = 20;
        } else {
            // Default for medium screens
            this.newMessageTopOffset = 10;
        }
    }
}
