import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsService } from '../chatbots.service';

@Component({
  selector: 'app-chatbot-hint',
  templateUrl: './chatbot-hint.component.html',
  styleUrls: ['./chatbot-hint.component.css']
})
export class ChatbotHintComponent implements OnInit {
  @Input() messages;
  @Input() messageIndex;
  msgToSend
  @Input() hintText = ''
  @Output() hintTextChange = new EventEmitter();
  showSpinner = false;
  @Input() lang: 'en' | 'es' = 'en'
  selectedChatbot
  currentMessage: any;
  previousUsersMessage: any;




  constructor(
    private aiService: ChatbotsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes?.messages?.currentValue && changes?.messages?.firstChange) {
      if (this.lang === 'en') {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Eduardo')
      }
      else {
        this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Gustavo')
      }
      this.currentMessage = this.messages[this.messageIndex].content || ''
      console.log("🚀 ~ ChatbotHintComponent ~ ngOnInit ~ currentMessage", this.currentMessage
      )
      this.previousUsersMessage = this.messages[this.messageIndex - 1].content || ''
      console.log("🚀 ~ ChatbotHintComponent ~ ngOnInit ~ previousUsersMessage:", this.previousUsersMessage)
      if (this.hintText === '' || !this.hintText) {
        this.sendMessage()
      }
    }
  }
  ngOnInit() {

  }

  sendMessage() {
    this.showSpinner = true;
    console.log('generating hints...')
    this.msgToSend = {
      "role": "user",
      "content": "student: " + this.previousUsersMessage

        + " teacher: " +
        this.currentMessage
    }
    const data = {
      messages: [
        this.msgToSend
      ],
      aiProvider: this.selectedChatbot.aiProvider,
      model: this.selectedChatbot.model,
      maxTokens: this.selectedChatbot.maxTokens,
      maxMessages: this.selectedChatbot.maxMessages,
      systemMsg: this.selectedChatbot.systemMsg,
      files: null
    };
    console.log("🚀 ~ ChatbotHintComponent ~ sendMessage ~ data //hint", data.messages[0].content)
    this.aiService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        console.log("🚀 ~ hintComponent ~ sendMessage ~ res:", res)
        this.showSpinner = false;
        this.hintText = res.res.content
        this.hintTextChange.emit({ hint: this.hintText, messageIndex: this.messageIndex, msgToSend: this.msgToSend })

      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
}
