import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  url;
  chatMessagesUpdated = new Subject();
  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService
  ) {
    this.url = this.platform.url;
  }

  translateToPolishWithChat(message) {


    return this.http
      .post<{ sets: any }>(
        this.url + '/api/chat/translate-to-polish-with-chat',
        {
          message: message,
        }
      )
      .pipe()
      .subscribe({
        next: (response: any) => {

          const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;


          // Ensure translation is not empty
          if (response.res && response.res.choices && response.res.choices[0] && response.res.choices[0].message) {
            this.chatMessagesUpdated.next(response);
          } else {
            console.error('ChatService: Nieprawidłowa odpowiedź z API:', response);
            const errorResponse = {
              res: {
                choices: [{
                  message: {
                    content: 'Niestety wystąpił błąd podczas tłumaczenia. Proszę spróbować ponownie.'
                  }
                }]
              }
            };
            this.chatMessagesUpdated.next(errorResponse);
          }
        },
        error: (error) => {
          console.error('ChatService: Błąd podczas tłumaczenia:', error);
          const errorResponse = {
            res: {
              choices: [{
                message: {
                  content: 'Niestety wystąpił błąd podczas tłumaczenia. Proszę spróbować ponownie.'
                }
              }]
            }
          };
          this.chatMessagesUpdated.next(errorResponse);
        }
      });
  }

  // New method to translate word and return formatted result for dictionary display
  translateWordToDictionaryFormat(word) {


    // Create a properly formatted dictionary entry from chat translation
    const formatTranslationToDictionaryEntry = (translation) => {
      return [{
        id: 'chat-translation-' + Date.now(),
        type: 'translation',
        level: 'A1',
        translation: translation,
        originWord: word,
        examples: [],
        source: 'AI Translation',
        tags: [{ tag: word }]
      }];
    };

    return new Promise((resolve) => {
      const subscription = this.chatMessagesUpdated.subscribe((response: any) => {


        if (response?.res?.choices?.[0]?.message?.content) {
          const translation = response.res.choices[0].message.content;

          resolve(formatTranslationToDictionaryEntry(translation));
        } else {
          console.error('ChatService: Nieprawidłowa odpowiedź:', response);
          resolve(formatTranslationToDictionaryEntry('Nie udało się przetłumaczyć tekstu.'));
        }

        subscription.unsubscribe();
      });

      // Call translation API
      this.translateToPolishWithChat(word);
    });
  }

  correctPolishText(message) {
    this.http
      .post<{ sets: any }>(this.url + '/api/chat/correct-polish-text', {
        message: message,
      })
      .pipe()
      .subscribe((response: any) => {

        const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;

        this.chatMessagesUpdated.next(response);
      });
  }
  chatMessagesListener() {
    return this.chatMessagesUpdated.asObservable();
  }
}
