import { Injectable } from '@angular/core';

export interface TravelDestination {
    id: number;
    country: {
        pl: string;
        en: string;
        es: string;
    };
    place: {
        pl: string;
        en: string;
        es: string;
    };
    continent: {
        pl: string;
        en: string;
        es: string;
    };
    description: {
        pl: string;
        en: string;
        es: string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class TravelDestinationsService {
    private destinations: TravelDestination[] = [
        {

            id: 1,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Wybrzeże Amalfi', en: 'Amalfi Coast', es: ' Costa Amalfitana' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Wybrzeże Amalfi zachwyca klifami, kolorowymi wioskami i błękitnym morzem. Odwiedzający mogą zwiedzać urocze miasteczka, próbować świeżych owoców morza i podziwiać spektakularne widoki.', en: 'The Amalfi Coast has stunning cliffs, colorful villages, and blue sea. Visitors can explore charming towns, try fresh seafood, and enjoy breathtaking coastal views.', es: 'La Costa Amalfitana tiene acantilados impresionantes, pueblos coloridos y un mar azul. Los visitantes pueden explorar encantadores pueblos, probar mariscos frescos y disfrutar de vistas costeras impresionantes.' }

        },

        {

            id: 2,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Grobla Olbrzyma', en: 'Giant\’s Causeway', es: ' Calzada del Gigante' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To naturalne cudo ma tysiące heksagonalnych kolumn skalnych. Powstało w wyniku działalności wulkanicznej i wiąże się z wieloma legendami o gigantach.', en: 'This natural wonder has thousands of hexagonal rock columns. It was formed by volcanic activity and has many legends about giants.', es: 'Esta maravilla natural tiene miles de columnas de roca hexagonales. Se formó por actividad volcánica y tiene muchas leyendas sobre gigantes.' }

        },

        {

            id: 3,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Wieża Eiffla (Paryż)', en: 'Eiffel Tower (Paris)', es: ' Torre Eiffel (París)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten wielki łuk jest symbolem historii Berlina. Kiedyś dzielił Wschodni i Zachodni Berlin. Dziś reprezentuje jedność i jest obowiązkowym punktem zwiedzania miasta.', en: 'The Eiffel Tower is France\’s most famous landmark. Visitors can take an elevator to the top for stunning views of Paris. At night, it lights up beautifully.', es: 'La Torre Eiffel es el monumento más famoso de Francia. Los visitantes pueden tomar un ascensor hasta la cima para disfrutar de vistas impresionantes de París. Por la noche, se ilumina bellamente.' }

        },

        {

            id: 4,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Santorini (Oia i Fira)', en: 'Santorini (Oia & Fira)', es: ' Santorini (Oia y Fira)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Santorini słynie z białych budynków, niebieskich kopuł i zachodów słońca. Zwiedzający mogą odkrywać wioski na klifach, odpoczywać na wulkanicznych plażach i delektować się pysznym greckim jedzeniem.', en: 'Santorini is known for its white buildings, blue domes, and sunset views. Visitors can explore cliffside villages, relax on volcanic beaches, and enjoy delicious Greek food.', es: 'Santorini es conocida por sus edificios blancos, cúpulas azules y vistas al atardecer. Los visitantes pueden explorar pueblos en los acantilados, relajarse en playas volcánicas y disfrutar de deliciosa comida griega.' }

        },

        {

            id: 5,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Plaza Mayor (Madryt)', en: 'Plaza Mayor (Madrid)', es: ' Plaza Mayor (Madrid)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Plaza Mayor to duży, zabytkowy plac w Madrycie, otoczony pięknymi budynkami. To świetne miejsce na relaks, jedzenie i cieszenie się lokalnymi wydarzeniami w sercu miasta.', en: 'Plaza Mayor is a large, historic square in Madrid, surrounded by beautiful buildings. It\’s a great place to relax, eat, and enjoy local events in the heart of the city.', es: 'La Plaza Mayor es una gran plaza histórica en Madrid, rodeada de hermosos edificios. Es un excelente lugar para relajarse, comer y disfrutar de eventos locales en el corazón de la ciudad.' }

        },

        {

            id: 6,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Pałac Dioklecjana (Split)', en: 'Diocletian\’s Palace (Split)', es: ' Palacio de Diocleciano (Split)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten starożytny rzymski pałac znajduje się w sercu Splitu. Dziś jest to tętniąca życiem okolica z kawiarniami, sklepami i zabytkowymi budynkami. Odwiedzający mogą zwiedzać podziemne tunele i podziwiać starożytną rzymską architekturę.', en: 'This ancient Roman palace is in the heart of Split. Today, it is a lively area with cafes, shops, and historic buildings. Visitors can explore underground tunnels and admire old Roman architecture.', es: 'Este antiguo palacio romano está en el corazón de Split. Hoy en día, es una zona animada con cafeterías, tiendas y edificios históricos. Los visitantes pueden explorar túneles subterráneos y admirar la antigua arquitectura romana.' }

        },

        {

            id: 7,

            country: { pl: 'Norwegia', en: 'Norway', es: 'Noruega' },

            place: { pl: 'Fiord Geirangerfjord', en: 'Geirangerfjord', es: ' Geirangerfjord' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Geirangerfjord to jeden z najpiękniejszych fiordów Norwegii. Ma strome klify, wodospady i głęboko niebieską wodę. Turyści mogą odbywać rejsy łodzią i podziwiać zapierający dech w piersiach krajobraz.', en: 'Geirangerfjord is one of Norway\’s most beautiful fjords. It has steep cliffs, waterfalls, and deep blue water. Visitors can take boat tours and admire the stunning landscape.', es: 'El Geirangerfjord es uno de los fiordos más hermosos de Noruega. Tiene acantilados empinados, cascadas y agua azul profunda. Los visitantes pueden hacer paseos en bote y admirar el paisaje impresionante.' }

        },

        {

            id: 8,

            country: { pl: 'Węgry', en: 'Hungary', es: 'Hungría' },

            place: { pl: 'Łaźnie termalne Széchenyi (Budapeszt)', en: 'Széchenyi Thermal Baths (Budapest)', es: ' Baños Termales Széchenyi (Budapest)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To największe termalne kąpielisko w Europie. Ciepłe wody są bogate w minerały i świetnie nadają się do relaksu. Baseny na świeżym powietrzu są szczególnie piękne zimą, gdy nad wodą unosi się para.', en: 'These are the largest thermal baths in Europe. The warm waters are rich in minerals and great for relaxation. The outdoor pools are especially beautiful in winter with steam rising into the air.', es: 'Estos son los baños termales más grandes de Europa. Las aguas termales son ricas en minerales y son perfectas para la relajación. Las piscinas al aire libre son especialmente hermosas en invierno, con el vapor ascendiendo al aire.' }

        },

        {

            id: 9,

            country: { pl: 'Czechy', en: 'Czech Republic', es: 'República Checa' },

            place: { pl: 'Stary Rynek i Zegar Astronomiczny', en: 'Old Town Square & Astronomical Clock', es: ' Plaza de la Ciudad Vieja y Reloj Astronómico' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Oryginalny park rozrywki Legoland to idealne miejsce dla rodzin. Ma atrakcje, pokazy i niesamowite modele słynnych zabytków zbudowane z klocków Lego. To świetna zabawa zarówno dla dzieci, jak i dorosłych.', en: 'The heart of Prague, this square has colorful buildings and a famous clock that shows moving figures every hour. Visitors can climb the clock tower for great views.', es: 'El corazón de Praga, esta plaza tiene edificios coloridos y un famoso reloj que muestra figuras en movimiento cada hora. Los visitantes pueden subir a la torre del reloj para obtener vistas maravillosas.' }

        },

        {

            id: 10,

            country: { pl: 'Cypr', en: 'Cyprus', es: 'Chipre' },

            place: { pl: 'Grobowce Królewskie (Pafos)', en: 'Tombs of the Kings (Paphos)', es: ' Tumbas de los Reyes (Pafos)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten słynny kamienny most łączy Stare Miasto Pragi z zamkiem. Jest ozdobiony rzeźbami i pełen ulicznych muzyków. Spacer o wschodzie lub zachodzie słońca to magiczne przeżycie.', en: 'This UNESCO site has ancient tombs carved into rock. Despite the name, no kings were buried here, but the tombs belonged to important nobles. The site is full of history and mystery.', es: 'Este sitio de la UNESCO tiene tumbas antiguas talladas en la roca. A pesar del nombre, no se enterraron reyes aquí, sino nobles importantes. El sitio está lleno de historia y misterio.' }

        },

        {

            id: 11,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Katedra Notre-Dame (Paryż)', en: 'Notre-Dame Cathedral (Paris)', es: ' Catedral de Notre-Dame (París)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta strzelista gotycka katedra to jeden z najbardziej imponujących budynków w Niemczech. Ma piękne witraże, a wejście na wieżę oferuje wspaniałe widoki na miasto.', en: 'This famous Gothic cathedral has beautiful stained glass and tall towers. Despite a fire in 2019, it remains a symbol of Paris. Visitors can admire its detailed sculptures.', es: 'Esta famosa catedral gótica tiene hermosos vitrales y altas torres. A pesar de un incendio en 2019, sigue siendo un símbolo de París. Los visitantes pueden admirar sus detalladas esculturas.' }

        },

        {

            id: 12,

            country: { pl: 'Czechy', en: 'Czech Republic', es: 'República Checa' },

            place: { pl: 'Most Karola (Praga)', en: 'Charles Bridge (Prague)', es: ' Puente de Carlos (Praga)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta mała brązowa statuetka jest symbolem Kopenhagi. Zainspirowana baśnią Hansa Christiana Andersena, siedzi na skale przy wodzie i przyciąga wielu turystów.', en: 'This famous stone bridge connects Prague\’s Old Town with the castle. It is lined with statues and street musicians. Walking across at sunrise or sunset is a magical experience.', es: 'Este famoso puente de piedra conecta el casco antiguo de Praga con el castillo. Está bordeado de estatuas y músicos callejeros. Caminar por él al amanecer o al atardecer es una experiencia mágica.' }

        },

        {

            id: 13,

            country: { pl: 'Belgia', en: 'Belgium', es: 'Bélgica' },

            place: { pl: 'Kanały Brugii', en: 'Bruges Canals', es: ' Canales de Brujas' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Brugia jest często nazywana „Wenecją Północy” ze względu na swoje kanały. Zwiedzający mogą wybrać się na rejs łodzią po urokliwym średniowiecznym mieście i podziwiać stare budynki.', en: 'Bruges is often called the “Venice of the North” because of its canals. Visitors can take a boat tour through the charming medieval town and admire the old buildings.', es: 'Brujas a menudo se llama la "Venecia del Norte" debido a sus canales. Los visitantes pueden hacer un recorrido en bote por la encantadora ciudad medieval y admirar los edificios antiguos.' }

        },

        {

            id: 14,

            country: { pl: 'Słowenia', en: 'Slovenia', es: 'Eslovenia' },

            place: { pl: 'Park Narodowy Triglav', en: 'Triglav National Park', es: ' Parque Nacional de Triglav' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Park Narodowy Triglav to jedyny park narodowy w Słowenii, znany z surowych gór, krystalicznie czystych jezior i różnorodnej fauny. To idealne miejsce dla wędrowców i miłośników natury.', en: 'Triglav National Park is Slovenia\'s only national park, known for its rugged mountains, crystal- clear lakes, and diverse wildlife.It\’s perfect for hikers and nature lovers.', es: 'El Parque Nacional Triglav es el único parque nacional de Eslovenia, conocido por sus montañas escarpadas, lagos cristalinos y fauna diversa.Es perfecto para los excursionistas y los amantes de la naturaleza.' }

        },

        {

            id: 15,

            country: { pl: 'Belgia', en: 'Belgium', es: 'Bélgica' },

            place: { pl: 'Zamek Gravensteen (Gandawa)', en: 'Gravensteen Castle (Ghent)', es: ' Castillo de Gravensteen (Gante)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten imponujący średniowieczny zamek w Gandawie został zbudowany w XII wieku. Ma wysokie kamienne mury, muzeum i wspaniałe widoki z góry. Odwiedzający mogą poznać jego mroczną historię.', en: 'This impressive medieval castle in Ghent was built in the 12th century. It has high stone walls, a museum, and great views from the top. Visitors can explore its dark history.', es: 'Este impresionante castillo medieval en Gante fue construido en el siglo XII. Tiene altas paredes de piedra, un museo y excelentes vistas desde la cima. Los visitantes pueden explorar su oscura historia.' }

        },

        {

            id: 16,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Zamek Korwinów', en: 'Corvin Castle', es: ' Castillo de Corvin' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Corvin to gotycka twierdza z wieżami i mostem zwodzonym. Wygląda jak zamek z filmu fantasy. Odwiedzający mogą zwiedzać ciemne sale i usłyszeć legendy o duchach.', en: 'Corvin Castle is a gothic fortress with towers and a drawbridge. It looks like a castle from a fantasy movie. Visitors can explore its dark halls and hear legends about ghosts.', es: 'El Castillo de Corvin es una fortaleza gótica con torres y un puente levadizo. Parece un castillo sacado de una película de fantasía. Los visitantes pueden explorar sus oscuros pasillos y escuchar leyendas sobre fantasmas.' }

        },

        {

            id: 17,

            country: { pl: 'Szwajcaria', en: 'Switzerland', es: 'Suiza' },

            place: { pl: 'Jungfraujoch (szczyt Europy)', en: 'Jungfraujoch (Top of Europe)', es: ' Jungfraujoch (Cima de Europa)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Jungfraujoch to najwyższa stacja kolejowa w Europie. Odwiedzający mogą podziwiać widoki lodowców, pokrytych śniegiem szczytów i zwiedzać Pałac Lodowy w tej górskiej destynacji.', en: 'Jungfraujoch is the highest railway station in Europe. Visitors can enjoy views of glaciers, snow-covered peaks, and visit the Ice Palace at this mountain-top destination.', es: 'Jungfraujoch es la estación de tren más alta de Europa. Los visitantes pueden disfrutar de vistas de glaciares, picos cubiertos de nieve y visitar el Palacio de Hielo en este destino en la cima de la montaña.' }

        },

        {

            id: 18,

            country: { pl: 'Niemcy', en: 'Germany', es: 'Alemania' },

            place: { pl: 'Zamek Neuschwanstein (Bawaria)', en: 'Neuschwanstein Castle (Bavaria)', es: ' Castillo de Neuschwanstein (Baviera)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten bajkowy zamek zainspirował Zamek Kopciuszka w Disneyu. Wznosi się na wzgórzu z zapierającymi dech w piersiach widokami. Odwiedzający mogą zwiedzać królewskie komnaty i wieże.', en: 'This fairytale castle inspired Disney\’s Cinderella Castle. It sits on a hill with breathtaking views. Visitors can explore its royal rooms and towers.', es: 'Este castillo de cuento de hadas inspiró el Castillo de la Cenicienta de Disney. Se encuentra en una colina con vistas impresionantes. Los visitantes pueden explorar sus salas reales y torres.' }

        },

        {

            id: 19,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Legoland Billund', en: 'Legoland Billund', es: ' Legoland Billund' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Nyhavn to kolorowy port z drewnianymi łodziami i tętniącymi życiem kawiarniami. To świetne miejsce na rejs łodzią, spróbowanie duńskich potraw lub spacer wzdłuż wody.', en: 'The original Legoland theme park is perfect for families. It has rides, shows, and incredible Lego-built models of famous landmarks. It is a fun experience for both kids and adults.', es: 'El parque temático original de Legoland es perfecto para familias. Tiene atracciones, espectáculos y modelos de Lego de famosos monumentos. Es una experiencia divertida tanto para niños como para adultos.' }

        },

        {

            id: 20,

            country: { pl: 'Portugalia', en: 'Portugal', es: 'Portugal' },

            place: { pl: 'Wyspa Madera', en: 'Madeira Island', es: ' Isla de Madeira' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Madera to zielona wyspa, znana z gór i kwiatów. Turyści mogą wędrować po malowniczych szlakach, skosztować lokalnego wina i zobaczyć słynne saneczkowe przejażdżki w Funchal.', en: 'Madeira is a lush island known for its mountains and flowers. Visitors can hike on scenic trails, taste local wine, and see the famous toboggan rides in Funchal.', es: 'Madeira es una isla exuberante conocida por sus montañas y flores. Los visitantes pueden hacer senderismo por senderos pintorescos, probar el vino local y ver los famosos paseos en trineo en Funchal.' }

        },

        {

            id: 21,

            country: { pl: 'Bułgaria', en: 'Bulgaria', es: 'Bulgaria' },

            place: { pl: 'Klasztor Riła', en: 'Rila Monastery', es: ' Monasterio de Rila' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Klasztor Rilski to najsłynniejszy klasztor w Bułgarii, ukryty w górach. Ma kolorowe freski, piękny dziedziniec i spokojną atmosferę. Odwiedzający mogą poznać tu historię Bułgarii.', en: 'Rila Monastery is Bulgaria\’s most famous monastery, hidden in the mountains. It has colorful frescoes, a beautiful courtyard, and a peaceful atmosphere. Visitors can learn about Bulgarian history here.', es: 'El Monasterio de Rila es el monasterio más famoso de Bulgaria, escondido en las montañas. Tiene frescos coloridos, un hermoso patio y una atmósfera tranquila. Los visitantes pueden aprender sobre la historia de Bulgaria allí.' }

        },

        {

            id: 22,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Zamek na Wawelu (Kraków)', en: 'Wawel Castle (Kraków)', es: ' Castillo de Wawel (Cracovia)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Wawelski to symbol polskiej historii i kultury. Wznosi się na wzgórzu nad Krakowem i ma piękne komnaty królewskie. Turyści mogą również zwiedzać legendarną smoczą jamę w pobliżu.', en: 'Wawel Castle is a symbol of Polish history and culture. It sits on a hill above Kraków and has beautiful royal chambers. Visitors can also see the legendary dragon\’s cave nearby.', es: 'El Castillo de Wawel es un símbolo de la historia y cultura polacas. Se encuentra en una colina sobre Cracovia y tiene hermosas habitaciones reales. Los visitantes también pueden ver la legendaria cueva del dragón cerca.' }

        },

        {

            id: 23,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Zamek Bran (Zamek Drakuli)', en: 'Bran Castle (Dracula\’s Castle)', es: ' Castillo de Bran (Castillo de Drácula)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Bran jest często kojarzony z legendą o Drakuli. Znajduje się na wzgórzu i ma tajemniczy, gotycki wygląd. Odwiedzający mogą zwiedzać średniowieczne komnaty i poznać jego historię.', en: 'Bran Castle is often linked to the Dracula legend. It sits on a hill and has a mysterious, gothic look. Visitors can explore its medieval rooms and learn about its history.', es: 'El Castillo de Bran a menudo se vincula con la leyenda de Drácula. Está en una colina y tiene un aspecto gótico y misterioso. Los visitantes pueden explorar sus habitaciones medievales y aprender sobre su historia.' }

        },

        {

            id: 24,

            country: { pl: 'Belgia', en: 'Belgium', es: 'Bélgica' },

            place: { pl: 'Grand Place (Bruksela)', en: 'Grand Place (Brussels)', es: ' Grand Place (Bruselas)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten plac w Brukseli jest jednym z najpiękniejszych w Europie. Otaczają go zabytkowe budynki z ozdobami ze złota. Nocą światła sprawiają, że staje się jeszcze bardziej magiczny.', en: 'This square in Brussels is one of the most beautiful in Europe. It is surrounded by historic buildings with gold decorations. At night, the lights make it even more magical.', es: 'Esta plaza en Bruselas es una de las más bellas de Europa. Está rodeada de edificios históricos con decoraciones doradas. Por la noche, las luces la hacen aún más mágica.' }

        },

        {

            id: 25,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Big Ben i budynki parlamentu (Londyn)', en: 'Big Ben & Houses of Parliament (London)', es: ' Big Ben y Casas del Parlamento (Londres)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Big Ben i Pałac Westminsterski to ikoniczne zabytki Londynu. Wieża zegarowa jest szczególnie piękna nocą. Turyści mogą wybrać się na zwiedzanie z przewodnikiem, by poznać historię Wielkiej Brytanii.', en: 'Big Ben and the Houses of Parliament are iconic landmarks in London. The clock tower is especially stunning at night. Visitors can take guided tours to learn about British history.', es: 'Big Ben y las Casas del Parlamento son monumentos icónicos en Londres. La torre del reloj es especialmente impresionante de noche. Los visitantes pueden hacer recorridos guiados para conocer la historia británica.' }

        },

        {

            id: 26,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Park Narodowy Teide', en: 'Teide National Park', es: ' Parque Nacional Teide' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Park Narodowy Teide to dom najwyższego szczytu Hiszpanii, Góry Teide. Odwiedzający mogą wędrować lub wjechać kolejką linową na szczyt, by podziwiać spektakularne widoki wyspy.', en: 'Teide National Park is home to Spain\’s highest peak, Mount Teide. Visitors can hike or take a cable car to the top for spectacular views of the island.', es: 'El Parque Nacional Teide es hogar del pico más alto de España, el Monte Teide. Los visitantes pueden hacer senderismo o tomar el teleférico hasta la cima para disfrutar de vistas espectaculares de la isla.' }

        },

        {

            id: 27,

            country: { pl: 'Holandia', en: 'Netherlands', es: 'Países Bajos' },

            place: { pl: 'Ogrody Keukenhof (pola tulipanów)', en: 'Keukenhof Gardens (Tulip Fields)', es: ' Jardines de Keukenhof (Campos de tulipanes)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Keukenhof to jeden z najpiękniejszych ogrodów na świecie. Co wiosnę kwitną tu miliony kolorowych tulipanów. Odwiedzający mogą spacerować po polach kwiatowych i robić wspaniałe zdjęcia.', en: 'Keukenhof is one of the most beautiful gardens in the world. Every spring, millions of colorful tulips bloom here. Visitors can walk through flower fields and take stunning photos.', es: 'Keukenhof es uno de los jardines más hermosos del mundo. Cada primavera, millones de tulipanes de colores florecen aquí. Los visitantes pueden caminar entre los campos de flores y tomar fotos impresionantes.' }

        },

        {

            id: 28,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Kopalnia Soli w Wieliczce', en: 'Wieliczka Salt Mine', es: ' Mina de sal de Wieliczka' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta zabytkowa kopalnia soli niedaleko Krakowa ma podziemne kaplice i rzeźby. Wszystko jest wyrzeźbione w soli, nawet żyrandole! Turyści mogą spacerować po tunelach i podziwiać niesamowitą sztukę.', en: 'This historic salt mine near Kraków has underground chapels and sculptures. Everything is carved from salt, even chandeliers! Visitors can walk through tunnels and see amazing art.', es: 'Esta mina de sal histórica cerca de Cracovia tiene capillas y esculturas subterráneas. Todo está tallado en sal, ¡incluso los candelabros! Los visitantes pueden caminar por los túneles y ver increíbles obras de arte.' }

        },

        {

            id: 29,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Tunele Cu Chi', en: 'Cu Chi Tunnels', es: ' Túneles de Cu Chi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Tunele Cu Chi to rozległa sieć podziemnych tuneli używanych podczas wojny w Wietnamie. Odwiedzający mogą dowiedzieć się o wojnie i zwiedzać tunele, które kiedyś były domem dla żołnierzy.', en: 'The Cu Chi Tunnels are a vast network of underground tunnels used during the Vietnam War. Visitors can learn about the war and explore the tunnels, which were once home to soldiers.', es: 'Los Túneles de Cu Chi son una vasta red de túneles subterráneos utilizados durante la Guerra de Vietnam. Los visitantes pueden aprender sobre la guerra y explorar los túneles, que fueron hogar de soldados.' }

        },

        {

            id: 30,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Camino de Santiago (Szlak pielgrzymkowy)', en: 'Camino de Santiago (Pilgrimage Route)', es: ' Camino de Santiago (Ruta de Peregrinación)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Camino de Santiago to starożytny szlak pielgrzymkowy prowadzący do Santiago de Compostela. Oferuje duchową refleksję i malownicze wędrówki przez hiszpańską wieś.', en: 'The Camino de Santiago is an ancient pilgrimage route leading to Santiago de Compostela. It offers spiritual reflection and scenic walks through the Spanish countryside.', es: 'El Camino de Santiago es una antigua ruta de peregrinación que lleva a Santiago de Compostela. Ofrece reflexión espiritual y caminatas panorámicas por el campo español.' }

        },

        {

            id: 31,

            country: { pl: 'Polinezja Francuska', en: 'French Polynesia', es: 'Polinesia Francesa' },

            place: { pl: 'Bora Bora', en: 'Bora Bora', es: ' Bora Bora' },

            continent: { pl: 'Oceania', en: 'Oceania', es: 'Oceanía' },

            description: { pl: 'Bora Bora to rajska wyspa z turkusowymi wodami i bungalowami na wodzie. Jest idealna do nurkowania, snorkelingu i relaksu. Wyspa słynie ze swojej oszałamiającej laguny.', en: 'Bora Bora is a paradise island with turquoise waters and overwater bungalows. It is perfect for snorkeling, diving, and relaxing. The island is famous for its stunning lagoon.', es: 'Bora Bora es una isla paradisíaca con aguas turquesas y bungalows sobre el agua. Es perfecta para hacer snorkel, buceo y relajarse. La isla es famosa por su impresionante laguna.' }

        },

        {

            id: 32,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Alhambra (Granada)', en: 'Alhambra (Granada)', es: ' Alhambra (Granada)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Alhambra to wspaniały pałac i kompleks warowny w Granadzie. Jego islamska architektura, piękne ogrody i misternie wykonane rzeźby czynią go obowiązkowym punktem turystycznym.', en: 'The Alhambra is a magnificent palace and fortress complex in Granada. Its Islamic architecture, beautiful gardens, and intricate carvings make it a must-see historical site.', es: 'La Alhambra es un magnífico palacio y complejo fortificado en Granada. Su arquitectura islámica, hermosos jardines y detalladas tallas lo convierten en un sitio histórico imprescindible.' }

        },

        {

            id: 33,

            country: { pl: 'Irlandia', en: 'Ireland', es: 'Irlanda' },

            place: { pl: 'Zamek Blarney', en: 'Blarney Castle', es: ' Castillo de Blarney' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten zabytkowy zamek słynie z Kamienia Blarney. Mówi się, że ci, którzy go pocałują, otrzymują „dar wymowy” – umiejętność płynnego mówienia.', en: 'This historic castle is famous for the Blarney Stone. Visitors who kiss the stone are said to receive the "gift of gab" – the ability to speak well.', es: 'Este castillo histórico es famoso por la Piedra de Blarney. Se dice que los visitantes que besan la piedra reciben el "don de la elocuencia", la habilidad de hablar bien.' }

        },

        {

            id: 34,

            country: { pl: 'Maroko', en: 'Morocco', es: 'Marruecos' },

            place: { pl: 'Meczet Hassana II (Casablanca)', en: 'Hassan II Mosque (Casablanca)', es: ' Mezquita Hassan II (Casablanca)' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'To jedna z największych meczetów na świecie. Znajduje się nad oceanem, z częścią zbudowaną nad wodą. Złożone detale i ogromny minaret czynią go arcydziełem architektury.', en: 'This is one of the largest mosques in the world. It sits by the ocean, with part of it built over the water. The intricate details and huge minaret make it an architectural masterpiece.', es: 'Esta es una de las mezquitas más grandes del mundo. Está junto al océano, con parte de ella construida sobre el agua. Los detalles intrincados y su enorme minarete la convierten en una obra maestra arquitectónica.' }

        },

        {

            id: 35,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Góry Błękitne', en: 'Blue Mountains', es: ' Montañas Azules' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Błękitne Góry to piękny park narodowy niedaleko Sydney. Słyną z niebieskiej mgiełki, wodospadów i formacji skalnych. Można tam wędrować, przejechać się malowniczą kolejką lub zobaczyć formację Trzy Siostry.', en: 'The Blue Mountains are a beautiful national park near Sydney. They are famous for their blue haze, waterfalls, and rock formations. Visitors can hike, take a scenic railway, or see the Three Sisters.', es: 'Las Montañas Azules son un hermoso parque nacional cerca de Sídney. Son famosas por su niebla azul, cascadas y formaciones rocosas. Los visitantes pueden hacer senderismo, tomar un tren panorámico o ver las Tres Hermanas.' }

        },

        {

            id: 36,

            country: { pl: 'Słowenia', en: 'Slovenia', es: 'Eslovenia' },

            place: { pl: 'Jezioro Bled', en: 'Lake Bled', es: ' Lago Bled' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Jezioro Bled to oszałamiające jezioro polodowcowe z małą wyspą na środku. Odwiedzający mogą wypłynąć tradycyjną łodzią na wyspę i odwiedzić kościół lub wędrować wokół jeziora, by podziwiać spektakularne widoki.', en: 'Lake Bled is a stunning glacial lake with a small island in the middle. Visitors can take a traditional boat to the island and visit the church or hike around the lake for spectacular views.', es: 'El Lago Bled es un impresionante lago glaciar con una pequeña isla en el centro. Los visitantes pueden tomar un bote tradicional hasta la isla y visitar la iglesia o hacer una caminata alrededor del lago para disfrutar de vistas espectaculares.' }

        },

        {

            id: 37,

            country: { pl: 'Austria', en: 'Austria', es: 'Austria' },

            place: { pl: 'Pałac Belvedere (Wiedeń)', en: 'Belvedere Palace (Vienna)', es: ' Palacio Belvedere (Viena)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten piękny pałac w Wiedniu wyróżnia się imponującą architekturą barokową i urokliwymi ogrodami. W środku odwiedzający mogą zobaczyć słynne obrazy, w tym „Pocałunek” Gustava Klimta.', en: 'This beautiful palace in Vienna has impressive Baroque architecture and lovely gardens. Inside, visitors can see famous paintings, including Gustav Klimt\’s The Kiss.', es: 'Este hermoso palacio en Viena tiene una impresionante arquitectura barroca y hermosos jardines. En su interior, los visitantes pueden ver pinturas famosas, incluida La Beso de Gustav Klimt.' }

        },

        {

            id: 38,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Wielki Budda z Leshan', en: 'Leshan Giant Buddha', es: ' Gran Buda de Leshan' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Leshan Giant Buddha to największy kamienny posąg Buddy na świecie. Został wyrzeźbiony w klifie ponad 1200 lat temu. Odwiedzający mogą popłynąć łodzią, aby zobaczyć go od strony rzeki.', en: 'The Leshan Giant Buddha is the world\’s largest stone Buddha statue. It was carved into a cliff over 1,200 years ago. Visitors can take a boat to see it from the river.', es: 'El Gran Buda de Leshan es la estatua de Buda de piedra más grande del mundo. Fue tallada en un acantilado hace más de 1.200 años. Los visitantes pueden tomar un bote para verlo desde el río.' }

        },

        {

            id: 39,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Delta Dunaju', en: 'Danube Delta', es: ' Delta del Danubio' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Delta Dunaju to ogromne tereny podmokłe pełne ptaków i dzikiej przyrody. To idealne miejsce na rejsy łodzią i obserwację ptaków. Odwiedzający mogą zobaczyć pelikanów, kanały i pływające wioski.', en: 'The Danube Delta is a huge wetland full of birds and wildlife. It is perfect for boat trips and birdwatching. Visitors can see pelicans, canals, and floating villages.', es: 'El Delta del Danubio es un enorme humedal lleno de aves y vida salvaje. Es perfecto para paseos en barco y observación de aves. Los visitantes pueden ver pelícanos, canales y aldeas flotantes.' }

        },

        {

            id: 40,

            country: { pl: 'Portugalia', en: 'Portugal', es: 'Portugal' },

            place: { pl: 'Pałac Pena (Sintra)', en: 'Pena Palace (Sintra)', es: ' Palacio de Pena (Sintra)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Pałac Pena to kolorowy zamek na wzgórzu w Sintrze. Wygląda jak coś z bajki. Pałac otoczony jest pięknymi ogrodami i oferuje zapierające dech widoki.', en: 'Pena Palace is a colorful castle on a hill in Sintra. It looks like something from a fairy tale. The palace is surrounded by beautiful gardens and offers stunning views.', es: 'El Palacio da Pena es un castillo colorido en una colina en Sintra. Parece algo sacado de un cuento de hadas. El palacio está rodeado por hermosos jardines y ofrece vistas impresionantes.' }

        },

        {

            id: 41,

            country: { pl: 'Bułgaria', en: 'Bulgaria', es: 'Bulgaria' },

            place: { pl: 'Stare Miasto w Nesebyrze', en: 'Nessebar Old Town', es: ' Casco Antiguo de Nessebar' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Nessebar to małe miasteczko nad Morzem Czarnym z antycznymi ruinami i starymi kościołami. Ma piękne widoki, urokliwe uliczki i doskonałe owoce morza. Jest wpisane na listę UNESCO.', en: 'Nessebar is a small town on the Black Sea with ancient ruins and old churches. It has beautiful views, charming streets, and great seafood. The town is a UNESCO World Heritage Site.', es: 'Nessebar es una pequeña ciudad en el Mar Negro con ruinas antiguas y viejas iglesias. Tiene vistas hermosas, calles encantadoras y mariscos frescos. La ciudad es un Patrimonio de la Humanidad de la UNESCO.' }

        },

        {

            id: 42,

            country: { pl: 'Tunezja', en: 'Tunisia', es: 'Túnez' },

            place: { pl: 'Amfiteatr El Djem', en: 'El Djem Amphitheatre', es: ' Anfiteatro de El Djem' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Amfiteatr El Djem to jeden z najlepiej zachowanych rzymskich amfiteatrów. Kiedyś odbywały się tu walki gladiatorów, a obecnie jest to obiekt wpisany na listę Światowego Dziedzictwa UNESCO. Turyści mogą zwiedzać ruiny i poznawać historię starożytną.', en: 'The El Djem Amphitheatre is one of the best-preserved Roman amphitheaters. It once hosted gladiatorial contests and is now a UNESCO World Heritage Site. Visitors can explore the ruins and learn about ancient history.', es: 'El Anfiteatro de El Djem es uno de los anfiteatros romanos mejor conservados. En su época, acogió combates de gladiadores y ahora es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO. Los visitantes pueden explorar las ruinas y aprender sobre la historia antigua.' }

        },

        {

            id: 43,

            country: { pl: 'Irlandia', en: 'Ireland', es: 'Irlanda' },

            place: { pl: 'Trinity College i Księga z Kells w Dublinie', en: 'Dublin\’s Trinity College & Book of Kells', es: ' Universidad de Dublín y el Libro de Kells' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Trinity College jest domem dla najstarszej biblioteki w Irlandii. Główną atrakcją jest Księga z Kells – pięknie ilustrowany manuskrypt.', en: 'Trinity College is home to Ireland\’s oldest library. The Book of Kells, a beautifully decorated manuscript, is one of its main attractions.', es: 'El Trinity College alberga la biblioteca más antigua de Irlanda. El Libro de Kells, un manuscrito bellamente decorado, es una de sus principales atracciones.' }

        },

        {

            id: 44,

            country: { pl: 'Cypr', en: 'Cyprus', es: 'Chipre' },

            place: { pl: 'Starożytny Kurion', en: 'Ancient Kourion', es: ' Kourion Antiguo' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Praski to największy kompleks zamkowy na świecie. Obejmuje piękne kościoły, budynki królewskie i zapierające dech w piersiach widoki na miasto. Zwiedzający mogą odkrywać jego historię i spacerować po Złotej Uliczce.', en: 'Kourion is an ancient city with well-preserved ruins. The most famous site is the large amphitheater with sea views. Visitors can also see Roman baths and beautiful mosaics.', es: 'Kourion es una ciudad antigua con ruinas bien conservadas. El sitio más famoso es el gran anfiteatro con vistas al mar. Los visitantes también pueden ver baños romanos y hermosos mosaicos.' }

        },

        {

            id: 45,

            country: { pl: 'Portugalia', en: 'Portugal', es: 'Portugal' },

            place: { pl: 'Wyspy Azorów', en: 'Azores Islands', es: ' Islas Azores' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Azory to wulkaniczne wyspy na Oceanie Atlantyckim. Mają zielone góry, gorące źródła i jeziora w kraterach. Turyści mogą obserwować wieloryby, wędrować lub relaksować się w naturze.', en: 'The Azores are volcanic islands in the Atlantic Ocean. They have green mountains, hot springs, and crater lakes. Visitors can go whale watching, hike, or relax in nature.', es: 'Las Azores son islas volcánicas en el océano Atlántico. Tienen montañas verdes, aguas termales y lagos cráter. Los visitantes pueden observar ballenas, hacer senderismo o relajarse en la naturaleza.' }

        },

        {

            id: 46,

            country: { pl: 'Oman', en: 'Oman', es: 'Omán' },

            place: { pl: 'Wielki Meczet Sułtana Qaboosa (Maskat)', en: 'Sultan Qaboos Grand Mosque (Muscat)', es: ' Gran Mezquita del Sultán Qaboos (Muscat)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Te meczet to oszałamiające dzieło architektury islamskiej. Ma piękne kopuły, misternie rzeźbione detale i ogromny żyrandol. Turyści mogą podziwiać jego spokojne piękno i wspaniały design.', en: 'This mosque is a stunning piece of Islamic architecture. It has beautiful domes, intricate carvings, and a massive chandelier. Visitors can admire its peaceful beauty and grand design.', es: 'Esta mezquita es una impresionante pieza de arquitectura islámica. Tiene hermosas cúpulas, intrincados grabados y un enorme candelabro. Los visitantes pueden admirar su belleza pacífica y su gran diseño.' }

        },

        {

            id: 47,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Roque Nublo', en: 'Roque Nublo', es: ' Roque Nublo' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Roque Nublo to wulkaniczna formacja skalna na Gran Canarii. Oferuje doskonałe warunki do wędrówek i panoramiczne widoki na surowy krajobraz wyspy.', en: 'Roque Nublo is a volcanic rock formation in Gran Canaria. It offers excellent hiking opportunities and panoramic views of the island\’s rugged landscape.', es: 'Roque Nublo es una formación rocosa volcánica en Gran Canaria. Ofrece excelentes oportunidades para hacer senderismo y vistas panorámicas del paisaje agreste de la isla.' }

        },

        {

            id: 48,

            country: { pl: 'Czechy', en: 'Czech Republic', es: 'República Checa' },

            place: { pl: 'Zamek Praski', en: 'Prague Castle', es: ' Castillo de Praga' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten zamek jest znany jako sceneria „Hamleta”. Ma wspaniałe sale, podziemne tunele i piękne widoki na morze. Latem odbywają się tu spektakle sztuk Szekspira.', en: 'Prague Castle is the largest castle complex in the world. It includes beautiful churches, royal buildings, and stunning city views. Visitors can explore its history and enjoy the Golden Lane.', es: 'El Castillo de Praga es el complejo de castillos más grande del mundo. Incluye hermosas iglesias, edificios reales y vistas impresionantes de la ciudad. Los visitantes pueden explorar su historia y disfrutar de la Calle Dorada.' }

        },

        {

            id: 49,

            country: { pl: 'Litwa', en: 'Lithuania', es: 'Lituania' },

            place: { pl: 'Stare Miasto w Wilnie', en: 'Vilnius Old Town', es: ' Casco Antiguo de Vilna' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Stare Miasto w Wilnie pełne jest urokliwych uliczek, barokowych kościołów i przytulnych kawiarni. Jest wpisane na listę UNESCO. Można tu zwiedzać zamki, rynki i piękne miejskie place.', en: 'Vilnius Old Town is full of charming streets, baroque churches, and cozy cafés. It is a UNESCO World Heritage Site. Visitors can explore historic castles, markets, and beautiful city squares.', es: 'El casco antiguo de Vilnius está lleno de calles encantadoras, iglesias barrocas y cafés acogedores. Es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO. Los visitantes pueden explorar castillos históricos, mercados y hermosas plazas de la ciudad.' }

        },

        {

            id: 50,

            country: { pl: 'Malta', en: 'Malta', es: 'Malta' },

            place: { pl: 'Mdina (Ciche Miasto)', en: 'Mdina (Silent City)', es: ' Mdina (Ciudad Silenciosa)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Mdina to ciche, otoczone murami miasto z wąskimi uliczkami i średniowiecznym urokiem. Samochody nie są dozwolone wewnątrz, co sprawia, że jest to spokojne miejsce do zwiedzania. Odwiedzający mogą cieszyć się wspaniałymi widokami i odwiedzać zabytkowe pałace.', en: 'Mdina is a quiet, walled city with narrow streets and medieval charm. Cars are not allowed inside, making it peaceful to explore. Visitors can enjoy stunning views and visit historic palaces.', es: 'Mdina es una ciudad tranquila amurallada con calles estrechas y encanto medieval. No se permiten autos dentro, lo que la hace tranquila para explorar. Los visitantes pueden disfrutar de vistas impresionantes y visitar palacios históricos.' }

        },

        {

            id: 51,

            country: { pl: 'Niemcy', en: 'Germany', es: 'Alemania' },

            place: { pl: 'Brama Brandenburska (Berlin)', en: 'Brandenburg Gate (Berlin)', es: ' Puerta de Brandeburgo (Berlín)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten wielki łuk to symbol historii Berlina. Kiedyś dzielił Berlin Wschodni i Zachodni. Dziś reprezentuje jedność i jest obowiązkowym punktem na mapie miasta.', en: 'This grand arch is a symbol of Berlin\’s history. It once divided East and West Berlin. Today, it represents unity and is a must-see landmark in the city.', es: 'Este gran arco es un símbolo de la historia de Berlín. En su momento, dividió Berlín Este y Oeste. Hoy, representa la unidad y es un hito imprescindible en la ciudad.' }

        },

        {

            id: 52,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Laguna Bacalar', en: 'Bacalar Lagoon', es: ' Laguna Bacalar' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Laguna Bacalar nazywana jest "Jeziorem Siedmiu Kolorów" z powodu swojej jasnoniebieskiej wody. Odwiedzający mogą pływać, pływać kajakiem i relaksować się w tej spokojnej naturalnej cudzie.', en: 'Bacalar Lagoon is called the "Lake of Seven Colors" because of its bright blue water. Visitors can swim, kayak, and relax in this peaceful natural wonder.', es: 'La Laguna Bacalar es conocida como el "Lago de los Siete Colores" por su agua azul brillante. Los visitantes pueden nadar, hacer kayak y relajarse en esta maravilla natural tranquila.' }

        },

        {

            id: 53,

            country: { pl: 'Szwajcaria', en: 'Switzerland', es: 'Suiza' },

            place: { pl: 'Dolina Lauterbrunnen', en: 'Lauterbrunnen Valley', es: ' Valle de Lauterbrunnen' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Dolina Lauterbrunnen to malownicza dolina z wodospadami i alpejskimi łąkami. To doskonałe miejsce na wędrówki, oferujące oszałamiające widoki na okoliczne góry.', en: 'Lauterbrunnen Valley is a picturesque valley with waterfalls and alpine meadows. It\’s a great destination for hiking and offers stunning views of the surrounding mountains.', es: 'El Valle de Lauterbrunnen es un valle pintoresco con cascadas y praderas alpinas. Es un excelente destino para hacer senderismo y ofrece vistas impresionantes de las montañas circundantes.' }

        },

        {

            id: 54,

            country: { pl: 'Albania', en: 'Albania', es: 'Albania' },

            place: { pl: 'Stanowisko archeologiczne Butrint', en: 'Butrint Archaeological Site', es: ' Sitio Arqueológico de Butrint' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Butrint to starożytne miasto z greckimi i rzymskimi ruinami. Można tam zobaczyć teatr, fortecę i stare mury miejskie. Otoczone naturą, jest to spokojne miejsce do odkrywania historii.', en: 'Butrint is an ancient city with Greek and Roman ruins. You can see a theater, a fortress, and old city walls. Surrounded by nature, it is a peaceful place to explore history.', es: 'Butrint es una ciudad antigua con ruinas griegas y romanas. Se puede ver un teatro, una fortaleza y viejas murallas de la ciudad. Rodeada de naturaleza, es un lugar tranquilo para explorar la historia.' }

        },

        {

            id: 55,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Plaża Bondi', en: 'Bondi Beach', es: ' Playa Bondi' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Bondi Beach to jedna z najsłynniejszych plaż Australii. Ma złoty piasek, świetne fale do surfowania i tętniącą życiem atmosferę. Można tam spacerować malowniczą ścieżką wzdłuż wybrzeża lub po prostu odpoczywać na słońcu.', en: 'Bondi Beach is one of Australia\’s most famous beaches. It has golden sand, great waves for surfing, and a lively atmosphere. Visitors can walk the scenic coastal trail or relax in the sun.', es: 'Bondi Beach es una de las playas más famosas de Australia. Tiene arena dorada, excelentes olas para surfear y un ambiente animado. Los visitantes pueden caminar por el sendero costero panorámico o relajarse al sol.' }

        },

        {

            id: 56,

            country: { pl: 'Finlandia', en: 'Finland', es: 'Finlandia' },

            place: { pl: 'Ośrodek narciarski Levi', en: 'Levi Ski Resort', es: ' Estación de Esquí Levi' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta luksusowa linia brzegowa oferuje słoneczne plaże, luksusowe jachty i urokliwe wioski. Zwiedzający mogą odkrywać Niceę, Monako i Cannes lub po prostu odpoczywać nad błękitnym Morzem Śródziemnym.', en: 'Levi is Finland\’s top ski resort, offering slopes for all levels. In winter, visitors can also enjoy snowmobile rides, husky safaris, and the Northern Lights.', es: 'Levi es la principal estación de esquí de Finlandia, que ofrece pistas para todos los niveles. En invierno, los visitantes también pueden disfrutar de paseos en moto de nieve, safaris de huskies y la aurora boreal.' }

        },

        {

            id: 57,

            country: { pl: 'Słowacja', en: 'Slovakia', es: 'Eslovaquia' },

            place: { pl: 'Zamek Spiski', en: 'Spiš Castle', es: ' Castillo de Spiš' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Spiš to jeden z największych zamków w Europie Środkowej. Wznosi się na wzgórzu, oferując wspaniałe widoki. Zamek jest wpisany na listę UNESCO.', en: 'Spiš Castle is one of the largest castles in Central Europe. It is perched on a hill and provides magnificent views. The castle is a UNESCO World Heritage Site.', es: 'El Castillo de Spiš es uno de los castillos más grandes de Europa Central. Está ubicado en una colina y ofrece vistas magníficas. El castillo es Patrimonio de la Humanidad de la UNESCO.' }

        },

        {

            id: 58,

            country: { pl: 'Belgia', en: 'Belgium', es: 'Bélgica' },

            place: { pl: 'Manneken Pis (Bruksela)', en: 'Manneken Pis (Brussels)', es: ' Manneken Pis (Bruselas)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta mała rzeźba sikającego chłopca to jeden z najsłynniejszych symboli Brukseli. Ma wiele różnych kostiumów, a czasem podczas festiwali „sika” specjalnymi napojami!', en: 'This small statue of a boy urinating is one of Brussels’ most famous landmarks. It has many different costumes, and sometimes it even “pees” special drinks for festivals!', es: 'Esta pequeña estatua de un niño orinando es uno de los monumentos más famosos de Bruselas. Tiene muchos disfraces diferentes y, a veces, ¡incluso "orina" bebidas especiales para festivales!' }

        },

        {

            id: 59,

            country: { pl: 'Szwecja', en: 'Sweden', es: 'Suecia' },

            place: { pl: 'Hotel lodowy (Jukkasjärvi)', en: 'Icehotel (Jukkasjärvi)', es: ' Icehotel (Jukkasjärvi)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Icehotel to wyjątkowy hotel zbudowany w całości z lodu i śniegu. Odwiedzający mogą spać w lodowych pokojach, podziwiać rzeźby lodowe i brać udział w zimowych aktywnościach, takich jak jazda na psich zaprzęgach.', en: 'The Icehotel is a unique hotel made entirely of ice and snow. Visitors can sleep in ice rooms, enjoy ice sculptures, and take part in winter activities like dog sledding.', es: 'El Icehotel es un hotel único hecho completamente de hielo y nieve. Los visitantes pueden dormir en habitaciones de hielo, disfrutar de esculturas de hielo y participar en actividades invernales como el trineo tirado por perros.' }

        },

        {

            id: 60,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Duomo di Milano (katedra w Mediolanie)', en: 'Duomo di Milano (Milan Cathedral)', es: ' Duomo de Milán (Catedral de Milán)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta gotycka katedra budowana była przez prawie 600 lat. Ma piękne rzeźby i witraże. Odwiedzający mogą wejść na dach, by podziwiać panoramę miasta.', en: 'This Gothic cathedral took nearly 600 years to complete. It has beautiful statues and stained glass. Visitors can climb to the rooftop for an amazing city view.', es: 'Esta catedral gótica tardó casi 600 años en completarse. Tiene hermosas estatuas y vitrales. Los visitantes pueden subir al techo para obtener una vista increíble de la ciudad.' }

        },

        {

            id: 61,

            country: { pl: 'Liechtenstein', en: 'Liechtenstein', es: 'Liechtenstein' },

            place: { pl: 'Zamek Vaduz', en: 'Vaduz Castle', es: ' Castillo de Vaduz' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Vaduz jest rezydencją rodziny książęcej Liechtensteinu. Znajduje się na wzgórzu z pięknym widokiem na stolicę. Chociaż nie można go zwiedzać, jest świetnym miejscem do robienia zdjęć.', en: 'Vaduz Castle is home to Liechtenstein\’s royal family. It sits on a hill with great views of the capital. While visitors can’t go inside, it is a great photo spot.', es: 'El Castillo de Vaduz es la residencia de la familia real de Liechtenstein. Se encuentra en una colina con grandes vistas de la capital. Aunque los visitantes no pueden entrar, es un gran lugar para fotos.' }

        },

        {

            id: 62,

            country: { pl: 'Litwa', en: 'Lithuania', es: 'Lituania' },

            place: { pl: 'Zamek na wyspie Troki', en: 'Trakai Island Castle', es: ' Castillo de Isla Trakai' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten bajkowy zamek znajduje się na wyspie na spokojnym jeziorze. Zbudowany w XIV wieku, dziś jest muzeum. Zwiedzający mogą popływać łodzią i poznać historię Litwy.', en: 'This fairytale-like castle sits on an island in a peaceful lake. Built in the 14th century, it is now a museum. Visitors can enjoy boat rides and learn about Lithuania\’s history.', es: 'Este castillo, parecido a un cuento de hadas, se encuentra en una isla en un lago tranquilo. Construido en el siglo XIV, ahora es un museo. Los visitantes pueden disfrutar de paseos en bote y aprender sobre la historia de Lituania.' }

        },

        {

            id: 63,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Lanzarote (Wyspy Kanaryjskie Wyspy) – Jameos del Agua', en: 'Lanzarote (Canary Islands) – Jameos del Agua', es: ' Lanzarote (Islas Canarias) – Jameos del Agua' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Jameos del Agua to unikalna atrakcja na Lanzarote, gdzie wulkaniczne jaskinie zostały przekształcone w podziemny raj. Znajduje się tam naturalny basen i piękne ogrody.', en: 'Jameos del Agua is a unique attraction in Lanzarote, where volcanic caves are turned into an underground paradise. It features a natural swimming pool and beautiful gardens.', es: 'Jameos del Agua es una atracción única en Lanzarote, donde las cuevas volcánicas se han transformado en un paraíso subterráneo. Cuenta con una piscina natural y hermosos jardines.' }

        },

        {

            id: 64,

            country: { pl: 'Irlandia', en: 'Ireland', es: 'Irlanda' },

            place: { pl: 'Dzielnica Łacińska w Galway', en: 'Galway\’s Latin Quarter', es: ' Barrio Latino de Galway' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta urocza dzielnica pełna jest kolorowych budynków, tętniących życiem pubów i muzyki ulicznej. To świetne miejsce, by poczuć irlandzką kulturę i nocne życie.', en: 'This charming area is full of colorful buildings, lively pubs, and street music. It\’s a great place to experience Irish culture and nightlife.', es: 'Esta encantadora zona está llena de edificios coloridos, pubs animados y música en la calle. Es un lugar ideal para experimentar la cultura y la vida nocturna irlandesa.' }

        },

        {

            id: 65,

            country: { pl: 'Bośnia i Hercegowina', en: 'Bosnia and Herzegovina', es: 'Bosnia y Herzegovina' },

            place: { pl: 'Wodospad Jajce', en: 'Jajce Waterfall', es: ' Catarata de Jajce' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Jajce to małe miasteczko z imponującym wodospadem w samym centrum. Odwiedzający mogą podziwiać go z góry lub zejść na dół, by poczuć jego moc z bliska.', en: 'Jajce is a small town with a stunning waterfall in the middle of it. Visitors can see the waterfall from above or walk down to feel its power up close.', es: 'Jajce es una pequeña ciudad con una impresionante cascada en su centro. Los visitantes pueden ver la cascada desde arriba o caminar hasta abajo para sentir su poder de cerca.' }

        },

        {

            id: 66,

            country: { pl: 'Węgry', en: 'Hungary', es: 'Hungría' },

            place: { pl: 'Budynek parlamentu (Budapeszt)', en: 'Parliament Building (Budapest)', es: ' Parlamento de Budapest' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten imponujący budynek to jeden z największych w Europie. Stoi nad Dunajem i ma piękną gotycką fasadę. Zwiedzający mogą zwiedzać jego wspaniałe wnętrza z przewodnikiem.', en: 'This grand building is one of the largest in Europe. It sits along the Danube River and has a beautiful Gothic design. Visitors can take guided tours to see its stunning interior.', es: 'Este grandioso edificio es uno de los más grandes de Europa. Se encuentra a lo largo del río Danubio y tiene un hermoso diseño gótico. Los visitantes pueden hacer recorridos guiados para ver su impresionante interior.' }

        },

        {

            id: 67,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Most Sydney Harbour', en: 'Sydney Harbour Bridge', es: ' Puente del Puerto de Sídney' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Ten most łączy dwa brzegi portu w Sydney. Odważni zwiedzający mogą wspiąć się na jego szczyt, by podziwiać niesamowite widoki. Most jest szczególnie piękny podczas sylwestrowych fajerwerków.', en: 'This bridge connects the two sides of Sydney\’s harbor. Brave visitors can climb to the top for amazing views. The bridge is especially beautiful during the New Year\’s Eve fireworks.', es: 'Este puente conecta los dos lados del puerto de Sídney. Los visitantes valientes pueden subir a la cima para disfrutar de vistas impresionantes. El puente es especialmente hermoso durante los fuegos artificiales de la víspera de Año Nuevo.' }

        },

        {

            id: 68,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Jaskinie Ellora i Ajanta', en: 'Ellora & Ajanta Caves', es: ' Cuevas de Ellora y Ajanta' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Te jaskinie kryją starożytne, wykute w skale świątynie i rzeźby. Jaskinie Ajanta mają buddyjskie malowidła, a Ellora – rzeźby hinduistyczne, dżinijskie i buddyjskie.', en: 'These caves have ancient rock-cut temples and sculptures. The Ajanta caves have Buddhist paintings, while Ellora has Hindu, Jain, and Buddhist carvings.', es: 'Estas cavernas tienen templos y esculturas talladas en la roca antigua. Las cavernas de Ajanta tienen pinturas budistas, mientras que Ellora tiene tallados hindúes, jainistas y budistas.' }

        },

        {

            id: 69,

            country: { pl: 'Bułgaria', en: 'Bulgaria', es: 'Bulgaria' },

            place: { pl: 'Belogradchik Skały', en: 'Belogradchik Rocks', es: ' Rocas de Belogradchik' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te gigantyczne czerwone skały wyglądają jak z bajki. Wśród nich zbudowano średniowieczną fortecę. Zwiedzający mogą wędrować, robić niesamowite zdjęcia i podziwiać wspaniałe widoki.', en: 'These giant red rock formations look like something from a fairy tale. A medieval fortress was built among the rocks. Visitors can hike, take great photos, and enjoy the amazing views.', es: 'Estas enormes formaciones de rocas rojas parecen sacadas de un cuento de hadas. Se construyó una fortaleza medieval entre las rocas. Los visitantes pueden hacer senderismo, tomar fotos increíbles y disfrutar de vistas asombrosas.' }

        },

        {

            id: 70,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Pałac w Wersalu', en: 'Palace of Versailles', es: ' Palacio de Versalles' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten wspaniały pałac był domem francuskich królów. Ma złote sale, niekończące się ogrody i słynną Salę Luster. Odwiedzający mogą wyobrazić sobie luksus królewskiego życia.', en: 'This grand palace was home to French kings. It has golden halls, endless gardens, and a famous Hall of Mirrors. Visitors can imagine the luxury of royal life.', es: 'Este grandioso palacio fue la residencia de los reyes franceses. Tiene pasillos dorados, jardines interminables y la famosa Galería de los Espejos. Los visitantes pueden imaginar el lujo de la vida real.' }

        },

        {

            id: 71,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Klasztory Meteory', en: 'Meteora Monasteries', es: ' Monasterios de Meteora' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te starożytne klasztory zostały zbudowane na wysokich skalnych filarach. Kiedyś stanowiły bezpieczne schronienie dla mnichów. Zwiedzający mogą wspiąć się na górę i podziwiać zapierające dech w piersiach widoki.', en: 'These ancient monasteries are built on top of high rock pillars. They were once a safe place for monks. Visitors can climb up and enjoy breathtaking views.', es: 'Estos antiguos monasterios están construidos sobre altas columnas rocosas. Fueron en su momento un lugar seguro para los monjes. Los visitantes pueden subir y disfrutar de vistas impresionantes.' }

        },

        {

            id: 72,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Pomnik Małej Syrenki (Kopenhaga)', en: 'The Little Mermaid Statue (Copenhagen)', es: ' Estatua de La Sirenita (Copenhague)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ilulissat słynie ze wspaniałego lodowca Ilulissat, wpisanego na listę UNESCO. Zwiedzający mogą podziwiać ogromne góry lodowe i korzystać z rejsów, wędrówek oraz psich zaprzęgów w malowniczym arktycznym krajobrazie.', en: 'This small bronze statue is a symbol of Copenhagen. Inspired by Hans Christian Andersen\’s fairy tale, it sits on a rock by the water and attracts many visitors.', es: 'Esta pequeña estatua de bronce es un símbolo de Copenhague. Inspirada en el cuento de hadas de Hans Christian Andersen, se encuentra sobre una roca junto al agua y atrae a muchos visitantes.' }

        },

        {

            id: 73,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Zamek Kronborg (Elsynor)', en: 'Kronborg Castle (Elsinore)', es: ' Castillo de Kronborg (Elsinore)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Roseau to stolica Dominiki, pełna francuskich i brytyjskich kolonialnych budynków. Jest świetną bazą wypadową do zwiedzania wodospadów, gorących źródeł i lasów deszczowych.', en: 'This castle is famous as the setting for Hamlet. It has grand halls, underground tunnels, and great views of the sea. Visitors can watch Shakespeare performances here in the summer.', es: 'Este castillo es famoso por ser el escenario de Hamlet. Tiene grandes salas, túneles subterráneos y hermosas vistas al mar. Los visitantes pueden ver representaciones de Shakespeare aquí durante el verano.' }

        },

        {

            id: 74,

            country: { pl: 'Czechy', en: 'Czech Republic', es: 'República Checa' },

            place: { pl: 'Uzdrowisko Karlowe Wary', en: 'Karlovy Vary Spa Town', es: ' Ciudad Balneario Karlovy Vary' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Tivoli to jeden z najstarszych parków rozrywki na świecie. Ma piękne ogrody, ekscytujące atrakcje i występy na żywo. Nocą park rozświetla się, tworząc magiczną atmosferę.', en: 'Karlovy Vary is a famous spa town with hot mineral springs. Visitors can drink healing waters, relax in spas, and explore beautiful 19th-century buildings.', es: 'Karlovy Vary es una famosa ciudad balnearia con aguas termales. Los visitantes pueden beber aguas curativas, relajarse en los spas y explorar hermosos edificios del siglo XIX.' }

        },

        {

            id: 75,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Caño Cristales', en: 'Caño Cristales', es: ' Caño Cristales' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Znana jako „Rzeka Pięciu Kolorów”, Caño Cristales słynie z jaskrawoczerwonych, żółtych, zielonych i niebieskich wód. Kolory pochodzą od specjalnych roślin. To oszałamiający cud natury.', en: 'Known as the "River of Five Colors," Caño Cristales is famous for its bright red, yellow, green, and blue waters. The colors come from special plants. It is a stunning natural wonder to visit.', es: 'Conocido como el "Río de los Cinco Colores," Caño Cristales es famoso por sus aguas brillantes de color rojo, amarillo, verde y azul. Los colores provienen de plantas especiales. Es una impresionante maravilla natural para visitar.' }

        },

        {

            id: 76,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Watykan (katedra św. Bazylika św. Piotra i Kaplica Sykstyńska)', en: 'Vatican City (St. Peter\’s Basilica & Sistine Chapel)', es: ' Ciudad del Vaticano (Basílica de San Pedro y Capilla Sixtina)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Watykan to najmniejsze państwo na świecie. Znajduje się tu Bazylika św. Piotra – największy kościół – oraz Kaplica Sykstyńska, słynna z fresków Michała Anioła.', en: 'The Vatican is the world\’s smallest country. It has St. Peter\’s Basilica, the largest church, and the Sistine Chapel, famous for Michelangelo\’s paintings.', es: 'El Vaticano es el país más pequeño del mundo. Tiene la Basílica de San Pedro, la iglesia más grande, y la Capilla Sixtina, famosa por las pinturas de Miguel Ángel.' }

        },

        {

            id: 77,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Arena w Puli (rzymski amfiteatr)', en: 'Pula Arena (Roman Amphitheater)', es: ' Arena de Pula (Anfiteatro Romano)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten doskonale zachowany rzymski amfiteatr ma ponad 2 000 lat. Kiedyś odbywały się w nim walki gladiatorów. Dziś służy do koncertów i festiwali, co czyni go doskonałym miejscem do doświadczenia historii i kultury.', en: 'This well-preserved Roman amphitheater is over 2,000 years old. It once hosted gladiator fights. Today, it is used for concerts and festivals, making it a great place to experience history and culture.', es: 'Este bien conservado anfiteatro romano tiene más de 2,000 años. En su momento, albergaba luchas de gladiadores. Hoy se utiliza para conciertos y festivales, lo que lo convierte en un excelente lugar para experimentar historia y cultura.' }

        },

        {

            id: 78,

            country: { pl: 'Urugwaj', en: 'Uruguay', es: 'Uruguay' },

            place: { pl: 'Punta del Este', en: 'Punta del Este', es: ' Punta del Este' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Punta del Este to luksusowe nadmorskie miasteczko. Znane z pięknych plaż, luksusowych jachtów i stylowego życia nocnego, jest popularnym miejscem zarówno do wypoczynku, jak i zabawy.', en: 'Punta del Este is a glamorous beach resort town. Known for its beautiful beaches, luxury yachts, and stylish nightlife, it is a popular destination for both relaxation and entertainment.', es: 'Punta del Este es una glamorosa ciudad balnearia. Conocida por sus hermosas playas, yates de lujo y animada vida nocturna, es un destino popular tanto para relajarse como para divertirse.' }

        },

        {

            id: 79,

            country: { pl: 'Czechy', en: 'Czech Republic', es: 'República Checa' },

            place: { pl: 'Ossuarium w Sedlec (Kościół Czaszek)', en: 'Sedlec Ossuary (Bone Church)', es: ' Osario de Sedlec (Iglesia de los Huesos)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Wyspy Owcze to ukryty skarb z dramatycznymi klifami, zielonymi wzgórzami i urokliwymi wioskami. Zwiedzający mogą wędrować, podziwiać maskonury i poznać wyjątkową mieszankę kultury skandynawskiej i wikingów.', en: 'This small chapel is decorated with thousands of human bones. The chandeliers, crosses, and walls are all made of skulls and bones. It is one of the most unusual churches in the world.', es: 'Esta pequeña capilla está decorada con miles de huesos humanos. Los candelabros, cruces y paredes están hechos de calaveras y huesos. Es una de las iglesias más inusuales del mundo.' }

        },

        {

            id: 80,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Pływające targi (Damnoen Saduak)', en: 'Floating Markets (Damnoen Saduak)', es: ' Mercados Flotantes (Damnoen Saduak)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Pływające rynki to unikalne doświadczenie, gdzie sprzedawcy oferują towary z łodzi. Odwiedzający mogą kupować owoce, pamiątki i lokalne jedzenie, pływając po kanałach.', en: 'The floating markets are a unique experience where vendors sell goods from boats. Visitors can shop for fruits, souvenirs, and local food while cruising along the canals.', es: 'Los mercados flotantes son una experiencia única donde los vendedores venden productos desde botes. Los visitantes pueden comprar frutas, souvenirs y comida local mientras navegan por los canales.' }

        },

        {

            id: 81,

            country: { pl: 'Norwegia', en: 'Norway', es: 'Noruega' },

            place: { pl: 'Nabrzeże Bryggen w Bergen', en: 'Bergen\’s Bryggen Wharf', es: ' Muelle Bryggen de Bergen' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Bryggen to rząd kolorowych drewnianych domków przy porcie. Jest to miejsce wpisane na listę UNESCO i przypomnienie o wikingowej przeszłości Bergen. Turyści mogą zwiedzać małe sklepy, muzea i restauracje serwujące owoce morza.', en: 'Bryggen is a row of colorful wooden houses by the harbor. It is a UNESCO site and a reminder of Bergen\’s Viking past. Visitors can explore small shops, museums, and seafood restaurants.', es: 'Bryggen es una fila de casas de madera coloridas junto al puerto. Es un sitio de la UNESCO y un recordatorio del pasado vikingo de Bergen. Los visitantes pueden explorar pequeñas tiendas, museos y restaurantes de mariscos.' }

        },

        {

            id: 82,

            country: { pl: 'Saint Kitts i Nevis', en: 'St. Kitts & Nevis', es: 'San Cristóbal y Nieves' },

            place: { pl: 'Nevis', en: 'Nevis', es: ' Nevis' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Nevis to tropikalna wyspa na Karaibach, znana z plaż, bujnej dżungli i wulkanicznych gór. Odwiedzający mogą wspiąć się na szczyt Nevis Peak lub zrelaksować się na cichych plażach.', en: 'Nevis is a tropical island in the Caribbean known for its beaches, lush rainforest, and volcanic mountains. Visitors can hike the Nevis Peak or relax on the quiet beaches.', es: 'Nevis es una isla tropical en el Caribe conocida por sus playas, su exuberante selva tropical y sus montañas volcánicas. Los visitantes pueden hacer senderismo en el Pico Nevis o relajarse en las tranquilas playas.' }

        },

        {

            id: 83,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Sardynia', en: 'Sardinia', es: ' Cerdeña' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Sardynia to wyspa z krystalicznie czystą wodą i białymi plażami. Znajdują się tu starożytne kamienne wieże zwane nuraghe. Odwiedzający mogą zwiedzać jaskinie, góry i delektować się pysznymi owocami morza.', en: 'Sardinia is an island with crystal-clear waters and white sand beaches. It has ancient stone towers called nuraghe. Visitors can explore caves, mountains, and delicious seafood.', es: 'Cerdeña es una isla con aguas cristalinas y playas de arena blanca. Tiene torres de piedra antiguas llamadas nuraghe. Los visitantes pueden explorar cuevas, montañas y disfrutar de mariscos deliciosos.' }

        },

        {

            id: 84,

            country: { pl: 'Estonia', en: 'Estonia', es: 'Estonia' },

            place: { pl: 'Stare Miasto w Tallinie', en: 'Tallinn Old Town', es: ' Casco Antiguo de Tallin' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta słynna gotycka katedra ma piękne witraże i wysokie wieże. Pomimo pożaru w 2019 roku pozostaje symbolem Paryża. Zwiedzający mogą podziwiać jej misternie rzeźbione detale.', en: 'Tallinn\’s Old Town is like a fairytale with medieval streets, towers, and churches. It has colorful buildings and cozy cafés. Visitors can walk along the ancient city walls.', es: 'El casco antiguo de Tallin es como un cuento de hadas con calles medievales, torres e iglesias. Tiene edificios coloridos y cafés acogedores. Los visitantes pueden caminar a lo largo de las antiguas murallas de la ciudad.' }

        },

        {

            id: 85,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Majorka (Baleary) – Katedra w Palmie', en: 'Mallorca (Balearic Islands) – Palma Cathedral', es: ' Mallorca (Islas Baleares) – Catedral de Palma' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Majorka to piękna wyspa znana z plaż, gór i Katedry w Palma. Wspaniała architektura katedry i jej położenie nad morzem sprawiają, że jest to miejsce, które warto odwiedzić.', en: 'Mallorca is a beautiful island known for its beaches, mountains, and Palma Cathedral. The cathedral\’s stunning architecture and location by the sea make it a must-visit.', es: 'Mallorca es una hermosa isla conocida por sus playas, montañas y la Catedral de Palma. La impresionante arquitectura de la catedral y su ubicación junto al mar la convierten en un lugar de visita obligada.' }

        },

        {

            id: 86,

            country: { pl: 'USA/Kanada', en: 'USA/Canada', es: 'EE.UU./Canadá' },

            place: { pl: 'Wodospady Niagara', en: 'Niagara Falls', es: ' Cataratas del Niágara' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Wodospad Niagara to jedno z najsłynniejszych cudów natury na świecie. Wodospady są widoczne zarówno z USA, jak i Kanady, oferując rejsy łodzią, malownicze widoki i szlaki turystyczne.', en: 'Niagara Falls is one of the most famous natural wonders in the world. The falls can be seen from both the USA and Canada, offering boat tours, scenic views, and hiking paths.', es: 'Las Cataratas del Niágara son una de las maravillas naturales más famosas del mundo. Las cataratas se pueden ver desde los EE. UU. y Canadá, ofreciendo paseos en bote, vistas panorámicas y senderos para caminar.' }

        },

        {

            id: 87,

            country: { pl: 'Portugalia', en: 'Portugal', es: 'Portugal' },

            place: { pl: 'Wieża Belem (Lizbona)', en: 'Belem Tower (Lisbon)', es: ' Torre de Belém (Lisboa)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta zabytkowa wieża stoi nad brzegiem rzeki Tag. Została zbudowana, aby chronić Lizbonę przed najeźdźcami. Turyści mogą wspiąć się na szczyt, by podziwiać wspaniałe widoki na miasto i rzekę.', en: 'This historic tower stands on the banks of the Tagus River. It was built to protect Lisbon from invaders. Visitors can climb to the top for great views of the city and river.', es: 'Esta torre histórica se encuentra a orillas del río Tajo. Fue construida para proteger Lisboa de los invasores. Los visitantes pueden subir hasta la cima para obtener vistas espectaculares de la ciudad y el río.' }

        },

        {

            id: 88,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Krzywa Wieża w Pizie', en: 'Leaning Tower of Pisa', es: ' Torre Inclinada de Pisa' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta słynna wieża przechyla się z powodu miękkiego podłoża. Została zbudowana w XII wieku i do dziś stoi. Odwiedzający mogą wspiąć się na szczyt, by podziwiać widoki na Pizę.', en: 'This famous tower leans because of soft ground. It was built in the 12th century and still stands today. Visitors can climb to the top for great views of Pisa.', es: 'Esta famosa torre se inclina debido al terreno blando. Fue construida en el siglo XII y sigue en pie hoy. Los visitantes pueden subir a la cima para obtener vistas panorámicas de Pisa.' }

        },

        {

            id: 89,

            country: { pl: 'Liechtenstein', en: 'Liechtenstein', es: 'Liechtenstein' },

            place: { pl: 'Rzeka Ren Dolina', en: 'Rhine River Valley', es: ' Valle del Río Rin' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta dolina zachwyca pięknymi widokami, zielonymi wzgórzami i uroczymi wioskami. Zwiedzający mogą spacerować, jeździć na rowerze i degustować wina wzdłuż rzeki.', en: 'This valley has beautiful views, green hills, and charming villages. Visitors can enjoy hiking, cycling, and wine tasting along the river.', es: 'Este valle tiene hermosas vistas, colinas verdes y pueblos encantadores. Los visitantes pueden disfrutar de caminatas, paseos en bicicleta y degustación de vinos a lo largo del río.' }

        },

        {

            id: 90,

            country: { pl: 'Szwecja', en: 'Sweden', es: 'Suecia' },

            place: { pl: 'Pałac Drottningholm', en: 'Drottningholm Palace', es: ' Palacio de Drottningholm' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Pałac Drottningholm to rezydencja szwedzkiej rodziny królewskiej. Otoczony jest pięknymi ogrodami i wpisany na Listę Światowego Dziedzictwa UNESCO. Odwiedzający mogą zwiedzać pałac i jego tereny.', en: 'Drottningholm Palace is the residence of the Swedish royal family. It is surrounded by beautiful gardens and is a UNESCO World Heritage site. Visitors can tour the palace and its grounds.', es: 'El Palacio de Drottningholm es la residencia de la familia real sueca. Está rodeado por hermosos jardines y es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO. Los visitantes pueden recorrer el palacio y sus terrenos.' }

        },

        {

            id: 91,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Sagrada Familia (Barcelona)', en: 'Sagrada Familia (Barcelona)', es: ' Sagrada Familia (Barcelona)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: {
                pl: 'Sagrada Familia to oszałamiająca bazylika zaprojektowana przez Antoniego Gaudiego. Misternie wykonane detale i wysokie iglice czynią ją jednym z najsłynniejszych zabytków Barcelony.', en: 'The Sagrada Familia is a stunning basilica designed by Antoni Gaudí. The intricate details and towering spires make it one of Barcelona\'s most famous landmarks.', es: 'La Sagrada Familia es una impresionante basílica diseñada por Antoni Gaudí.Los intrincados detalles y sus altas torres la convierten en uno de los monumentos más famosos de Barcelona.'
            }

        },

        {

            id: 92,

            country: { pl: 'Monako', en: 'Monaco', es: 'Mónaco' },

            place: { pl: 'Pałac Książęcy Monako', en: 'Prince\’s Palace of Monaco', es: ' Palacio del Príncipe de Mónaco' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Pałac Książęcy to oficjalna rezydencja rodziny królewskiej Monako. Odwiedzający mogą obejrzeć zmianę warty i zwiedzać eleganckie pokoje.', en: 'The Prince\’s Palace is the official home of Monaco\’s royal family. Visitors can watch the changing of the guard and tour its elegant rooms.', es: 'El Palacio del Príncipe es la residencia oficial de la familia real de Mónaco. Los visitantes pueden ver el cambio de guardia y recorrer sus elegantes habitaciones.' }

        },

        {

            id: 93,

            country: { pl: 'Austria', en: 'Austria', es: 'Austria' },

            place: { pl: 'Wioska Hallstatt', en: 'Hallstatt Village', es: ' Pueblo de Hallstatt' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Hallstatt to bajkowa wioska nad jeziorem w austriackich Alpach. Słynie z uroczych domów, kopalni soli i oszałamiających widoków górskich. Odwiedzający mogą wybrać się na rejs łodzią lub wędrować po okolicznych szlakach.', en: 'Hallstatt is a fairy-tale village by a lake in the Austrian Alps. It is famous for its charming houses, salt mines, and stunning mountain views. Visitors can take boat rides or hike nearby trails.', es: 'Hallstatt es un pueblo de cuento de hadas junto a un lago en los Alpes austriacos. Es famoso por sus encantadoras casas, minas de sal y vistas impresionantes de las montañas. Los visitantes pueden dar paseos en barco o hacer caminatas por los senderos cercanos.' }

        },

        {

            id: 94,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Klify Dover', en: 'Cliffs of Dover', es: ' Acantilados de Dover' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Klify Dover słyną z uderzających białych wapiennych klifów, które wznoszą się nad Kanałem La Manche. Turyści mogą spacerować, podziwiając niesamowite widoki na morze i Francję.', en: 'The Cliffs of Dover are famous for their striking white chalk cliffs that rise over the English Channel. Visitors can enjoy walks with incredible views across the sea to France.', es: 'Los Acantilados de Dover son famosos por sus impresionantes acantilados blancos de tiza que se elevan sobre el Canal de la Mancha. Los visitantes pueden disfrutar de caminatas con vistas increíbles al mar hacia Francia.' }

        },

        {

            id: 95,

            country: { pl: 'Słowenia', en: 'Slovenia', es: 'Eslovenia' },

            place: { pl: 'Stare Miasto w Piran', en: 'Piran Old Town', es: ' Casco Antiguo de Piran' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Piran to urokliwe nadmorskie miasteczko z wąskimi uliczkami i kolorowymi budynkami. Wenecka architektura miasteczka i widoki na Morze Adriatyckie sprawiają, że jest to wspaniałe miejsce do zwiedzania.', en: 'Piran is a charming coastal town with narrow streets and colorful buildings. The town\’s Venetian architecture and views of the Adriatic Sea make it a lovely place to explore.', es: 'Piran es un encantador pueblo costero con calles estrechas y edificios coloridos. La arquitectura veneciana del pueblo y las vistas del mar Adriático lo convierten en un lugar encantador para explorar.' }

        },

        {

            id: 96,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Ogrody Tivoli (Kopenhaga)', en: 'Tivoli Gardens (Copenhagen)', es: ' Jardines de Tivoli (Copenhague)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Boiling Lake to cud natury – parujące jezioro gorącej wody w wulkanicznym kraterze. Wędrówka do niego jest wymagająca, ale nagradza zapierającymi dech w piersiach widokami dzikiej Dominiki.', en: 'Tivoli is one of the oldest amusement parks in the world. It has beautiful gardens, fun rides, and live entertainment. At night, the park lights up, creating a magical atmosphere.', es: 'Tivoli es uno de los parques de atracciones más antiguos del mundo. Tiene hermosos jardines, divertidas atracciones y entretenimiento en vivo. Por la noche, el parque se ilumina, creando una atmósfera mágica.' }

        },

        {

            id: 97,

            country: { pl: 'Szwajcaria', en: 'Switzerland', es: 'Suiza' },

            place: { pl: 'Matterhorn (Zermatt)', en: 'Matterhorn (Zermatt)', es: ' Matterhorn (Zermatt)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Matterhorn to jedna z najbardziej ikonicznych gór na świecie. Słynie z piramidalnego kształtu i przyciąga wspinaczy i narciarzy z całego świata.', en: 'The Matterhorn is one of the most iconic mountains in the world. It\’s famous for its pyramid shape and attracts climbers and skiers from around the world.', es: 'El Matterhorn es una de las montañas más icónicas del mundo. Es famosa por su forma piramidal y atrae a escaladores y esquiadores de todo el mundo.' }

        },

        {

            id: 98,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Zamek w Edynburgu (Szkocja)', en: 'Edinburgh Castle (Scotland)', es: ' Castillo de Edimburgo (Escocia)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Edinburgh znajduje się na wulkanicznej skale z widokiem na miasto. Jest siedzibą Szkockich Klejnotów Koronnych i oferuje wspaniałe widoki na okolicę.', en: 'Edinburgh Castle is perched on a volcanic rock overlooking the city. It is home to the Crown Jewels of Scotland and offers stunning views of the surrounding area.', es: 'El Castillo de Edimburgo está en una roca volcánica con vistas a la ciudad. Es el hogar de las Joyas de la Corona de Escocia y ofrece vistas espectaculares de los alrededores.' }

        },

        {

            id: 99,

            country: { pl: 'Antigua i Barbuda', en: 'Antigua & Barbuda', es: 'Antigua y Barbuda' },

            place: {
                pl: 'St. John\'s', en: 'St.John\’s', es: ' St.John\’s'
            },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'St. John\’s to kolorowa stolica Antigui. Miasto oferuje tętniący życiem targ, zabytkowe budynki i piękny port. To świetne miejsce na zakupy, jedzenie i poznawanie historii wyspy.', en: 'St. John\’s is the colorful capital of Antigua. It has a lively market, historic buildings, and a beautiful harbor. The city is a great place to shop, eat, and learn about the island\’s history.', es: 'San Juan es la colorida capital de Antigua. Tiene un mercado animado, edificios históricos y un hermoso puerto. La ciudad es un gran lugar para comprar, comer y aprender sobre la historia de la isla.' }

        },

        {

            id: 100,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Park Güell (Barcelona)', en: 'Park Güell (Barcelona)', es: ' Parque Güell (Barcelona)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Park Güell to publiczny park zaprojektowany przez Gaudiego, pełen kolorowych mozaik i unikalnych cech architektonicznych. Oferuje panoramiczne widoki na Barcelonę i spokojną ucieczkę od miasta.', en: 'Park Güell is a public park designed by Gaudí, filled with colorful mosaics and unique architectural features. It offers panoramic views of Barcelona and a peaceful escape from the city.', es: 'El Parque Güell es un parque público diseñado por Gaudí, lleno de coloridos mosaicos y características arquitectónicas únicas. Ofrece vistas panorámicas de Barcelona y es un lugar de escape tranquilo de la ciudad.' }

        },

        {

            id: 101,

            country: { pl: 'Egipt', en: 'Egypt', es: 'Egipto' },

            place: { pl: 'Świątynie Abu Simbel', en: 'Abu Simbel Temples', es: ' Templos de Abu Simbel' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Stare Miasto w Tallinnie wygląda jak z bajki – ma średniowieczne uliczki, wieże i kościoły. Znajdziemy tu kolorowe kamienice i przytulne kawiarnie. Zwiedzający mogą przejść się po dawnych murach miejskich.', en: 'These temples were carved into a cliff by Pharaoh Ramses II. The statues at the entrance are massive. Every year, sunlight illuminates the temple\’s inner chamber on special dates.', es: 'Estos templos fueron tallados en un acantilado por el faraón Ramsés II. Las estatuas en la entrada son enormes. Cada año, la luz solar ilumina la cámara interior del templo en fechas especiales.' }

        },

        {

            id: 102,

            country: { pl: 'Słowacja', en: 'Slovakia', es: 'Eslovaquia' },

            place: { pl: 'Zamek Bojnice', en: 'Bojnice Castle', es: ' Castillo de Bojnice' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Bojnice to jeden z najpiękniejszych zamków na Słowacji, otoczony pięknym parkiem. Słynie ze swojego baśniowego wyglądu i średniowiecznej architektury.', en: 'Bojnice Castle is one of the most picturesque castles in Slovakia, surrounded by a beautiful park. It is famous for its fairy-tale appearance and medieval architecture.', es: 'El Castillo de Bojnice es uno de los castillos más pintorescos de Eslovaquia, rodeado de un hermoso parque. Es famoso por su apariencia de cuento de hadas y su arquitectura medieval.' }

        },

        {

            id: 103,

            country: { pl: 'Norwegia', en: 'Norway', es: 'Noruega' },

            place: { pl: 'Zorza polarna (Tromsø)', en: 'Northern Lights (Tromsø)', es: ' Auroras Boreales (Tromsø)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Tromsø to jedno z najlepszych miejsc do obserwacji zorzy polarnej. Zimą niebo rozświetlają zielone i fioletowe barwy. Turyści mogą również pojechać na psie zaprzęgi i cieszyć się kulturą Arktyki.', en: 'Tromsø is one of the best places to see the Northern Lights. The sky lights up in green and purple colors during winter. Visitors can also go dog sledding and enjoy Arctic culture.', es: 'Tromsø es uno de los mejores lugares para ver la aurora boreal. El cielo se ilumina en colores verdes y morados durante el invierno. Los visitantes también pueden hacer paseos en trineo con perros y disfrutar de la cultura ártica.' }

        },

        {

            id: 104,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Waranasi Ghaty', en: 'Varanasi Ghats', es: ' Ghats de Varanasi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ghats to schody prowadzące do świętej rzeki Ganges. Ludzie przychodzą tu na rytuały duchowe i rejsy łodzią. To jedno z najświętszych miejsc w Indiach.', en: 'The ghats are steps leading down to the holy Ganges River. People come here for spiritual rituals and boat rides. It is one of the most sacred places in India.', es: 'Los ghats son escaleras que bajan hasta el río Ganges sagrado. La gente viene aquí para rituales espirituales y paseos en bote. Es uno de los lugares más sagrados de la India.' }

        },

        {

            id: 105,

            country: { pl: 'Holandia', en: 'Netherlands', es: 'Países Bajos' },

            place: { pl: 'Wiatrak w Kinderdijk', en: 'Kinderdijk Windmills', es: ' Molinos de viento de Kinderdijk' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kinderdijk ma 19 tradycyjnych wiatraków, które zostały zbudowane, aby kontrolować powodzie. Obszar ten jest wpisany na listę UNESCO, a odwiedzający mogą spacerować lub jeździć rowerem, aby cieszyć się spokojnym krajobrazem.', en: 'Kinderdijk has 19 traditional windmills that were built to control floods. The area is a UNESCO site, and visitors can walk or cycle around to enjoy the peaceful scenery.', es: 'Kinderdijk tiene 19 molinos de viento tradicionales construidos para controlar las inundaciones. El área es un sitio de la UNESCO, y los visitantes pueden caminar o andar en bicicleta para disfrutar del paisaje tranquilo.' }

        },

        {

            id: 106,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Koloseum (Rzym)', en: 'Colosseum (Rome)', es: ' Coliseo (Roma)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Koloseum to ogromna starożytna arena, w której walczyli gladiatorzy. To jeden z najsłynniejszych zabytków świata. Odwiedzający mogą wejść do środka i wyobrazić sobie jego historię.', en: 'The Colosseum is a huge ancient arena where gladiators once fought. It is one of the most famous landmarks in the world. Visitors can walk inside and imagine its history.', es: 'El Coliseo es una enorme arena antigua donde los gladiadores luchaban. Es uno de los monumentos más famosos del mundo. Los visitantes pueden caminar por dentro e imaginar su historia.' }

        },

        {

            id: 107,

            country: { pl: 'Albania', en: 'Albania', es: 'Albania' },

            place: { pl: 'Zamek Gjirokastër', en: 'Gjirokastër Castle', es: ' Castillo de Gjirokastër' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten duży kamienny zamek znajduje się na wzgórzu z widokiem na Gjirokastër. W zamku mieści się muzeum, kolekcja starych broni i punkt widokowy. Odbywa się tam także festiwal folklorystyczny z tradycyjną albańską muzyką.', en: 'This large stone castle sits on a hill overlooking Gjirokastër. It has a museum, old weapons, and great views. The castle also hosts a folklore festival with traditional Albanian music.', es: 'Este gran castillo de piedra está situado en una colina con vistas a Gjirokastër. Tiene un museo, armas antiguas y unas vistas impresionantes. El castillo también alberga un festival de folclore con música tradicional albanesa.' }

        },

        {

            id: 108,

            country: { pl: 'Szwajcaria', en: 'Switzerland', es: 'Suiza' },

            place: { pl: 'Zamek Chillon (Montreux)', en: 'Château de Chillon (Montreux)', es: ' Château de Chillon (Montreux)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Château de Chillon to średniowieczna forteca położona nad brzegiem Jeziora Genewskiego. Odwiedzający mogą zwiedzać jego komnaty, wieże i dowiedzieć się o jego historii oraz słynnych artystach, którzy się nim inspirowali.', en: 'Château de Chillon is a medieval castle located on the shores of Lake Geneva. Visitors can explore its rooms, towers, and learn about its history and the famous artists who were inspired by it.', es: 'El Château de Chillon es un castillo medieval ubicado a orillas del Lago de Ginebra. Los visitantes pueden explorar sus habitaciones, torres y conocer su historia y los artistas famosos que se inspiraron en él.' }

        },

        {

            id: 109,

            country: { pl: 'Chile', en: 'Chile', es: 'Chile' },

            place: { pl: 'Wyspa Chiloé', en: 'Chiloé Island', es: ' Isla de Chiloé' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Chiloé słynie z kolorowych drewnianych kościołów i domów na palach. Wyspa ma piękne krajobrazy i bogaty folklor. Odwiedzający mogą spróbować świeżych owoców morza i zobaczyć pingwiny na wybrzeżu.', en: 'Chiloé is known for its colorful wooden churches and stilt houses. The island has beautiful landscapes and rich folklore. Visitors can try fresh seafood and see penguins along the coast.', es: 'Chiloé es conocida por sus iglesias de madera de colores y sus casas sobre pilotes. La isla tiene hermosos paisajes y rica tradición folklórica. Los visitantes pueden probar mariscos frescos y ver pingüinos en la costa.' }

        },

        {

            id: 110,

            country: { pl: 'Albania', en: 'Albania', es: 'Albania' },

            place: { pl: 'Berat (Miasto tysiąca okien)', en: 'Berat (City of a Thousand Windows)', es: ' Berat (Ciudad de las Mil Ventanas)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Berat to zabytkowe miasto znane z białych osmańskich domów z wieloma oknami. Ma piękny zamek na wzgórzu i rzekę przepływającą przez środek miasta. Jest wpisane na listę światowego dziedzictwa UNESCO.', en: 'Berat is a historic city known for its white Ottoman houses with many windows. It has a beautiful castle on a hill and a river running through it. The city is a UNESCO World Heritage Site.', es: 'Berat es una ciudad histórica conocida por sus casas otomanas blancas con muchas ventanas. Tiene un hermoso castillo en una colina y un río que la atraviesa. La ciudad es un sitio Patrimonio de la Humanidad de la UNESCO.' }

        },

        {

            id: 111,

            country: { pl: 'Litwa', en: 'Lithuania', es: 'Lituania' },

            place: { pl: 'Góra Krzyży', en: 'Hill of Crosses', es: ' Colina de las Cruces' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To wyjątkowe miejsce ma tysiące krzyży umieszczonych przez odwiedzających na przestrzeni lat. Jest symbolem wiary i wytrwałości. Spacer wśród krzyży to niezapomniane przeżycie.', en: 'This unique site has thousands of crosses placed by visitors over many years. It is a symbol of faith and resilience. Walking among the crosses is a peaceful and unforgettable experience.', es: 'Este sitio único tiene miles de cruces colocadas por los visitantes a lo largo de los años. Es un símbolo de fe y resiliencia. Caminar entre las cruces es una experiencia tranquila e inolvidable.' }

        },

        {

            id: 112,

            country: { pl: 'Holandia', en: 'Netherlands', es: 'Países Bajos' },

            place: { pl: 'Rijksmuseum (Amsterdam)', en: 'Rijksmuseum (Amsterdam)', es: ' Museo Rijksmuseum (Ámsterdam)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Rijksmuseum to najsłynniejsze muzeum w Holandii. Ma holenderskie arcydzieła, w tym prace Rembrandta i Vermeera. Budynek sam w sobie jest również dziełem sztuki.', en: 'The Rijksmuseum is the most famous museum in the Netherlands. It has Dutch masterpieces, including works by Rembrandt and Vermeer. The building itself is also a work of art.', es: 'El Rijksmuseum es el museo más famoso de los Países Bajos. Tiene obras maestras holandesas, incluyendo obras de Rembrandt y Vermeer. El edificio en sí también es una obra de arte.' }

        },

        {

            id: 113,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Stare Miasto w Dubrowniku i mury miejskie', en: 'Dubrovnik Old Town & City Walls', es: ' Casco Antiguo de Dubrovnik y Murallas de la Ciudad' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Dubrownik to piękne nadmorskie miasto z średniowiecznymi murami. Spacer po nich oferuje niesamowite widoki na morze. Miasto pełne jest zabytków i było miejscem kręcenia Gry o Tron.', en: 'Dubrovnik is a beautiful coastal city with medieval walls. Walking along the walls offers amazing sea views. The town is full of historic buildings and was a filming location for Game of Thrones.', es: 'Dubrovnik es una hermosa ciudad costera con murallas medievales. Caminar por las murallas ofrece increíbles vistas del mar. La ciudad está llena de edificios históricos y fue un lugar de rodaje para Juego de Tronos.' }

        },

        {

            id: 114,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Rynek Starego Miasta (Warszawa)', en: 'Old Town Market Square (Warsaw)', es: ' Plaza del Mercado del Casco Antiguo (Varsovia)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten tętniący życiem plac jest sercem warszawskiej Starówki. Ma kolorowe budynki, artystów ulicznych i kawiarnie na świeżym powietrzu. Obszar został odbudowany po II wojnie światowej i jest teraz wpisany na listę UNESCO.', en: 'This lively square is the heart of Warsaw\’s Old Town. It has colorful buildings, street performers, and outdoor cafes. The area was rebuilt after World War II and is now a UNESCO site.', es: 'Esta animada plaza es el corazón del casco antiguo de Varsovia. Tiene edificios coloridos, artistas callejeros y cafés al aire libre. El área fue reconstruida después de la Segunda Guerra Mundial y ahora es un sitio de la UNESCO.' }

        },

        {

            id: 115,

            country: { pl: 'Finlandia', en: 'Finland', es: 'Finlandia' },

            place: { pl: 'Wioska Świętego Mikołaja (Rovaniemi)', en: 'Santa Claus Village (Rovaniemi)', es: ' Pueblo de Papá Noel (Rovaniemi)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten klasztor na wyspie wygląda magicznie, wznosząc się z morza. Podczas przypływu otacza go woda. Średniowieczne uliczki prowadzą na szczyt, do imponującego opactwa.', en: 'This is the official home of Santa Claus in the Arctic Circle. Visitors can meet Santa, see reindeer, and send postcards from Santa\’s special post office.', es: 'Este es el hogar oficial de Santa Claus en el Círculo Polar Ártico. Los visitantes pueden conocer a Santa, ver renos y enviar postales desde la oficina de correos especial de Santa.' }

        },

        {

            id: 116,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Chichen Itza', en: 'Chichen Itza', es: ' Chichén Itzá' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Chichen Itza to jedna z najsłynniejszych ruin Majów. Piramida Kukulkana jest jej najważniejszym punktem. Odwiedzający mogą zwiedzać starożytne świątynie i dowiedzieć się o kalendarzu Majów i astronomii.', en: 'Chichen Itza is one of the most famous Mayan ruins. The pyramid of Kukulkan is the highlight. Visitors can explore ancient temples and learn about the Mayan calendar and astronomy.', es: 'Chichen Itzá es una de las ruinas mayas más famosas. La pirámide de Kukulkán es el punto culminante. Los visitantes pueden explorar templos antiguos y aprender sobre el calendario maya y la astronomía.' }

        },

        {

            id: 117,

            country: { pl: 'Słowacja', en: 'Slovakia', es: 'Eslovaquia' },

            place: { pl: 'Park Narodowy Wysokie Tatry', en: 'High Tatras National Park', es: ' Parque Nacional de los Tatras Altos' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Park Narodowy Wysokie Tatry to piękny łańcuch górski, znany z pieszych wędrówek, narciarstwa i krystalicznie czystych jezior. To raj dla miłośników przyrody, pełen dzikich zwierząt i malowniczych krajobrazów.', en: 'High Tatras National Park is a beautiful mountain range known for hiking, skiing, and crystal-clear lakes. It\’s a nature lover\’s paradise with plenty of wildlife and picturesque scenery.', es: 'El Parque Nacional de los Altos Tatras es una hermosa cadena montañosa conocida por el senderismo, el esquí y los lagos cristalinos. Es el paraíso de los amantes de la naturaleza, con mucha fauna y paisajes pintorescos.' }

        },

        {

            id: 118,

            country: { pl: 'Aruba', en: 'Aruba', es: 'Aruba' },

            place: { pl: 'Oranjestad', en: 'Oranjestad', es: ' Oranjestad' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Oranjestad to kolorowa stolica Aruby. Miasto oferuje budynki w holenderskim stylu, świetne sklepy i piaszczyste plaże w pobliżu. Odwiedzający mogą delektować się lokalnym jedzeniem, targami i relaksującą karaibską atmosferą.', en: 'Oranjestad is the colorful capital of Aruba. The city has Dutch-style buildings, great shopping, and sandy beaches nearby. Visitors can enjoy local food, markets, and a relaxed Caribbean atmosphere.', es: 'Oranjestad es la colorida capital de Aruba. La ciudad tiene edificios de estilo holandés, excelentes tiendas y playas cercanas. Los visitantes pueden disfrutar de comida local, mercados y un ambiente relajado del Caribe.' }

        },

        {

            id: 119,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Carcassonne', en: 'Carcassonne', es: ' Carcasona' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To średniowieczne miasto-forteca ma wysokie mury i kamienne wieże. Wygląda jak coś z bajki. Odwiedzający mogą zwiedzać wąskie uliczki i poznać jego historię.', en: 'This medieval fortress city has high walls and stone towers. It looks like something from a fairytale. Visitors can explore narrow streets and learn about its history.', es: 'Esta ciudad medieval fortificada tiene altas murallas y torres de piedra. Parece sacada de un cuento de hadas. Los visitantes pueden explorar sus calles estrechas y aprender sobre su historia.' }

        },

        {

            id: 120,

            country: { pl: 'Kuwejt', en: 'Kuwait', es: 'Kuwait' },

            place: { pl: 'Wieże Kuwejckie', en: 'Kuwait Towers', es: ' Torres de Kuwait' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wieże Kuwejckie to najbardziej znane budowle w mieście. Mają taras widokowy i restaurację z pięknym widokiem. Nocą wieże efektownie się rozświetlają.', en: 'The Kuwait Towers are the most famous landmarks in the city. They have a viewing deck and a restaurant with great views. The towers light up beautifully at night.', es: 'Las Torres de Kuwait son los monumentos más famosos de la ciudad. Tienen una plataforma de observación y un restaurante con excelentes vistas. Las torres se iluminan hermosamente por la noche.' }

        },

        {

            id: 121,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Park Merliona', en: 'Merlion Park', es: ' Parque Merlion' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Park Merlion to miejsce, w którym znajduje się ikoniczna statua Merliona, symbolizująca Singapur. Odwiedzający mogą podziwiać widok na port i panoramę miasta, robiąc zdjęcia z posągiem przedstawiającym połowę lwa, połowę rybę.', en: 'Merlion Park is home to the iconic Merlion statue, symbolizing Singapore. Visitors can enjoy the view of the harbor and the city skyline while taking photos with the famous half-lion, half-fish statue.', es: 'El Parque Merlion alberga la icónica estatua del Merlion, que simboliza Singapur. Los visitantes pueden disfrutar de la vista del puerto y el horizonte de la ciudad mientras se toman fotos con la famosa estatua mitad león, mitad pez.' }

        },

        {

            id: 122,

            country: { pl: 'Cypr', en: 'Cyprus', es: 'Chipre' },

            place: { pl: 'Petra tou Romiou (Skała Afrodyty)', en: 'Petra tou Romiou (Aphrodite\’s Rock)', es: ' Petra tou Romiou (Roca de Afrodita)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Karlowe Wary to słynne uzdrowisko z gorącymi źródłami mineralnymi. Goście mogą pić lecznicze wody, relaksować się w spa i podziwiać piękne XIX-wieczne budynki.', en: 'This scenic rock on the coast is said to be the birthplace of Aphrodite, the Greek goddess of love. The beach is a great spot for sunset views and taking beautiful photos.', es: 'Esta roca pintoresca en la costa se dice que es el lugar de nacimiento de Afrodita, la diosa griega del amor. La playa es un excelente lugar para ver el atardecer y tomar hermosas fotos.' }

        },

        {

            id: 123,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Hagia Sophia (Stambuł)', en: 'Hagia Sophia (Istanbul)', es: ' Santa Sofía (Estambul)' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Hagia Sophia to ikoniczny meczet i dawna świątynia w Stambule. Ma bogatą historię i imponującą architekturę. Wnętrze wypełniają mozaiki, a kopuła oferuje niesamowite widoki na miasto.', en: 'Hagia Sophia is an iconic mosque and former church in Istanbul. It has a rich history and stunning architecture. The interior is filled with mosaics, and the dome offers amazing views of the city.', es: 'Santa Sofía es una icónica mezquita y antigua iglesia en Estambul. Tiene una rica historia y una arquitectura impresionante. El interior está lleno de mosaicos, y la cúpula ofrece vistas increíbles de la ciudad.' }

        },

        {

            id: 124,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Puszcza Białowieska', en: 'Białowieża Forest', es: ' Bosque de Białowieża' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten starożytny las jest domem dla żubra europejskiego. Jest jednym z ostatnich pierwotnych lasów w Europie. Turyści mogą wędrować po lesie i spotkać rzadkie dzikie zwierzęta.', en: 'This ancient forest is home to the European bison. It is one of the last primeval forests in Europe. Visitors can hike through the woods and spot rare wildlife.', es: 'Este antiguo bosque es hogar del bisonte europeo. Es uno de los últimos bosques primitivos en Europa. Los visitantes pueden hacer senderismo por el bosque y avistar fauna rara.' }

        },

        {

            id: 125,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Lazurowe Wybrzeże (Riwiera Francuska)', en: 'Côte d’Azur (French Riviera)', es: ' Costa Azul (Riviera Francesa)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta wspaniała linia brzegowa ma słoneczne plaże, luksusowe jachty i urokliwe wioski. Odwiedzający mogą cieszyć się Niceą, Monako i Cannes, lub po prostu zrelaksować się nad błękitnym Morzem Śródziemnym.', en: 'This glamorous coastline has sunny beaches, luxury yachts, and charming villages. Visitors can enjoy Nice, Monaco, and Cannes, or just relax by the blue Mediterranean.', es: 'Esta glamurosa costa tiene playas soleadas, yates de lujo y encantadores pueblos. Los visitantes pueden disfrutar de Niza, Mónaco y Cannes, o simplemente relajarse junto al azul Mediterráneo.' }

        },

        {

            id: 126,

            country: { pl: 'Finlandia', en: 'Finland', es: 'Finlandia' },

            place: { pl: 'Twierdza Suomenlinna (Helsinki)', en: 'Suomenlinna Fortress (Helsinki)', es: ' Fortaleza de Suomenlinna (Helsinki)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Luwr to najsłynniejsze muzeum na świecie. Jest domem dla Mony Lisy, starożytnych rzeźb i niezliczonych dzieł sztuki. Szklana piramida przy wejściu stała się nowoczesnym symbolem.', en: 'Suomenlinna is a sea fortress built on six islands. It has tunnels, museums, and stunning sea views. It is a great place for a peaceful walk near Helsinki.', es: 'Suomenlinna es una fortaleza marina construida en seis islas. Tiene túneles, museos y vistas impresionantes al mar. Es un lugar ideal para dar un paseo tranquilo cerca de Helsinki.' }

        },

        {

            id: 127,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Park Narodowy Krka', en: 'Krka National Park', es: ' Parque Nacional Krka' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Plitwickie Jeziora to magiczny park z wodospadami i turkusowymi jeziorami. Drewniane kładki pozwalają odwiedzającym spacerować nad wodą. To idealne miejsce dla miłośników przyrody i fotografii.', en: 'Krka is a stunning park with waterfalls and natural pools. Visitors can take boat tours, walk along scenic trails, and even swim near some waterfalls. It is a peaceful escape into nature.', es: 'Krka es un impresionante parque con cascadas y piscinas naturales. Los visitantes pueden tomar paseos en bote, caminar por senderos pintorescos e incluso nadar cerca de algunas cascadas. Es una escapatoria pacífica a la naturaleza.' }

        },

        {

            id: 128,

            country: { pl: 'Norwegia', en: 'Norway', es: 'Noruega' },

            place: { pl: 'Preikestolen (skała ambonowa)', en: 'Preikestolen (Pulpit Rock)', es: ' Preikestolen (Roca del Púlpito)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta słynna formacja skalna wznosi się wysoko nad fiordem. Wędrówka na szczyt jest wyzwaniem, ale oferuje zapierające dech w piersiach widoki. Jest to jedna z najpopularniejszych atrakcji naturalnych Norwegii.', en: 'This famous rock formation stands high above a fjord. The hike to the top is challenging but offers breathtaking views. It is one of Norway\’s most popular natural attractions.', es: 'Esta famosa formación rocosa se alza sobre un fiordo. La caminata hasta la cima es desafiante, pero ofrece vistas impresionantes. Es una de las atracciones naturales más populares de Noruega.' }

        },

        {

            id: 129,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Stare Miasto Rodos', en: 'Rhodes Old Town', es: ' Casco Antiguo de Rodas' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Stare Miasto na Rodos pełne jest średniowiecznego uroku. Ma kamienne mury, wąskie uliczki i pałac. Zwiedzający mogą odkrywać mieszankę historii greckiej i osmańskiej.', en: 'Rhodes Old Town is full of medieval charm. It has stone walls, narrow streets, and a palace. Visitors can explore its mix of Greek and Ottoman history.', es: 'El casco antiguo de Rodas está lleno de encanto medieval. Tiene murallas de piedra, calles estrechas y un palacio. Los visitantes pueden explorar su mezcla de historia griega y otomana.' }

        },

        {

            id: 130,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Akropol w Atenach', en: 'Acropolis of Athens', es: ' Acrópolis de Atenas' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Akropol to najsłynniejsze starożytne miejsce w Grecji. Wznosi się na wzgórzu z widokiem na Ateny. Jego najbardziej znaną świątynią jest Partenon z wysokimi kolumnami.', en: 'The Acropolis is Greece\’s most famous ancient site. It stands on a hill overlooking Athens. The Parthenon, with its tall columns, is its most famous temple.', es: 'La Acrópolis es el sitio más famoso de la antigua Grecia. Se encuentra en una colina con vistas a Atenas. El Partenón, con sus altas columnas, es su templo más famoso.' }

        },

        {

            id: 131,

            country: { pl: 'Norwegia', en: 'Norway', es: 'Noruega' },

            place: { pl: 'Wyspy Lofoty', en: 'Lofoten Islands', es: ' Islas Lofoten' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Wyspy Lofoty to dramatyczne góry, wioski rybackie i głębokie fiordy. To raj dla fotografów i miłośników przygód. Turyści mogą wędrować, pływać kajakiem lub podziwiać słońce o północy.', en: 'The Lofoten Islands have dramatic mountains, fishing villages, and deep fjords. It is a paradise for photographers and adventure lovers. Visitors can hike, kayak, or see the midnight sun.', es: 'Las Islas Lofoten tienen montañas dramáticas, pueblos pesqueros y fiordos profundos. Es un paraíso para los fotógrafos y los amantes de la aventura. Los visitantes pueden hacer senderismo, kayak o ver el sol de medianoche.' }

        },

        {

            id: 132,

            country: { pl: 'Słowenia', en: 'Slovenia', es: 'Eslovenia' },

            place: { pl: 'Zamek Predjama', en: 'Predjama Castle', es: ' Castillo de Predjama' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Predjama jest wbudowany w zbocze klifu, co czyni go jednym z najbardziej unikalnych zamków w Europie. Otoczony jest przez jaskinie i oferuje wycieczki po średniowiecznych komnatach i ukrytych tunelach.', en: 'Predjama Castle is built into the side of a cliff, making it one of the most unique castles in Europe. It is surrounded by caves and offers tours of its medieval rooms and hidden tunnels.', es: 'El Castillo de Predjama está construido en el costado de un acantilado, lo que lo convierte en uno de los castillos más singulares de Europa. Está rodeado de cuevas y ofrece visitas a sus habitaciones medievales y túneles secretos.' }

        },

        {

            id: 133,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Park Narodowy Yosemite', en: 'Yosemite National Park', es: ' Parque Nacional de Yosemite' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Yosemite to wspaniały park narodowy z wysokimi klifami, wodospadami i gigantycznymi drzewami sekwojowymi. Odwiedzający mogą wędrować, wspinać się na skały lub po prostu podziwiać widoki.', en: 'Yosemite is a stunning national park with towering cliffs, waterfalls, and giant sequoia trees. Visitors can hike, rock climb, or just enjoy the views.', es: 'Yosemite es un impresionante parque nacional con acantilados imponentes, cascadas y gigantescos árboles de secuoya. Los visitantes pueden hacer senderismo, escalar rocas o simplemente disfrutar de las vistas.' }

        },

        {

            id: 134,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Wyspa Hvar', en: 'Hvar Island', es: ' Isla de Hvar' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Hawana to kolorowa stolica Kuby, znana z klasycznych samochodów, kolonialnej architektury i żywej muzyki. Odwiedzający mogą zwiedzać Stare Miasto, spacerować po Malecón i napić się mojito w zabytkowym barze.', en: 'Hvar is a sunny island famous for its lavender fields and nightlife. Visitors can relax on beautiful beaches, explore historic towns, and enjoy fresh seafood by the sea.', es: 'Hvar es una isla soleada famosa por sus campos de lavanda y vida nocturna. Los visitantes pueden relajarse en hermosas playas, explorar pueblos históricos y disfrutar de mariscos frescos junto al mar.' }

        },

        {

            id: 135,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Zamek w Malborku', en: 'Malbork Castle', es: ' Castillo de Malbork' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Malbork to największy zamek z cegły na świecie. Zbudowany przez Krzyżaków, ma imponujące mury i wieże. Turyści mogą zwiedzać jego średniowieczne sale i muzeum.', en: 'Malbork Castle is the largest brick castle in the world. Built by the Teutonic Knights, it has impressive walls and towers. Visitors can explore its medieval halls and museum.', es: 'El Castillo de Malbork es el castillo de ladrillo más grande del mundo. Construido por los Caballeros Teutónicos, tiene impresionantes muros y torres. Los visitantes pueden explorar sus pasillos medievales y su museo.' }

        },

        {

            id: 136,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Kanały Wenecji', en: 'Venice Canals', es: ' Canales de Venecia' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Wenecja słynie z kanałów zamiast ulic. Odwiedzający mogą popłynąć gondolą, zwiedzać piękne mosty i delektować się włoskim jedzeniem nad wodą.', en: 'Venice is famous for its canals instead of streets. Visitors can take a gondola ride, explore beautiful bridges, and enjoy Italian food along the waterways.', es: 'Venecia es famosa por sus canales en lugar de calles. Los visitantes pueden dar un paseo en góndola, explorar hermosos puentes y disfrutar de la comida italiana a lo largo de los canales.' }

        },

        {

            id: 137,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Windsor Zamek', en: 'Windsor Castle', es: ' Castillo de Windsor' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Windsor to najstarszy zamieszkały zamek na świecie. Jest to oficjalna rezydencja brytyjskiej rodziny królewskiej, a turyści mogą zwiedzać piękne ogrody i apartamenty państwowe.', en: 'Windsor Castle is the oldest occupied castle in the world. It is the official residence of the British Royal Family, and visitors can explore the beautiful grounds and State Apartments.', es: 'El Castillo de Windsor es el castillo habitado más antiguo del mundo. Es la residencia oficial de la Familia Real Británica, y los visitantes pueden explorar los hermosos jardines y los Apartamentos del Estado.' }

        },

        {

            id: 138,

            country: { pl: 'Słowacja', en: 'Slovakia', es: 'Eslovaquia' },

            place: { pl: 'Zamek Bratysławski', en: 'Bratislava Castle', es: ' Castillo de Bratislava' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Bratysławski oferuje wspaniałe widoki na miasto i rzekę Dunaj. Ma bogatą historię i mieści muzeum przedstawiające słowackie dziedzictwo kulturowe.', en: 'Bratislava Castle offers stunning views of the city and the Danube River. It has a rich history and houses a museum showcasing Slovakia\’s cultural heritage.', es: 'El Castillo de Bratislava ofrece vistas impresionantes de la ciudad y el río Danubio. Tiene una rica historia y alberga un museo que muestra el patrimonio cultural de Eslovaquia.' }

        },

        {

            id: 139,

            country: { pl: 'Austria', en: 'Austria', es: 'Austria' },

            place: { pl: 'Pałac Schönbrunn (Wiedeń)', en: 'Schönbrunn Palace (Vienna)', es: ' Palacio de Schönbrunn (Viena)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Pałac Schönbrunn to oszałamiający pałac królewski w Wiedniu. Ma piękne ogrody, wielkie sale i fascynującą historię. Odwiedzający mogą zwiedzać pałac i podziwiać niesamowite widoki.', en: 'Schönbrunn Palace is a stunning royal palace in Vienna. It has beautiful gardens, grand rooms, and a fascinating history. Visitors can tour the palace and enjoy the amazing views.', es: 'El Palacio de Schönbrunn es un impresionante palacio real en Viena. Tiene hermosos jardines, grandes habitaciones y una fascinante historia. Los visitantes pueden hacer un recorrido por el palacio y disfrutar de las vistas sorprendentes.' }

        },

        {

            id: 140,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Park historyczny Ayutthaya', en: 'Ayutthaya Historical Park', es: ' Parque Histórico de Ayutthaya' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ayutthaya to starożytne miasto z imponującymi ruinami świątyń. Park jest wpisany na Listę Światowego Dziedzictwa UNESCO. Odwiedzający mogą zwiedzać świątynie, posągi i stary pałac królewski.', en: 'Ayutthaya is an ancient city with impressive temple ruins. The park is a UNESCO World Heritage Site. Visitors can explore the temples, statues, and the old royal palace.', es: 'Ayutthaya es una ciudad antigua con impresionantes ruinas de templos. El parque es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO. Los visitantes pueden explorar los templos, estatuas y el antiguo palacio real.' }

        },

        {

            id: 141,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Góry Camerona', en: 'Cameron Highlands', es: ' Highlands de Cameron' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta górska miejscowość słynie z plantacji herbaty i chłodnego klimatu. Zwiedzający mogą odwiedzać farmy herbaciane, spacerować po ogrodach kwiatowych i delektować się świeżymi truskawkami. To spokojna ucieczka od zgiełku miasta.', en: 'This hill station is known for its tea plantations and cool weather. Visitors can tour tea farms, walk through flower gardens, and enjoy fresh strawberries. It is a peaceful escape from the city.', es: 'Esta estación de montaña es conocida por sus plantaciones de té y su clima fresco. Los visitantes pueden recorrer las granjas de té, caminar por jardines de flores y disfrutar de fresas. Es un escape tranquilo de la ciudad.' }

        },

        {

            id: 142,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Park Narodowy Ranthambore', en: 'Ranthambore National Park', es: ' Parque Nacional Ranthambore' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'To jedno z najlepszych miejsc do obserwacji dzikich tygrysów bengalskich. Park ma także jeziora, stare ruiny i wiele innych zwierząt, takich jak lamparty i jelenie.', en: 'This is one of the best places to see wild Bengal tigers. The park also has lakes, old ruins, and many other animals like leopards and deer.', es: 'Este es uno de los mejores lugares para ver tigres bengalíes salvajes. El parque también tiene lagos, ruinas antiguas y muchos otros animales como leopardos y ciervos.' }

        },

        {

            id: 143,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Wiatraki w Mykonos', en: 'Mykonos Windmills', es: ' Molinos de Mykonos' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te białe wiatraki to symbol Mykonos. W przeszłości służyły do mielenia pszenicy. Dziś są jedną z najbardziej charakterystycznych atrakcji wyspy.', en: 'These white windmills are a famous sight in Mykonos. They were used for grinding wheat in the past. Today, they are a symbol of the island\’s charm.', es: 'Estos molinos de viento blancos son un famoso símbolo de Mykonos. Se usaban para moler trigo en el pasado. Hoy en día, son un símbolo del encanto de la isla.' }

        },

        {

            id: 144,

            country: { pl: 'Szwecja', en: 'Sweden', es: 'Suecia' },

            place: { pl: 'Gamla Stan (Stare Miasto w Sztokholmie)', en: 'Gamla Stan (Stockholm Old Town)', es: ' Gamla Stan (Ciudad Vieja de Estocolmo)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Gamla Stan to historyczne centrum Sztokholmu. Znajdują się tam brukowane uliczki, kolorowe budynki i atrakcje, takie jak Pałac Królewski i Katedra Storkyrkan.', en: 'Gamla Stan is the historic heart of Stockholm. It has cobbled streets, colorful buildings, and attractions like the Royal Palace and Storkyrkan Cathedral.', es: 'Gamla Stan es el corazón histórico de Estocolmo. Tiene calles empedradas, edificios coloridos y atracciones como el Palacio Real y la Catedral de Storkyrkan.' }

        },

        {

            id: 145,

            country: { pl: 'Cypr', en: 'Cyprus', es: 'Chipre' },

            place: { pl: 'Port w Kyrenii', en: 'Kyrenia Harbour', es: ' Puerto de Kyrenia' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta mała kaplica jest ozdobiona tysiącami ludzkich kości. Żyrandole, krzyże i ściany są wykonane z czaszek i kości. To jedno z najbardziej niezwykłych miejsc sakralnych na świecie.', en: 'Kyrenia Harbour is a picturesque marina with old stone buildings and fishing boats. It is a great place to enjoy fresh seafood, walk along the waterfront, and explore the historic castle.', es: 'El Puerto de Kyrenia es una pintoresca marina con antiguos edificios de piedra y barcos pesqueros. Es un excelente lugar para disfrutar de mariscos frescos, caminar por la costa y explorar el castillo histórico.' }

        },

        {

            id: 146,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Mont Saint-Michel (Normandia)', en: 'Mont Saint-Michel (Normandy)', es: ' Monte Saint-Michel (Normandía)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To wyspiarskie klasztor wygląda magicznie, wznosząc się z morza. Podczas wysokiej wody jest otoczone wodą. Średniowieczne uliczki prowadzą do wielkiego opactwa na szczycie.', en: 'This island monastery looks magical, rising from the sea. During high tide, it is surrounded by water. The medieval streets lead to the grand abbey at the top.', es: 'Este monasterio isleño parece mágico, emergiendo del mar. Durante la marea alta, está rodeado de agua. Las calles medievales conducen a la gran abadía en la cima.' }

        },

        {

            id: 147,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Gdańska', en: 'Gdańsk', es: ' Gdańsk' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: {
                pl: 'Gdańsk to zabytkowe miasto nad Bałtykiem, znane z pięknej architektury i bogatego dziedzictwa morskiego. Ma malowniczą Starówkę z kolorowymi budynkami, słynna fontanną Neptuna i imponującym Kościołem Mariackim.', en: 'Gdańsk is a historic city on the Baltic coast, known for its beautiful architecture and rich maritime heritage. It has a picturesque Old Town with colorful buildings, the famous Neptune Fountain, and the impressive St. Mary\'s Church.', es: 'Gdańsk es una ciudad histórica en la costa báltica, conocida por su hermosa arquitectura y rica herencia marítima.Tiene un pintoresco casco antiguo con edificios coloridos, la famosa Fuente de Neptuno y la impresionante Iglesia de Santa María.'
            }

        },

        {

            id: 148,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Park Narodowy Kakadu', en: 'Kakadu National Park', es: ' Parque Nacional Kakadu' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Kakadu to park narodowy wpisany na listę UNESCO, z mokradłami, wodospadami i starożytną sztuką naskalną. Jest domem dla krokodyli i wielu gatunków ptaków. Zwiedzający mogą poznawać kulturę Aborygenów i podziwiać oszałamiające krajobrazy.', en: 'Kakadu is a UNESCO-listed park with wetlands, waterfalls, and ancient rock art. It is home to crocodiles and many bird species. Visitors can explore Aboriginal culture and stunning landscapes.', es: 'Kakadu es un parque listado por la UNESCO con humedales, cascadas y arte rupestre antiguo. Es hogar de cocodrilos y muchas especies de aves. Los visitantes pueden explorar la cultura aborigen y paisajes impresionantes.' }

        },

        {

            id: 149,

            country: { pl: 'Grecja', en: 'Greece', es: 'Grecia' },

            place: { pl: 'Ruiny Delf', en: 'Delphi Ruins', es: ' Ruinas de Delfos' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Delfy były niegdyś domem słynnej wyroczni. Ruiny obejmują świątynie, teatr i stadion. Miejsce otaczają malownicze góry.', en: 'Delphi was once home to the famous oracle. The ruins include temples, a theater, and a stadium. The site is surrounded by beautiful mountains.', es: 'Delphi fue una vez hogar del famoso oráculo. Las ruinas incluyen templos, un teatro y un estadio. El sitio está rodeado por hermosas montañas.' }

        },

        {

            id: 150,

            country: { pl: 'Gibraltar', en: 'Gibraltar', es: 'Gibraltar' },

            place: { pl: 'Skała Gibraltarska', en: 'Rock of Gibraltar', es: ' Peñón de Gibraltar' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta gigantyczna wapienna skała jest domem dla dzikich małp i ukrytych tuneli. Zwiedzający mogą wjechać kolejką linową na szczyt, by podziwiać niesamowite widoki na morze i Afrykę.', en: 'This giant limestone rock is home to wild monkeys and secret tunnels. Visitors can take a cable car to the top for amazing views of the sea and Africa.', es: 'Este enorme peñasco de piedra caliza es hogar de monos salvajes y túneles secretos. Los visitantes pueden tomar un teleférico hasta la cima para disfrutar de vistas increíbles del mar y África.' }

        },

        {

            id: 151,

            country: { pl: 'Bułgaria', en: 'Bulgaria', es: 'Bulgaria' },

            place: { pl: 'Stare Miasto w Płowdiwie', en: 'Plovdiv Old Town', es: ' Casco Antiguo de Plovdiv' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Płowdiw to jedno z najstarszych miast Europy. Stare Miasto ma brukowane uliczki, rzymskie ruiny i kolorowe domy. Zwiedzający mogą odkrywać muzea i cieszyć się żywą sceną artystyczną.', en: 'Plovdiv is one of Europe\’s oldest cities. The Old Town has cobbled streets, Roman ruins, and colorful houses. Visitors can explore museums and enjoy the lively art scene.', es: 'Plovdiv es una de las ciudades más antiguas de Europa. El casco antiguo tiene calles empedradas, ruinas romanas y casas coloridas. Los visitantes pueden explorar museos y disfrutar de la animada escena artística.' }

        },

        {

            id: 152,

            country: { pl: 'Liban', en: 'Lebanon', es: 'Líbano' },

            place: { pl: 'Grota Jeita', en: 'Jeita Grotto', es: ' Gruta de Jeita' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Jeita Grotto to zachwycający system jaskiń z krystalicznie czystą wodą. Zwiedzający mogą popłynąć łodzią wewnątrz jaskini. Formacje skalne wyglądają jak z innego świata.', en: 'Jeita Grotto is a stunning cave system with crystal-clear water. Visitors can take boat rides inside the cave. The rock formations look like something from another world.', es: 'La Gruta de Jeita es un impresionante sistema de cuevas con agua cristalina. Los visitantes pueden dar paseos en bote dentro de la cueva. Las formaciones rocosas parecen algo de otro mundo.' }

        },

        {

            id: 153,

            country: { pl: 'Słowacja', en: 'Slovakia', es: 'Eslovaquia' },

            place: { pl: 'Zamek Devin', en: 'Devin Castle', es: ' Castillo de Devin' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Devin wznosi się na skalistym wzgórzu nad rzeką Dunaj. Ma bogatą historię i jest popularnym miejscem do pieszych wędrówek oraz zwiedzania ruin, oferując wspaniałe widoki na okolicę.', en: 'Devin Castle sits on a rocky hill above the Danube River. It has a rich history and is a popular spot for hiking and exploring its ruins, offering fantastic views of the surrounding area.', es: 'El Castillo de Devin se encuentra en una colina rocosa sobre el río Danubio. Tiene una rica historia y es un lugar popular para hacer senderismo y explorar sus ruinas, ofreciendo vistas fantásticas de la zona circundante.' }

        },

        {

            id: 154,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Autostrada Transfagaraska', en: 'Transfagarasan Highway', es: ' Carretera Transfăgărășan' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta górska droga to jedna z najpiękniejszych tras widokowych na świecie. Wiąże się przez Karpaty, pełna ostrych zakrętów i tuneli. Widoki zapierają dech w piersiach.', en: 'This mountain road is one of the most scenic drives in the world. It winds through the Carpathian Mountains with sharp curves and tunnels. The views are breathtaking.', es: 'Esta carretera de montaña es una de las rutas más panorámicas del mundo. Se serpentea a través de los Montes Cárpatos con curvas cerradas y túneles. Las vistas son impresionantes.' }

        },

        {

            id: 155,

            country: { pl: 'Finlandia', en: 'Finland', es: 'Finlandia' },

            place: { pl: 'Jezioro Saimaa', en: 'Lake Saimaa', es: ' Lago Saimaa' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Bora Bora to rajska wyspa z turkusowymi wodami i domkami na wodzie. Jest idealna do nurkowania, snorkelingu i relaksu. Wyspa słynie z zachwycającej laguny.', en: 'Finland\’s largest lake is surrounded by forests and charming villages. Visitors can go boating, fishing, or spot the rare Saimaa ringed seal.', es: 'El lago más grande de Finlandia está rodeado de bosques y encantadores pueblos. Los visitantes pueden hacer paseos en bote, pescar o ver la rara foca anillada de Saimaa.' }

        },

        {

            id: 156,

            country: { pl: 'Estonia', en: 'Estonia', es: 'Estonia' },

            place: { pl: 'Katedra Aleksandra Newskiego (Tallin)', en: 'Alexander Nevsky Cathedral (Tallinn)', es: ' Catedral de Alejandro Nevski (Tallin)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten wspaniały pałac był domem francuskich królów. Posiada złote sale, niekończące się ogrody i słynną Salę Lustrzaną. Zwiedzający mogą wyobrazić sobie luksus królewskiego życia.', en: 'This grand Russian Orthodox cathedral stands on a hill in Tallinn. It has beautiful onion domes and rich interior decorations. The cathedral is an important landmark in the city.', es: 'Esta gran catedral ortodoxa rusa se encuentra en una colina en Tallin. Tiene hermosas cúpulas en forma de cebolla y decoraciones interiores ricas. La catedral es un importante hito en la ciudad.' }

        },

        {

            id: 157,

            country: { pl: 'Chorwacja', en: 'Croatia', es: 'Croacia' },

            place: { pl: 'Park Narodowy Jezior Plitwickich', en: 'Plitvice Lakes National Park', es: ' Parque Nacional Lagos de Plitvice' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To jest miejsce z listy UNESCO, gdzie starożytne grobowce zostały wykute w skale. Mimo nazwy, nie pochowano tu żadnych królów – groby należały do ważnych arystokratów. To miejsce pełne historii i tajemnic.', en: 'Plitvice Lakes is a magical park with waterfalls and turquoise lakes. Wooden pathways let visitors walk above the water. It is a perfect place for nature lovers and photographers.', es: 'Los Lagos de Plitvice son un parque mágico con cascadas y lagos turquesa. Los senderos de madera permiten a los visitantes caminar sobre el agua. Es un lugar perfecto para los amantes de la naturaleza y los fotógrafos.' }

        },

        {

            id: 158,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Dwunastu Apostołów', en: 'Twelve Apostles', es: ' Doce Apóstoles' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'To wysokie wapienne formacje skalne wzdłuż Great Ocean Road. Ocean przez wieki kształtował je w unikalne kolumny. Najlepszy moment na wizytę to zachód słońca, gdy skały przybierają złocisty odcień.', en: 'These are tall limestone rock formations along the Great Ocean Road. Over time, the ocean has shaped them into unique pillars. The best time to visit is at sunset when they glow golden.', es: 'Estas son altas formaciones rocosas de piedra caliza a lo largo de la Great Ocean Road. Con el tiempo, el océano las ha moldeado en pilares únicos. El mejor momento para visitarlas es al atardecer, cuando brillan de color dorado.' }

        },

        {

            id: 159,

            country: { pl: 'Węgry', en: 'Hungary', es: 'Hungría' },

            place: { pl: 'Bastion Rybacki (Budapeszt)', en: 'Fisherman\’s Bastion (Budapest)', es: ' Bastión de los Pescadores (Budapest)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta bajkowa tarasowa konstrukcja oferuje niesamowite widoki na miasto. Ma wieżyczki, łuki i białe kamienne mury. To świetne miejsce na zdjęcia, zwłaszcza o wschodzie lub zachodzie słońca.', en: 'This fairy-tale-like terrace offers amazing views of the city. It has towers, arches, and white stone walls. It\’s a great place for photos, especially at sunrise or sunset.', es: 'Esta terraza de cuento de hadas ofrece vistas increíbles de la ciudad. Tiene torres, arcos y paredes de piedra blanca. Es un excelente lugar para tomar fotos, especialmente al amanecer o al atardecer.' }

        },

        {

            id: 160,

            country: { pl: 'Bahrajn', en: 'Bahrain', es: 'Baréin' },

            place: { pl: 'Meczet Al Fateh', en: 'Al Fateh Mosque', es: ' Mezquita Al Fateh' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'To jeden z największych meczetów na świecie. Ma oszałamiającą kopułę wykonaną z włókna szklanego i piękną arabską kaligrafię. Odwiedzający mogą skorzystać z wycieczek z przewodnikiem, aby poznać kulturę islamską.', en: 'This is one of the largest mosques in the world. It has a stunning dome made of fiberglass and beautiful Arabic calligraphy. Visitors can take guided tours to learn about Islamic culture.', es: 'Esta es una de las mezquitas más grandes del mundo. Tiene una impresionante cúpula de fibra de vidrio y hermosas caligrafías árabes. Los visitantes pueden hacer recorridos guiados para aprender sobre la cultura islámica.' }

        },

        {

            id: 161,

            country: { pl: 'Niemcy', en: 'Germany', es: 'Alemania' },

            place: { pl: 'Katedra w Kolonii', en: 'Cologne Cathedral', es: ' Catedral de Colonia' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta wysoka gotycka katedra jest jednym z najbardziej imponujących budynków Niemiec. Ma piękne witraże. Wspinaczka na wieżę oferuje wspaniałe widoki na miasto.', en: 'This towering Gothic cathedral is one of Germany\’s most impressive buildings. It has beautiful stained-glass windows. Climbing the tower offers great city views.', es: 'Esta imponente catedral gótica es uno de los edificios más impresionantes de Alemania. Tiene hermosos vitrales. Subir a la torre ofrece vistas increíbles de la ciudad.' }

        },

        {

            id: 162,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Rezerwat Biosfery Sian Ka’an', en: 'Sian Ka’an Biosphere Reserve', es: ' Reserva de la Biosfera Sian Ka’an' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ten chroniony obszar ma plaże, laguny i dziką przyrodę dżungli. Odwiedzający mogą wziąć udział w wycieczkach łodzią, aby zobaczyć krokodyle, delfiny i tropikalne ptaki.', en: 'This protected area has beaches, lagoons, and jungle wildlife. Visitors can take boat tours to see crocodiles, dolphins, and tropical birds.', es: 'Esta área protegida tiene playas, lagunas y vida silvestre en la jungla. Los visitantes pueden hacer paseos en bote para ver cocodrilos, delfines y aves tropicales.' }

        },

        {

            id: 163,

            country: { pl: 'Andora', en: 'Andorra', es: 'Andorra' },

            place: { pl: 'Caldea Spa (największe uzdrowisko termalne w Europie)', en: 'Caldea Spa (Europe\’s Largest Thermal Spa)', es: ' Spa Caldea (El Spa Termal Más Grande de Europa)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Caldea to ogromny kompleks spa z ciepłymi wodami bogatymi w minerały. Goście mogą korzystać z gorących basenów, saun i wodospadów. To doskonałe miejsce na relaks po nartach lub pieszych wędrówkach w górach.', en: 'Caldea is a huge spa complex with warm mineral-rich waters. Visitors can enjoy hot pools, saunas, and waterfalls. It is a great place to relax after skiing or hiking in the mountains.', es: 'Caldea es un enorme complejo de spa con aguas termales ricas en minerales. Los visitantes pueden disfrutar de piscinas calientes, saunas y cascadas. Es un excelente lugar para relajarse después de esquiar o hacer senderismo en las montañas.' }

        },

        {

            id: 164,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Malowane klasztory Bukowiny', en: 'Painted Monasteries of Bucovina', es: ' Monasterios Pintados de Bucovina' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te klasztory mają kolorowe freski na swoich ścianach. Obrazy opowiadają religijne historie i mają setki lat. Odwiedzający mogą podziwiać sztukę i spokojne otoczenie.', en: 'These monasteries have colorful frescoes on their walls. The paintings tell religious stories and are hundreds of years old. Visitors can admire the art and peaceful surroundings.', es: 'Estos monasterios tienen frescos coloridos en sus paredes. Las pinturas cuentan historias religiosas y tienen cientos de años. Los visitantes pueden admirar el arte y el entorno tranquilo.' }

        },

        {

            id: 165,

            country: { pl: 'Belgia', en: 'Belgium', es: 'Bélgica' },

            place: { pl: 'Atomium (Bruksela)', en: 'Atomium (Brussels)', es: ' Atomium (Bruselas)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Atomium to futurystyczny budynek w kształcie atomu. Został zbudowany na Expo 1958. Zwiedzający mogą wejść do środka, by podziwiać niesamowite widoki na miasto i ciekawe wystawy.', en: 'The Atomium is a futuristic building shaped like an atom. It was built for the 1958 World Expo. Visitors can go inside for amazing city views and interesting exhibitions.', es: 'El Atomium es un edificio futurista con forma de átomo. Fue construido para la Exposición Mundial de 1958. Los visitantes pueden entrar para disfrutar de vistas increíbles de la ciudad y exposiciones interesantes.' }

        },

        {

            id: 166,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Jezioro Zachodnie (Hangzhou)', en: 'West Lake (Hangzhou)', es: ' Lago del Oeste (Hangzhou)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Jezioro Zachodnie to malownicze jezioro otoczone ogrodami i świątyniami. Można tam wybrać się na spokojny rejs łodzią i spacerować pięknymi ścieżkami. To jedno z najbardziej romantycznych miejsc w Chinach.', en: 'West Lake is a scenic lake surrounded by gardens and temples. It has peaceful boat rides and beautiful walking paths. It is one of China\’s most romantic destinations.', es: 'El Lago del Oeste es un lago pintoresco rodeado de jardines y templos. Tiene tranquilos paseos en bote y hermosos senderos. Es uno de los destinos más románticos de China.' }

        },

        {

            id: 167,

            country: { pl: 'Holandia', en: 'Netherlands', es: 'Países Bajos' },

            place: { pl: 'Kanały Amsterdamu', en: 'Amsterdam Canals', es: ' Canales de Ámsterdam' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kanały Amsterdamu są jak żyły miasta. Turyści mogą wybrać się na rejs po kanałach, spacerować po urokliwych mostach lub usiąść w kawiarniach nad kanałem. Kanały nadają miastu romantyczny i spokojny charakter.', en: 'Amsterdam\’s canals are like the veins of the city. Visitors can take boat tours, walk over charming bridges, or sit at canal-side cafes. The canals give the city a romantic and peaceful feel.', es: 'Los canales de Ámsterdam son como las venas de la ciudad. Los visitantes pueden hacer paseos en barco, caminar sobre encantadores puentes o sentarse en los cafés junto al canal. Los canales le dan a la ciudad una sensación romántica y tranquila.' }

        },

        {

            id: 168,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Wyspy Owcze', en: 'Faroe Islands', es: ' Islas Feroe' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Punta Cana słynie z białych, piaszczystych plaż i luksusowych resortów all-inclusive. Turyści mogą relaksować się nad morzem, uprawiać sporty wodne lub wybrać się na rejs katamaranem na pobliskie wyspy.', en: 'The Faroe Islands are a hidden gem with dramatic cliffs, green hills, and charming villages. Visitors can hike, see puffins, and experience a unique blend of Scandinavian and Viking culture.', es: 'Las Islas Feroe son una joya escondida con acantilados dramáticos, colinas verdes y encantadores pueblos. Los visitantes pueden hacer senderismo, ver frailecillos y experimentar una mezcla única de cultura escandinava y vikinga.' }

        },

        {

            id: 169,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Nyhavn (Kopenhaga)', en: 'Nyhavn (Copenhagen)', es: ' Nyhavn (Copenhague)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Santo Domingo to najstarsze europejskie miasto w Ameryce. Jego strefa kolonialna ma piękne stare budynki, tętniące życiem place i zabytkowe forty. Pełne kultury i historii.', en: 'Nyhavn is a colorful harbor with old wooden boats and lively cafes. It is a great place to take a boat tour, enjoy Danish food, or simply walk along the water.', es: 'Nyhavn es un puerto colorido con viejos botes de madera y cafés animados. Es un excelente lugar para tomar un tour en bote, disfrutar de la comida danesa o simplemente caminar a lo largo del agua.' }

        },

        {

            id: 170,

            country: { pl: 'Bahamy', en: 'Bahamas', es: 'Bahamas' },

            place: { pl: 'Paradise Island', en: 'Paradise Island', es: ' Isla Paraíso' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta wyspa w pobliżu Nassau to miejsce luksusowych kurortów i białych, piaszczystych plaż. Słynny kurort Atlantis ma park wodny, akwarium i ekscytujące atrakcje.', en: 'This island near Nassau is home to luxurious resorts and white sandy beaches. The famous Atlantis resort has a water park, an aquarium, and exciting entertainment.', es: 'Esta isla cerca de Nassau alberga resorts lujosos y playas de arena blanca. El famoso resort Atlantis tiene un parque acuático, un acuario y entretenimiento emocionante.' }

        },

        {

            id: 171,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Tower of London', en: 'Tower of London', es: ' Torre de Londres' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: {
                pl: 'Tower of London to zabytkowa twierdza i dawna królewska rezydencja. Można zobaczyć Klejnoty Koronne, spotkać Beefeaterów i poznać fascynującą historię wieży.', en: 'The Tower of London is a historic fortress and former royal palace. You can see the Crown Jewels, meet the Beefeaters, and explore the tower\'s fascinating history.', es: 'La Torre de Londres es una fortaleza histórica y antiguo palacio real.Puedes ver las Joyas de la Corona, conocer a los Beefeaters y explorar la fascinante historia de la torre.'
            }

        },

        {

            id: 172,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Las bambusowy Arashiyama (Kioto)', en: 'Arashiyama Bamboo Forest (Kyoto)', es: ' Bosque de Bambú de Arashiyama (Kioto)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten magiczny las pełen jest wysokiego, zielonego bambusa. Spacer po nim sprawia wrażenie wejścia do innego świata. Szum wiatru wśród bambusa działa kojąco.', en: 'This magical forest is filled with tall green bamboo. Walking through it feels like entering another world. The sound of the wind through the bamboo is peaceful.', es: 'Este bosque mágico está lleno de bambú verde y alto. Caminar por él es como entrar en otro mundo. El sonido del viento a través del bambú es tranquilo.' }

        },

        {

            id: 173,

            country: { pl: 'Włochy', en: 'Italy', es: 'Italia' },

            place: { pl: 'Sycylia', en: 'Sicily', es: ' Sicilia' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Sycylia to największa wyspa na Morzu Śródziemnym. Ma starożytne ruiny, plaże i wulkan Etna. Odwiedzający mogą spróbować lokalnych przysmaków, takich jak cannoli i arancini.', en: 'Sicily is the largest island in the Mediterranean. It has ancient ruins, beaches, and Mount Etna, an active volcano. Visitors can enjoy local food like cannoli and arancini.', es: 'Sicilia es la isla más grande del Mediterráneo. Tiene ruinas antiguas, playas y el monte Etna, un volcán activo. Los visitantes pueden disfrutar de la comida local como cannoli y arancini.' }

        },

        {

            id: 174,

            country: { pl: 'Serbia', en: 'Serbia', es: 'Serbia' },

            place: { pl: 'Park Narodowy Tara', en: 'Tara National Park', es: ' Parque Nacional Tara' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Park Narodowy Tara to piękny obszar z lasami, rzekami i dziką przyrodą. Mieszkają tam niedźwiedzie brunatne, a szlaki turystyczne oferują wspaniałe widoki. Odwiedzający mogą cieszyć się naturą i odpoczywać na świeżym powietrzu.', en: 'Tara National Park is a beautiful area with forests, rivers, and wildlife. It is home to brown bears and has great hiking trails. Visitors can enjoy nature and relax in the fresh air.', es: 'El Parque Nacional Tara es una hermosa área con bosques, ríos y vida silvestre. Es hogar de osos pardos y tiene excelentes senderos para hacer caminatas. Los visitantes pueden disfrutar de la naturaleza y relajarse al aire libre.' }

        },

        {

            id: 175,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Big Sur', en: 'Big Sur', es: ' Big Sur' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Big Sur to dziki odcinek wybrzeża w Kalifornii. Odwiedzający mogą cieszyć się malowniczymi przejażdżkami, zwiedzać plaże i podziwiać spektakularne klify oraz widoki na ocean.', en: 'Big Sur is a rugged stretch of coastline in California. Visitors can enjoy scenic drives, visit beaches, and take in the stunning cliffs and ocean views.', es: 'Big Sur es una franja de costa agreste en California. Los visitantes pueden disfrutar de rutas panorámicas, visitar playas y admirar los impresionantes acantilados y vistas al océano.' }

        },

        {

            id: 176,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Klasztor Sumela', en: 'Sumela Monastery', es: ' Monasterio de Sumela' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Monastyr Sumela to wspaniałe miejsce wbudowane w klify regionu Morza Czarnego. Oferuje zapierające dech w piersiach widoki i jest domem dla starożytnych fresków oraz religijnych artefaktów.', en: 'Sumela Monastery is a stunning place built into the cliffs of the Black Sea region. It offers breathtaking views and is home to ancient frescoes and religious artifacts.', es: 'El Monasterio de Sumela es un lugar impresionante construido en los acantilados de la región del Mar Negro. Ofrece vistas impresionantes y alberga antiguos frescos y artefactos religiosos.' }

        },

        {

            id: 177,

            country: { pl: 'Wielka Brytania', en: 'United Kingdom', es: 'Reino Unido' },

            place: { pl: 'Stonehenge', en: 'Stonehenge', es: ' Stonehenge' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Stonehenge to starożytny krąg kamienny otoczony tajemnicą. Jest to jedno z najsłynniejszych prehistorycznych miejsc na świecie, gdzie turyści mogą poznać jego historię i znaczenie.', en: 'Stonehenge is an ancient stone circle surrounded by mystery. It is one of the world\’s most famous prehistoric sites, and visitors can learn about its history and significance.', es: 'Stonehenge es un antiguo círculo de piedras rodeado de misterio. Es uno de los sitios prehistóricos más famosos del mundo, y los visitantes pueden aprender sobre su historia y significado.' }

        },

        {

            id: 178,

            country: { pl: 'Irlandia', en: 'Ireland', es: 'Irlanda' },

            place: { pl: 'Klify Moheru', en: 'Cliffs of Moher', es: ' Acantilados de Moher' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te wysokie klify dramatycznie wznoszą się nad oceanem. Odwiedzający mogą spacerować po ich krawędzi i obserwować ptaki morskie. W pogodny dzień widoki rozciągają się daleko nad Atlantykiem.', en: 'These tall cliffs rise dramatically from the ocean. Visitors can walk along the edge and see seabirds. On clear days, the views stretch far over the Atlantic.', es: 'Estos altos acantilados se elevan dramáticamente desde el océano. Los visitantes pueden caminar por el borde y ver aves marinas. En los días claros, las vistas se extienden hasta el Atlántico.' }

        },

        {

            id: 179,

            country: { pl: 'Łotwa', en: 'Latvia', es: 'Letonia' },

            place: { pl: 'Stare Miasto w Rydze', en: 'Riga Old Town', es: ' Casco Antiguo de Riga' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Stare Miasto w Rydze pełne jest kolorowych budynków, kościołów i brukowanych uliczek. Dom Bractwa Czarnogłowych to obowiązkowy punkt zwiedzania. Okolica jest idealna na spacer i odkrywanie historii.', en: 'Riga\’s Old Town is full of colorful buildings, churches, and cobbled streets. The House of the Blackheads is a must-see. The area is great for walking and enjoying history.', es: 'El casco antiguo de Riga está lleno de edificios coloridos, iglesias y calles empedradas. La Casa de los Cabezas Negras es una visita obligada. La zona es ideal para caminar y disfrutar de la historia.' }

        },

        {

            id: 180,

            country: { pl: 'Albania', en: 'Albania', es: 'Albania' },

            place: { pl: 'Blue Eye (Syri i Kaltër)', en: 'Blue Eye (Syri i Kaltër)', es: ' Ojo Azul (Syri i Kaltër)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Błękitne Oko to głębokie naturalne źródło o krystalicznie czystej, niebieskiej wodzie. Woda jest tak przejrzysta, że można zobaczyć dno. Źródło otoczone jest zielonym lasem, co czyni je idealnym miejscem dla miłośników przyrody.', en: 'The Blue Eye is a deep natural spring with clear blue water. The water is so pure that you can see straight to the bottom. It is surrounded by green forests, making it a perfect place for nature lovers.', es: 'El Ojo Azul es un manantial natural profundo con agua azul clara. El agua es tan pura que se puede ver hasta el fondo. Está rodeado de bosques verdes, lo que lo convierte en un lugar perfecto para los amantes de la naturaleza.' }

        },

        {

            id: 181,

            country: { pl: 'Rumunia', en: 'Romania', es: 'Rumanía' },

            place: { pl: 'Zamek Pelesa', en: 'Peles Castle', es: ' Castillo de Peleș' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Zamek Peles to jeden z najpiękniejszych zamków w Europie. Wygląda jak coś z baśni. W środku ma eleganckie pokoje z obrazami, rzeźbami w drewnie i żyrandolami.', en: 'Peles Castle is one of the most beautiful castles in Europe. It looks like something from a fairy tale. Inside, it has elegant rooms with paintings, wood carvings, and chandeliers.', es: 'El Castillo de Peleș es uno de los castillos más hermosos de Europa. Parece algo sacado de un cuento de hadas. Por dentro, tiene elegantes habitaciones con pinturas, tallas de madera y candelabros.' }

        },

        {

            id: 182,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'La Rambla (Barcelona)', en: 'La Rambla (Barcelona)', es: ' La Rambla (Barcelona)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'La Rambla to słynna ulica w Barcelonie, znana z artystów ulicznych, sklepów i kawiarni. Odwiedzający mogą cieszyć się żywą atmosferą i zwiedzać pobliskie rynki i atrakcje.', en: 'La Rambla is a famous street in Barcelona known for its street performers, shops, and cafes. Visitors can enjoy the lively atmosphere and explore nearby markets and attractions.', es: 'La Rambla es una famosa calle en Barcelona conocida por sus artistas callejeros, tiendas y cafeterías. Los visitantes pueden disfrutar del ambiente animado y explorar los mercados y atracciones cercanas.' }

        },

        {

            id: 183,

            country: { pl: 'Francja', en: 'France', es: 'Francia' },

            place: { pl: 'Muzeum Luwr (Paryż)', en: 'Louvre Museum (Paris)', es: ' Museo del Louvre (París)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Luwr to najsłynniejsze muzeum na świecie. Mieści Mona Lisę, starożytne rzeźby i niezliczone dzieła sztuki. Szklany wejściowy piramida to nowoczesny symbol.', en: 'The Louvre is the world\’s most famous museum. It is home to the Mona Lisa, ancient statues, and countless artworks. The glass pyramid entrance is a modern icon.', es: 'El Louvre es el museo más famoso del mundo. Alberga la Mona Lisa, estatuas antiguas y un sinfín de obras de arte. La entrada con pirámide de vidrio es un ícono moderno.' }

        },

        {

            id: 184,

            country: { pl: 'Portugalia', en: 'Portugal', es: 'Portugal' },

            place: { pl: 'Livraria Lello (Porto)', en: 'Livraria Lello (Porto)', es: ' Livraria Lello (Oporto)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Księgarnia Lello to jedna z najpiękniejszych księgarni na świecie. Ma oszałamiające czerwone schody i rzeźbione drewniane półki. Mówi się, że zainspirowała książki o Harrym Potterze.', en: 'Livraria Lello is one of the most beautiful bookstores in the world. It has a stunning red staircase and carved wooden shelves. It is said to have inspired the Harry Potter books.', es: 'Livraria Lello es una de las librerías más hermosas del mundo. Tiene una impresionante escalera roja y estanterías de madera tallada. Se dice que inspiró los libros de Harry Potter.' }

        },

        {

            id: 185,

            country: { pl: 'Polska', en: 'Poland', es: 'Polonia' },

            place: { pl: 'Wydmy w Łebie', en: 'Dunes in Leba', es: ' Dunas de Leba' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te wydmy w Słowińskim Parku Narodowym wyglądają jak pustynia. Wiatr nieustannie przemieszcza wydmy, zmieniając ich kształt. Turyści mogą wspiąć się na nie i cieszyć się niesamowitymi widokami morza.', en: 'These sand dunes in Słowiński National Park look like a desert. The wind constantly moves the dunes, changing their shape. Visitors can hike up and enjoy amazing sea views.', es: 'Estas dunas de arena en el Parque Nacional Słowiński parecen un desierto. El viento mueve constantemente las dunas, cambiando su forma. Los visitantes pueden caminar hacia arriba y disfrutar de impresionantes vistas del mar.' }

        },

        {

            id: 186,

            country: { pl: 'Niemcy', en: 'Germany', es: 'Alemania' },

            place: { pl: 'Zamek w Heidelbergu', en: 'Heidelberg Castle', es: ' Castillo de Heidelberg' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te ruiny zamku wznoszą się nad rzeką Neckar, oferując malownicze widoki. Łączą elementy średniowiecznej i renesansowej architektury. Zwiedzający mogą również zobaczyć ogromną beczkę na wino.', en: 'This castle ruins sit above the Neckar River, offering beautiful views. It has a mix of medieval and Renaissance architecture. Visitors can also see a giant wine barrel inside.', es: 'Estas ruinas de castillo están sobre el río Neckar, ofreciendo hermosas vistas. Tiene una mezcla de arquitectura medieval y renacentista. Los visitantes también pueden ver un enorme barril de vino en su interior.' }

        },

        {

            id: 187,

            country: { pl: 'Hiszpania', en: 'Spain', es: 'España' },

            place: { pl: 'Gran Canaria (Wyspy Kanaryjskie) – Wydmy Maspalomas', en: 'Gran Canaria (Canary Islands) – Maspalomas Dunes', es: ' Gran Canaria (Islas Canarias) – Dunas de Maspalomas' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Gran Canaria słynie z złotych wydm w Maspalomas. To idealne miejsce na opalanie, jazdę na wielbłądach i odkrywanie naturalnego piękna wyspy.', en: 'Gran Canaria is famous for its golden sand dunes in Maspalomas. It\’s a perfect spot for sunbathing, camel rides, and exploring the island\’s natural beauty.', es: 'Gran Canaria es famosa por sus dunas de arena dorada en Maspalomas. Es un lugar perfecto para tomar el sol, montar en camello y explorar la belleza natural de la isla.' }

        },

        {

            id: 188,

            country: { pl: 'Kenia', en: 'Kenya', es: 'Kenia' },

            place: { pl: 'Rezerwat Narodowy Masajów Mara', en: 'Maasai Mara National Reserve', es: ' Reserva Nacional Maasai Mara' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Ten rezerwat przyrody słynie z wielkich zwierząt, takich jak lwy, słonie i gepardy. Odbywa się tu Wielka Migracja, podczas której tysiące gnu przemierzają sawannę.', en: 'This wildlife reserve is famous for its big animals like lions, elephants, and cheetahs. The Great Migration happens here, with thousands of wildebeest crossing the land.', es: 'Esta reserva de vida silvestre es famosa por sus grandes animales como leones, elefantes y guepardos. La Gran Migración ocurre aquí, con miles de ñus cruzando la tierra.' }

        },

        {

            id: 189,

            country: { pl: 'Jordania', en: 'Jordan', es: 'Jordania' },

            place: { pl: 'Amfiteatr rzymski (Amman)', en: 'Roman Amphitheater (Amman)', es: ' Anfiteatro Romano (Amán)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten starożytny amfiteatr w Ammanie ma ponad 1 800 lat. Mógł pomieścić 6 000 widzów na przedstawieniach. Zwiedzający mogą wspiąć się na szczyt, by podziwiać widok na miasto.', en: 'This ancient amphitheater in Amman is over 1,800 years old. It could hold 6,000 people for performances. Visitors can climb to the top for a great city view.', es: 'Este antiguo anfiteatro en Ammán tiene más de 1.800 años. Podía albergar a 6.000 personas para actuaciones. Los visitantes pueden subir a la cima para obtener una gran vista de la ciudad.' }

        },

        {

            id: 190,

            country: { pl: 'Mongolia', en: 'Mongolia', es: 'Mongolia' },

            place: { pl: 'Pustynia Gobi', en: 'Gobi Desert', es: ' Desierto de Gobi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Pustynia Gobi to kraina wydm, skalistych gór i skamieniałości dinozaurów. Odwiedzający mogą zobaczyć wielbłądy, nocować w tradycyjnych jurtach i zwiedzać jej unikalne krajobrazy.', en: 'The Gobi Desert is a land of dunes, rocky mountains, and dinosaur fossils. Visitors can see camels, stay in traditional yurts, and explore its unique landscapes.', es: 'El desierto de Gobi es una tierra de dunas, montañas rocosas y fósiles de dinosaurios. Los visitantes pueden ver camellos, hospedarse en yurts tradicionales y explorar sus paisajes únicos.' }

        },

        {

            id: 191,

            country: { pl: 'Boliwia', en: 'Bolivia', es: 'Bolivia' },

            place: { pl: 'Droga Śmierci (Droga Yungas)', en: 'Death Road (Yungas Road)', es: ' Camino de la Muerte (Yungas)' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ta kręta górska droga jest znana jako najniebezpieczniejsza droga na świecie. Dziś wiele osób zjeżdża nią na rowerach, przeżywając ekstremalną przygodę z zapierającymi dech w piersiach widokami.', en: 'This winding mountain road is known as the world\’s most dangerous road. Today, many people cycle down it for an extreme adventure with breathtaking views.', es: 'Esta sinuosa carretera de montaña es conocida como la carretera más peligrosa del mundo. Hoy en día, muchas personas la recorren en bicicleta para vivir una aventura extrema con vistas impresionantes.' }

        },

        {

            id: 192,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Świątynia Zęba Buddy', en: 'Buddha Tooth Relic Temple', es: ' Templo de la Reliquia del Diente de Buda' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta świątynia w Chinatown mieści święty relikt Buddy. Słynie z wspaniałej architektury i spokojnej atmosfery. Odwiedzający mogą poznać buddyzm i podziwiać misternie wykonane rzeźby.', en: 'This temple in Chinatown houses a sacred relic of the Buddha. It is known for its grand architecture and peaceful atmosphere. Visitors can learn about Buddhism and admire the intricate carvings.', es: 'Este templo en Chinatown alberga una reliquia sagrada del Buda. Es conocido por su gran arquitectura y ambiente pacífico. Los visitantes pueden aprender sobre el budismo y admirar las intrincadas tallas.' }

        },

        {

            id: 193,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Plaża z czarnym piaskiem Reynisfjara', en: 'Reynisfjara Black Sand Beach', es: ' Playa de Arena Negra Reynisfjara' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta wyjątkowa plaża ma czarny wulkaniczny piasek i wysokie bazaltowe kolumny. Fale są bardzo silne, więc kąpiel jest zabroniona. To jedno z najczęściej fotografowanych miejsc na Islandii.', en: 'This unique beach has black volcanic sand and tall basalt columns. The waves are very strong, so swimming is not allowed. It is one of the most photographed places in Iceland.', es: 'Esta playa única tiene arena volcánica negra y altas columnas de basalto. Las olas son muy fuertes, por lo que no se permite nadar. Es uno de los lugares más fotografiados de Islandia.' }

        },

        {

            id: 194,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Jaskinie Batu', en: 'Batu Caves', es: ' Cuevas Batu' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Batu Caves to hinduistyczna świątynia w ogromnej jaskini wapiennej. Przy wejściu stoi wysoki, złoty posąg. Zwiedzający wspinają się po 272 kolorowych schodach, by dotrzeć do świątyń wewnątrz jaskiń.', en: 'Batu Caves is a Hindu shrine inside a huge limestone cave. A tall golden statue stands at the entrance. Visitors climb 272 colorful steps to reach the caves and see Hindu temples inside.', es: 'Las cuevas de Batu son un santuario hindú dentro de una enorme cueva de piedra caliza. Una alta estatua dorada se erige en la entrada. Los visitantes suben 272 escalones coloridos para llegar a las cuevas y ver templos hindúes en su interior.' }

        },

        {

            id: 195,

            country: { pl: 'Tajwan', en: 'Taiwan', es: 'Taiwán' },

            place: { pl: 'Tajpej 101', en: 'Taipei 101', es: ' Taipei 101' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Taipei 101 to wieżowiec, który kiedyś był najwyższym budynkiem na świecie. Odwiedzający mogą wjechać na szczyt windą, by podziwiać spektakularne widoki na miasto i otaczające góry.', en: 'Taipei 101 is a towering skyscraper that was once the tallest in the world. Visitors can take an elevator to the top for spectacular views of the city and surrounding mountains.', es: 'Taipei 101 es un rascacielos imponente que fue el más alto del mundo. Los visitantes pueden tomar un ascensor hasta la cima para disfrutar de vistas espectaculares de la ciudad y las montañas circundantes.' }

        },

        {

            id: 196,

            country: { pl: 'Bośnia i Hercegowina', en: 'Bosnia and Herzegovina', es: 'Bosnia y Herzegovina' },

            place: { pl: 'Stari Most (Mostar)', en: 'Stari Most (Mostar)', es: ' Stari Most (Mostar)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Stari Most to słynny most w Mostarze. Został zniszczony podczas wojny, ale odbudowano go w 2004 roku. Odważni miejscowi wciąż skaczą z niego do rzeki poniżej.', en: 'Stari Most is a famous old bridge in Mostar. It was destroyed during the war but rebuilt in 2004. Brave locals still dive from it into the river below.', es: 'El Stari Most es un famoso puente antiguo en Mostar. Fue destruido durante la guerra, pero fue reconstruido en 2004. Los valientes locales aún se lanzan desde él al río abajo.' }

        },

        {

            id: 197,

            country: { pl: 'Namibia', en: 'Namibia', es: 'Namibia' },

            place: { pl: 'Park Narodowy Etoszy', en: 'Etosha National Park', es: ' Parque Nacional Etosha' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Etosha to jeden z najlepszych parków dzikich zwierząt w Afryce. Ma ogromne solniska i wiele zwierząt, w tym słonie, lwy i nosorożce. Odwiedzający mogą wybrać się na safari i zobaczyć dziką przyrodę wokół wodopojów.', en: 'Etosha is one of Africa\’s best wildlife parks. It has large salt flats and many animals, including elephants, lions, and rhinos. Visitors can go on safaris and see wildlife around waterholes.', es: 'Etosha es uno de los mejores parques de vida salvaje de África. Tiene grandes llanuras salinas y muchos animales, incluidos elefantes, leones y rinocerontes. Los visitantes pueden hacer safaris y ver la vida salvaje alrededor de los pozos de agua.' }

        },

        {

            id: 198,

            country: { pl: 'Oman', en: 'Oman', es: 'Omán' },

            place: { pl: 'Fort Nizwy', en: 'Nizwa Fort', es: ' Fortaleza de Nizwa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Fort Nizwa to jedna z najsłynniejszych atrakcji historycznych Omanu. Ma dużą okrągłą wieżę i oferuje niesamowite widoki na miasto. Wewnątrz turyści mogą poznać historię i kulturę Omanu.', en: 'Nizwa Fort is one of Oman\’s most famous historical sites. It has a large round tower and offers amazing views of the city. Inside, visitors can learn about Oman\’s history and culture.', es: 'El Fuerte de Nizwa es uno de los sitios históricos más famosos de Omán. Tiene una gran torre redonda y ofrece vistas increíbles de la ciudad. En su interior, los visitantes pueden aprender sobre la historia y cultura de Omán.' }

        },

        {

            id: 199,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Francuska Dzielnica Nowego Orleanu', en: 'New Orleans’ French Quarter', es: ' Barrio Francés de Nueva Orleans' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Francuska Dzielnica w Nowym Orleanie jest znana z zabytkowych budynków, tętniących życiem ulic i muzyki jazzowej. Odwiedzający mogą spacerować po Bourbon Street, delektować się kuchnią kreolską i słuchać muzyki na żywo.', en: 'The French Quarter in New Orleans is known for its historic buildings, lively streets, and jazz music. Visitors can stroll down Bourbon Street, enjoy Creole cuisine, and hear live music.', es: 'El Barrio Francés en Nueva Orleans es conocido por sus edificios históricos, calles animadas y música de jazz. Los visitantes pueden pasear por Bourbon Street, disfrutar de la cocina criolla y escuchar música en vivo.' }

        },

        {

            id: 200,

            country: { pl: 'Arabia Saudyjska', en: 'Saudi Arabia', es: 'Arabia Saudita' },

            place: { pl: 'Al-Masjid al-Haram (Mekka)', en: 'Al-Masjid al-Haram (Mecca)', es: ' Al-Masjid al-Haram (La Meca)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'To najświętszy meczet w Islamie. Otacza Kaabę, najświętsze miejsce w Mekce. Miliony muzułmanów odwiedzają go każdego roku w ramach pielgrzymki.', en: 'This is the holiest mosque in Islam. It surrounds the Kaaba, the most sacred site in Mecca. Millions of Muslims visit it every year for pilgrimage.', es: 'Esta es la mezquita más sagrada del Islam. Rodea la Kaaba, el sitio más sagrado en La Meca. Millones de musulmanes la visitan cada año para la peregrinación.' }

        },

        {

            id: 201,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Kapadocja', en: 'Cappadocia', es: ' Capadocia' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Kapadocja słynie z bajkowych kominów i lotów balonem na gorące powietrze. Region ma wykute w skałach mieszkania oraz starożytne kościoły. Turyści mogą podziwiać wspaniałe krajobrazy i unikalne doświadczenia.', en: 'Cappadocia is famous for its fairy chimneys and hot air balloon rides. The region has cave dwellings and ancient churches carved into the rocks. Visitors can enjoy stunning landscapes and unique experiences.', es: 'Capadocia es famosa por sus chimeneas de hadas y paseos en globo aerostático. La región tiene viviendas en cuevas y antiguas iglesias talladas en las rocas. Los visitantes pueden disfrutar de impresionantes paisajes y experiencias únicas.' }

        },

        {

            id: 202,

            country: { pl: 'Nepal/Tybet', en: 'Nepal/Tibet', es: 'Nepal/Tíbet' },

            place: { pl: 'Mount Everest – najwyższy szczyt świata', en: 'Mount Everest – The world\’s highest peak', es: ' Mount Everest – El pico más alto del mundo' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Everest to najwyższa góra na świecie. Wspinacze próbują dotrzeć na szczyt, ale odwiedzający mogą także podziwiać ją z punktów widokowych w Nepalu lub Tybecie. Okolica jest bogata w kulturę i piękno natury.', en: 'Everest is the tallest mountain in the world. Climbers try to reach the summit, but visitors can also see it from viewpoints in Nepal or Tibet. The area is rich in culture and natural beauty.', es: 'El Everest es la montaña más alta del mundo. Los escaladores intentan llegar a la cima, pero los visitantes también pueden verla desde los miradores en Nepal o el Tíbet. El área está llena de cultura y belleza natural.' }

        },

        {

            id: 203,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Park Narodowy Jasper', en: 'Jasper National Park', es: ' Parque Nacional Jasper' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Jasper to dziki i mniej zatłoczony park narodowy. Znajdują się tu jeziora, wodospady i duże szanse na zobaczenie niedźwiedzi oraz łosi. Odwiedzający mogą eksplorować lodowe jaskinie i podziwiać piękne górskie widoki.', en: 'Jasper is a wild and less crowded national park. It has lakes, waterfalls, and great chances to see wildlife like bears and elk. Visitors can explore ice caves and enjoy stunning mountain views.', es: 'Jasper es un parque nacional salvaje y menos concurrido. Tiene lagos, cascadas y grandes oportunidades para ver vida silvestre, como osos y alces. Los visitantes pueden explorar cuevas de hielo y disfrutar de vistas impresionantes de las montañas.' }

        },

        {

            id: 204,

            country: { pl: 'Finlandia', en: 'Finland', es: 'Finlandia' },

            place: { pl: 'Szklane igloo Kakslauttanen (Laponia)', en: 'Kakslauttanen Glass Igloos (Lapland)', es: ' Iglús de Cristal de Kakslauttanen (Laponia)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten bajkowy zamek zainspirował Zamek Kopciuszka w Disneylandzie. Stoi na wzgórzu, oferując zapierające dech w piersiach widoki. Zwiedzający mogą eksplorować królewskie komnaty i wieże.', en: 'These glass igloos offer an unforgettable stay under the Northern Lights. Guests can sleep in warm beds while watching the sky light up with colors.', es: 'Estos iglús de vidrio ofrecen una estancia inolvidable bajo la aurora boreal. Los huéspedes pueden dormir en camas cálidas mientras ven el cielo iluminado con colores.' }

        },

        {

            id: 205,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Pulau Ubin', en: 'Pulau Ubin', es: ' Pulau Ubin' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: {
                pl: 'Pulau Ubin to wyspa, gdzie odwiedzający mogą doświadczyć wiejskiej przeszłości Singapuru. Oferuje szlaki rowerowe, tradycyjne wioski i szansę na kontakt z naturą z dala od miejskiego zgiełku.', en: 'Pulau Ubin is an island where visitors can experience Singapore\'s rural past.It offers cycling trails, traditional villages, and a chance to enjoy nature away from the city bustle.', es: 'Pulau Ubin es una isla donde los visitantes pueden experimentar el pasado rural de Singapur.Ofrece senderos para bicicletas, aldeas tradicionales y la oportunidad de disfrutar de la naturaleza lejos del bullicio de la ciudad.'
            }

        },

        {

            id: 206,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Horseshoe Bend', en: 'Horseshoe Bend', es: ' Horseshoe Bend' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Horseshoe Bend to słynne zakrzywienie rzeki Kolorado. Zachwycający widok z punktu widokowego jest szczególnie piękny o wschodzie i zachodzie słońca.', en: 'Horseshoe Bend is a famous bend in the Colorado River. The stunning view from the lookout is especially beautiful at sunrise and sunset.', es: 'Horseshoe Bend es un famoso recodo en el río Colorado. La impresionante vista desde el mirador es especialmente hermosa al amanecer y al atardecer.' }

        },

        {

            id: 207,

            country: { pl: 'Dominika', en: 'Dominica', es: 'Dominica' },

            place: { pl: 'Roseau', en: 'Roseau', es: ' Roseau' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Quito to wysokogórska stolica z kolonialnymi kościołami i tętniącymi życiem placami. Jego historyczne centrum znajduje się na liście UNESCO. Zwiedzający mogą wjechać kolejką linową, by podziwiać panoramę miasta.', en: 'Roseau is the capital of Dominica, with a mix of French and British colonial buildings. It is a great base for exploring waterfalls, hot springs, and rainforests.', es: 'Roseau es la capital de Dominica, con una mezcla de edificios coloniales franceses y británicos. Es una excelente base para explorar cascadas, aguas termales y selvas tropicales.' }

        },

        {

            id: 208,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Efez', en: 'Ephesus', es: ' Éfeso' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Efez to starożytne miasto z imponującymi grecko-rzymskimi ruinami. Turyści mogą zwiedzać Świątynię Artemidy, jedno z Siedmiu Cudów Świata, oraz inne zabytkowe miejsca.', en: 'Ephesus is an ancient city with impressive Greco-Roman ruins. Visitors can explore the Temple of Artemis, one of the Seven Wonders of the Ancient World, and other historic sites.', es: 'Éfeso es una ciudad antigua con impresionantes ruinas grecorromanas. Los visitantes pueden explorar el Templo de Artemisa, una de las Siete Maravillas del Mundo Antiguo, y otros sitios históricos.' }

        },

        {

            id: 209,

            country: { pl: 'Peru', en: 'Peru', es: 'Perú' },

            place: { pl: 'Jezioro Titicaca', en: 'Lake Titicaca', es: ' Lago Titicaca' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Jezioro Titicaca to najwyżej położone żeglowne jezioro na świecie. Słynie z pływających wysp stworzonych przez lud Uros. Turyści mogą wziąć udział w rejsie i poznać lokalne tradycje.', en: 'Lake Titicaca is the highest navigable lake in the world. It is famous for its floating islands made by the Uros people. Visitors can take boat tours and learn about local traditions.', es: 'El Lago Titicaca es el lago navegable más alto del mundo. Es famoso por sus islas flotantes hechas por el pueblo Uros. Los visitantes pueden tomar tours en barco y aprender sobre las tradiciones locales.' }

        },

        {

            id: 210,

            country: { pl: 'Chile', en: 'Chile', es: 'Chile' },

            place: { pl: 'Pustynia Atacama', en: 'Atacama Desert', es: ' Desierto de Atacama' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Atakama to najsuchsza pustynia na świecie. Znajdują się tam niezwykłe krajobrazy, solniska i gejzery. Odwiedzający mogą zobaczyć flamingi, zwiedzać stare wioski i podziwiać gwiazdy na czystym niebie.', en: 'The Atacama is the driest desert in the world. It has otherworldly landscapes, salt flats, and geysers. Visitors can see flamingos, explore old villages, and stargaze under clear skies.', es: 'El Atacama es el desierto más seco del mundo. Tiene paisajes de otro mundo, salares y géiseres. Los visitantes pueden ver flamencos, explorar pueblos antiguos y observar las estrellas bajo cielos despejados.' }

        },

        {

            id: 211,

            country: { pl: 'Łotwa', en: 'Latvia', es: 'Letonia' },

            place: { pl: 'Ventas Rumba w Kuldīga', en: 'Kuldīga\’s Ventas Rumba', es: ' Rumba de Ventas en Kuldīga' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ventas Rumba to najszerszy wodospad w Europie. Nie jest wysoki, ale wiosną można zobaczyć ryby skaczące w górę wodospadu. Miasteczko Kuldīga jest urocze i spokojne.', en: 'Ventas Rumba is the widest waterfall in Europe. It is not very high, but in spring, you can see fish jumping up the falls. The town of Kuldīga is charming and peaceful.', es: 'Ventas Rumba es la cascada más ancha de Europa. No es muy alta, pero en primavera, se pueden ver peces saltando por las caídas. El pueblo de Kuldīga es encantador y tranquilo.' }

        },

        {

            id: 212,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Tulum', en: 'Tulum', es: ' Tulum' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Tulum to nadmorskie miasto z pięknymi plażami i ruinami Majów. Ruiny znajdują się na klifie z widokiem na turkusowe morze. Odwiedzający mogą zgłębiać historię, a potem relaksować się na plaży.', en: 'Tulum is a coastal town with beautiful beaches and Mayan ruins. The ruins sit on a cliff overlooking the turquoise sea. Visitors can explore history and then relax on the beach.', es: 'Tulum es un pueblo costero con hermosas playas y ruinas mayas. Las ruinas están en un acantilado con vistas al mar turquesa. Los visitantes pueden explorar la historia y luego relajarse en la playa.' }

        },

        {

            id: 213,

            country: { pl: 'Korea Południowa', en: 'South Korea', es: 'Corea del Sur' },

            place: { pl: 'Plac Gwanghwamun', en: 'Gwanghwamun Square', es: ' Plaza Gwanghwamun' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Plac Gwanghwamun w Seulu to centrum kultury i historii. Odwiedzający mogą zwiedzać pobliski Pałac Gyeongbokgung, pomniki historycznych postaci i cieszyć się żywą atmosferą.', en: 'Gwanghwamun Square in Seoul is a cultural and historical hub. Visitors can explore the nearby Gyeongbokgung Palace, statues of historical figures, and enjoy the vibrant atmosphere.', es: 'La Plaza Gwanghwamun en Seúl es un centro cultural e histórico. Los visitantes pueden explorar el cercano Palacio Gyeongbokgung, estatuas de figuras históricas y disfrutar del ambiente vibrante.' }

        },

        {

            id: 214,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Pałac Topkapi (Stambuł)', en: 'Topkapi Palace (Istanbul)', es: ' Palacio Topkapi (Estambul)' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Pałac Topkapi to pałac z okresu osmańskiego w Stambule. Turyści mogą zwiedzać królewskie komnaty, piękne ogrody i imponującą kolekcję artefaktów. Pałac oferuje wgląd w bogatą historię Turcji.', en: 'Topkapi Palace is an Ottoman-era palace in Istanbul. Visitors can explore the royal chambers, beautiful gardens, and impressive collection of artifacts. The palace offers a glimpse into Turkey\’s rich history.', es: 'El Palacio Topkapi es un palacio de la era otomana en Estambul. Los visitantes pueden explorar las habitaciones reales, hermosos jardines y una impresionante colección de artefactos. El palacio ofrece una visión de la rica historia de Turquía.' }

        },

        {

            id: 215,

            country: { pl: 'Malta', en: 'Malta', es: 'Malta' },

            place: { pl: 'Stare Miasto w Valletcie', en: 'Valletta Old Town', es: ' Casco Antiguo de Valletta' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Valletta to mała, ale tętniąca życiem stolica pełna historii. Ma wielkie pałace, stare fortyfikacje i oszałamiające widoki na morze. Odwiedzający mogą spacerować wąskimi uliczkami i cieszyć się bogatą kulturą Malty.', en: 'Valletta is a small but lively capital full of history. It has grand palaces, old fortifications, and stunning sea views. Visitors can walk through narrow streets and enjoy Malta\’s rich culture.', es: 'La Valeta es una pequeña pero animada capital llena de historia. Tiene grandes palacios, antiguas fortificaciones y vistas impresionantes al mar. Los visitantes pueden caminar por calles estrechas y disfrutar de la rica cultura de Malta.' }

        },

        {

            id: 216,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Nowy Orlean (Luizjana)', en: 'New Orleans (Louisiana)', es: ' Nueva Orleans (Luisiana)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Nowy Orlean jest znany z muzyki jazzowej, kuchni kreolskiej i żywiołowych obchodów Mardi Gras. Francuska Dzielnica oferuje kolorowe ulice, zabytkowe budynki i niesamowitą muzykę na żywo.', en: 'New Orleans is famous for its jazz music, Creole food, and vibrant Mardi Gras celebrations. The French Quarter offers colorful streets, historic buildings, and amazing live music.', es: 'Nueva Orleans es famosa por su música jazz, comida criolla y las vibrantes celebraciones del Mardi Gras. El Barrio Francés ofrece calles coloridas, edificios históricos y música en vivo increíble.' }

        },

        {

            id: 217,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Wyspy Gili', en: 'Gili Islands', es: ' Islas Gili' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Te wyspy mają białe, piaszczyste plaże i brak samochodów. Odwiedzający mogą nurkować, pływać z rurką i jeździć na rowerze. Atmosfera jest spokojna i idealna na wypoczynek.', en: 'These islands have white sand beaches and no cars. Visitors can enjoy snorkeling, diving, and cycling around. The atmosphere is relaxed and perfect for a getaway.', es: 'Estas islas tienen playas de arena blanca y no tienen coches. Los visitantes pueden disfrutar de esnórquel, buceo y paseos en bicicleta. La atmósfera es relajada y perfecta para una escapada.' }

        },

        {

            id: 218,

            country: { pl: 'Bośnia i Hercegowina', en: 'Bosnia and Herzegovina', es: 'Bosnia y Herzegovina' },

            place: { pl: 'Klasztor Blagaj Tekija', en: 'Blagaj Tekija Monastery', es: ' Monasterio Blagaj Tekija' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten 600-letni klasztor został zbudowany obok rzeki, pod ogromną skałą. To spokojne miejsce z pięknymi widokami i głębokim poczuciem historii.', en: 'This 600-year-old monastery is built next to a river, under a huge cliff. It is a peaceful place with beautiful views and a deep sense of history.', es: 'Este monasterio de 600 años está construido junto a un río, bajo un enorme acantilado. Es un lugar tranquilo con hermosas vistas y un profundo sentido de historia.' }

        },

        {

            id: 219,

            country: { pl: 'Portoryko', en: 'Puerto Rico', es: 'Puerto Rico' },

            place: { pl: 'San Juan', en: 'San Juan', es: ' San Juan' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'San Juan to kolorowa stolica Portoryko. Ma zabytkowe fortece, brukowane uliczki i tętniące życiem życie nocne. Stare miasto pełne jest hiszpańskiego kolonialnego uroku.', en: 'San Juan is the colorful capital of Puerto Rico. It has historic forts, cobblestone streets, and lively nightlife. The old town is full of Spanish colonial charm.', es: 'San Juan es la colorida capital de Puerto Rico. Tiene fortalezas históricas, calles empedradas y una animada vida nocturna. El casco antiguo está lleno de encanto colonial español.' }

        },

        {

            id: 220,

            country: { pl: 'Wyspy Zielonego Przylądka', en: 'Cape Verde Islands', es: 'Cabo Verde' },

            place: { pl: 'Zjednoczone Królestwo Przylądka', en: 'Cape Verdef', es: ' Cabo Verde' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Republika Zielonego Przylądka to grupa wysp z złotymi plażami, wulkanicznymi krajobrazami i żywą muzyką. Odwiedzający mogą wspiąć się na wulkany, zwiedzać rybackie wioski lub tańczyć do tradycyjnych rytmów.', en: 'Cape Verde is a group of islands with golden beaches, volcanic landscapes, and lively music. Visitors can hike up volcanoes, explore fishing villages, or dance to traditional Cape Verdean beats.', es: 'Cabo Verde es un grupo de islas con playas doradas, paisajes volcánicos y música animada. Los visitantes pueden hacer senderismo en los volcanes, explorar pueblos pesqueros o bailar al ritmo tradicional caboverdiano.' }

        },

        {

            id: 221,

            country: { pl: 'Madagaskar', en: 'Madagascar', es: 'Madagascar' },

            place: { pl: 'Aleja Baobabów', en: 'Avenue of the Baobabs', es: ' Avenida de los Baobabs' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Ta droga jest otoczona olbrzymimi baobabami, z których niektóre mają ponad 800 lat. Szczególnie magicznie wygląda o zachodzie słońca. Można tu zrobić niesamowite zdjęcia i poznać unikalną przyrodę Madagaskaru.', en: 'This road is lined with giant baobab trees, some over 800 years old. It looks especially magical at sunset. Visitors can take amazing photos and learn about Madagascar\’s unique nature.', es: 'Este camino está flanqueado por gigantescos árboles baobabs, algunos de más de 800 años. Se ve especialmente mágico al atardecer. Los visitantes pueden tomar fotos impresionantes y aprender sobre la naturaleza única de Madagascar.' }

        },

        {

            id: 222,

            country: { pl: 'Brazylia', en: 'Brazil', es: 'Brasil' },

            place: { pl: 'Amazoński las deszczowy i Manaus', en: 'Amazon Rainforest & Manaus', es: ' Selva Amazónica y Manaos' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Amazonia to największy las deszczowy na świecie. Odwiedzający mogą popłynąć łodzią z Manaus, by zobaczyć egzotyczne zwierzęta i doświadczyć niezwykłej bioróżnorodności.', en: 'The Amazon is the world\’s largest rainforest. Visitors can take boat trips from Manaus to see exotic wildlife and experience the incredible biodiversity.', es: 'El Amazonas es la selva tropical más grande del mundo. Los visitantes pueden hacer paseos en barco desde Manaos para ver la fauna exótica y experimentar la increíble biodiversidad.' }

        },

        {

            id: 223,

            country: { pl: 'Barbados', en: 'Barbados', es: 'Barbados' },

            place: { pl: 'Bridgetown', en: 'Bridgetown', es: ' Bridgetown' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Bridgetown to tętniąca życiem stolica Barbadosu. Znajduje się tam architektura kolonialna, sklepy wolnocłowe i zabytkowy port. Odwiedzający mogą również zwiedzać pobliskie plaże i targi.', en: 'Bridgetown is the lively capital of Barbados. It has colonial architecture, duty-free shopping, and a historic harbor. Visitors can also explore nearby beaches and markets.', es: 'Bridgetown es la animada capital de Barbados. Tiene arquitectura colonial, compras libres de impuestos y un puerto histórico. Los visitantes también pueden explorar las playas cercanas y los mercados.' }

        },

        {

            id: 224,

            country: { pl: 'Dominika', en: 'Dominica', es: 'Dominica' },

            place: { pl: 'Wrzące Jezioro', en: 'Boiling Lake', es: ' Lago Hirviente' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Wyspy Galapagos to dom dla unikalnych zwierząt, takich jak gigantyczne żółwie i głuptaki niebieskonogie. Zwiedzający mogą nurkować z lwami morskimi i odkrywać wulkaniczne krajobrazy.', en: 'Boiling Lake is a natural wonder, a steaming pool of hot water in a volcanic crater. The hike to reach it is challenging but offers breathtaking views of Dominica\’s wild landscape.', es: 'El Lago Hirviente es una maravilla natural, una piscina de agua caliente en un cráter volcánico. La caminata para llegar a él es desafiante, pero ofrece vistas impresionantes del paisaje salvaje de Dominica.' }

        },

        {

            id: 225,

            country: { pl: 'Izrael', en: 'Israel', es: 'Israel' },

            place: { pl: 'Ejlat (kurort nad Morzem Czerwonym)', en: 'Eilat (Red Sea Resort)', es: ' Eilat (Estación balnearia del Mar Rojo)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Eilat to nadmorski kurort z krystalicznie czystą wodą i rafami koralowymi. To świetne miejsce do nurkowania, snorkelingu i relaksu na słońcu.', en: 'Eilat is a beach resort with clear waters and coral reefs. It\’s a great place for diving, snorkeling, and enjoying the sun.', es: 'Eilat es un resort de playa con aguas claras y arrecifes de coral. Es un lugar excelente para bucear, hacer snorkel y disfrutar del sol.' }

        },

        {

            id: 226,

            country: { pl: 'Malta', en: 'Malta', es: 'Malta' },

            place: { pl: 'Błękitna Grota', en: 'Blue Grotto', es: ' Gruta Azul' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Błękitna Grota to zespół morskich jaskiń z jaskrawo niebieską wodą. Odwiedzający mogą odbywać rejsy łodzią, by odkrywać jaskinie. Najlepszym czasem na wizytę jest poranek, gdy światło słoneczne sprawia, że woda świeci.', en: 'The Blue Grotto is a series of sea caves with bright blue water. Visitors can take boat trips to explore the caves. The best time to visit is in the morning when the sunlight makes the water glow.', es: 'La Gruta Azul es una serie de cavernas marinas con agua de un azul brillante. Los visitantes pueden tomar paseos en bote para explorar las cavernas. El mejor momento para visitarlas es por la mañana, cuando la luz del sol hace que el agua brille.' }

        },

        {

            id: 227,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Hampi', en: 'Hampi', es: ' Hampi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Hampi to starożytne miasto z ruinami świątyń i ogromnymi głazami. Kiedyś było potężnym królestwem. Zwiedzający mogą podziwiać kamienne rzeźby i wspiąć się na wzgórza, by podziwiać niesamowite widoki.', en: 'Hampi is an ancient city with temple ruins and giant boulders. It was once a powerful kingdom. Visitors can explore stone carvings and climb hills for amazing views.', es: 'Hampi es una ciudad antigua con ruinas de templos y enormes rocas. Fue una vez un poderoso reino. Los visitantes pueden explorar tallados en piedra y subir colinas para obtener vistas increíbles.' }

        },

        {

            id: 228,

            country: { pl: 'Chile', en: 'Chile', es: 'Chile' },

            place: { pl: 'Park Narodowy Torres del Paine', en: 'Torres del Paine National Park', es: ' Parque Nacional Torres del Paine' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ten park słynie z oszałamiających gór, błękitnych jezior i lodowców. Odwiedzający mogą wędrować do pięknych punktów widokowych i obserwować dziką przyrodę, taką jak guanako i kondory. To raj dla miłośników natury.', en: 'This park is famous for its stunning mountains, blue lakes, and glaciers. Visitors can hike to beautiful viewpoints and see wildlife like guanacos and condors. It is a paradise for nature lovers.', es: 'Este parque es famoso por sus impresionantes montañas, lagos azules y glaciares. Los visitantes pueden hacer senderismo a hermosos miradores y ver vida silvestre como guanacos y cóndores. Es un paraíso para los amantes de la naturaleza.' }

        },

        {

            id: 229,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Boston (Massachusetts)', en: 'Boston (Massachusetts)', es: ' Boston (Massachusetts)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Boston to miasto pełne amerykańskiej historii. Turyści mogą przejść Szlakiem Wolności, odwiedzić statki Boston Tea Party lub zrelaksować się w pięknym parku Boston Common.', en: 'Boston is a city full of American history. Visitors can walk the Freedom Trail, visit the Boston Tea Party Ships, or relax in the beautiful Boston Common park.', es: 'Boston es una ciudad llena de historia estadounidense. Los visitantes pueden caminar por el Freedom Trail, visitar los barcos del Boston Tea Party o relajarse en el hermoso parque Boston Common.' }

        },

        {

            id: 230,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Wielki Pałac (Bangkok)', en: 'Grand Palace (Bangkok)', es: ' Gran Palacio (Bangkok)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wielki Pałac to majestatyczny kompleks w Bangkoku. To siedziba Błotnego Buddy, świętego posągu. Odwiedzający mogą podziwiać złote iglice, misterną sztukę i piękne ogrody.', en: 'The Grand Palace is a majestic complex in Bangkok. It is home to the Emerald Buddha, a sacred statue. Visitors can admire the golden spires, intricate artwork, and beautiful gardens.', es: 'El Gran Palacio es un majestuoso complejo en Bangkok. Es hogar del Buda Esmeralda, una estatua sagrada. Los visitantes pueden admirar las agujas doradas, las intrincadas obras de arte y los hermosos jardines.' }

        },

        {

            id: 231,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Laguna lodowcowa Jökulsárlón', en: 'Jökulsárlón Glacier Lagoon', es: ' Laguna Glaciar Jökulsárlón' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta laguna jest pełna unoszących się gór lodowych o różnych kształtach i kolorach. Zwiedzający mogą popłynąć łodzią, by zobaczyć lód z bliska. Często można tu zobaczyć foki pływające w zimnych wodach.', en: 'This lagoon is filled with floating icebergs of different shapes and colors. Visitors can take boat tours to see the ice up close. Seals are often seen swimming in the cold waters.', es: 'Esta laguna está llena de icebergs flotantes de diferentes formas y colores. Los visitantes pueden hacer recorridos en barco para ver el hielo de cerca. A menudo se ven focas nadando en las aguas frías.' }

        },

        {

            id: 232,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Pamukkale', en: 'Pamukkale', es: ' Pamukkale' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Pamukkale słynie z białych basenów termalnych. Baseny te powstały w wyniku osadów wapnia i wyglądają jak tarasy. Turyści mogą kąpać się w ciepłych wodach, podziwiając piękny krajobraz.', en: 'Pamukkale is famous for its white thermal pools. The pools are formed from calcium deposits and look like terraces. Visitors can soak in the warm waters while enjoying the beautiful landscape.', es: 'Pamukkale es famoso por sus piscinas térmicas blancas. Las piscinas están formadas por depósitos de calcio y parecen terrazas. Los visitantes pueden sumergirse en las aguas cálidas mientras disfrutan del hermoso paisaje.' }

        },

        {

            id: 233,

            country: { pl: 'Kuba', en: 'Cuba', es: 'Cuba' },

            place: { pl: 'Trinidad', en: 'Trinidad', es: ' Trinidad' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Kourion to starożytne miasto z dobrze zachowanymi ruinami. Najsłynniejszym miejscem jest duży amfiteatr z widokiem na morze. Zwiedzający mogą także zobaczyć rzymskie łaźnie i piękne mozaiki.', en: 'Trinidad is a charming colonial town with cobblestone streets and colorful houses. It feels like stepping back in time. Nearby, visitors can explore sugar plantations and beautiful waterfalls.', es: 'Trinidad es un encantador pueblo colonial con calles empedradas y casas coloridas. Se siente como si se retrocediera en el tiempo. Cerca, los visitantes pueden explorar plantaciones de azúcar y hermosas cascadas.' }

        },

        {

            id: 234,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Tarasy ryżowe Sa Pa', en: 'Sa Pa Rice Terraces', es: ' Terrazas de Arroz de Sa Pa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Sa Pa słynie z zapierających dech w piersiach tarasów ryżowych, wyrzeźbionych w górach. Tarasy zmieniają kolory w zależności od pory roku, oferując wspaniałe widoki i możliwość doświadczenia lokalnej kultury.', en: 'Sa Pa is famous for its breathtaking rice terraces, carved into the mountains. The terraces change colors with the seasons, offering stunning views and an opportunity to experience local culture.', es: 'Sa Pa es famosa por sus impresionantes terrazas de arroz talladas en las montañas. Las terrazas cambian de color con las estaciones, ofreciendo vistas impresionantes y la oportunidad de experimentar la cultura local.' }

        },

        {

            id: 235,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Most Golden Gate', en: 'Golden Gate Bridge', es: ' Puente Golden Gate' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Złota Brama to jeden z najbardziej rozpoznawalnych pomników na świecie. Odwiedzający mogą spacerować lub jeździć na rowerze po moście, podziwiać widoki lub robić zdjęcia z pobliskich parków.', en: 'The Golden Gate Bridge is one of the most recognizable landmarks in the world. Visitors can walk or bike across it, enjoy the views, or take photos from nearby parks.', es: 'El Puente Golden Gate es uno de los monumentos más reconocidos del mundo. Los visitantes pueden caminar o andar en bicicleta a través de él, disfrutar de las vistas o tomar fotos desde los parques cercanos.' }

        },

        {

            id: 236,

            country: { pl: 'Czarnogóra', en: 'Montenegro', es: 'Montenegro' },

            place: { pl: 'Stare Miasto w Kotorze i Zatoka Kotorska', en: 'Kotor Old Town & Bay of Kotor', es: ' Casco Antiguo de Kotor y Bahía de Kotor' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kotor to średniowieczne miasto z kamiennymi ulicami i starymi kościołami. Zatoka otoczona jest górami, oferującymi niesamowite widoki. Odwiedzający mogą wspiąć się na twierdzę, aby podziwiać zapierający dech w piersiach panoramę.', en: 'Kotor is a medieval town with stone streets and old churches. The bay is surrounded by mountains, offering stunning views. Visitors can climb the fortress for a breathtaking panorama.', es: 'Kotor es una ciudad medieval con calles de piedra y antiguas iglesias. La bahía está rodeada por montañas, ofreciendo vistas impresionantes. Los visitantes pueden subir a la fortaleza para un panorama impresionante.' }

        },

        {

            id: 237,

            country: { pl: 'Argentyna', en: 'Argentina', es: 'Argentina' },

            place: { pl: 'Buenos Aires', en: 'Buenos Aires', es: ' Buenos Aires' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Buenos Aires to tętniąca życiem stolica Argentyny, słynąca z tanga i steków. Turyści mogą zwiedzać kolorowe dzielnice, takie jak La Boca, oraz historyczne miejsca, jak cmentarz Recoleta. Miasto emanuje kulturą i energią.', en: 'Buenos Aires is Argentina\’s vibrant capital, famous for tango and steak. Visitors can explore colorful neighborhoods like La Boca and historic sites like the Recoleta Cemetery. The city is full of culture and energy.', es: 'Buenos Aires es la vibrante capital de Argentina, famosa por el tango y el bistec. Los visitantes pueden explorar barrios coloridos como La Boca y sitios históricos como el Cementerio de la Recoleta. La ciudad está llena de cultura y energía.' }

        },

        {

            id: 238,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Wielki Budda w Phuket', en: 'Phuket\’s Big Buddha', es: ' Gran Buda de Phuket' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wielki Budda to ogromny biały posąg z marmuru na wyspie Phuket. Z jego szczytu roztacza się wspaniały widok na wyspę. Spokojna atmosfera czyni to miejsce doskonałym do refleksji.', en: 'The Big Buddha is a massive white marble statue on Phuket Island. It overlooks the island and offers stunning views. The peaceful atmosphere makes it a great spot for reflection.', es: 'El Gran Buda es una enorme estatua de mármol blanco en la isla de Phuket. Se asoma sobre la isla y ofrece vistas espectaculares. La atmósfera tranquila lo convierte en un gran lugar para la reflexión.' }

        },

        {

            id: 239,

            country: { pl: 'Republika Południowej Afryki', en: 'South Africa', es: 'Sudáfrica' },

            place: { pl: 'Wyspa Robben', en: 'Robben Island', es: ' Isla Robben' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Wyspa Robben to historyczne miejsce, w którym Nelson Mandela był więziony. Odwiedzający mogą zwiedzać wyspę i dowiedzieć się o walce Południowej Afryki o wolność.', en: 'Robben Island is a historic site where Nelson Mandela was imprisoned. Visitors can tour the island and learn about South Africa\’s struggle for freedom.', es: 'La Isla Robben es un sitio histórico donde Nelson Mandela fue encarcelado. Los visitantes pueden recorrer la isla y aprender sobre la lucha de Sudáfrica por la libertad.' }

        },

        {

            id: 240,

            country: { pl: 'Zimbabwe', en: 'Zimbabwe', es: 'Zimbabue' },

            place: { pl: 'Wodospady Wiktorii', en: 'Victoria Falls', es: ' Cataratas Victoria' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Wodospad Wiktorii to jeden z największych i najbardziej spektakularnych wodospadów na świecie. Odwiedzający mogą podziwiać wodospady z różnych punktów widokowych i spróbować ekscytujących atrakcji, takich jak skoki na bungee.', en: 'Victoria Falls is one of the largest and most spectacular waterfalls in the world. Visitors can view the falls from various viewpoints and experience thrilling activities like bungee jumping.', es: 'Las Cataratas Victoria son una de las cascadas más grandes y espectaculares del mundo. Los visitantes pueden ver las cataratas desde varios puntos de vista y disfrutar de actividades emocionantes como el puenting.' }

        },

        {

            id: 241,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Wzgórza Ba Na i Złoty Most', en: 'Ba Na Hills & Golden Bridge', es: ' Colinas Ba Na y Puente Dorado' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ba Na Hills słynie z Złotego Mostu, podtrzymywanego przez gigantyczne kamienne dłonie. W tym rejonie znajduje się także kolejka linowa, malownicze widoki i park rozrywki, idealny dla odwiedzających w każdym wieku.', en: 'Ba Na Hills is famous for its Golden Bridge, held up by giant stone hands. The area also features a cable car, scenic views, and a fun amusement park for visitors of all ages.', es: 'Ba Na Hills es famosa por su Puente Dorado, sostenido por enormes manos de piedra. La zona también cuenta con un teleférico, vistas panorámicas y un divertido parque de atracciones para visitantes de todas las edades.' }

        },

        {

            id: 242,

            country: { pl: 'Kajmany', en: 'Cayman Islands', es: 'Islas Caimán' },

            place: { pl: 'George Town', en: 'George Town', es: ' George Town' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'George Town to stolica Kajmanów. Znajdują się tam sklepy wolnocłowe, świetne restauracje i piękna nadmorska promenada. Odwiedzający mogą także nurkować z rurką lub odwiedzić słynne Stingray City.', en: 'George Town is the capital of the Cayman Islands. It has duty-free shopping, great restaurants, and a beautiful waterfront. Visitors can also go snorkeling or visit the famous Stingray City.', es: 'George Town es la capital de las Islas Caimán. Tiene compras libres de impuestos, excelentes restaurantes y un hermoso paseo marítimo. Los visitantes también pueden hacer snorkel o visitar la famosa Stingray City.' }

        },

        {

            id: 243,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Dzikie tereny Bisti/De-Na-Zin', en: 'Bisti/De-Na-Zin Wilderness', es: ' Bisti/De-Na-Zin Wilderness' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta pustynna dzicz ma niezwykłe formacje skalne i kolorowe nieużytki. To spokojne miejsce do wędrówek, fotografii i oglądania gwiazd. Czujesz się, jakbyś wszedł do innego świata.', en: 'This desert wilderness has unusual rock formations and colorful badlands. It is a peaceful place for hiking, photography, and stargazing. It feels like stepping into another world.', es: 'Este desierto salvaje tiene formaciones rocosas inusuales y tierras de coloridas malas tierras. Es un lugar tranquilo para hacer senderismo, fotografía y observar las estrellas. Se siente como entrar en otro mundo.' }

        },

        {

            id: 244,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Kolej Miedzianego Kanionu', en: 'Copper Canyon Railway', es: ' Ferrocarril del Cañón del Cobre' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta podróż pociągiem przebiega przez największy kanion Meksyku, jeszcze większy niż Wielki Kanion. Widoki na klify, rzeki i lasy są niezapomniane.', en: 'This train journey goes through Mexico\’s largest canyon, even bigger than the Grand Canyon. The views of cliffs, rivers, and forests are unforgettable.', es: 'Este viaje en tren recorre el cañón más grande de México, aún más grande que el Gran Cañón. Las vistas de los acantilados, los ríos y los bosques son inolvidables.' }

        },

        {

            id: 245,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Cancún i Riwiera Majów', en: 'Cancún & Riviera Maya', es: ' Cancún y la Riviera Maya' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Cancún słynie z białych piaszczystych plaż i życia nocnego. Riviera Maya oferuje wspaniałe kurorty, starożytne ruiny i rafy koralowe. To popularne miejsce zarówno do relaksu, jak i przygód.', en: 'Cancún is famous for its white sandy beaches and nightlife. The Riviera Maya offers stunning resorts, ancient ruins, and coral reefs. It is a top destination for both relaxation and adventure.', es: 'Cancún es famoso por sus playas de arena blanca y su vida nocturna. La Riviera Maya ofrece impresionantes resorts, ruinas antiguas y arrecifes de coral. Es un destino ideal para relajación y aventura.' }

        },

        {

            id: 246,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Zamek Himeji', en: 'Himeji Castle', es: ' Castillo de Himeji' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Zamek Himeji to najpiękniejszy samurajski zamek w Japonii. Nazywany jest „Zamkiem Białej Czapli” ze względu na swoje białe ściany. Odwiedzający mogą zwiedzać jego drewniane hale i wieże obronne.', en: 'Himeji Castle is Japan\’s most beautiful samurai-era castle. It is called the "White Heron Castle" because of its white walls. Visitors can explore its wooden halls and defensive towers.', es: 'El Castillo de Himeji es el castillo más hermoso de la era samurái en Japón. Se llama "Castillo de la Garza Blanca" debido a sus paredes blancas. Los visitantes pueden explorar sus pasillos de madera y torres defensivas.' }

        },

        {

            id: 247,

            country: { pl: 'Wenezuela', en: 'Venezuela', es: 'Venezuela' },

            place: { pl: 'Caracas', en: 'Caracas', es: ' Caracas' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Caracas, stolica Wenezueli, oferuje mieszankę nowoczesnych i tradycyjnych atrakcji. Odwiedzający mogą zwiedzać muzea, rynki i cieszyć się wspaniałymi widokami z otaczających gór.', en: 'Caracas, the capital of Venezuela, offers a mix of modern and traditional attractions. Visitors can explore museums, markets, and enjoy stunning views from the surrounding mountains.', es: 'Caracas, la capital de Venezuela, ofrece una mezcla de atracciones modernas y tradicionales. Los visitantes pueden explorar museos, mercados y disfrutar de impresionantes vistas desde las montañas circundantes.' }

        },

        {

            id: 248,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Wat Arun (Bangkok)', en: 'Wat Arun (Bangkok)', es: ' Wat Arun (Bangkok)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wat Arun to nadmorska świątynia znana z oszałamiającego projektu. Świątynia ma centralną wieżę, która błyszczy w słońcu. To doskonałe miejsce na wizytę podczas zachodu słońca, aby podziwiać piękne widoki.', en: 'Wat Arun is a riverside temple known for its stunning design. The temple has a central spire that glows in the sunlight. It\’s a great place to visit during sunset for beautiful views.', es: 'Wat Arun es un templo a orillas del río conocido por su diseño impresionante. El templo tiene una aguja central que brilla al sol. Es un gran lugar para visitar al atardecer y disfrutar de hermosas vistas.' }

        },

        {

            id: 249,

            country: { pl: 'Kuba', en: 'Cuba', es: 'Cuba' },

            place: { pl: 'Varadero', en: 'Varadero', es: ' Varadero' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta malownicza skała na wybrzeżu jest uważana za miejsce narodzin Afrodyty, greckiej bogini miłości. Plaża to doskonałe miejsce na oglądanie zachodu słońca i robienie pięknych zdjęć.', en: 'Varadero is one of Cuba\’s best beach destinations. It has white sand, clear blue water, and luxury resorts. Visitors can relax, go snorkeling, or take boat trips to nearby islands.', es: 'Varadero es uno de los mejores destinos de playa de Cuba. Tiene arena blanca, agua azul clara y resorts de lujo. Los visitantes pueden relajarse, hacer snorkel o tomar paseos en bote a islas cercanas.' }

        },

        {

            id: 250,

            country: { pl: 'Monako', en: 'Monaco', es: 'Mónaco' },

            place: { pl: 'Muzeum Oceanograficzne', en: 'Oceanographic Museum', es: ' Museo Oceanográfico' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To muzeum jest jednym z najlepszych na świecie w zakresie życia morskiego. Ma gigantyczne akwaria, wystawy o żółwiach morskich i taras z pięknymi widokami.', en: 'This museum is one of the best in the world for marine life. It has giant aquariums, sea turtle exhibits, and a rooftop with great views.', es: 'Este museo es uno de los mejores del mundo para la vida marina. Tiene acuarios gigantes, exposiciones de tortugas marinas y una azotea con excelentes vistas.' }

        },

        {

            id: 251,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Świątynia Todai-ji w Narze', en: 'Nara\’s Todai-ji Temple', es: ' Templo Todai-ji de Nara' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Świątynia Todai-ji jest domem dla jednego z największych posągów Buddy w Japonii. Wokół niej spacerują jelenie, które kłaniają się odwiedzającym w zamian za jedzenie. Impresjonujące jest także drewniane wejście do świątyni.', en: 'Todai-ji Temple is home to one of the biggest Buddha statues in Japan. It is surrounded by deer that bow to visitors for food. The temple\’s wooden gate is also impressive.', es: 'El Templo Todai-ji alberga una de las estatuas de Buda más grandes de Japón. Está rodeado de ciervos que se inclinan ante los visitantes por comida. La puerta de madera del templo también es impresionante.' }

        },

        {

            id: 252,

            country: { pl: 'Serbia', en: 'Serbia', es: 'Serbia' },

            place: { pl: 'Kanion Uvac', en: 'Uvac Canyon', es: ' Cañón de Uvac' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kanion Uvac ma kręte rzeki i wysokie klify. Słynie ze swoich meandrów i rzadkich sępów griffonów. Odwiedzający mogą odbyć rejs łodzią i podziwiać niesamowite punkty widokowe.', en: 'Uvac Canyon has winding rivers and high cliffs. It is famous for its meanders and rare griffon vultures. Visitors can take boat trips and see amazing viewpoints.', es: 'El Cañón Uvac tiene ríos serpenteantes y altos acantilados. Es famoso por sus meandros y sus raros buitres leonados. Los visitantes pueden hacer paseos en bote y disfrutar de vistas impresionantes.' }

        },

        {

            id: 253,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'The Wave', en: 'The Wave', es: ' The Wave' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'The Wave to unikalna formacja skalna z piaskowca, z gładkimi, wirującymi wzorami. To popularne miejsce na wędrówki, choć wymagane są pozwolenia na wizytę.', en: 'The Wave is a unique sandstone rock formation with smooth, swirling patterns. It\’s a popular hiking spot, though permits are required to visit.', es: 'The Wave es una formación única de rocas de arenisca con patrones suaves y ondulados. Es un popular lugar de senderismo, aunque se requieren permisos para visitarlo.' }

        },

        {

            id: 254,

            country: { pl: 'Portoryko', en: 'Puerto Rico', es: 'Puerto Rico' },

            place: { pl: 'Culebra', en: 'Culebra', es: ' Culebra' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Culebra to mała wyspa z jednymi z najlepszych plaż na Karaibach. Plaża Flamenco ma biały piasek i krystalicznie czystą niebieską wodę. To idealne miejsce do nurkowania i relaksu.', en: 'Culebra is a small island with some of the best beaches in the Caribbean. Flamenco Beach has white sand and clear blue water. It is a perfect place for snorkeling and relaxing.', es: 'Culebra es una pequeña isla con algunas de las mejores playas del Caribe. Flamenco Beach tiene arena blanca y agua cristalina. Es un lugar perfecto para practicar esnórquel y relajarse.' }

        },

        {

            id: 255,

            country: { pl: 'Oman', en: 'Oman', es: 'Omán' },

            place: { pl: 'Piaski Wahiba', en: 'Wahiba Sands', es: ' Arenas de Wahiba' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta ogromna pustynia ma falujące złote wydmy. Turyści mogą jeździć na wielbłądach, spróbować sandboardingu lub spędzić noc w obozie na pustyni. Zachody słońca są tu niezapomniane.', en: 'This vast desert has rolling golden dunes. Visitors can go on camel rides, try sandboarding, or spend a night in a desert camp. The sunsets here are unforgettable.', es: 'Este vasto desierto tiene dunas doradas y onduladas. Los visitantes pueden montar camellos, probar el sandboarding o pasar una noche en un campamento en el desierto. Las puestas de sol aquí son inolvidables.' }

        },

        {

            id: 256,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Wielki Meczet Szejka Zayeda (Abu Zabi)', en: 'Sheikh Zayed Grand Mosque (Abu Dhabi)', es: ' Gran Mezquita Sheikh Zayed (Abu Dabi)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten meczet jest jednym z największych na świecie. Posiada wspaniałe białe marmurowe ściany, 82 kopuły oraz misternie wykonane zdobienia. Turyści mogą podziwiać piękną architekturę i spokojną atmosferę.', en: 'This mosque is one of the largest in the world. It has stunning white marble walls, 82 domes, and intricate designs. Visitors can admire the beautiful architecture and peaceful atmosphere.', es: 'Esta mezquita es una de las más grandes del mundo. Tiene impresionantes paredes de mármol blanco, 82 cúpulas y diseños intrincados. Los visitantes pueden admirar la hermosa arquitectura y la atmósfera tranquila.' }

        },

        {

            id: 257,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Statua Wolności', en: 'Statue of Liberty', es: ' Estatua de la Libertad' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Statua Wolności dumnie stoi na Wyspie Wolności w porcie Nowego Jorku. Symbol wolności, oferuje wspaniałe widoki na miasto. Odwiedzający mogą popłynąć promem, aby ją odwiedzić i poznać jej historię.', en: 'The Statue of Liberty stands proudly on Liberty Island in New York Harbor. A symbol of freedom, it offers stunning views of the city. Visitors can take a ferry to visit and learn about its history.', es: 'La Estatua de la Libertad se erige orgullosa en la Isla de la Libertad en el puerto de Nueva York. Símbolo de la libertad, ofrece vistas impresionantes de la ciudad. Los visitantes pueden tomar un ferry para visitarla y conocer su historia.' }

        },

        {

            id: 258,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Nowy Jork (Nowy Jork)', en: 'New York City (New York)', es: ' Nueva York (Nueva York)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Nowy Jork słynie z ikonicznych zabytków, takich jak Times Square, Central Park i Statua Wolności. To tętniące życiem, różnorodne miasto pełne kultury, zakupów i rozrywki.', en: 'New York City is known for its iconic landmarks like Times Square, Central Park, and the Statue of Liberty. It is a vibrant, diverse city full of culture, shopping, and entertainment.', es: 'La Ciudad de Nueva York es conocida por sus monumentos icónicos como Times Square, Central Park y la Estatua de la Libertad. Es una ciudad vibrante y diversa llena de cultura, compras y entretenimiento.' }

        },

        {

            id: 259,

            country: { pl: 'Egipt', en: 'Egypt', es: 'Egipto' },

            place: { pl: 'Dolina Królów', en: 'Valley of the Kings', es: ' Valle de los Reyes' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Ta wielka prawosławna katedra na wzgórzu w Tallinnie ma piękne cebulaste kopuły i bogato zdobione wnętrze. Jest ważnym punktem miasta.', en: 'This valley is home to the tombs of pharaohs, including King Tutankhamun. The colorful wall paintings show scenes from ancient beliefs. Visitors can explore these hidden treasures.', es: 'Este valle alberga las tumbas de los faraones, incluido el rey Tutankamón. Las coloridas pinturas murales muestran escenas de creencias antiguas. Los visitantes pueden explorar estos tesoros escondidos.' }

        },

        {

            id: 260,

            country: { pl: 'Wenezuela', en: 'Venezuela', es: 'Venezuela' },

            place: { pl: 'Wyspa Margarita', en: 'Margarita Island', es: ' Isla Margarita' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Wyspa Margarita to popularna destynacja z pięknymi plażami, tętniącym życiem nocnym i relaksującą atmosferą. Odwiedzający mogą uprawiać sporty wodne, robić zakupy i delektować się pyszną lokalną kuchnią.', en: 'Margarita Island is a popular destination for its beautiful beaches, vibrant nightlife, and relaxed atmosphere. Visitors can enjoy water sports, shopping, and delicious local cuisine.', es: 'La Isla Margarita es un destino popular por sus hermosas playas, vibrante vida nocturna y ambiente relajado. Los visitantes pueden disfrutar de deportes acuáticos, compras y deliciosa cocina local.' }

        },

        {

            id: 261,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Operę w Sydney', en: 'Sydney Opera House', es: ' Ópera de Sídney' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Opera w Sydney to jeden z najsłynniejszych budynków na świecie. Jej dach przypomina białe żagle. Można tam obejrzeć koncerty, zwiedzać wnętrza lub po prostu podziwiać widok z portu.', en: 'The Sydney Opera House is one of the most famous buildings in the world. Its roof looks like white sails. Visitors can watch concerts, take tours, or just enjoy the view from the harbor.', es: 'La Ópera de Sídney es uno de los edificios más famosos del mundo. Su techo parece velas blancas. Los visitantes pueden ver conciertos, hacer recorridos o simplemente disfrutar de la vista desde el puerto.' }

        },

        {

            id: 262,

            country: { pl: 'Maroko', en: 'Morocco', es: 'Marruecos' },

            place: { pl: 'Niebieskie Miasto Chefchaouen', en: 'The Blue City of Chefchaouen', es: ' La Ciudad Azul de Chefchaouen' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Chefchaouen słynie z niebieskich malowanych ulic i budynków. Miasto znajduje się w górach, oferując piękne widoki. Odwiedzający mogą wędrować w wąskich uliczkach i podziwiać lokalne rzemiosło.', en: 'Chefchaouen is famous for its blue-painted streets and buildings. The town is set in the mountains, offering beautiful views. Visitors can wander through narrow alleys and enjoy local crafts.', es: 'Chefchaouen es famosa por sus calles y edificios pintados de azul. El pueblo está situado en las montañas, ofreciendo hermosas vistas. Los visitantes pueden caminar por callejones estrechos y disfrutar de la artesanía local.' }

        },

        {

            id: 263,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Los Angeles (Kalifornia)', en: 'Los Angeles (California)', es: ' Los Ángeles (California)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Los Angeles jest znane z Hollywood, pięknych plaż i słonecznej pogody. Turyści mogą udać się na wycieczkę po studiach, przejść się Hollywood Walk of Fame i zrelaksować się na plaży Venice.', en: 'Los Angeles is famous for Hollywood, beautiful beaches, and sunny weather. Visitors can take a studio tour, walk along the Hollywood Walk of Fame, and relax on Venice Beach.', es: 'Los Ángeles es famosa por Hollywood, hermosas playas y clima soleado. Los visitantes pueden hacer un recorrido por un estudio, caminar por el Paseo de la Fama de Hollywood y relajarse en Venice Beach.' }

        },

        {

            id: 264,

            country: { pl: 'Peru', en: 'Peru', es: 'Perú' },

            place: { pl: 'Linie Nazca', en: 'Nazca Lines', es: ' Líneas de Nazca' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Linie Nazca to gigantyczne rysunki na pustyni. Przedstawiają zwierzęta, kształty i tajemnicze symbole. Najlepiej widać je z małego samolotu.', en: 'The Nazca Lines are giant drawings in the desert. They include animals, shapes, and mysterious symbols. The best way to see them is from a small plane.', es: 'Las Líneas de Nazca son enormes dibujos en el desierto. Incluyen animales, formas y símbolos misteriosos. La mejor manera de verlas es desde un avión pequeño.' }

        },

        {

            id: 265,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Park Narodowy Yellowstone', en: 'Yellowstone National Park', es: ' Parque Nacional Yellowstone' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Yellowstone to pierwszy park narodowy na świecie, słynący z geotermalnych zjawisk, takich jak gejzery i gorące źródła. Ma również dziką faunę, taką jak bawoły, wilki i niedźwiedzie, co czyni go idealnym miejscem dla miłośników przyrody.', en: 'Yellowstone is the first national park in the world, famous for its geothermal features like geysers and hot springs. It also has wildlife such as bison, wolves, and bears, making it perfect for outdoor enthusiasts.', es: 'Yellowstone es el primer parque nacional del mundo, famoso por sus características geotermales como géiseres y fuentes termales. También tiene vida silvestre como bisontes, lobos y osos, lo que lo convierte en el lugar perfecto para los entusiastas del aire libre.' }

        },

        {

            id: 266,

            country: { pl: 'Czarnogóra', en: 'Montenegro', es: 'Montenegro' },

            place: { pl: 'Perast i Matka Boska na Skale', en: 'Perast & Our Lady of the Rocks', es: ' Perast y Nuestra Señora de las Rocas' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Perast to urocze nadmorskie miasteczko z barokowymi budynkami. Tuż przy wybrzeżu odwiedzający mogą zobaczyć Naszą Panią Skał, małą sztuczną wyspę z zabytkowym kościołem. Wycieczki łodzią oferują świetne okazje do robienia zdjęć.', en: 'Perast is a charming coastal town with baroque buildings. Just offshore, visitors can see Our Lady of the Rocks, a small artificial island with a historic church. Boat trips offer great photo opportunities.', es: 'Perast es un encantador pueblo costero con edificios barrocos. Justo frente a la costa, los visitantes pueden ver Nuestra Señora de las Rocas, una pequeña isla artificial con una iglesia histórica. Los paseos en barco ofrecen excelentes oportunidades para tomar fotos.' }

        },

        {

            id: 267,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Willa Haw Par', en: 'Haw Par Villa', es: ' Haw Par Villa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Haw Par Villa to wyjątkowy park tematyczny z kolorowymi posągami przedstawiającymi chińską mitologię i folklor. To ciekawy sposób na poznanie tradycyjnej chińskiej kultury w zabawny sposób.', en: 'Haw Par Villa is a unique theme park with colorful statues depicting Chinese mythology and folklore. It offers a glimpse into traditional Chinese culture in a fun, quirky way.', es: 'Haw Par Villa es un parque temático único con estatuas coloridas que representan la mitología y el folclore chino. Ofrece una visión de la cultura tradicional china de una manera divertida y peculiar.' }

        },

        {

            id: 268,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Wieża CN', en: 'CN Tower', es: ' Torre CN' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Wieża CN jest jednym z najwyższych budynków na świecie. Odwiedzający mogą cieszyć się tarasem widokowym ze szklaną podłogą, a nawet spróbować EdgeWalk. Widok na Toronto jest niezapomniany.', en: 'The CN Tower is one of the tallest buildings in the world. Visitors can enjoy a glass-floor observation deck and even try the EdgeWalk. The view of Toronto is unforgettable.', es: 'La Torre CN es uno de los edificios más altos del mundo. Los visitantes pueden disfrutar de una plataforma de observación con piso de vidrio e incluso probar el EdgeWalk. La vista de Toronto es inolvidable.' }

        },

        {

            id: 269,

            country: { pl: 'Republika Południowej Afryki', en: 'South Africa', es: 'Sudáfrica' },

            place: { pl: 'Kanion Rzeki Blyde', en: 'Blyde River Canyon', es: ' Cañón del Río Blyde' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Kanion Rzeki Blyde to jeden z największych kanionów na świecie. Oferuje zapierające dech w piersiach widoki, unikalne formacje skalne i bujną roślinność. Odwiedzający mogą skorzystać z wycieczek łodzią wzdłuż rzeki.', en: 'Blyde River Canyon is one of the largest canyons in the world. It offers breathtaking views, unique rock formations, and lush vegetation. Visitors can take boat tours along the river.', es: 'El Cañón Blyde River es uno de los cañones más grandes del mundo. Ofrece vistas impresionantes, formaciones rocosas únicas y vegetación exuberante. Los visitantes pueden hacer paseos en bote por el río.' }

        },

        {

            id: 270,

            country: { pl: 'Martynika', en: 'Martinique', es: 'Martinica' },

            place: { pl: 'Fort-de-France', en: 'Fort-de-France', es: ' Fort-de-France' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Fort-de-France to stolica Martyniki. Ma połączenie kultury francuskiej i karaibskiej. Odwiedzający mogą zwiedzać lokalne rynki, odwiedzać zabytkowe fortece i cieszyć się pięknymi plażami w pobliżu.', en: 'Fort-de-France is the capital of Martinique. It has a mix of French and Caribbean culture. Visitors can explore local markets, visit historic forts, and enjoy beautiful beaches nearby.', es: 'Fort-de-France es la capital de Martinica. Tiene una mezcla de cultura francesa y caribeña. Los visitantes pueden explorar mercados locales, visitar fortalezas históricas y disfrutar de hermosas playas cercanas.' }

        },

        {

            id: 271,

            country: { pl: 'Ekwador', en: 'Ecuador', es: 'Ecuador' },

            place: { pl: 'Quito', en: 'Quito', es: ' Quito' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ta dolina kryje grobowce faraonów, w tym Tutanchamona. Kolorowe malowidła na ścianach przedstawiają sceny ze starożytnych wierzeń. Zwiedzający mogą odkrywać te ukryte skarby.', en: 'Quito is a high-altitude capital with colonial churches and lively plazas. The historic center is a UNESCO site. Visitors can take a cable car for great views of the city.', es: 'Quito es una capital de gran altitud con iglesias coloniales y plazas animadas. El centro histórico es un sitio de la UNESCO. Los visitantes pueden tomar un teleférico para disfrutar de vistas impresionantes de la ciudad.' }

        },

        {

            id: 272,

            country: { pl: 'Wenezuela', en: 'Venezuela', es: 'Venezuela' },

            place: { pl: 'Roraima', en: 'Roraima', es: ' Roraima' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Roraima to góra o płaskim wierzchołku w Wenezueli, słynąca z unikalnego ekosystemu i oszałamiających widoków. Zainspirowała Sir Arthura Conana Doyla do napisania "Zaginionego Świata" i oferuje przygodowe wyprawy.', en: 'Roraima is a flat-topped mountain in Venezuela, famous for its unique ecosystem and stunning views. It inspired Sir Arthur Conan Doyle\’s "The Lost World" and offers adventurous treks.', es: 'Roraima es una montaña de cima plana en Venezuela, famosa por su ecosistema único y vistas impresionantes. Inspiró a Sir Arthur Conan Doyle para escribir "El Mundo Perdido" y ofrece emocionantes excursiones.' }

        },

        {

            id: 273,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Wodospady Erawan', en: 'Erawan Waterfalls', es: ' Cascadas de Erawan' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wodospady Erawan to wielopoziomowe wodospady otoczone bujną dżunglą. Turkusowe baseny wodne są idealne do pływania. W okolicy znajdują się piękne szlaki turystyczne z malowniczymi widokami.', en: 'The Erawan Waterfalls are multi-tiered waterfalls surrounded by lush jungle. The turquoise water pools are perfect for swimming. The area offers beautiful hikes with scenic views.', es: 'Las cascadas de Erawan son cascadas de varios niveles rodeadas de jungla exuberante. Las piscinas de agua turquesa son perfectas para nadar. La zona ofrece hermosas caminatas con vistas panorámicas.' }

        },

        {

            id: 274,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Różowe Jeziora', en: 'Pink Lakes', es: ' Lagos Rosados' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Australia ma kilka różowych jezior, takich jak Lake Hillier i Hutt Lagoon. Ich niezwykły kolor pochodzi od specjalnych alg. Jeziora stanowią unikalny i piękny widok w suchym australijskim krajobrazie.', en: 'Australia has several pink lakes, like Lake Hillier and Hutt Lagoon. The pink color comes from special algae. These lakes are a unique and beautiful sight in the dry Australian landscape.', es: 'Australia tiene varios lagos rosas, como el Lago Hillier y la Laguna Hutt. El color rosa proviene de algas especiales. Estos lagos son una vista única y hermosa en el paisaje árido de Australia.' }

        },

        {

            id: 275,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Charleston', en: 'Charleston', es: ' Charleston' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Charleston to urokliwe południowe miasto z brukowanymi ulicami, zabytkowymi domami i pięknymi ogrodami. Słynie z bogatej historii, pysznych owoców morza i przyjaznej atmosfery.', en: 'Charleston is a charming southern city with cobblestone streets, historic homes, and beautiful gardens. It is known for its rich history, delicious seafood, and welcoming atmosphere.', es: 'Charleston es una encantadora ciudad del sur con calles empedradas, casas históricas y hermosos jardines. Es conocida por su rica historia, delicioso marisco y su ambiente acogedor.' }

        },

        {

            id: 276,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: {
                pl: 'Chicago\'s Cloud Gate(The Bean)', en: 'Chicago\’s Cloud Gate(The Bean)', es: ' Cloud Gate de Chicago(The Bean)'
            },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: {
                pl: 'Cloud Gate, znane jako "The Bean", to błyszcząca, odbijająca rzeźba w Millennium Park w Chicago. Odwiedzający mogą robić zdjęcia swoich odbić i cieszyć się otaczającym parkiem.', en: 'Cloud Gate, known as "The Bean," is a shiny, reflective sculpture in Chicago\'s Millennium Park.Visitors can take pictures of their reflections and enjoy the surrounding park.', es: 'Cloud Gate, conocida como "The Bean," es una escultura brillante y reflectante en el Parque Millennium de Chicago.Los visitantes pueden tomar fotos de sus reflejos y disfrutar del parque circundante.'
            }

        },

        {

            id: 277,

            country: { pl: 'Paragwaj', en: 'Paraguay', es: 'Paraguay' },

            place: { pl: 'Misje jezuickie w La Santísima Trinidad', en: 'Jesuit Missions of La Santísima Trinidad', es: ' Misiones Jesuíticas de La Santísima Trinidad' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Te ruiny to przypomnienie o jezuickiej przeszłości Paragwaju. Stare kamienne budynki są spokojne i pełne historii. Turyści mogą zwiedzać to miejsce i poznać jego wyjątkową historię.', en: 'These ruins are a reminder of Paraguay\’s Jesuit past. The old stone buildings are peaceful and full of history. Visitors can explore the site and learn about its unique story.', es: 'Estas ruinas son un recordatorio del pasado jesuita de Paraguay. Los viejos edificios de piedra son tranquilos y llenos de historia. Los visitantes pueden explorar el sitio y aprender sobre su historia única.' }

        },

        {

            id: 278,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Wyspy Whitsunday', en: 'Whitsunday Islands', es: ' Islas Whitsunday' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Te wyspy to tropikalny raj niedaleko Wielkiej Rafy Koralowej. Białe piaszczyste plaże i krystalicznie czysta woda sprawiają, że są idealne do nurkowania i żeglowania. Najbardziej znanym miejscem jest plaża Whitehaven Beach.', en: 'These islands are a tropical paradise near the Great Barrier Reef. White sand beaches and clear blue water make them perfect for snorkeling and sailing. Whitehaven Beach is the most famous spot.', es: 'Estas islas son un paraíso tropical cerca de la Gran Barrera de Coral. Las playas de arena blanca y el agua cristalina las hacen perfectas para hacer snorkel y navegar. La playa Whitehaven es el lugar más famoso.' }

        },

        {

            id: 279,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Wyspy Phi Phi', en: 'Phi Phi Islands', es: ' Islas Phi Phi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wyspy Phi Phi to tropikalny raj z krystalicznie czystą wodą. Turyści mogą nurkować, odpoczywać na plaży i podziwiać piękne klify wapienne. To popularne miejsce na wycieczki po wyspach.', en: 'The Phi Phi Islands are a tropical paradise with crystal-clear waters. Visitors can snorkel, relax on the beach, and see beautiful limestone cliffs. It\’s a popular destination for island-hopping.', es: 'Las Islas Phi Phi son un paraíso tropical con aguas cristalinas. Los visitantes pueden hacer snorkel, relajarse en la playa y ver hermosos acantilados de piedra caliza. Es un destino popular para hacer island hopping.' }

        },

        {

            id: 280,

            country: { pl: 'Portoryko', en: 'Puerto Rico', es: 'Puerto Rico' },

            place: { pl: 'Vieques', en: 'Vieques', es: ' Vieques' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Vieques to spokojna wyspa z oszałamiającymi plażami. Słynie z Bioluminescent Bay, gdzie woda świeci w nocy. Odwiedzający mogą także zobaczyć dzikie konie swobodnie biegające po wyspie.', en: 'Vieques is a peaceful island with stunning beaches. It is famous for its Bioluminescent Bay, where the water glows at night. Visitors can also see wild horses roaming freely.', es: 'Vieques es una isla tranquila con playas impresionantes. Es famosa por su Bahía Bioluminiscente, donde el agua brilla por la noche. Los visitantes también pueden ver caballos salvajes deambulando libremente.' }

        },

        {

            id: 281,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Sanktuarium My Son', en: 'My Son Sanctuary', es: ' Santuario My Son' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'My Son to starożytny kompleks świątynny hinduskiej cywilizacji Champa w Wietnamie. Otoczony dżunglą, to spokojne i historycznie ważne miejsce do zwiedzania.', en: 'My Son is an ancient Hindu temple complex in Vietnam, built by the Champa civilization. Surrounded by jungle, it is a peaceful and historically significant site to explore.', es: 'My Son es un antiguo complejo de templos hindúes en Vietnam, construido por la civilización Champa. Rodeado de selva, es un lugar tranquilo e históricamente significativo para explorar.' }

        },

        {

            id: 282,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Wieża Diabła', en: 'Devil\’s Tower', es: ' Torre del Diablo' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: {
                pl: 'Devil\’s Tower to unikalna, wznosząca się formacja skalna w Wyoming. Jest święta dla plemion rdzennych Amerykanów i oferuje wspaniałe widoki. Odwiedzający mogą wędrować wokół podstawy lub podziwiać ją z daleka.', en: 'Devil\’s Tower is a unique, towering rock formation in Wyoming. It\’s sacred to Native American tribes and offers stunning views. Visitors can hike around the base or admire it from afar.', es: 'Devil\'s Tower es una formación rocosa única y imponente en Wyoming.Es sagrada para las tribus nativas americanas y ofrece vistas espectaculares.Los visitantes pueden caminar alrededor de su base o admirarla desde lejos.'
            }

        },

        {

            id: 283,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Wodospady Havasu', en: 'Havasu Falls', es: ' Cascadas de Havasu' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Havasu Falls to zapierający dech w piersiach wodospad, położony w odosobnionym kanionie. Krystalicznie czysta niebieska woda i bujna zieleń sprawiają, że jest to obowiązkowa atrakcja dla poszukiwaczy przygód.', en: 'Havasu Falls is a breathtaking waterfall located in a remote canyon. The clear blue water and lush greenery make it a must-see for adventurous travelers.', es: 'Las Cataratas Havasu son una impresionante cascada ubicada en un cañón remoto. El agua clara y azul y la vegetación exuberante la convierten en un lugar imprescindible para los viajeros aventureros.' }

        },

        {

            id: 284,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Cesarskie Miasto Hue', en: 'Hue Imperial City', es: ' Ciudad Imperial de Hue' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Hue było niegdyś stolicą Wietnamu i jest domem dla starożytnego Miasta Cesarskiego. Odwiedzający mogą zwiedzać zabytkowe pałace, świątynie i grobowce, poznając królewską przeszłość Wietnamu.', en: 'Hue was once the capital of Vietnam and is home to an ancient Imperial City. Visitors can explore historic palaces, temples, and tombs while learning about Vietnam\’s royal past.', es: 'Hue fue la capital de Vietnam y alberga una antigua Ciudad Imperial. Los visitantes pueden explorar palacios, templos y tumbas históricas mientras aprenden sobre el pasado real de Vietnam.' }

        },

        {

            id: 285,

            country: { pl: 'Kuwejt', en: 'Kuwait', es: 'Kuwait' },

            place: { pl: 'Wieża Al Hamra', en: 'Al Hamra Tower', es: ' Torre Al Hamra' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'To najwyższy budynek w Kuwejcie. Ma unikalny design z zakrzywionym szczytem. Wewnątrz znajdują się biura, sklepy i luksusowe kino.', en: 'This is the tallest building in Kuwait. It has a unique design with a curved top. Inside, there are offices, shops, and a luxury cinema.', es: 'Este es el edificio más alto de Kuwait. Tiene un diseño único con una cima curva. En su interior, hay oficinas, tiendas y un cine de lujo.' }

        },

        {

            id: 286,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Wodospad Skogafoss', en: 'Skogafoss Waterfall', es: ' Cascada Skógafoss' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten ogromny wodospad to jeden z najsłynniejszych w Islandii. Schody prowadzą na punkt widokowy na szczycie. Zwiedzający mogą także podejść blisko i poczuć mgiełkę na twarzy.', en: 'This huge waterfall is one of Iceland\’s most famous. A staircase leads to a viewpoint at the top. Visitors can also walk close to the falls and feel the mist on their faces.', es: 'Esta enorme cascada es una de las más famosas de Islandia. Una escalera conduce a un mirador en la parte superior. Los visitantes también pueden caminar cerca de la cascada y sentir la niebla en sus rostros.' }

        },

        {

            id: 287,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Cabo San Lucas', en: 'Cabo San Lucas', es: ' Cabo San Lucas' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Cabo jest znane z formacji skalnych, luksusowych kurortów i obserwacji wielorybów. Odwiedzający mogą wybrać się na wycieczki łodzią, aby zobaczyć słynny Łuk w Cabo San Lucas. Plaże są idealne do aktywności wodnych.', en: 'Cabo is known for its rock formations, luxury resorts, and whale watching. Visitors can take boat trips to see the famous Arch of Cabo San Lucas. The beaches are perfect for water activities.', es: 'Cabo es conocido por sus formaciones rocosas, resorts de lujo y avistamiento de ballenas. Los visitantes pueden hacer paseos en bote para ver el famoso Arco de Cabo San Lucas. Las playas son perfectas para actividades acuáticas.' }

        },

        {

            id: 288,

            country: { pl: 'Etiopia', en: 'Ethiopia', es: 'Etiopía' },

            place: { pl: 'Kościoły skalne Lalibela', en: 'Lalibela Rock-Hewn Churches', es: ' Iglesias Excavadas en Roca de Lalibela' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'To średniowieczne miasto-twierdza ma wysokie mury i kamienne wieże. Wygląda jak z bajki. Zwiedzający mogą spacerować wąskimi uliczkami i poznawać jego historię.', en: 'These 12th-century churches were carved from solid rock. They are still used for worship today. Visitors can explore the tunnels and passages connecting them.', es: 'Estas iglesias del siglo XII fueron talladas en roca sólida. Aún se utilizan para el culto hoy en día. Los visitantes pueden explorar los túneles y pasajes que las conectan.' }

        },

        {

            id: 289,

            country: { pl: 'Republika Południowej Afryki', en: 'South Africa', es: 'Sudáfrica' },

            place: { pl: 'Góra Stołowa i Kapsztad', en: 'Table Mountain & Cape Town', es: ' Montaña de la Mesa y Ciudad del Cabo' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Góra Stołowa w Cape Town oferuje zapierające dech w piersiach widoki na miasto i Ocean Atlantycki. Odwiedzający mogą wjechać kolejką linową na szczyt lub przejść szlakami, by podziwiać panoramiczne widoki.', en: 'Table Mountain in Cape Town offers stunning views of the city and the Atlantic Ocean. Visitors can take a cable car to the top or hike the trails for panoramic views.', es: 'La Montaña de la Mesa en Ciudad del Cabo ofrece vistas espectaculares de la ciudad y el océano Atlántico. Los visitantes pueden tomar el teleférico hasta la cima o hacer senderismo por los senderos para disfrutar de vistas panorámicas.' }

        },

        {

            id: 290,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Miasto George (Penang)', en: 'George Town (Penang)', es: ' George Town (Penang)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'George Town łączy kolonialną historię z nowoczesną sztuką uliczną. Można tu zobaczyć kolorowe murale, stare świątynie i spróbować pysznego jedzenia ulicznego. Zwiedzanie pieszo lub rikszą to świetna opcja.', en: 'George Town is a mix of colonial history and modern street art. It has colorful murals, old temples, and delicious street food. Visitors can explore by foot or take a trishaw ride.', es: 'George Town es una mezcla de historia colonial y arte callejero moderno. Tiene murales coloridos, templos antiguos y deliciosa comida callejera. Los visitantes pueden explorar a pie o tomar un paseo en triciclo.' }

        },

        {

            id: 291,

            country: { pl: 'Peru', en: 'Peru', es: 'Perú' },

            place: { pl: 'Kanion Colca', en: 'Colca Canyon', es: ' Cañón del Colca' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Kanion Colca to jeden z najgłębszych kanionów na świecie. Turyści mogą podziwiać zapierające dech widoki i oglądać kondory latające w powietrzu. To także doskonałe miejsce do wędrówek i zwiedzania tradycyjnych wiosek.', en: 'Colca Canyon is one of the deepest canyons in the world. Visitors can see breathtaking views and watch condors fly. It is also a great place for hiking and exploring traditional villages.', es: 'El Cañón del Colca es uno de los cañones más profundos del mundo. Los visitantes pueden ver vistas impresionantes y observar cóndores volando. También es un excelente lugar para hacer senderismo y explorar pueblos tradicionales.' }

        },

        {

            id: 292,

            country: { pl: 'Jamajka', en: 'Jamaica', es: 'Jamaica' },

            place: { pl: 'Montego Bay', en: 'Montego Bay', es: ' Montego Bay' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Montego Bay słynie z białych piaszczystych plaż i błękitnej wody. Oferuje luksusowe kurorty, snorkeling i zabytkowe miejsca, takie jak Rose Hall Great House.', en: 'Montego Bay is famous for its white sandy beaches and clear blue water. It offers luxury resorts, snorkeling, and historic sites like Rose Hall Great House.', es: 'Montego Bay es famosa por sus playas de arena blanca y aguas cristalinas. Ofrece resorts de lujo, snorkel y sitios históricos como la Rose Hall Great House.' }

        },

        {

            id: 293,

            country: { pl: 'Liban', en: 'Lebanon', es: 'Líbano' },

            place: { pl: 'Ruiny Baalbek', en: 'Baalbek Ruins', es: ' Ruinas de Baalbek' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Baalbek ma jedne z największych ruin rzymskich na świecie. Świątynia Jowisza jest szczególnie imponująca. To świetne miejsce dla miłośników historii.', en: 'Baalbek has some of the largest Roman ruins in the world. The Temple of Jupiter is especially impressive. It is a great place for history lovers.', es: 'Baalbek tiene algunas de las ruinas romanas más grandes del mundo. El Templo de Júpiter es especialmente impresionante. Es un gran lugar para los amantes de la historia.' }

        },

        {

            id: 294,

            country: { pl: 'Jordania', en: 'Jordan', es: 'Jordania' },

            place: { pl: 'Petra', en: 'Petra', es: ' Petra' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Petra to starożytne miasto wykute w czerwonej skale. Najsłynniejszy budynek, Al-Khazneh, przypomina świątynię. Aby dotrzeć do tego niezwykłego miejsca, trzeba przejść przez wąski wąwóz.', en: 'Petra is an ancient city carved into red rock. The most famous building, Al-Khazneh, looks like a temple. Visitors walk through a narrow canyon to enter this incredible place.', es: 'Petra es una ciudad antigua tallada en roca roja. El edificio más famoso, Al-Khazneh, parece un templo. Los visitantes caminan a través de un estrecho cañón para entrar en este increíble lugar.' }

        },

        {

            id: 295,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Tarasy ryżowe na Bali', en: 'Bali\’s Rice Terraces', es: ' Terrazas de Arroz de Bali' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Te zielone pola ryżowe są wykute na wzgórzach. Stanowią ważną część balijskiej kultury. Odwiedzający mogą spacerować po nich i obserwować pracujących rolników.', en: 'These green rice fields are carved into hillsides. They are an important part of Balinese culture. Visitors can walk through them and see farmers at work.', es: 'Estos campos de arroz verdes están tallados en las colinas. Son una parte importante de la cultura balinesa. Los visitantes pueden caminar por ellos y ver a los agricultores trabajando.' }

        },

        {

            id: 296,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Anchorage (Alaska)', en: 'Anchorage (Alaska)', es: ' Anchorage (Alaska)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Anchorage oferuje oszałamiające widoki na góry i lodowce. Odwiedzający mogą cieszyć się aktywnościami na świeżym powietrzu, takimi jak wędrówki, psie zaprzęgi lub podziwianie zorzy polarnej.', en: 'Anchorage offers stunning views of mountains and glaciers. Visitors can enjoy outdoor activities like hiking, dog sledding, or seeing the Northern Lights.', es: 'Anchorage ofrece impresionantes vistas de montañas y glaciares. Los visitantes pueden disfrutar de actividades al aire libre como senderismo, paseos en trineo tirado por perros o ver las Auroras Boreales.' }

        },

        {

            id: 297,

            country: { pl: 'Bahrajn', en: 'Bahrain', es: 'Baréin' },

            place: { pl: 'Wyspy Amwaj', en: 'Amwaj Islands', es: ' Islas Amwaj' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wyspy Amwaj to sztuczne wyspy z luksusowymi hotelami, plażami i marinami. Odwiedzający mogą korzystać z sportów wodnych, wykwintnej kuchni i relaksować się nad morzem.', en: 'The Amwaj Islands are man-made islands with luxury hotels, beaches, and marinas. Visitors can enjoy water sports, fine dining, and relaxing by the sea.', es: 'Las Islas Amwaj son islas artificiales con hoteles de lujo, playas y marinas. Los visitantes pueden disfrutar de deportes acuáticos, cenas de lujo y relajarse junto al mar.' }

        },

        {

            id: 298,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Wyspa Sipadan', en: 'Sipadan Island', es: ' Isla Sipadan' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Sipadan to jedno z najlepszych miejsc do nurkowania na świecie. Wyspa ma przejrzystą wodę, rafy koralowe i żółwie morskie. Aby chronić przyrodę, liczba odwiedzających jest ograniczona każdego dnia.', en: 'Sipadan is one of the best diving spots in the world. The island has clear water, coral reefs, and sea turtles. Only a limited number of visitors can go each day to protect the marine life.', es: 'Sipadan es uno de los mejores lugares de buceo del mundo. La isla tiene agua cristalina, arrecifes de coral y tortugas marinas. Solo un número limitado de visitantes puede ir cada día para proteger la vida marina.' }

        },

        {

            id: 299,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Wave Rock', en: 'Wave Rock', es: ' Roca Wave' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Wave Rock to naturalna formacja skalna przypominająca gigantyczną falę. Ma ponad 2,7 miliarda lat. Zwiedzający mogą spacerować u jej podstawy i robić zdjęcia tej niezwykłej formacji geologicznej.', en: 'Wave Rock is a natural rock formation shaped like a huge wave. It is over 2.7 billion years old. Visitors can walk along the base and take photos of this amazing geological wonder.', es: 'Wave Rock es una formación rocosa natural con forma de enorme ola. Tiene más de 2.7 mil millones de años. Los visitantes pueden caminar a lo largo de la base y tomar fotos de esta increíble maravilla geológica.' }

        },

        {

            id: 300,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'San Diego (Kalifornia)', en: 'San Diego (California)', es: ' San Diego (California)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'San Diego oferuje piękne plaże, słynne zoo i wspaniałą pogodę przez cały rok. Odwiedzający mogą korzystać z atrakcji, takich jak Balboa Park, lub wybrać się na rejs łodzią, aby zobaczyć lwy morskie.', en: 'San Diego offers beautiful beaches, a famous zoo, and amazing weather year-round. Visitors can enjoy attractions like Balboa Park or take a boat tour to see sea lions.', es: 'San Diego ofrece hermosas playas, un zoológico famoso y un clima increíble durante todo el año. Los visitantes pueden disfrutar de atracciones como el Balboa Park o hacer un recorrido en barco para ver leones marinos.' }

        },

        {

            id: 301,

            country: { pl: 'Jordania', en: 'Jordan', es: 'Jordania' },

            place: { pl: 'Morze Martwe', en: 'Dead Sea', es: ' Mar Muerto' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Morze Martwe jest tak słone, że ludzie unoszą się na jego powierzchni bez wysiłku. Błoto z tego regionu podobno dobrze wpływa na skórę. Jest to także najniżej położony punkt na Ziemi.', en: 'The Dead Sea is so salty that people float without effort. The mud is said to be good for the skin. It is also the lowest point on Earth.', es: 'El Mar Muerto es tan salado que las personas flotan sin esfuerzo. Se dice que el barro es bueno para la piel. También es el punto más bajo de la Tierra.' }

        },

        {

            id: 302,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Wodospad Gullfossa', en: 'Gullfoss Waterfall', es: ' Cascada Gullfoss' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Gullfoss to potężny wodospad opadający w dwóch etapach. Jest częścią Złotego Kręgu Islandii. W słoneczne dni często można zobaczyć tęczę w unoszącej się mgle.', en: 'Gullfoss is a powerful waterfall that drops in two stages. It is part of Iceland\’s Golden Circle route. On sunny days, you can often see rainbows in the mist.', es: 'Gullfoss es una cascada poderosa que cae en dos etapas. Es parte de la ruta del Círculo Dorado de Islandia. En los días soleados, a menudo se pueden ver arco iris en la niebla.' }

        },

        {

            id: 303,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'The Pinnacles', en: 'The Pinnacles', es: ' Los Pináculos' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Pinnacles to dziwaczne wapienne skały na pustyni. Wyglądają jak krajobraz z innej planety. Można spacerować wśród nich lub przejechać przez park o zachodzie słońca, aby zobaczyć niesamowite widoki.', en: 'The Pinnacles are strange limestone rocks in a desert. They look like something from another planet. Visitors can walk among them or drive through the park at sunset for amazing views.', es: 'The Pinnacles son extrañas formaciones de roca caliza en un desierto. Parecen algo de otro planeta. Los visitantes pueden caminar entre ellas o conducir por el parque al atardecer para disfrutar de unas vistas impresionantes.' }

        },

        {

            id: 304,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Cenoty Jukatanu', en: 'Cenotes of Yucatán', es: ' Cenotes de Yucatán' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Półwysep Jukatan pełen jest cenotów, naturalnych zapadlisk wypełnionych czystą wodą. Odwiedzający mogą pływać, nurkować lub zwiedzać podziemne jaskinie.', en: 'The Yucatán Peninsula is full of cenotes, natural sinkholes filled with clear water. Visitors can swim, dive, or explore underground caves.', es: 'La península de Yucatán está llena de cenotes, sumideros naturales llenos de agua cristalina. Los visitantes pueden nadar, bucear o explorar cuevas subterráneas.' }

        },

        {

            id: 305,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Błękitna Laguna', en: 'Blue Lagoon', es: ' Laguna Azul' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To geotermalne spa ma jasnoniebieską wodę bogatą w minerały. Zwiedzający mogą zrelaksować się w ciepłej wodzie, podziwiając czarne lawowe skały. Laguna słynie z właściwości leczniczych dla skóry.', en: 'This geothermal spa has bright blue water and is rich in minerals. Visitors can relax in the warm water while enjoying views of black lava rocks. The lagoon is famous for its skin-healing properties.', es: 'Este spa geotérmico tiene aguas de color azul brillante y es rico en minerales. Los visitantes pueden relajarse en el agua caliente mientras disfrutan de las vistas de las rocas de lava negra. La laguna es famosa por sus propiedades curativas para la piel.' }

        },

        {

            id: 306,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Bogota', en: 'Bogotá', es: ' Bogotá' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Bogotá to stolica Kolumbii, pełna historii i kultury. Stara dzielnica La Candelaria ma kolorowe domy i świetne muzea. Odwiedzający mogą wjechać kolejką linową na wzgórze Monserrate, by podziwiać widoki miasta.', en: 'Bogotá is Colombia\’s capital, full of history and culture. The old district, La Candelaria, has colorful houses and great museums. Visitors can take a cable car up Monserrate for city views.', es: 'Bogotá es la capital de Colombia, llena de historia y cultura. El distrito antiguo, La Candelaria, tiene casas coloridas y grandes museos. Los visitantes pueden tomar el teleférico hasta Monserrate para obtener vistas de la ciudad.' }

        },

        {

            id: 307,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Wielka Rafa Koralowa', en: 'Great Barrier Reef', es: ' Gran Barrera de Coral' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Wielka Rafa Koralowa to największy na świecie system raf koralowych. Żyją tam kolorowe ryby, żółwie morskie i rekiny. Odwiedzający mogą nurkować lub snorkelować, by odkrywać podwodny świat.', en: 'The Great Barrier Reef is the world\’s largest coral reef system. It is home to colorful fish, sea turtles, and sharks. Visitors can go snorkeling or diving to explore the underwater world.', es: 'La Gran Barrera de Coral es el sistema de arrecifes de coral más grande del mundo. Es hogar de peces coloridos, tortugas marinas y tiburones. Los visitantes pueden hacer snorkel o buceo para explorar el mundo submarino.' }

        },

        {

            id: 308,

            country: { pl: 'Tybet', en: 'Tibet', es: 'Tíbet' },

            place: { pl: 'Pałac Potala', en: 'Potala Palace', es: ' Palacio Potala' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Pałac Potala to wspaniała budowla zbudowana na górze. Była to rezydencja Dalajlamy. Turyści mogą zwiedzać skomplikowane sale, świątynie oraz podziwiać wspaniałe widoki na Lhasę.', en: 'Potala Palace is a stunning structure built on a mountain. It was the former residence of the Dalai Lama. Visitors can explore the intricate halls, temples, and stunning views of Lhasa.', es: 'El Palacio Potala es una impresionante estructura construida en una montaña. Fue la residencia del Dalai Lama. Los visitantes pueden explorar los intrincados pasillos, templos y disfrutar de vistas espectaculares de Lhasa.' }

        },

        {

            id: 309,

            country: { pl: 'Senegal', en: 'Senegal', es: 'Senegal' },

            place: { pl: 'Wyspa Gorée w Dakarze', en: 'Dakar\’s Gorée Island', es: ' Isla Gorée de Dakar' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Wyspa Gorée to miejsce na liście UNESCO z mroczną historią. Była niegdyś ważnym ośrodkiem handlu niewolnikami. Odwiedzający mogą zobaczyć Dom Niewolników i spacerować po jej spokojnych uliczkach.', en: 'Gorée Island is a UNESCO site with a dark history. It was once a major center of the transatlantic slave trade. Visitors can see the House of Slaves and walk its peaceful streets.', es: 'La Isla Gorée es un sitio de la UNESCO con una historia oscura. Fue una vez un centro importante del comercio transatlántico de esclavos. Los visitantes pueden ver la Casa de los Esclavos y caminar por sus tranquilas calles.' }

        },

        {

            id: 310,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Fort Amber w Dżajpurze', en: 'Jaipur\’s Amber Fort', es: ' Fuerte Amber de Jaipur' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta forteca na wzgórzu ma wspaniałe pałace, dziedzińce i sale pokryte lustrami. Odwiedzający mogą podziwiać zachwycające widoki na Jaipur. Dawniej słonie były używane do przewożenia gości na szczyt wzgórza.', en: 'This hilltop fort has grand palaces, courtyards, and mirror-covered halls. Visitors can enjoy stunning views of Jaipur. Elephants were once used to carry guests up the hill.', es: 'Este fuerte en la cima de una colina tiene palacios grandiosos, patios y salas cubiertas de espejos. Los visitantes pueden disfrutar de vistas impresionantes de Jaipur. En el pasado, se usaban elefantes para transportar a los invitados hasta la cima.' }

        },

        {

            id: 311,

            country: { pl: 'Brytyjskie Wyspy Dziewicze', en: 'British Virgin Islands', es: 'Islas Vírgenes Británicas' },

            place: { pl: 'Virgin Gorda', en: 'Virgin Gorda', es: ' Virgin Gorda' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Virgin Gorda jest znana z The Baths – plaży z gigantycznymi głazami i ukrytymi basenami. Wyspa oferuje spokojne zatoki, luksusowe kurorty i świetne warunki do nurkowania. To raj dla miłośników natury.', en: 'Virgin Gorda is known for The Baths, a beach with giant boulders and hidden pools. The island has quiet coves, luxury resorts, and great snorkeling. It is a peaceful paradise for nature lovers.', es: 'Virgin Gorda es conocida por The Baths, una playa con enormes rocas y piscinas ocultas. La isla tiene calas tranquilas, resorts de lujo y un excelente snorkeling. Es un paraíso pacífico para los amantes de la naturaleza.' }

        },

        {

            id: 312,

            country: { pl: 'Korea Południowa', en: 'South Korea', es: 'Corea del Sur' },

            place: { pl: 'Wyspa Dżedżu', en: 'Jeju Island', es: ' Isla Jeju' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wyspa Jeju to wulkaniczna wyspa znana z pięknych krajobrazów, w tym wodospadów i wulkanicznych kraterów. Jest to popularne miejsce do wędrówek i relaksu.', en: 'Jeju Island is a volcanic island known for its beautiful landscapes, including waterfalls and volcanic craters. It\’s a popular destination for hiking and relaxation.', es: 'La Isla Jeju es una isla volcánica conocida por sus hermosos paisajes, incluidas cascadas y cráteres volcánicos. Es un destino popular para hacer senderismo y relajarse.' }

        },

        {

            id: 313,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Aleja Reforma i Anioł Niepodległości', en: 'Reforma Avenue & Angel of Independence', es: ' Avenida Reforma y Ángel de la Independencia' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta szeroka aleja w Meksyku jest domem dla muzeów, parków i zabytków. Anioł Niepodległości to jeden z najbardziej charakterystycznych pomników w mieście.', en: 'This wide avenue in Mexico City is home to museums, parks, and landmarks. The Angel of Independence is one of the most iconic monuments in the city.', es: 'Esta amplia avenida en Ciudad de México alberga museos, parques y monumentos. El Ángel de la Independencia es uno de los monumentos más icónicos de la ciudad.' }

        },

        {

            id: 314,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Teotihuakan', en: 'Teotihuacan', es: ' Teotihuacán' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Teotihuacan to starożytne miasto z ogromnymi piramidami. Piramida Słońca jest największa i oferuje niesamowite widoki. Odwiedzający mogą spacerować Aleją Umarłych i zwiedzać ruiny.', en: 'Teotihuacan is an ancient city with massive pyramids. The Pyramid of the Sun is the largest and offers amazing views. Visitors can walk along the Avenue of the Dead and explore the ruins.', es: 'Teotihuacán es una ciudad antigua con enormes pirámides. La Pirámide del Sol es la más grande y ofrece vistas asombrosas. Los visitantes pueden caminar por la Avenida de los Muertos y explorar las ruinas.' }

        },

        {

            id: 315,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Świątynia Meenakshi (Madurai)', en: 'Meenakshi Temple (Madurai)', es: ' Templo Meenakshi (Madurai)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta świątynia słynie z kolorowych wież pokrytych tysiącami rzeźb. Jest poświęcona bogini Meenakshi i przyciąga wielu pielgrzymów.', en: 'This temple is famous for its colorful towers covered in thousands of statues. It is dedicated to the goddess Meenakshi and attracts many pilgrims.', es: 'Este templo es famoso por sus torres coloridas cubiertas de miles de estatuas. Está dedicado a la diosa Meenakshi y atrae a muchos peregrinos.' }

        },

        {

            id: 316,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Góra Fuji', en: 'Mount Fuji', es: ' Monte Fuji' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Mount Fuji to najsłynniejszy wulkan Japonii. Wielu turystów wspina się na niego latem, a inni podziwiają widok z pobliskich jezior. Szczególnie pięknie wygląda wśród kwitnących wiśni.', en: 'Mount Fuji is Japan\’s most famous volcano. Many visitors climb it in summer, while others enjoy the view from nearby lakes. It looks especially beautiful with cherry blossoms.', es: 'El Monte Fuji es el volcán más famoso de Japón. Muchos visitantes lo suben en verano, mientras que otros disfrutan de la vista desde los lagos cercanos. Se ve especialmente hermoso con las flores de cerezo.' }

        },

        {

            id: 317,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Medellín', en: 'Medellín', es: ' Medellín' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Medellín to miasto innowacji i wspaniałej pogody. Kiedyś znane z trudnej przeszłości, dziś pełne jest sztuki, parków i kolejek linowych oferujących piękne widoki. Ludzie są tu przyjaźni i gościnni.', en: 'Medellín is a city of innovation and great weather. Once known for its troubled past, it is now full of art, parks, and cable cars offering great views. The people are friendly and welcoming.', es: 'Medellín es una ciudad de innovación y buen clima. Antes conocida por su pasado problemático, ahora está llena de arte, parques y teleféricos que ofrecen vistas increíbles. La gente es amigable y acogedora.' }

        },

        {

            id: 318,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Złota Świątynia (Amritsar)', en: 'Golden Temple (Amritsar)', es: ' Templo Dorado (Amritsar)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta święta świątynia sikhijska jest pokryta złotem i otoczona spokojnym jeziorem. Odwiedzający mogą skosztować darmowych posiłków w dużej jadalni. Świątynia pięknie lśni nocą.', en: 'This sacred Sikh temple is covered in gold and surrounded by a peaceful lake. Visitors can enjoy free meals in its large dining hall. The temple shines beautifully at night.', es: 'Este templo sikh sagrado está cubierto de oro y rodeado de un lago tranquilo. Los visitantes pueden disfrutar de comidas gratuitas en su gran comedor. El templo brilla hermosamente por la noche.' }

        },

        {

            id: 319,

            country: { pl: 'Republika Południowej Afryki', en: 'South Africa', es: 'Sudáfrica' },

            place: { pl: 'Park Narodowy Krugera', en: 'Kruger National Park', es: ' Parque Nacional Kruger' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: {
                pl: 'Park Narodowy Krugera to jeden z największych rezerwatów przyrody w Afryce. Mieszka tam "Wielka Piątka", a zwiedzający mogą uczestniczyć w safari, gdzie będą mogli podziwiać dzikie zwierzęta w ich naturalnym środowisku.', en: 'Kruger National Park is one of Africa\'s largest game reserves.It is home to the "Big Five" and offers safaris where visitors can spot wildlife in their natural habitat.', es: 'El Parque Nacional Kruger es una de las mayores reservas de caza de África.Es hogar de los "Cinco Grandes" y ofrece safaris donde los visitantes pueden observar la fauna en su hábitat natural.'
            }

        },

        {

            id: 320,

            country: { pl: 'Katar', en: 'Qatar', es: 'Catar' },

            place: { pl: 'Perła-Katar', en: 'The Pearl-Qatar', es: ' The Pearl-Qatar' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'The Pearl to luksusowa sztuczna wyspa w Dosze. Ma eleganckie sklepy, restauracje i marinę. Odwiedzający mogą cieszyć się widokiem na wybrzeże i odkrywać stylowe dzielnice.', en: 'The Pearl is a luxurious man-made island in Doha. It has fancy shops, restaurants, and a marina. Visitors can enjoy waterfront views and explore its stylish neighborhoods.', es: 'The Pearl es una isla de lujo creada por el hombre en Doha. Tiene tiendas elegantes, restaurantes y un puerto deportivo. Los visitantes pueden disfrutar de vistas frente al mar y explorar sus barrios elegantes.' }

        },

        {

            id: 321,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Tadż Mahal', en: 'Taj Mahal', es: ' Taj Mahal' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Tadż Mahal to marmurowe mauzoleum i jeden z siedmiu cudów świata. Został zbudowany przez cesarza Shah Jahan dla jego żony. Najlepszy czas na wizytę to wschód słońca.', en: 'The Taj Mahal is a white marble mausoleum and one of the Seven Wonders of the World. It was built by Emperor Shah Jahan for his wife. The best time to visit is at sunrise.', es: 'El Taj Mahal es un mausoleo de mármol blanco y una de las Siete Maravillas del Mundo. Fue construido por el emperador Shah Jahan para su esposa. El mejor momento para visitarlo es al amanecer.' }

        },

        {

            id: 322,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Huanglong', en: 'Huanglong', es: ' Huanglong' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Huanglong słynie z kolorowych basenów wodnych. Obszar ten ma również wodospady, lasy i ośnieżone szczyty. To świetne miejsce na wędrówki i robienie niesamowitych zdjęć.', en: 'Huanglong is famous for its colorful pools of water. The area also has waterfalls, forests, and snow-capped peaks. It is a great place for hiking and taking stunning photos.', es: 'Huanglong es famoso por sus coloridas piscinas de agua. El área también tiene cascadas, bosques y picos cubiertos de nieve. Es un gran lugar para hacer senderismo y tomar fotos impresionantes.' }

        },

        {

            id: 323,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Teatr Aspendos', en: 'Aspendos Theater', es: ' Teatro de Aspendos' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Teatr Aspendos to jeden z najlepiej zachowanych rzymskich teatrów. Nadal odbywają się w nim przedstawienia, oferując niezapomniane wrażenia. Akustyka i znaczenie historyczne sprawiają, że jest to miejsce, które warto odwiedzić.', en: 'Aspendos Theater is one of the best-preserved Roman theaters. It still hosts performances today, offering an unforgettable experience. The acoustics and historical significance make it a must-visit.', es: 'El Teatro de Aspendo es uno de los teatros romanos mejor conservados. Todavía alberga representaciones hoy en día, ofreciendo una experiencia inolvidable. La acústica y la importancia histórica lo hacen un lugar imprescindible para visitar.' }

        },

        {

            id: 324,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Most Ruyi', en: 'Ruyi Bridge', es: ' Puente Ruyi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Most Ruyi to spektakularna kładka w Chinach, znana ze swojego unikalnego, zakrzywionego kształtu przypominającego unoszącą się wstążkę. Rozciąga się nad głębokim kanionem, oferując zapierające dech w piersiach widoki. Spacer po jego szklanych fragmentach to ekscytujące (lub przerażające) przeżycie dla odwiedzających.', en: 'Ruyi Bridge is a spectacular footbridge in China, known for its unique curved design that looks like a floating ribbon. It stretches across a deep canyon, offering breathtaking views. Walking on its glass sections is an exciting (or terrifying) experience for visitors.', es: 'El Puente Ruyi es un espectacular puente peatonal en China, conocido por su diseño curvado único que parece una cinta flotante. Se extiende sobre un profundo cañón, ofreciendo vistas impresionantes. Caminar sobre sus secciones de vidrio es una experiencia emocionante (o aterradora) para los visitantes.' }

        },

        {

            id: 325,

            country: { pl: 'Nowa Zelandia', en: 'New Zealand', es: 'Nueva Zelanda' },

            place: { pl: 'Hobbiton', en: 'Hobbiton', es: ' Hobbiton' },

            continent: { pl: 'Oceania', en: 'Oceania', es: 'Oceanía' },

            description: { pl: 'Hobbiton to rzeczywista sceneria z filmu Władca Pierścieni. Turyści mogą przejść przez zielone wzgórza, zobaczyć malutkie domki Hobbitów, a nawet napić się w Green Dragon Inn.', en: 'Hobbiton is the real-life movie set from The Lord of the Rings. Visitors can walk through the green hills, see the tiny Hobbit houses, and even have a drink at the Green Dragon Inn.', es: 'Hobbiton es el set de película real de El Señor de los Anillos. Los visitantes pueden caminar por las colinas verdes, ver las pequeñas casas hobbit e incluso tomar una bebida en la taberna Green Dragon.' }

        },

        {

            id: 326,

            country: { pl: 'Brazylia', en: 'Brazil', es: 'Brasil' },

            place: { pl: 'Wodospady Iguaçu', en: 'Iguaçu Falls', es: ' Cataratas de Iguaçu' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Te wodospady są większe niż Niagara. Rozciągają się na granicy Brazylii i Argentyny, a ścieżki widokowe oferują zapierające dech w piersiach widoki.', en: 'These waterfalls are bigger than Niagara Falls. They stretch across Brazil and Argentina, with walkways offering breathtaking views.', es: 'Estas cascadas son más grandes que las Cataratas del Niágara. Se extienden a través de Brasil y Argentina, con pasarelas que ofrecen vistas impresionantes.' }

        },

        {

            id: 327,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Ogrody Botaniczne w Singapurze', en: 'Singapore Botanic Gardens', es: ' Jardines Botánicos de Singapur' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ogród Botaniczny Singapuru to obiekt wpisany na listę UNESCO, z bujnymi terenami zielonymi, tropikalnymi roślinami i spokojnymi jeziorami. To idealne miejsce na spacer lub piknik.', en: 'The Singapore Botanic Gardens is a UNESCO World Heritage Site with lush green spaces, tropical plants, and serene lakes. It\’s a peaceful spot for a walk or picnic.', es: 'Los Jardines Botánicos de Singapur son un sitio Patrimonio de la Humanidad de la UNESCO con espacios verdes exuberantes, plantas tropicales y lagos serenos. Es un lugar tranquilo para caminar o hacer un picnic.' }

        },

        {

            id: 328,

            country: { pl: 'Egipt', en: 'Egypt', es: 'Egipto' },

            place: { pl: 'Piramidy w Gizie i Sfinks', en: 'Pyramids of Giza & Sphinx', es: ' Pirámides de Giza y Esfinge' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Te XII-wieczne kościoły zostały wykute w litej skale. Do dziś są miejscem kultu. Zwiedzający mogą odkrywać tunele i przejścia je łączące.', en: 'The pyramids are Egypt\’s most famous landmarks. Built over 4,500 years ago, they still amaze visitors. The nearby Sphinx, with a lion\’s body and human face, is a mystery of ancient history.', es: 'Las pirámides son los monumentos más famosos de Egipto. Construidas hace más de 4,500 años, siguen asombrando a los visitantes. La cercana Esfinge, con cuerpo de león y cara humana, es un misterio de la historia antigua.' }

        },

        {

            id: 329,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Delta Mekongu', en: 'Mekong Delta', es: ' Delta del Mekong' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Delta Mekongu to sieć rzek, bagien i wysp w południowym Wietnamie. Słynie z pływających targów, tradycyjnych wiosek i unikalnego stylu życia wzdłuż wody.', en: 'The Mekong Delta is a network of rivers, swamps, and islands in southern Vietnam. It is known for its floating markets, traditional villages, and unique way of life along the water.', es: 'El Delta del Mekong es una red de ríos, pantanos e islas en el sur de Vietnam. Es conocido por sus mercados flotantes, aldeas tradicionales y la forma de vida única a lo largo del agua.' }

        },

        {

            id: 330,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Dallas (Teksas)', en: 'Dallas (Texas)', es: ' Dallas (Texas)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Dallas to nowoczesne miasto z bogatą historią. Odwiedzający mogą zwiedzać Muzeum Szóstej Kondygnacji, dowiedzieć się o zamachu na JFK lub cieszyć się zakupami w modnej dzielnicy centrum miasta.', en: 'Dallas is a modern city with a rich history. Visitors can explore the Sixth Floor Museum, learn about the JFK assassination, or enjoy shopping in the trendy downtown area.', es: 'Dallas es una ciudad moderna con una rica historia. Los visitantes pueden explorar el Museo del Sexto Piso, aprender sobre el asesinato de JFK o disfrutar de compras en el área de moda del centro.' }

        },

        {

            id: 331,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Isla Mujeres', en: 'Isla Mujeres', es: ' Isla Mujeres' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Isla Mujeres to mała wyspa blisko Cancún z pięknymi plażami. Jest idealna do nurkowania, relaksu i zwiedzania na golfowym wózku.', en: 'Isla Mujeres is a small island near Cancún with stunning beaches. It is perfect for snorkeling, relaxing, and exploring by golf cart.', es: 'Isla Mujeres es una pequeña isla cerca de Cancún con playas impresionantes. Es perfecta para hacer snorkel, relajarse y explorar en carrito de golf.' }

        },

        {

            id: 332,

            country: { pl: 'Gwatemala', en: 'Guatemala', es: 'Guatemala' },

            place: { pl: 'Jezioro Atitlán', en: 'Lake Atitlán', es: ' Lago Atitlán' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'To wulkaniczne jezioro otoczone jest górami i wioskami. Zwiedzający mogą popłynąć łodzią, wędrować po okolicy lub poznać lokalne społeczności Majów. Widoki są oszałamiające.', en: 'This volcanic lake is surrounded by mountains and villages. Visitors can take boat rides, hike, or meet local Mayan communities. The views are stunning.', es: 'Este lago volcánico está rodeado de montañas y aldeas. Los visitantes pueden dar paseos en barco, hacer senderismo o conocer a las comunidades mayas locales. Las vistas son impresionantes.' }

        },

        {

            id: 333,

            country: { pl: 'Gwatemala', en: 'Guatemala', es: 'Guatemala' },

            place: { pl: 'Tikal', en: 'Tikal', es: ' Tikal' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Tikal to starożytne miasto Majów ukryte w dżungli. Ma wysokie piramidy i tajemnicze ruiny. Zwiedzający mogą wspiąć się na świątynie i posłuchać odgłosów wyjców w koronach drzew.', en: 'Tikal is an ancient Mayan city hidden in the jungle. It has tall pyramids and mysterious ruins. Visitors can climb the temples and hear howler monkeys in the trees.', es: 'Tikal es una antigua ciudad maya escondida en la selva. Tiene pirámides altas y ruinas misteriosas. Los visitantes pueden subir a los templos y escuchar a los monos aulladores en los árboles.' }

        },

        {

            id: 334,

            country: { pl: 'Chile', en: 'Chile', es: 'Chile' },

            place: { pl: 'Santiago', en: 'Santiago', es: ' Santiago' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Santiago to tętniące życiem miasto z górami w tle. Ma muzea, targi i świetną scenę kulinarną. Odwiedzający mogą podziwiać widoki z wzgórza San Cristóbal lub zwiedzać zabytkowe dzielnice.', en: 'Santiago is a lively city with mountains in the background. It has museums, markets, and a great food scene. Visitors can enjoy the view from San Cristóbal Hill or explore historic neighborhoods.', es: 'Santiago es una ciudad animada con montañas de fondo. Tiene museos, mercados y una excelente escena gastronómica. Los visitantes pueden disfrutar de la vista desde el Cerro San Cristóbal o explorar barrios históricos.' }

        },

        {

            id: 335,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Plaża Railay', en: 'Railay Beach', es: ' Playa Railay' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Plaża Railay słynie z białego piasku i krystalicznie czystej wody. To doskonałe miejsce do wspinaczki skałkowej i podziwiania malowniczych widoków. Otaczające ją klify sprawiają, że jest to zachwycające miejsce do odwiedzenia.', en: 'Railay Beach is famous for its white sand and crystal-clear water. It\’s a great spot for rock climbing and enjoying the scenic beauty. The surrounding cliffs make it a stunning place to visit.', es: 'La Playa Railay es famosa por su arena blanca y aguas cristalinas. Es un excelente lugar para la escalada en roca y disfrutar de la belleza escénica. Los acantilados circundantes hacen que sea un lugar impresionante para visitar.' }

        },

        {

            id: 336,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Armia Terakotowa (Xi’an)', en: 'Terracotta Army (Xi’an)', es: ' Ejército de Terracota (Xi’an)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Terakotowa Armia to kolekcja tysięcy glinianych żołnierzy naturalnej wielkości. Każdy ma unikalną twarz. Zostały stworzone, aby chronić pierwszego cesarza Chin w zaświatach.', en: 'The Terracotta Army is a collection of thousands of life-sized clay soldiers. Each one has a unique face. They were built to protect China\’s first emperor in the afterlife.', es: 'El Ejército de Terracota es una colección de miles de soldados de arcilla a tamaño real. Cada uno tiene un rostro único. Fueron construidos para proteger al primer emperador de China en la vida después de la muerte.' }

        },

        {

            id: 337,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Borobudur', en: 'Borobudur', es: ' Borobudur' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Borobudur to największa buddyjska świątynia na świecie. Ma piękne kamienne rzeźby i oferuje niesamowite widoki o wschodzie słońca. Odwiedzający mogą wspiąć się na szczyt, by zaznać chwili spokoju.', en: 'Borobudur is the largest Buddhist temple in the world. It has beautiful stone carvings and offers great views at sunrise. Visitors can climb to the top for a peaceful experience.', es: 'Borobudur es el templo budista más grande del mundo. Tiene hermosos tallados en piedra y ofrece vistas espectaculares al amanecer. Los visitantes pueden subir hasta la cima para una experiencia tranquila.' }

        },

        {

            id: 338,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Burdż Chalifa (Dubaj)', en: 'Burj Khalifa (Dubai)', es: ' Burj Khalifa (Dubái)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Burj Khalifa to najwyższy budynek na świecie. Turyści mogą udać się na taras widokowy, skąd roztaczają się zapierające dech w piersiach widoki na Dubaj. Budynek oświetla się nocą, tworząc spektakularny widok.', en: 'The Burj Khalifa is the tallest building in the world. Visitors can go to the observation deck for stunning views of Dubai. The building lights up at night, creating a spectacular sight.', es: 'El Burj Khalifa es el edificio más alto del mundo. Los visitantes pueden ir al mirador para disfrutar de vistas impresionantes de Dubái. El edificio se ilumina por la noche, creando una vista espectacular.' }

        },

        {

            id: 339,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Zatoka Wineglass', en: 'Wineglass Bay', es: ' Bahía Wineglass' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Wineglass Bay to piękna, zakrzywiona plaża na Tasmanii. Piasek jest biały, a woda jaskrawo niebieska. Najlepszy widok roztacza się z punktu widokowego po krótkiej wędrówce.', en: 'Wineglass Bay is a beautiful curved beach in Tasmania. The sand is white, and the water is bright blue. The best way to see it is from a lookout after a short hike.', es: 'Wineglass Bay es una hermosa playa curva en Tasmania. La arena es blanca y el agua es de un azul brillante. La mejor manera de verla es desde un mirador tras una corta caminata.' }

        },

        {

            id: 340,

            country: { pl: 'Argentyna', en: 'Argentina', es: 'Argentina' },

            place: { pl: 'Wodospady Iguazu', en: 'Iguazú Falls', es: ' Cataratas del Iguazú' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Wodospady Iguazú to jeden z największych systemów wodospadów na świecie. Potężne kaskady otoczone są bujnym lasem deszczowym. Zwiedzający mogą spacerować po szlakach lub podpłynąć łodzią blisko wodospadów.', en: 'Iguazú Falls is one of the largest waterfall systems in the world. The powerful waterfalls are surrounded by lush rainforest. Visitors can walk along trails and even take a boat close to the falls.', es: 'Las Cataratas del Iguazú son uno de los sistemas de cascadas más grandes del mundo. Las poderosas cascadas están rodeadas de selva tropical. Los visitantes pueden caminar por senderos e incluso tomar un barco cerca de las cataratas.' }

        },

        {

            id: 341,

            country: { pl: 'Bośnia i Hercegowina', en: 'Bosnia and Herzegovina', es: 'Bosnia y Herzegovina' },

            place: { pl: 'Wodospady Kravica', en: 'Kravica Waterfalls', es: ' Cataratas Kravica' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te wodospady przypominają miniaturową Niagarę. Odwiedzający mogą pływać w krystalicznej wodzie i cieszyć się spokojnym, naturalnym otoczeniem.', en: 'These waterfalls are like a mini Niagara Falls in Bosnia. Visitors can swim in the clear water and enjoy the peaceful natural surroundings.', es: 'Estas cascadas son como una mini Cataratas del Niágara en Bosnia. Los visitantes pueden nadar en el agua clara y disfrutar de la paz natural que las rodea.' }

        },

        {

            id: 342,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Park Narodowy Redwood', en: 'Redwood National Park', es: ' Parque Nacional Redwood' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Park Narodowy Redwood to dom dla najwyższych drzew na świecie. Odwiedzający mogą spacerować po starożytnych lasach, biwakować i cieszyć się spokojnym otoczeniem.', en: 'Redwood National Park is home to the tallest trees in the world. Visitors can walk through ancient forests, camp, and enjoy the peaceful surroundings.', es: 'El Parque Nacional Redwood alberga los árboles más altos del mundo. Los visitantes pueden caminar por antiguos bosques, acampar y disfrutar de la tranquilidad del entorno.' }

        },

        {

            id: 343,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Orlando (Floryda)', en: 'Orlando (Florida)', es: ' Orlando (Florida)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Orlando jest znane z parków rozrywki, takich jak Walt Disney World i Universal Studios. To miejsce przyjazne rodzinom, oferujące zabawę dla wszystkich grup wiekowych.', en: 'Orlando is known for its theme parks like Walt Disney World and Universal Studios. It\’s a family-friendly destination with fun for all ages.', es: 'Orlando es conocida por sus parques temáticos como Walt Disney World y Universal Studios. Es un destino familiar con diversión para todas las edades.' }

        },

        {

            id: 344,

            country: { pl: 'Wietnam', en: 'Vietnam', es: 'Vietnam' },

            place: { pl: 'Zatoka Ha Long', en: 'Halong Bay', es: ' Bahía de Ha Long' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Zatoka Ha Long słynie z szmaragdowych wód i wznoszących się wapiennych wysp. Odwiedzający mogą wybrać się na rejs łodzią przez zatokę, zwiedzać jaskinie i cieszyć się naturalnym pięknem tego miejsca, wpisanego na listę światowego dziedzictwa UNESCO.', en: 'Halong Bay is famous for its emerald waters and towering limestone islands. Visitors can take boat trips through the bay, explore caves, and enjoy the natural beauty of this UNESCO World Heritage site.', es: 'La Bahía de Halong es famosa por sus aguas esmeraldas y sus imponentes islas de piedra caliza. Los visitantes pueden hacer paseos en bote por la bahía, explorar cuevas y disfrutar de la belleza natural de este sitio Patrimonio de la Humanidad de la UNESCO.' }

        },

        {

            id: 345,

            country: { pl: 'Arabia Saudyjska', en: 'Saudi Arabia', es: 'Arabia Saudita' },

            place: { pl: 'Kraniec Świata (Riyad)', en: 'Edge of the World (Riyadh)', es: ' El Fin del Mundo (Riad)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta naturalna formacja skalna oferuje niesamowite widoki na pustynię. Klify opadają ostro, tworząc dramatyczne wrażenie "krawędzi". Odwiedzający mogą wędrować i robić niesamowite zdjęcia.', en: 'This natural rock formation offers amazing views over the desert. The cliffs drop down sharply, creating a dramatic "edge" feeling. Visitors can hike and take stunning photos.', es: 'Esta formación rocosa natural ofrece vistas sorprendentes del desierto. Los acantilados caen abruptamente, creando una sensación dramática de "borde". Los visitantes pueden hacer senderismo y tomar fotos impresionantes.' }

        },

        {

            id: 346,

            country: { pl: 'Peru', en: 'Peru', es: 'Perú' },

            place: { pl: 'Cuzco', en: 'Cusco', es: ' Cusco' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Cusco było kiedyś stolicą Imperium Inków. Jego wąskie uliczki, kolonialne budynki i ruiny Inków sprawiają, że jest to fascynujące miasto. To także brama do Machu Picchu.', en: 'Cusco was once the capital of the Inca Empire. Its narrow streets, colonial buildings, and Inca ruins make it a fascinating city. It is also the gateway to Machu Picchu.', es: 'Cuzco fue alguna vez la capital del Imperio Inca. Sus calles estrechas, edificios coloniales y ruinas incas lo convierten en una ciudad fascinante. También es la puerta de entrada a Machu Picchu.' }

        },

        {

            id: 347,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Świątynia Tanah Lot', en: 'Tanah Lot Temple', es: ' Templo Tanah Lot' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta hinduistyczna świątynia stoi na skale w morzu. Podczas przypływu wygląda, jakby unosiła się na wodzie. To jeden z najsłynniejszych zabytków Bali.', en: 'This Hindu temple sits on a rock in the sea. At high tide, it looks like it is floating. It is one of Bali\’s most famous landmarks.', es: 'Este templo hindú se encuentra sobre una roca en el mar. En marea alta, parece que está flotando. Es uno de los puntos más famosos de Bali.' }

        },

        {

            id: 348,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Houston (Teksas)', en: 'Houston (Texas)', es: ' Houston (Texas)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Houston to dynamiczne miasto, znane z centrum kosmicznego, muzeów i różnorodnej sceny kulinarnej. Odwiedzający mogą dowiedzieć się o badaniach kosmicznych i zwiedzać liczne atrakcje kulturalne.', en: 'Houston is a dynamic city known for its space center, museums, and diverse food scene. Visitors can learn about space exploration and explore the many cultural attractions.', es: 'Houston es una ciudad dinámica conocida por su centro espacial, museos y escena gastronómica diversa. Los visitantes pueden aprender sobre la exploración espacial y explorar muchas atracciones culturales.' }

        },

        {

            id: 349,

            country: { pl: 'Filipiny', en: 'Philippines', es: 'Filipinas' },

            place: { pl: 'Zaczarowana Rzeka', en: 'The Enchanted River', es: ' Río Encantado' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta głęboko niebieska rzeka w Mindanao wygląda magicznie. Woda jest tak czysta, że widać pływające ryby. Ludzie wierzą, że nie ma dna, co dodaje jej tajemniczości.', en: 'This deep blue river in Mindanao looks magical. The water is so clear that you can see the fish swimming. People believe it has no bottom, adding to its mystery.', es: 'Este río de color azul profundo en Mindanao parece mágico. El agua es tan clara que se pueden ver los peces nadando. La gente cree que no tiene fondo, lo que añade misterio.' }

        },

        {

            id: 350,

            country: { pl: 'Egipt', en: 'Egypt', es: 'Egipto' },

            place: { pl: 'Świątynia Luksorska', en: 'Luxor Temple', es: ' Templo de Luxor' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Levi to najlepszy ośrodek narciarski w Finlandii, oferujący trasy dla wszystkich poziomów. Zimą można tu także jeździć skuterem śnieżnym, odwiedzić husky safari i podziwiać zorzę polarną.', en: 'Luxor Temple is an ancient Egyptian temple in the heart of the city. It is beautifully lit at night. Large statues and tall columns make it a fascinating place to explore.', es: 'El Templo de Luxor es un antiguo templo egipcio en el corazón de la ciudad. Está bellamente iluminado por la noche. Sus grandes estatuas y columnas altas lo convierten en un lugar fascinante para explorar.' }

        },

        {

            id: 351,

            country: { pl: 'Belize', en: 'Belize', es: 'Belice' },

            place: { pl: 'Caye Caulker', en: 'Caye Caulker', es: ' Cayo Caulker' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta mała wyspa ma luźną, karaibską atmosferę. Nie ma tu samochodów, tylko piaszczyste uliczki, świetne miejsca do nurkowania i przyjaźni mieszkańcy. Turyści uwielbiają jej motto: „Go slow” („Zwolnij”).', en: 'This small island has a relaxed Caribbean vibe. There are no cars, just sandy streets, great snorkeling, and friendly locals. Visitors love the island\’s motto: “Go slow.”', es: 'Esta pequeña isla tiene una vibra caribeña relajada. No hay coches, solo calles de arena, un excelente lugar para hacer snorkel y locales amigables. A los visitantes les encanta el lema de la isla: “Ve despacio”.' }

        },

        {

            id: 352,

            country: { pl: 'Brytyjskie Wyspy Dziewicze', en: 'British Virgin Islands', es: 'Islas Vírgenes Británicas' },

            place: { pl: 'Tortola', en: 'Tortola', es: ' Tórtola' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Tortola to największa wyspa Brytyjskich Wysp Dziewiczych. Znajdują się tu piękne plaże, doskonałe miejsca do żeglowania i tętniące życiem targi. Odwiedzający mogą zwiedzać stare plantacje trzciny cukrowej lub odpoczywać nad morzem.', en: 'Tortola is the largest island in the British Virgin Islands. It has beautiful beaches, great sailing spots, and lively markets. Visitors can explore old sugar plantations or relax by the sea.', es: 'Tortola es la isla más grande de las Islas Vírgenes Británicas. Tiene hermosas playas, excelentes lugares para navegar y mercados animados. Los visitantes pueden explorar antiguas plantaciones de azúcar o relajarse junto al mar.' }

        },

        {

            id: 353,

            country: { pl: 'Islandia', en: 'Iceland', es: 'Islandia' },

            place: { pl: 'Park Narodowy Thingvellir', en: 'Thingvellir National Park', es: ' Parque Nacional Thingvellir' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten park wpisany na listę UNESCO to miejsce spotkania dwóch płyt tektonicznych. Ma niesamowite krajobrazy, szlaki turystyczne i historyczne znaczenie. To także miejsce pierwszego islandzkiego parlamentu.', en: 'This UNESCO-listed park is where two tectonic plates meet. It has stunning landscapes, hiking trails, and historic significance. It\’s also the site of Iceland\’s first parliament.', es: 'Este parque, inscrito en la UNESCO, es donde se encuentran dos placas tectónicas. Tiene paisajes impresionantes, senderos de senderismo y una gran importancia histórica. También es el sitio del primer parlamento de Islandia.' }

        },

        {

            id: 354,

            country: { pl: 'Dominikana', en: 'Dominican Republic', es: 'República Dominicana' },

            place: { pl: 'Punta Cana', en: 'Punta Cana', es: ' Punta Cana' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Cuenca to urocze miasto z brukowanymi uliczkami i zabytkowymi kościołami. Znane jest z relaksującej atmosfery i pięknych kolonialnych budynków.', en: 'Punta Cana is famous for its white sandy beaches and all-inclusive resorts. Visitors can relax by the sea, try water sports, or take a catamaran tour to nearby islands.', es: 'Punta Cana es famosa por sus playas de arena blanca y resorts todo incluido. Los visitantes pueden relajarse junto al mar, probar deportes acuáticos o tomar un tour en catamarán a islas cercanas.' }

        },

        {

            id: 355,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Corniche w Abu Zabi', en: 'Abu Dhabi Corniche', es: ' Corniche de Abu Dabi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: {
                pl: 'Corniche w Abu Zabi to piękny obszar nadmorski z ścieżkami spacerowymi, plażami i parkami. To doskonałe miejsce na relaks, podziwianie widoków lub przejażdżkę rowerową wzdłuż wybrzeża.', en: 'The Corniche in Abu Dhabi is a beautiful waterfront area with walking paths, beaches, and parks. It\'s a great spot to relax, enjoy the view, or take a bike ride along the coast.', es: 'La Corniche en Abu Dabi es una hermosa área junto al agua con senderos para caminar, playas y parques.Es un gran lugar para relajarse, disfrutar de la vista o dar un paseo en bicicleta a lo largo de la costa.'
            }

        },

        {

            id: 356,

            country: { pl: 'Mauritius', en: 'Mauritius', es: 'Mauricio' },

            place: { pl: 'Plaże Mauritiusa', en: 'Mauritius Beaches', es: ' Playas de Mauricio' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Mauritius ma jedne z najpiękniejszych plaż na świecie. Biały piasek i turkusowa woda sprawiają, że są one idealne do relaksu. Odwiedzający mogą pływać, nurkować lub po prostu cieszyć się tropikalnym rajem.', en: 'Mauritius has some of the most beautiful beaches in the world. The white sand and turquoise water make them perfect for relaxation. Visitors can swim, snorkel, or just enjoy the tropical paradise.', es: 'Mauricio tiene algunas de las playas más hermosas del mundo. La arena blanca y el agua turquesa las hacen perfectas para relajarse. Los visitantes pueden nadar, hacer snorkel o simplemente disfrutar del paraíso tropical.' }

        },

        {

            id: 357,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Raja Ampat', en: 'Raja Ampat', es: ' Raja Ampat' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta grupa wysp to raj dla nurków. Rafy koralowe są pełne kolorowych ryb i żółwi morskich. To jedno z najbardziej bioróżnorodnych miejsc w oceanie.', en: 'This group of islands is a paradise for divers. The coral reefs are full of colorful fish and sea turtles. It is one of the most biodiverse places in the ocean.', es: 'Este grupo de islas es un paraíso para los buceadores. Los arrecifes de coral están llenos de peces coloridos y tortugas marinas. Es uno de los lugares más biodiversos del océano.' }

        },

        {

            id: 358,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Park Narodowy Banff', en: 'Banff National Park', es: ' Parque Nacional Banff' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Banff to oszałamiający park narodowy z górami, jeziorami i dziką przyrodą. Odwiedzający mogą wędrować, jeździć na nartach lub relaksować się w gorących źródłach. Miasto Banff oferuje świetne sklepy i restauracje.', en: 'Banff is a stunning national park with mountains, lakes, and wildlife. Visitors can hike, ski, or relax in hot springs. The town of Banff offers great shops and restaurants.', es: 'Banff es un impresionante parque nacional con montañas, lagos y vida silvestre. Los visitantes pueden hacer senderismo, esquiar o relajarse en aguas termales. La ciudad de Banff ofrece excelentes tiendas y restaurantes.' }

        },

        {

            id: 359,

            country: { pl: 'Kajmany', en: 'Cayman Islands', es: 'Islas Caimán' },

            place: { pl: 'Plaża Seven Mile', en: 'Seven Mile Beach', es: ' Playa Seven Mile' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta długa, biała, piaszczysta plaża jest jedną z najlepszych na Karaibach. Woda jest spokojna i przejrzysta, idealna do pływania i nurkowania. To doskonałe miejsce na relaks w słońcu.', en: 'This long, white-sand beach is one of the best in the Caribbean. The water is calm and clear, perfect for swimming and snorkeling. It is a great place to relax under the sun.', es: 'Esta larga playa de arena blanca es una de las mejores del Caribe. El agua es tranquila y clara, perfecta para nadar y hacer snorkel. Es un gran lugar para relajarse al sol.' }

        },

        {

            id: 360,

            country: { pl: 'Tanzania', en: 'Tanzania', es: 'Tanzania' },

            place: { pl: 'Park Narodowy Serengeti', en: 'Serengeti National Park', es: ' Parque Nacional del Serengeti' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Serengeti to jeden z najsłynniejszych parków dzikiej przyrody na świecie. To dom dla Wielkiej Migracji antylop gnu i zebra. Odwiedzający mogą uczestniczyć w safari, by zobaczyć lwy, słonie i inne zwierzęta.', en: 'Serengeti is one of the most famous wildlife parks in the world. It is home to the Great Migration of wildebeest and zebras. Visitors can take safaris to see lions, elephants, and more.', es: 'El Serengeti es uno de los parques de vida silvestre más famosos del mundo. Es hogar de la Gran Migración de ñus y cebras. Los visitantes pueden hacer safaris para ver leones, elefantes y más.' }

        },

        {

            id: 361,

            country: { pl: 'Malta', en: 'Malta', es: 'Malta' },

            place: { pl: 'Klify Dingli', en: 'Dingli Cliffs', es: ' Acantilados Dingli' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Klify Dingli to najwyższy punkt Malty. Oferują zapierające dech w piersiach widoki na morze. Odwiedzający mogą cieszyć się spokojnymi spacerami i oglądać zachód słońca nad bezkresnym, niebieskim horyzontem.', en: 'Dingli Cliffs are the highest point in Malta. They offer breathtaking views of the sea. Visitors can enjoy peaceful walks and watch the sunset over the endless blue horizon.', es: 'Los acantilados de Dingli son el punto más alto de Malta. Ofrecen vistas impresionantes del mar. Los visitantes pueden disfrutar de caminatas tranquilas y ver el atardecer sobre el horizonte azul interminable.' }

        },

        {

            id: 362,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Seattle (Waszyngton)', en: 'Seattle (Washington)', es: ' Seattle (Washington)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Seattle jest słynne z wieży Space Needle, Pike Place Market i kultury kawowej. Odwiedzający mogą spróbować świeżych owoców morza, zwiedzać muzea i podziwiać widoki na górę Rainier.', en: 'Seattle is famous for the Space Needle, Pike Place Market, and coffee culture. Visitors can enjoy fresh seafood, visit museums, and take in the views of Mount Rainier.', es: 'Seattle es famosa por la Space Needle, el Mercado Pike Place y la cultura del café. Los visitantes pueden disfrutar de mariscos frescos, visitar museos y disfrutar de las vistas del Monte Rainier.' }

        },

        {

            id: 363,

            country: { pl: 'San Marino', en: 'San Marino', es: 'San Marino' },

            place: { pl: 'Rocca della Guaita', en: 'Rocca della Guaita', es: ' Rocca della Guaita' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'To najstarsza i najsłynniejsza wieża w San Marino. Ma grube kamienne ściany i dramatyczną lokalizację na klifie. Widoki na okolicę są niesamowite.', en: 'This is the oldest and most famous tower in San Marino. It has thick stone walls and a dramatic location on a cliff. The views over the countryside are incredible.', es: 'Esta es la torre más antigua y famosa de San Marino. Tiene gruesos muros de piedra y una ubicación dramática en un acantilado. Las vistas del campo son increíbles.' }

        },

        {

            id: 364,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'San Miguel de Allende', en: 'San Miguel de Allende', es: ' San Miguel de Allende' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'To piękne miasto słynie z kolorowych budynków i sceny artystycznej. Odwiedzający mogą zwiedzać brukowane ulice, odwiedzać zabytkowe kościoły i cieszyć się lokalnymi festiwalami.', en: 'This beautiful city is famous for its colorful buildings and art scene. Visitors can explore cobblestone streets, visit historic churches, and enjoy local festivals.', es: 'Esta hermosa ciudad es famosa por sus edificios coloridos y su escena artística. Los visitantes pueden explorar calles empedradas, visitar iglesias históricas y disfrutar de festivales locales.' }

        },

        {

            id: 365,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Park Narodowy Zhangjiajie', en: 'Zhangjiajie National Park', es: ' Parque Nacional Zhangjiajie' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten park ma strzeliste filary z piaskowca, które zainspirowały unoszące się góry w filmie Avatar. Odwiedzający mogą spacerować po szklanych kładkach i korzystać z kolejek linowych, aby podziwiać niesamowite widoki.', en: 'This park has towering sandstone pillars that inspired the floating mountains in the movie Avatar. Visitors can take glass walkways and cable cars for incredible views.', es: 'Este parque tiene enormes pilares de arenisca que inspiraron las montañas flotantes en la película Avatar. Los visitantes pueden caminar por pasarelas de vidrio y tomar teleféricos para disfrutar de vistas increíbles.' }

        },

        {

            id: 366,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Niebiański most Langkawi', en: 'Langkawi Sky Bridge', es: ' Puente Sky de Langkawi' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten zakrzywiony most znajduje się wysoko nad lasem deszczowym. Oferuje zapierające dech w piersiach widoki na wyspę i morze. Można po nim spacerować, ciesząc się świeżym powietrzem i piękną scenerią.', en: 'This curved bridge stands high above the rainforest. It offers breathtaking views of the island and sea. Visitors can walk on it and enjoy the fresh air and stunning scenery.', es: 'Este puente curvado se eleva sobre la selva tropical. Ofrece vistas impresionantes de la isla y el mar. Los visitantes pueden caminar sobre él y disfrutar del aire fresco y el paisaje impresionante.' }

        },

        {

            id: 367,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Miasto Quebec', en: 'Quebec City', es: ' Ciudad de Quebec' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Quebec City wygląda jak europejskie miasteczko, z brukowanymi uliczkami i zabytkowymi budynkami. Château Frontenac to najsłynniejszy punkt orientacyjny. Odwiedzający mogą spróbować francuskiej kuchni i odwiedzić urocze kawiarenki.', en: 'Quebec City looks like a European town, with cobbled streets and historic buildings. The Château Frontenac is the most famous landmark. Visitors can enjoy French cuisine and charming cafés.', es: 'Ciudad de Quebec parece una ciudad europea, con calles empedradas y edificios históricos. El Château Frontenac es el monumento más famoso. Los visitantes pueden disfrutar de la cocina francesa y encantadores cafés.' }

        },

        {

            id: 368,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Świątynia Itsukushima (Miyajima)', en: 'Itsukushima Shrine (Miyajima)', es: ' Santuario Itsukushima (Miyajima)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta słynna świątynia ma gigantyczną czerwoną bramę torii, która wygląda, jakby unosiła się na wodzie. To przepiękny widok, zwłaszcza o zachodzie słońca. Po wyspie swobodnie spacerują jelenie.', en: 'This famous shrine has a giant red torii gate that looks like it is floating in the sea. It is a beautiful sight, especially at sunset. Deer also roam freely on the island.', es: 'Este famoso santuario tiene un enorme torii rojo que parece flotar en el mar. Es una vista hermosa, especialmente al atardecer. Los ciervos también deambulan libremente por la isla.' }

        },

        {

            id: 369,

            country: { pl: 'Seszele', en: 'Seychelles Islands', es: 'Islas Seychelles' },

            place: { pl: 'Wyspy Seszeli', en: 'Seychelles Islands', es: ' Islas Seychelles' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Seszele to archipelag na Oceanie Indyjskim, słynący z plaż z białym piaskiem i krystalicznie czystą wodą. To idealne miejsce dla miłośników przyrody, z wieloma gatunkami ptaków i unikalną florą.', en: 'Seychelles is an archipelago in the Indian Ocean, famous for its white-sand beaches and crystal-clear waters. It\’s a perfect destination for nature lovers, with many species of birds and unique flora.', es: 'Seychelles es un archipiélago en el Océano Índico, famoso por sus playas de arena blanca y aguas cristalinas. Es un destino perfecto para los amantes de la naturaleza, con muchas especies de aves y flora única.' }

        },

        {

            id: 370,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Huangshan (Góry Żółte)', en: 'Huangshan (Yellow Mountains)', es: ' Huangshan (Montañas Amarillas)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Żółte Góry słyną z mglistych szczytów, poskręcanych sosen i dramatycznych wschodów słońca. Odwiedzający mogą wjechać kolejką linową lub wspiąć się po starożytnych kamiennych schodach, aby podziwiać zapierające dech w piersiach widoki.', en: 'The Yellow Mountains are famous for misty peaks, twisted pine trees, and dramatic sunrises. Visitors can take cable cars up or hike ancient stone steps for breathtaking views.', es: 'Las Montañas Amarillas son famosas por sus picos cubiertos de niebla, sus pinos retorcidos y sus amaneceres dramáticos. Los visitantes pueden tomar teleféricos o subir antiguos escalones de piedra para disfrutar de vistas impresionantes.' }

        },

        {

            id: 371,

            country: { pl: 'Argentyna', en: 'Argentina', es: 'Argentina' },

            place: { pl: 'Ushuaia', en: 'Ushuaia', es: ' Ushuaia' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ushuaia to najbardziej wysunięte na południe miasto na świecie. Jest bramą do Antarktydy i oferuje niesamowite wycieczki przyrodnicze. Można tam zobaczyć pingwiny, odbyć rejsy łodzią i zwiedzić pobliski park narodowy.', en: 'Ushuaia is the southernmost city in the world. It is the gateway to Antarctica and offers amazing wildlife tours. Visitors can see penguins, take boat trips, and explore the nearby national park.', es: 'Ushuaia es la ciudad más austral del mundo. Es la puerta de entrada a la Antártida y ofrece increíbles tours de vida silvestre. Los visitantes pueden ver pingüinos, hacer paseos en barco y explorar el cercano parque nacional.' }

        },

        {

            id: 372,

            country: { pl: 'Malediwy', en: 'Maldives', es: 'Maldivas' },

            place: { pl: 'Tęczowa Rafa', en: 'Rainbow Reef', es: ' Arrecife del Arco Iris' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Rafa Tęczowa na Malediwach jest znana z tętniących życiem raf koralowych i różnorodnego życia morskiego. Snorkelerzy i nurkowie mogą pływać wśród kolorowych ryb, żółwi morskich i oszałamiających podwodnych krajobrazów.', en: 'Rainbow Reef in the Maldives is known for its vibrant coral and diverse marine life. Snorkelers and divers can swim among colorful fish, sea turtles, and stunning underwater landscapes.', es: 'El Arrecife Arcoíris en las Maldivas es conocido por su vibrante coral y su diversa vida marina. Los snorkelistas y buceadores pueden nadar entre peces coloridos, tortugas marinas y paisajes submarinos impresionantes.' }

        },

        {

            id: 373,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Wybrzeże Na Pali na Hawajach', en: 'Hawaii\’s Na Pali Coast', es: ' Costa Na Pali de Hawái' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Na Pali Coast to dramatyczny odcinek wybrzeża na Kauai. Słynie z zielonych klifów, morskich jaskiń i dziewiczych plaż, najlepiej zwiedzanych łodzią lub pieszo.', en: 'The Na Pali Coast is a dramatic stretch of coastline on Kauai. It is famous for its green cliffs, sea caves, and pristine beaches, best explored by boat or on foot.', es: 'La Costa Na Pali es una franja dramática de costa en Kauai. Es famosa por sus acantilados verdes, cuevas marinas y playas vírgenes, y se explora mejor en barco o a pie.' }

        },

        {

            id: 374,

            country: { pl: 'Tunezja', en: 'Tunisia', es: 'Túnez' },

            place: { pl: 'Ruiny Kartaginy', en: 'Carthage Ruins', es: ' Ruinas de Cartago' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Kartagina to starożytne miasto z imponującymi ruinami w pobliżu Tunisu. Turyści mogą zwiedzać pozostałości świątyń, łaźni i starożytnego portu. To fascynujące miejsce dla miłośników historii.', en: 'Carthage is an ancient city with impressive ruins near Tunis. Visitors can explore the remains of temples, baths, and the ancient harbor. It\’s a fascinating place for history lovers.', es: 'Carthago es una ciudad antigua con impresionantes ruinas cerca de Túnez. Los visitantes pueden explorar los restos de templos, baños y el antiguo puerto. Es un lugar fascinante para los amantes de la historia.' }

        },

        {

            id: 375,

            country: { pl: 'Monako', en: 'Monaco', es: 'Mónaco' },

            place: { pl: 'Kasyno Monte Carlo', en: 'Monte Carlo Casino', es: ' Casino de Montecarlo' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kasyno Monte Carlo to symbol luksusu. Odwiedzający mogą podziwiać jego grandiozną architekturę, spróbować szczęścia w hazardzie lub po prostu cieszyć się glamour atmosferą.', en: 'The Monte Carlo Casino is a symbol of luxury. Visitors can admire its grand architecture, try their luck at gambling, or just enjoy the glamorous atmosphere.', es: 'El Casino de Monte Carlo es un símbolo de lujo. Los visitantes pueden admirar su gran arquitectura, probar suerte en el juego o simplemente disfrutar de su ambiente glamoroso.' }

        },

        {

            id: 376,

            country: { pl: 'Belize', en: 'Belize', es: 'Belice' },

            place: { pl: 'Wielka Błękitna Dziura', en: 'Great Blue Hole', es: ' Gran Agujero Azul' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Great Blue Hole to gigantyczny podwodny lej krasowy. To jedno z najlepszych miejsc do nurkowania na świecie, z krystalicznie czystą wodą i wyjątkową fauną morską.', en: 'The Great Blue Hole is a giant underwater sinkhole. It is one of the best diving spots in the world, with crystal-clear water and unique marine life.', es: 'El Gran Agujero Azul es un enorme sumidero submarino. Es uno de los mejores lugares para bucear en el mundo, con aguas cristalinas y una vida marina única.' }

        },

        {

            id: 377,

            country: { pl: 'Ekwador', en: 'Ecuador', es: 'Ecuador' },

            place: { pl: 'Wyspy Galapagos', en: 'Galápagos Islands', es: ' Islas Galápagos' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Piramidy to najsłynniejsze zabytki Egiptu. Zbudowane ponad 4500 lat temu, wciąż zachwycają turystów. W pobliżu znajduje się Sfinks – posąg o ciele lwa i ludzkiej twarzy, pełen starożytnych tajemnic.', en: 'The Galápagos Islands are home to unique animals like giant tortoises and blue-footed boobies. Visitors can snorkel with sea lions and explore volcanic landscapes.', es: 'Las Islas Galápagos son el hogar de animales únicos como las tortugas gigantes y los piqueros de patas azules. Los visitantes pueden hacer snorkel con leones marinos y explorar paisajes volcánicos.' }

        },

        {

            id: 378,

            country: { pl: 'Panama', en: 'Panama', es: 'Panamá' },

            place: { pl: 'Wyspy San Blas', en: 'San Blas Islands', es: ' Islas San Blas' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Wyspy San Blas to tropikalny raj. Są domem dla rdzennych ludzi Guna. Turyści mogą odpoczywać na białych plażach i cieszyć się prostym życiem wyspiarskim.', en: 'The San Blas Islands are a tropical paradise. They are home to the indigenous Guna people. Visitors can relax on white beaches and enjoy simple island life.', es: 'Las Islas San Blas son un paraíso tropical. Son hogar del pueblo indígena Guna. Los visitantes pueden relajarse en playas blancas y disfrutar de la vida isleña simple.' }

        },

        {

            id: 379,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Dolina Jiuzhaigou', en: 'Jiuzhaigou Valley', es: ' Valle de Jiuzhaigou' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Jiuzhaigou to dolina z kolorowymi jeziorami, wodospadami i ośnieżonymi szczytami. Woda jest tak czysta, że można zobaczyć głęboko pod powierzchnią. To magiczne miejsce dla fotografów i miłośników przyrody.', en: 'Jiuzhaigou is a valley with colorful lakes, waterfalls, and snowy peaks. The water is so clear that you can see deep below the surface. It is a magical place for photography and nature lovers.', es: 'Jiuzhaigou es un valle con lagos coloridos, cascadas y picos nevados. El agua es tan clara que se puede ver profundamente debajo de la superficie. Es un lugar mágico para la fotografía y los amantes de la naturaleza.' }

        },

        {

            id: 380,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Wyspa Holbox', en: 'Isla Holbox', es: ' Isla Holbox' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Isla Holbox to spokojna wyspa z białym piaskiem i bez samochodów. Odwiedzający mogą pływać z rekinami wielorybimi, zobaczyć różowe flamingi i cieszyć się wyluzowaną atmosferą wyspy.', en: 'Isla Holbox is a peaceful island with white sand and no cars. Visitors can swim with whale sharks, see pink flamingos, and enjoy the relaxed island vibe.', es: 'Isla Holbox es una isla tranquila con arena blanca y sin autos. Los visitantes pueden nadar con tiburones ballena, ver flamencos rosados y disfrutar del ambiente relajado de la isla.' }

        },

        {

            id: 381,

            country: { pl: 'Czarnogóra', en: 'Montenegro', es: 'Montenegro' },

            place: { pl: 'Sveti Stefan', en: 'Sveti Stefan', es: ' Sveti Stefan' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta malutka wyspa to jedna z najsłynniejszych atrakcji Czarnogóry. Ma czerwone dachy i jest połączona z lądem wąskim pasem piasku. Dziś to luksusowy kurort.', en: 'This tiny island is one of Montenegro\’s most famous sights. It has red-roofed buildings and is connected to the mainland by a narrow strip of sand. Today, it is a luxury resort.', es: 'Esta pequeña isla es uno de los lugares más famosos de Montenegro. Tiene edificios de techos rojos y está conectada al continente por una estrecha franja de arena. Hoy en día, es un resort de lujo.' }

        },

        {

            id: 382,

            country: { pl: 'Ekwador', en: 'Ecuador', es: 'Ecuador' },

            place: { pl: 'Cuenca', en: 'Cuenca', es: ' Cuenca' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Świątynia w Luksorze to starożytna egipska budowla w sercu miasta. Nocą jest pięknie oświetlona. Ogromne posągi i wysokie kolumny sprawiają, że to fascynujące miejsce do zwiedzania.', en: 'Cuenca is a charming city with cobblestone streets and historic churches. It is known for its relaxed atmosphere and beautiful colonial buildings.', es: 'Cuenca es una ciudad encantadora con calles empedradas y iglesias históricas. Es conocida por su ambiente relajado y sus hermosos edificios coloniales.' }

        },

        {

            id: 383,

            country: { pl: 'Kuba', en: 'Cuba', es: 'Cuba' },

            place: { pl: 'Hawana', en: 'Havana', es: ' La Habana' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Port Kyrenia to urocza marina z kamiennymi budynkami i łodziami rybackimi. To świetne miejsce na świeże owoce morza, spacer wzdłuż nabrzeża i zwiedzanie zabytkowego zamku.', en: 'Havana is Cuba\’s colorful capital, known for classic cars, colonial buildings, and lively music. Visitors can explore Old Havana, visit the Malecón, and enjoy a mojito in a historic bar.', es: 'La Habana es la colorida capital de Cuba, conocida por sus autos clásicos, edificios coloniales y música animada. Los visitantes pueden explorar La Habana Vieja, visitar el Malecón y disfrutar de un mojito en un bar histórico.' }

        },

        {

            id: 384,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Wieża Monas w Dżakarcie', en: 'Jakarta\’s Monas Tower', es: ' Torre Monas de Yakarta' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wieża Monas to symbol niepodległości Indonezji. Odwiedzający mogą wjechać windą na szczyt, by podziwiać widoki na miasto. Wieża jest szczególnie piękna nocą.', en: 'Monas Tower is a symbol of Indonesia\’s independence. Visitors can take an elevator to the top for great city views. The tower is especially beautiful at night.', es: 'La Torre Monas es un símbolo de la independencia de Indonesia. Los visitantes pueden tomar un ascensor hasta la cima para obtener vistas increíbles de la ciudad. La torre es especialmente hermosa de noche.' }

        },

        {

            id: 385,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Federation Square (Melbourne)', en: 'Federation Square (Melbourne)', es: ' Plaza Federation (Melbourne)' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Federation Square to tętniące życiem miejsce w Melbourne. Znajdują się tam muzea, galerie i organizowane są różne wydarzenia. Ludzie przychodzą tu, by odpocząć, oglądać występy i cieszyć się kulturą miasta.', en: 'Federation Square is a lively place in Melbourne. It has museums, galleries, and fun events. People come here to relax, watch performances, and enjoy the city\’s culture.', es: 'Federation Square es un lugar animado en Melbourne. Tiene museos, galerías y eventos divertidos. La gente viene aquí para relajarse, ver actuaciones y disfrutar de la cultura de la ciudad.' }

        },

        {

            id: 386,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Miejsce historyczne Port Arthur', en: 'Port Arthur Historic Site', es: ' Sitio Histórico de Port Arthur' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Port Arthur to dawna kolonia karna na Tasmanii. Zwiedzający mogą zwiedzać stare budynki więzienne i słuchać historii o skazańcach. Miejsce jest piękne, ale ma mroczną historię.', en: 'Port Arthur is a former prison colony in Tasmania. Visitors can explore old prison buildings and hear stories of convicts. The site is beautiful but has a dark history.', es: 'Port Arthur fue una colonia penal en Tasmania. Los visitantes pueden explorar los antiguos edificios de la prisión y escuchar historias sobre los prisioneros. El sitio es hermoso, pero tiene una historia oscura.' }

        },

        {

            id: 387,

            country: { pl: 'Panama', en: 'Panama', es: 'Panamá' },

            place: { pl: 'Kanał Panamski', en: 'Panama Canal', es: ' Canal de Panamá' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Kanał Panamski to jedno z największych osiągnięć inżynieryjnych na świecie. Łączy Oceany Atlantycki i Spokojny. Turyści mogą oglądać ogromne statki przechodzące przez śluzy.', en: 'The Panama Canal is one of the world\’s greatest engineering feats. It connects the Atlantic and Pacific Oceans. Visitors can watch huge ships pass through the locks.', es: 'El Canal de Panamá es una de las mayores hazañas de ingeniería del mundo. Conecta los océanos Atlántico y Pacífico. Los visitantes pueden ver grandes barcos pasar por las esclusas.' }

        },

        {

            id: 388,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Ogrody nad Zatoką', en: 'Gardens by the Bay', es: ' Gardens by the Bay' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ogrody przy Zatokach to park z futurystycznymi strukturami "Supertree" i dużymi szklarnia. To doskonałe miejsce, by doświadczyć zarówno natury, jak i technologii, z pięknymi roślinami i pokazami świetlnymi nocą.', en: 'Gardens by the Bay is a park with futuristic "Supertree" structures and large conservatories. It\’s a great place to experience both nature and technology, with beautiful plants and light shows at night.', es: 'Gardens by the Bay es un parque con estructuras futuristas de "Supertree" y grandes invernaderos. Es un excelente lugar para experimentar tanto la naturaleza como la tecnología, con hermosas plantas y espectáculos de luces por la noche.' }

        },

        {

            id: 389,

            country: { pl: 'Czarnogóra', en: 'Montenegro', es: 'Montenegro' },

            place: { pl: 'Park Narodowy Durmitor', en: 'Durmitor National Park', es: ' Parque Nacional Durmitor' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Durmitor to raj dla miłośników przyrody, z głębokimi kanionami, czystymi jeziorami i wysokimi szczytami. Odwiedzający mogą wędrować, płynąć w rzece Tara lub cieszyć się zimowym narciarstwem. To świetne miejsce na ucieczkę w dziką naturę.', en: 'Durmitor is a paradise for nature lovers, with deep canyons, clear lakes, and tall peaks. Visitors can hike, raft in the Tara River, or enjoy winter skiing. It\’s a great escape into the wild.', es: 'Durmitor es un paraíso para los amantes de la naturaleza, con cañones profundos, lagos cristalinos y altas cumbres. Los visitantes pueden hacer senderismo, navegar en rafting por el río Tara o disfrutar del esquí invernal. Es una gran escapatoria a la naturaleza.' }

        },

        {

            id: 390,

            country: { pl: 'Filipiny', en: 'Philippines', es: 'Filipinas' },

            place: { pl: 'Czekoladowe Wzgórza', en: 'Chocolate Hills', es: ' Colinas de Chocolate' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wzgórza Czekoladowe to ponad 1000 stożkowych wzgórz na Bohol. W porze suchej przybierają brązowy kolor, przypominając czekoladę. Turyści mogą je zobaczyć z punktu widokowego lub przejechać na quadzie w pobliżu.', en: 'The Chocolate Hills are over 1,000 cone-shaped hills in Bohol. In the dry season, they turn brown like chocolate. Visitors can see them from a viewing deck or ride an ATV nearby.', es: 'Las Colinas de Chocolate son más de 1,000 colinas con forma de cono en Bohol. En la temporada seca, se vuelven marrones como el chocolate. Los visitantes pueden verlas desde una plataforma de observación o montar un ATV cerca.' }

        },

        {

            id: 391,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Park Narodowy Tayrona', en: 'Tayrona National Park', es: ' Parque Nacional Tayrona' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Tayrona to oszałamiający park nadmorski z dżunglą i plażami. Odwiedzający mogą wędrować przez las deszczowy i odpoczywać nad czystym, błękitnym morzem. Park jest domem dla unikalnej fauny i rdzennych kultur.', en: 'Tayrona is a stunning coastal park with jungle and beaches. Visitors can hike through the rainforest and relax by the clear blue sea. The park is home to unique wildlife and indigenous culture.', es: 'Tayrona es un impresionante parque costero con selva y playas. Los visitantes pueden hacer senderismo por la selva y relajarse junto al mar azul cristalino. El parque alberga fauna única y cultura indígena.' }

        },

        {

            id: 392,

            country: { pl: 'Paragwaj', en: 'Paraguay', es: 'Paraguay' },

            place: { pl: 'Zapora Itaipu', en: 'Itaipu Dam', es: ' Represa de Itaipú' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Zapora Itaipu to jedna z największych elektrowni wodnych na świecie. Turyści mogą zwiedzać ogromną strukturę i dowiedzieć się, jak produkuje energię.', en: 'Itaipu Dam is one of the largest hydroelectric dams in the world. Visitors can take tours to see the massive structure and learn how it produces energy.', es: 'La represa de Itaipú es una de las represas hidroeléctricas más grandes del mundo. Los visitantes pueden hacer recorridos para ver la enorme estructura y aprender cómo produce energía.' }

        },

        {

            id: 393,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Plaże Goa', en: 'Goa\’s Beaches', es: ' Playas de Goa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Goa jest znana z piaszczystych plaż, tętniącego życia nocnego i owoców morza. Odwiedzający mogą odpocząć nad morzem, spróbować sportów wodnych lub zwiedzać stare portugalskie kościoły.', en: 'Goa is known for its sandy beaches, lively nightlife, and seafood. Visitors can relax by the sea, try water sports, or explore old Portuguese churches.', es: 'Goa es conocida por sus playas de arena, su animada vida nocturna y su marisco. Los visitantes pueden relajarse junto al mar, practicar deportes acuáticos o explorar antiguas iglesias portuguesas.' }

        },

        {

            id: 394,

            country: { pl: 'Tajlandia', en: 'Thailand', es: 'Tailandia' },

            place: { pl: 'Ko Tao', en: 'Koh Tao', es: ' Koh Tao' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Koh Tao to mała wyspa znana z miejsc do nurkowania. Czyste wody i żywe rafy koralowe czynią ją popularnym miejscem do podwodnych eksploracji. To także świetne miejsce do relaksu na plaży.', en: 'Koh Tao is a small island known for its diving spots. The clear waters and vibrant coral reefs make it a popular destination for underwater exploration. It\’s also a great place to relax on the beach.', es: 'Koh Tao es una pequeña isla conocida por sus puntos de buceo. Las aguas claras y los vibrantes arrecifes de coral la hacen un destino popular para la exploración submarina. También es un excelente lugar para relajarse en la playa.' }

        },

        {

            id: 395,

            country: { pl: 'Brazylia', en: 'Brazil', es: 'Brasil' },

            place: { pl: 'São Paulo', en: 'São Paulo', es: ' São Paulo' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'São Paulo to największe miasto Brazylii, pełne sztuki, kultury i świetnego jedzenia. Zwiedzający mogą odkrywać muzea, cieszyć się nocnym życiem i spróbować pysznego brazylijskiego barbecue.', en: 'São Paulo is Brazil\’s largest city, full of art, culture, and great food. Visitors can explore museums, enjoy the nightlife, and try delicious Brazilian barbecue.', es: 'São Paulo es la ciudad más grande de Brasil, llena de arte, cultura y buena comida. Los visitantes pueden explorar museos, disfrutar de la vida nocturna y probar la deliciosa barbacoa brasileña.' }

        },

        {

            id: 396,

            country: { pl: 'Brazylia', en: 'Brazil', es: 'Brasil' },

            place: { pl: 'Pantanal', en: 'Pantanal', es: ' Pantanal' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Pantanal to największe mokradło na świecie. To jedno z najlepszych miejsc do obserwacji jaguarów, wydr olbrzymich i egzotycznych ptaków.', en: 'The Pantanal is the world\’s largest wetland. It is one of the best places to see jaguars, giant otters, and exotic birds.', es: 'El Pantanal es el humedal más grande del mundo. Es uno de los mejores lugares para ver jaguares, nutrias gigantes y aves exóticas.' }

        },

        {

            id: 397,

            country: { pl: 'Dania', en: 'Denmark', es: 'Dinamarca' },

            place: { pl: 'Ilulissat (Grenlandia)', en: 'Ilulissat (Greenland)', es: ' Ilulissat (Groenlandia)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ta wyspa ma krystalicznie czystą wodę, palmy i miękki, biały piasek. Wielu turystów przypływa tu na rejsy, aby odpocząć, popływać i delektować się świeżymi owocami morza na plaży.', en: 'Ilulissat is famous for its stunning Icefjord, a UNESCO World Heritage site. Visitors can witness massive icebergs and enjoy boat trips, hiking, and dog sledding in a dramatic arctic landscape.', es: 'Ilulissat es famoso por su impresionante Icefjord, un sitio Patrimonio de la Humanidad de la UNESCO. Los visitantes pueden ver enormes icebergs y disfrutar de paseos en bote, senderismo y trineos tirados por perros en un paisaje ártico dramático.' }

        },

        {

            id: 398,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Honolulu (Hawaje)', en: 'Honolulu (Hawaii)', es: ' Honolulu (Hawái)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Honolulu to tropikalny raj z pięknymi plażami i ciepłą pogodą. Odwiedzający mogą surfować na plaży Waikiki, wspinać się na Diamond Head lub zwiedzać Pearl Harbor.', en: 'Honolulu is a tropical paradise with beautiful beaches and warm weather. Visitors can surf on Waikiki Beach, hike Diamond Head, or explore Pearl Harbor.', es: 'Honolulu es un paraíso tropical con hermosas playas y clima cálido. Los visitantes pueden surfear en Waikiki Beach, hacer senderismo en Diamond Head o explorar Pearl Harbor.' }

        },

        {

            id: 399,

            country: { pl: 'Meksyk', en: 'Mexico', es: 'México' },

            place: { pl: 'Guanajuato', en: 'Guanajuato', es: ' Guanajuato' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Guanajuato to kolorowe kolonialne miasto z podziemnymi ulicami. Ma zabytkowe place, urocze zaułki i słynne Muzeum Mumii. Odwiedzający mogą zwiedzać unikalne tunele i tętniące życiem rynki.', en: 'Guanajuato is a colorful colonial city with underground streets. It has historic plazas, charming alleys, and the famous Mummy Museum. Visitors can explore its unique tunnels and lively markets.', es: 'Guanajuato es una ciudad colonial colorida con calles subterráneas. Tiene plazas históricas, callejones encantadores y el famoso Museo de las Momias. Los visitantes pueden explorar sus túneles únicos y mercados animados.' }

        },

        {

            id: 400,

            country: { pl: 'Serbia', en: 'Serbia', es: 'Serbia' },

            place: { pl: 'Miasto Diabła (Đavolja Varoš)', en: 'Devil\’s Town (Đavolja Varoš)', es: ' Pueblo del Diablo (Đavolja Varoš)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ta naturalna formacja skalna wygląda dziwnie i tajemniczo. Wysokie kamienne filary zostały ukształtowane przez wiatr i deszcz. Legenda mówi, że kiedyś były to ludzie zamienieni w kamień.', en: 'This natural rock formation looks strange and mysterious. The tall stone pillars were shaped by wind and rain. Legends say they were once people turned to stone.', es: 'Esta formación rocosa natural tiene un aspecto extraño y misterioso. Los altos pilares de piedra fueron moldeados por el viento y la lluvia. Las leyendas dicen que alguna vez fueron personas convertidas en piedra.' }

        },

        {

            id: 401,

            country: { pl: 'Jamajka', en: 'Jamaica', es: 'Jamaica' },

            place: { pl: 'Negril', en: 'Negril', es: ' Negril' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Negril ma jedne z najpiękniejszych plaż na Jamajce. Odwiedzający mogą odpoczywać na Seven Mile Beach, oglądać skoki z klifów w Rick\’s Café i podziwiać zachody słońca.', en: 'Negril has one of the most beautiful beaches in Jamaica. Visitors can relax on Seven Mile Beach, watch cliff divers at Rick\’s Café, and enjoy stunning sunsets.', es: 'Negril tiene una de las playas más hermosas de Jamaica. Los visitantes pueden relajarse en Seven Mile Beach, ver a los buzos en los acantilados de Rick\’s Café y disfrutar de impresionantes atardeceres.' }

        },

        {

            id: 402,

            country: { pl: 'Turcja', en: 'Turkey', es: 'Turquía' },

            place: { pl: 'Góra Nemrut', en: 'Mount Nemrut', es: ' Monte Nemrut' },

            continent: { pl: 'Europa/Azja', en: 'Europe/Asia', es: 'Europa/Asia' },

            description: { pl: 'Góra Nemrut słynie z gigantycznych kamiennych głów starożytnych bogów. Posągi rozrzucone są po górze, a turyści mogą wędrować na szczyt, by podziwiać spektakularny widok o wschodzie słońca.', en: 'Mount Nemrut is known for its giant stone heads of ancient gods. The statues are spread across the mountain, and visitors can hike to the top for a spectacular view at sunrise.', es: 'El Monte Nemrut es conocido por sus gigantescas cabezas de piedra de dioses antiguos. Las estatuas están esparcidas por la montaña, y los visitantes pueden hacer senderismo hasta la cima para disfrutar de una vista espectacular al amanecer.' }

        },

        {

            id: 403,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Wielki Mur Chiński', en: 'Great Wall of China', es: ' Gran Muralla China' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wielki Mur to jeden z najsłynniejszych zabytków świata. Ciągnie się przez tysiące kilometrów. Odwiedzający mogą spacerować po odrestaurowanych odcinkach i podziwiać niesamowite widoki.', en: 'The Great Wall is one of the world\’s most famous landmarks. It stretches for thousands of kilometers. Visitors can walk on restored sections and enjoy amazing views.', es: 'La Gran Muralla es uno de los monumentos más famosos del mundo. Se extiende por miles de kilómetros. Los visitantes pueden caminar por secciones restauradas y disfrutar de vistas asombrosas.' }

        },

        {

            id: 404,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Marina Bay Sands', en: 'Marina Bay Sands', es: ' Marina Bay Sands' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: {
                pl: 'Marina Bay Sands to futurystyczny hotel z parkiem na dachu, oferującym panoramiczne widoki na miasto. Znajduje się tam centrum handlowe, restauracje i basen bez krawędzi. To ikoniczny punkt orientacyjny Singapuru.', en: 'Marina Bay Sands is a futuristic hotel with a sky park offering panoramic views of the city. It includes a mall, restaurants, and an infinity pool on the roof. It\'s an iconic landmark in Singapore.', es: 'Marina Bay Sands es un hotel futurista con un parque en el cielo que ofrece vistas panorámicas de la ciudad.Incluye un centro comercial, restaurantes y una piscina infinita en el techo.Es un hito icónico en Singapur.'
            }

        },

        {

            id: 405,

            country: { pl: 'Macedonia Północna', en: 'North Macedonia', es: 'Macedonia del Norte' },

            place: { pl: 'Klasztor św. Nauma', en: 'Saint Naum Monastery', es: ' Monasterio de Saint Naum' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten piękny klasztor znajduje się nad jeziorem Ochrydzkim. Otoczony jest ogrodami i oferuje niesamowity widok na jezioro. Turyści mogą również zobaczyć pawie spacerujące po okolicy.', en: 'This beautiful monastery is located by Lake Ohrid. It is surrounded by gardens and has amazing lake views. Visitors can also see peacocks roaming the area.', es: 'Este hermoso monasterio está ubicado junto al lago Ohrid. Está rodeado de jardines y tiene increíbles vistas del lago. Los visitantes también pueden ver pavos reales merodeando por el área.' }

        },

        {

            id: 406,

            country: { pl: 'Gujana', en: 'Guyana', es: 'Guyana' },

            place: { pl: 'Georgetown', en: 'Georgetown', es: ' Georgetown' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Georgetown to stolica Gujany, znana z drewnianych kolonialnych budynków. Zwiedzający mogą odkrywać targi, parki i słynną katedrę św. Jerzego.', en: 'Georgetown is Guyana\’s capital, known for its wooden colonial buildings. Visitors can explore markets, parks, and the famous St. George\’s Cathedral.', es: 'Georgetown es la capital de Guyana, conocida por sus edificios coloniales de madera. Los visitantes pueden explorar mercados, parques y la famosa Catedral de St. George.' }

        },

        {

            id: 407,

            country: { pl: 'Jamajka', en: 'Jamaica', es: 'Jamaica' },

            place: { pl: 'Ocho Rios', en: 'Ocho Rios', es: ' Ocho Ríos' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Ocho Rios to tropikalny raj z wodospadami i rzekami. Dunn\’s River Falls to obowiązkowy punkt zwiedzania, gdzie można wspinać się po kaskadowej wodzie. Popularne są też rejsy i spotkania z delfinami.', en: 'Ocho Rios is a tropical paradise with waterfalls and rivers. Dunn\’s River Falls is a must-visit, where tourists can climb the cascading water. Boat tours and dolphin encounters are also popular.', es: 'Ocho Ríos es un paraíso tropical con cascadas y ríos. Las Dunn\’s River Falls son una visita obligada, donde los turistas pueden escalar el agua en cascada. Los tours en bote y los encuentros con delfines también son populares.' }

        },

        {

            id: 408,

            country: { pl: 'Urugwaj', en: 'Uruguay', es: 'Uruguay' },

            place: { pl: 'Montevideo', en: 'Montevideo', es: ' Montevideo' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Montevideo to stolica Urugwaju. Ma żywą kulturę, piękne plaże i świetne jedzenie. Turyści mogą zwiedzać Stare Miasto i cieszyć się wibracyjną sceną muzyczną.', en: 'Montevideo is the capital of Uruguay. It has a lively culture, beautiful beaches, and great food. Visitors can explore the Old Town and enjoy the vibrant local music scene.', es: 'Montevideo es la capital de Uruguay. Tiene una cultura vibrante, hermosas playas y buena comida. Los visitantes pueden explorar el Casco Antiguo y disfrutar de la vibrante escena musical local.' }

        },

        {

            id: 409,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Dżajsalmer', en: 'Jaisalmer', es: ' Jaisalmer' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Jaisalmer jest nazywane „Złotym Miastem” ze względu na budynki z żółtego piaskowca. Znajduje się tu imponująca forteca, wąskie uliczki i pustynne krajobrazy. Odwiedzający mogą wziąć udział w safari na wielbłądach po wydmach.', en: 'Jaisalmer is known as the "Golden City" because of its yellow sandstone buildings. It has a stunning fort, narrow streets, and desert landscapes. Visitors can take camel safaris in the dunes.', es: 'Jaisalmer es conocida como la "Ciudad Dorada" debido a sus edificios de piedra arenisca amarilla. Tiene un impresionante fuerte, calles estrechas y paisajes desérticos. Los visitantes pueden hacer safaris en camello en las dunas.' }

        },

        {

            id: 410,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Wielki Kanion', en: 'Grand Canyon', es: ' Gran Cañón' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Wielki Kanion to ogromne cudo natury. Odwiedzający mogą wędrować szlakami, wybrać się na malowniczy lot helikopterem lub po prostu podziwiać oszałamiające widoki z krawędzi.', en: 'The Grand Canyon is a massive natural wonder. Visitors can hike along its trails, take a scenic helicopter ride, or simply admire the stunning views from the rim.', es: 'El Gran Cañón es una enorme maravilla natural. Los visitantes pueden caminar por sus senderos, hacer un recorrido en helicóptero panorámico o simplemente admirar las impresionantes vistas desde el borde.' }

        },

        {

            id: 411,

            country: { pl: 'Boliwia', en: 'Bolivia', es: 'Bolivia' },

            place: { pl: 'La Paz', en: 'La Paz', es: ' La Paz' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'La Paz to najwyżej położona stolica świata. Tętni kulturą, pełna jest ruchliwych targów i kolorowych ulic. Odwiedzający mogą skorzystać z kolejki linowej, by podziwiać niesamowite widoki miasta.', en: 'La Paz is the highest capital city in the world. It is full of culture, with busy markets and colorful streets. Visitors can take a cable car for stunning city views.', es: 'La Paz es la capital más alta del mundo. Está llena de cultura, con mercados animados y calles coloridas. Los visitantes pueden tomar un teleférico para disfrutar de impresionantes vistas de la ciudad.' }

        },

        {

            id: 412,

            country: { pl: 'Argentyna', en: 'Argentina', es: 'Argentina' },

            place: { pl: 'Bariloche', en: 'Bariloche', es: ' Bariloche' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Bariloche to piękne górskie miasteczko w Patagonii. Słynie z czekolady, jezior i narciarstwa. Latem odwiedzający mogą wędrować po pobliskich parkach narodowych.', en: 'Bariloche is a beautiful mountain town in Patagonia. It is famous for its chocolate, lakes, and skiing. In summer, visitors can hike in the nearby national parks.', es: 'Bariloche es una hermosa ciudad montañosa en Patagonia. Es famosa por su chocolate, lagos y esquí. En verano, los visitantes pueden hacer senderismo en los parques nacionales cercanos.' }

        },

        {

            id: 413,

            country: { pl: 'Ekwador', en: 'Ecuador', es: 'Ecuador' },

            place: { pl: 'Park Narodowy Cotopaxi', en: 'Cotopaxi National Park', es: ' Parque Nacional Cotopaxi' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ten ogromny kompleks świątynny jest jednym z największych na świecie. Ma gigantyczne filary, posągi i święte jeziora. Spacer po jego starożytnych halach to podróż w czasie.', en: 'Cotopaxi is one of the highest active volcanoes in the world. Visitors can hike in the park, see wild horses, and even try to climb the volcano.', es: 'Cotopaxi es uno de los volcanes activos más altos del mundo. Los visitantes pueden hacer senderismo en el parque, ver caballos salvajes e incluso intentar escalar el volcán.' }

        },

        {

            id: 414,

            country: { pl: 'Arabia Saudyjska', en: 'Saudi Arabia', es: 'Arabia Saudita' },

            place: { pl: 'Al-Ula (Madain Saleh)', en: 'Al-Ula (Madain Saleh)', es: ' Al-Ula (Madain Saleh)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Al-Ula to starożytne miejsce z grobowcami wykutymi w skale. Jest podobne do Petry w Jordanii. Odwiedzający mogą odkrywać jego oszałamiający krajobraz pustynny i zabytkowe ruiny.', en: 'Al-Ula is an ancient site with tombs carved into rock. It is similar to Petra in Jordan. Visitors can explore its stunning desert landscape and historic ruins.', es: 'Al-Ula es un sitio antiguo con tumbas talladas en roca. Es similar a Petra en Jordania. Los visitantes pueden explorar su impresionante paisaje desértico y sus ruinas históricas.' }

        },

        {

            id: 415,

            country: { pl: 'Bermudy', en: 'Bermuda', es: 'Bermudas' },

            place: { pl: 'Jaskinie Kryształowe', en: 'Crystal Caves', es: ' Cuevas de Cristal' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Te jaskinie mają piękne stalaktyty i podziemne jeziorka. Przejrzysta woda odbija formacje skalne, tworząc magiczny efekt. Odwiedzający mogą spacerować po drewnianych mostkach wśród jaskiń.', en: 'These caves have beautiful stalactites and underground pools. The clear water reflects the rock formations, creating a magical effect. Visitors can walk on wooden bridges through the caves.', es: 'Estas cavernas tienen hermosas estalactitas y piscinas subterráneas. El agua clara refleja las formaciones rocosas, creando un efecto mágico. Los visitantes pueden caminar por puentes de madera a través de las cavernas.' }

        },

        {

            id: 416,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Kamienny Las Yunnan', en: 'Yunnan Stone Forest', es: ' Bosque de Piedra de Yunnan' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten unikalny krajobraz wypełniony jest wysokimi wapiennymi filarami. Skały wyglądają jak gigantyczny kamienny las. Odwiedzający mogą spacerować między nimi i poznawać lokalne legendy.', en: 'This unique landscape is filled with tall limestone pillars. The rocks look like a giant stone forest. Visitors can walk among them and hear the legends of the local people.', es: 'Este paisaje único está lleno de altos pilares de piedra caliza. Las rocas parecen un enorme bosque de piedra. Los visitantes pueden caminar entre ellas y escuchar las leyendas de la gente local.' }

        },

        {

            id: 417,

            country: { pl: 'Indie', en: 'India', es: 'India' },

            place: { pl: 'Pałac Mysore', en: 'Mysore Palace', es: ' Palacio de Mysore' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Pałac w Mysore to imponujący budynek z misternymi rzeźbami i kolorowym oświetleniem nocnym. W środku można podziwiać piękne sufity, obrazy i złoty tron.', en: 'Mysore Palace is a grand building with detailed carvings and colorful lights at night. Inside, visitors can see beautiful ceilings, paintings, and a golden throne.', es: 'El Palacio de Mysore es un edificio grandioso con tallados detallados y luces coloridas por la noche. En su interior, los visitantes pueden ver hermosos techos, pinturas y un trono dorado.' }

        },

        {

            id: 418,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Wieża Tokijska', en: 'Tokyo Tower', es: ' Torre de Tokio' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Tokyo Tower przypomina Wieżę Eiffla, ale jest pomalowana na czerwono i biało. Oferuje wspaniałe widoki na miasto. Nocą pięknie się rozświetla, co czyni ją popularnym miejscem do robienia zdjęć.', en: 'Tokyo Tower looks like the Eiffel Tower but is painted red and white. It offers great city views. At night, it lights up beautifully, making it a popular photo spot.', es: 'La Torre de Tokio se parece a la Torre Eiffel, pero está pintada de rojo y blanco. Ofrece grandes vistas de la ciudad. Por la noche, se ilumina hermosamente, convirtiéndola en un lugar popular para fotos.' }

        },

        {

            id: 419,

            country: { pl: 'Macedonia Północna', en: 'North Macedonia', es: 'Macedonia del Norte' },

            place: { pl: 'Megalityczny Kokino Obserwatorium', en: 'Kokino Megalithic Observatory', es: ' Observatorio Megalítico de Kokino' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Kokino to starożytne miejsce używane do badania gwiazd. Ma ponad 3 800 lat! Turyści mogą stanąć na skałach, z których ludzie niegdyś obserwowali niebo w czasach prehistorycznych.', en: 'Kokino is an ancient site used to study the stars. It is over 3,800 years old! Visitors can stand on rocks where people once watched the sky in prehistoric times.', es: 'Kokino es un antiguo sitio utilizado para estudiar las estrellas. ¡Tiene más de 3,800 años! Los visitantes pueden pararse sobre rocas donde las personas solían observar el cielo en tiempos prehistóricos.' }

        },

        {

            id: 420,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Plaże Okinawy', en: 'Okinawa\’s Beaches', es: ' Playas de Okinawa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Okinawa ma jedne z najlepszych plaż w Japonii. Biały piasek i błękitna woda są idealne do pływania i nurkowania. Okinawa ma także unikalną kulturę, różniącą się od reszty Japonii.', en: 'Okinawa has some of Japan\’s best beaches. The white sand and blue water are perfect for swimming and diving. It also has a unique culture, different from the rest of Japan.', es: 'Okinawa tiene algunas de las mejores playas de Japón. La arena blanca y el agua azul son perfectas para nadar y bucear. También tiene una cultura única, diferente al resto de Japón.' }

        },

        {

            id: 421,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Południowy brzeg Brisbane', en: 'Brisbane\’s South Bank', es: ' South Bank de Brisbane' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'South Bank to popularna dzielnica nad rzeką Brisbane. Znajdują się tam parki, sztuczna plaża i centra kulturalne. Można spacerować nad rzeką, oglądać artystów ulicznych lub odpoczywać w ogrodach.', en: 'South Bank is a popular area along the Brisbane River. It has parks, a man-made beach, and cultural centers. Visitors can walk along the river, see street performers, or relax in the gardens.', es: 'South Bank es una zona popular a lo largo del río Brisbane. Tiene parques, una playa artificial y centros culturales. Los visitantes pueden caminar por el río, ver artistas callejeros o relajarse en los jardines.' }

        },

        {

            id: 422,

            country: { pl: 'Gujana', en: 'Guyana', es: 'Guyana' },

            place: { pl: 'Wodospady Kaieteura', en: 'Kaieteur Falls', es: ' Cataratas Kaieteur' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Kaieteur to jeden z najpotężniejszych wodospadów na świecie. Znajduje się w odległej dżungli, co czyni go ukrytym skarbem. Woda spada jednym potężnym strumieniem, tworząc spektakularny widok.', en: 'Kaieteur is one of the world\’s most powerful waterfalls. It is in a remote rainforest, making it a hidden gem. The water falls in a single drop, creating a spectacular sight.', es: 'Kaieteur es una de las cascadas más poderosas del mundo. Se encuentra en una remota selva tropical, lo que la convierte en una joya oculta. El agua cae en una sola caída, creando un espectáculo espectacular.' }

        },

        {

            id: 423,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Chicago (Illinois)', en: 'Chicago (Illinois)', es: ' Chicago (Illinois)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Chicago jest znane z imponującej panoramy miasta, pizzy po chicagowsku i muzeów. Odwiedzający mogą podziwiać widoki z Willis Tower lub wybrać się na rejs łodzią po rzece Chicago.', en: 'Chicago is known for its stunning skyline, deep-dish pizza, and museums. Visitors can admire the views from the Willis Tower or take a boat tour along the Chicago River.', es: 'Chicago es conocida por su impresionante horizonte, pizza de plato hondo y museos. Los visitantes pueden admirar las vistas desde la Torre Willis o hacer un recorrido en barco por el río Chicago.' }

        },

        {

            id: 424,

            country: { pl: 'Macedonia Północna', en: 'North Macedonia', es: 'Macedonia del Norte' },

            place: { pl: 'Jezioro Ochrydzkie', en: 'Lake Ohrid', es: ' Lago Ohrid' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Jezioro Ochrydzkie to jedno z najstarszych i najgłębszych jezior w Europie. Ma krystalicznie czystą, niebieską wodę i jest otoczone urokliwymi miasteczkami. Turyści mogą pływać, jeździć łódkami lub zwiedzać starożytne kościoły.', en: 'Lake Ohrid is one of Europe\’s oldest and deepest lakes. It has clear blue water and is surrounded by charming towns. Visitors can swim, take boat rides, or explore ancient churches.', es: 'El lago Ohrid es uno de los lagos más antiguos y profundos de Europa. Tiene agua cristalina y está rodeado de pueblos encantadores. Los visitantes pueden nadar, hacer paseos en bote o explorar iglesias antiguas.' }

        },

        {

            id: 425,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Jezioro Toba', en: 'Lake Toba', es: ' Lago Toba' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'To największe jezioro wulkaniczne na świecie. Powstało w wyniku ogromnej erupcji dawno temu. Odwiedzający mogą pływać, odpoczywać i poznawać tradycyjną kulturę Bataków.', en: 'This is the largest volcanic lake in the world. It was created by a huge eruption long ago. Visitors can swim, relax, and explore traditional Batak culture.', es: 'Este es el lago volcánico más grande del mundo. Fue creado por una gran erupción hace mucho tiempo. Los visitantes pueden nadar, relajarse y explorar la cultura tradicional Batak.' }

        },

        {

            id: 426,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Meczet Putrajaya', en: 'Putrajaya Mosque', es: ' Mezquita Putrajaya' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten różowy meczet jest jednym z najpiękniejszych budynków w Malezji. Położony nad jeziorem, wygląda niezwykle uroczo o zachodzie słońca. Zwiedzający mogą skorzystać z wycieczek z przewodnikiem i podziwiać jego architekturę.', en: 'This pink-colored mosque is one of Malaysia\’s most beautiful buildings. It is located by a lake and looks stunning at sunset. Visitors can take guided tours and admire its grand architecture.', es: 'Esta mezquita de color rosa es uno de los edificios más hermosos de Malasia. Está ubicada junto a un lago y se ve impresionante al atardecer. Los visitantes pueden hacer recorridos guiados y admirar su gran arquitectura.' }

        },

        {

            id: 427,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Jezioro Moraine', en: 'Moraine Lake', es: ' Lago Moraine' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Jezioro Moraine to jezioro zasilane wodami lodowca, o jaskrawo niebieskiej wodzie. Otoczone jest wysokimi szczytami. Odwiedzający mogą wędrować, pływać kajakiem lub po prostu cieszyć się zapierającymi dech w piersiach widokami.', en: 'Moraine Lake is a glacier-fed lake with bright blue water. It is surrounded by towering peaks. Visitors can hike, kayak, or simply enjoy the breathtaking scenery.', es: 'El Lago Moraine es un lago alimentado por glaciares con agua azul brillante. Está rodeado de picos imponentes. Los visitantes pueden hacer senderismo, kayak o simplemente disfrutar del paisaje impresionante.' }

        },

        {

            id: 428,

            country: { pl: 'Botswana', en: 'Botswana', es: 'Botsuana' },

            place: { pl: 'Park Narodowy Chobe', en: 'Chobe National Park', es: ' Parque Nacional Chobe' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Chobe to jedno z najlepszych miejsc w Afryce do obserwowania słoni. Zwiedzający mogą wybrać się na safari łodzią i podziwiać stada słoni pijących wodę z rzeki.', en: 'Chobe is one of Africa\’s best places to see elephants. Visitors can take boat safaris and watch herds of elephants drinking from the river.', es: 'Chobe es uno de los mejores lugares de África para ver elefantes. Los visitantes pueden hacer safaris en bote y ver manadas de elefantes bebiendo del río.' }

        },

        {

            id: 429,

            country: { pl: 'Tanzania', en: 'Tanzania', es: 'Tanzania' },

            place: { pl: 'Góra Kilimandżaro', en: 'Mount Kilimanjaro', es: ' Monte Kilimanjaro' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Mount Kilimanjaro to najwyższa góra Afryki. To popularne miejsce dla wspinaczy i wędrowców, którzy chcą dotrzeć na szczyt i podziwiać niesamowite widoki na okoliczny krajobraz.', en: 'Mount Kilimanjaro is the tallest mountain in Africa. It is a popular destination for climbers and trekkers who want to reach its summit and enjoy incredible views of the surrounding landscape.', es: 'El Monte Kilimanjaro es la montaña más alta de África. Es un destino popular para escaladores y excursionistas que quieren llegar a su cima y disfrutar de increíbles vistas del paisaje circundante.' }

        },

        {

            id: 430,

            country: { pl: 'Monako', en: 'Monaco', es: 'Mónaco' },

            place: { pl: 'Tor Grand Prix Monako', en: 'Monaco Grand Prix Circuit', es: ' Circuito del Gran Premio de Mónaco' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten słynny tor wyścigowy przebiega przez ulice Monako. Odwiedzający mogą spacerować wzdłuż niego lub obejrzeć wyścig Formuły 1, jeśli odwiedzą w odpowiednim czasie.', en: 'This famous race track runs through the streets of Monaco. Visitors can walk along it or see a Formula 1 race if they visit at the right time.', es: 'Este famoso circuito de carreras recorre las calles de Mónaco. Los visitantes pueden caminar por él o ver una carrera de Fórmula 1 si visitan en el momento adecuado.' }

        },

        {

            id: 431,

            country: { pl: 'Gwatemala', en: 'Guatemala', es: 'Guatemala' },

            place: { pl: 'Antigua', en: 'Antigua', es: ' Antigua' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Antigua to piękne kolonialne miasto otoczone wulkanami. Ma kolorowe domy, stare kościoły i tętniące życiem targi. Urok brukowanych uliczek dodaje mu wyjątkowego charakteru.', en: 'Antigua is a beautiful colonial town surrounded by volcanoes. It has colorful houses, old churches, and lively markets. The cobblestone streets add to its charm.', es: 'Antigua es una hermosa ciudad colonial rodeada de volcanes. Tiene casas coloridas, iglesias antiguas y mercados animados. Las calles de adoquinadas añaden encanto al lugar.' }

        },

        {

            id: 432,

            country: { pl: 'Wenezuela', en: 'Venezuela', es: 'Venezuela' },

            place: { pl: 'Park Narodowy Los Roques', en: 'Los Roques National Park', es: ' Parque Nacional Los Roques' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Los Roques to idylliczne archipelag z białymi, piaszczystymi plażami i krystalicznie czystą wodą. To idealne miejsce do nurkowania, snorkelingu lub po prostu relaksu w słońcu.', en: 'Los Roques is an idyllic archipelago with white sandy beaches and clear turquoise waters. It is perfect for diving, snorkeling, or simply relaxing in the sun.', es: 'Los Roques es un archipiélago idílico con playas de arena blanca y aguas turquesas claras. Es perfecto para bucear, hacer esnórquel o simplemente relajarse bajo el sol.' }

        },

        {

            id: 433,

            country: { pl: 'San Marino', en: 'San Marino', es: 'San Marino' },

            place: { pl: 'Wieża Cesta', en: 'Cesta Tower', es: ' Torre Cesta' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Wieża Cesta to jedna z trzech wież San Marino. Znajduje się na skalistym wzgórzu, oferując wspaniałe widoki. Odwiedzający mogą zwiedzać średniowieczną twierdzę i poznać jej historię.', en: 'Cesta Tower is one of the three towers of San Marino. It sits on a rocky hill with amazing views. Visitors can explore the medieval fortress and learn about its history.', es: 'La Torre Cesta es una de las tres torres de San Marino. Está en una colina rocosa con vistas asombrosas. Los visitantes pueden explorar la fortaleza medieval y aprender sobre su historia.' }

        },

        {

            id: 434,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Studnia Thora', en: 'Thor\’s Well', es: ' Pozo de Thor' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Thor\'s Well to naturalna studnia w Oregonie, która tworzy imponujący pokaz wodny.Podczas wysokiej wody ocean wlewa się do dziury, tworząc wrażenie gigantycznego gejzera.', en: 'Thor\’s Well is a natural sinkhole on Oregon\’s coast that creates an impressive water show.During high tide, the ocean rushes into the hole, making it look like a massive geyser.', es: 'Thor\'s Well es un sumidero natural en la costa de Oregón que crea un impresionante espectáculo de agua. Durante la marea alta, el océano se precipita en el agujero, haciendo que parezca un géiser masivo.' }

        },

        {

            id: 435,

            country: { pl: 'Dominikana', en: 'Dominican Republic', es: 'República Dominicana' },

            place: { pl: 'Santo Domingo', en: 'Santo Domingo', es: ' Santo Domingo' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Cotopaxi to jeden z najwyższych aktywnych wulkanów na świecie. Zwiedzający mogą wędrować po parku, obserwować dzikie konie, a nawet spróbować wspiąć się na wulkan.', en: 'Santo Domingo is the oldest European city in the Americas. Its colonial zone has beautiful old buildings, lively plazas, and historic forts. It is full of culture and history.', es: 'Santo Domingo es la ciudad europea más antigua de las Américas. Su zona colonial tiene hermosos edificios antiguos, plazas animadas y fortalezas históricas. Está llena de cultura e historia.' }

        },

        {

            id: 436,

            country: { pl: 'Maroko', en: 'Morocco', es: 'Marruecos' },

            place: { pl: 'Dżamaa el-Fna (Marrakesz)', en: 'Djemaa el-Fna (Marrakech)', es: ' Djemaa el-Fna (Marrakech)' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Ten słynny plac w Marrakeszu tętni życiem. Odwiedzający mogą zobaczyć artystów ulicznych, czarodziejów wężów i stragany z jedzeniem. W nocy plac zamienia się w tętniącą życiem, otwartą giełdę z pysznym marokańskim jedzeniem.', en: 'This famous square in Marrakech is full of life. Visitors can see street performers, snake charmers, and food stalls. At night, the square turns into a lively open-air market with delicious Moroccan food.', es: 'Esta famosa plaza en Marrakech está llena de vida. Los visitantes pueden ver artistas callejeros, encantadores de serpientes y puestos de comida. Por la noche, la plaza se convierte en un animado mercado al aire libre con deliciosa comida marroquí.' }

        },

        {

            id: 437,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Świątynia Fushimi Inari (Kioto)', en: 'Fushimi Inari Shrine (Kyoto)', es: ' Santuario Fushimi Inari (Kioto)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta świątynia słynie z tysięcy czerwonych bram torii. Zwiedzający mogą przejść przez nie w górę wzgórza. Jest to jedno z najczęściej fotografowanych miejsc w Japonii.', en: 'This shrine is famous for its thousands of red torii gates. Visitors can walk through them up the hillside. It is one of the most photographed places in Japan.', es: 'Este santuario es famoso por sus miles de puertas torii rojas. Los visitantes pueden caminar a través de ellas subiendo la colina. Es uno de los lugares más fotografiados de Japón.' }

        },

        {

            id: 438,

            country: { pl: 'Chile', en: 'Chile', es: 'Chile' },

            place: { pl: 'Wyspa Wielkanocna (Rapa Nui)', en: 'Easter Island (Rapa Nui)', es: ' Isla de Pascua (Rapa Nui)' },

            continent: { pl: 'Oceania', en: 'Oceania', es: 'Oceanía' },

            description: { pl: 'Wyspa Wielkanocna to dom dla gigantycznych kamiennych posągów zwanych moai. Historia wyspy jest tajemnicza, a odwiedzający mogą zwiedzać starożytne miejsca. Plaże i wulkaniczne krajobrazy czynią ją jeszcze bardziej wyjątkową.', en: 'Easter Island is home to giant stone statues called moai. The island\’s history is mysterious, and visitors can explore ancient sites. The beaches and volcanic landscapes make it even more special.', es: 'La Isla de Pascua es hogar de enormes estatuas de piedra llamadas moai. La historia de la isla es misteriosa, y los visitantes pueden explorar sitios antiguos. Las playas y los paisajes volcánicos la hacen aún más especial.' }

        },

        {

            id: 439,

            country: { pl: 'Peru', en: 'Peru', es: 'Perú' },

            place: { pl: 'Machu Picchu', en: 'Machu Picchu', es: ' Machu Picchu' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Machu Picchu to jedno z najsłynniejszych starożytnych miast na świecie. Ukryte w górach, zostało zbudowane przez cywilizację Inków. Turyści mogą wędrować lub podróżować pociągiem, aby zobaczyć to cud.', en: 'Machu Picchu is one of the most famous ancient cities in the world. Hidden in the mountains, it was built by the Inca civilization. Visitors can hike or take a train to see this wonder.', es: 'Machu Picchu es una de las ciudades antiguas más famosas del mundo. Escondida en las montañas, fue construida por la civilización inca. Los visitantes pueden hacer senderismo o tomar un tren para ver esta maravilla.' }

        },

        {

            id: 440,

            country: { pl: 'Bahamy', en: 'Bahamas', es: 'Bahamas' },

            place: { pl: 'Nassau', en: 'Nassau', es: ' Nasáu' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Nassau to stolica Bahamów, znana z kolorowych budynków i bogatej historii. Odwiedzający mogą zwiedzać stare fortece, robić zakupy na rynku z wiklinowymi wyrobami lub relaksować się na pięknych plażach.', en: 'Nassau is the capital of the Bahamas, known for its colorful buildings and rich history. Visitors can explore old forts, shop at the straw market, or relax on beautiful beaches.', es: 'Nassau es la capital de las Bahamas, conocida por sus edificios coloridos y su rica historia. Los visitantes pueden explorar antiguos fuertes, comprar en el mercado de paja o relajarse en hermosas playas.' }

        },

        {

            id: 441,

            country: { pl: 'Curaçao', en: 'Curaçao', es: 'Curazao' },

            place: { pl: 'Willemstad', en: 'Willemstad', es: ' Willemstad' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Serce Pragi, ten plac ma kolorowe budynki i słynny zegar, który co godzinę pokazuje poruszające się figurki. Zwiedzający mogą wspiąć się na wieżę zegarową, aby podziwiać wspaniałe widoki.', en: 'Willemstad, the capital of Curaçao, is famous for its bright Dutch-style buildings. It has beautiful beaches, great diving spots, and a floating bridge that opens for ships.', es: 'Willemstad, la capital de Curazao, es famosa por sus brillantes edificios de estilo holandés. Tiene hermosas playas, excelentes lugares para bucear y un puente flotante que se abre para los barcos.' }

        },

        {

            id: 442,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Las Vegas (Nevada)', en: 'Las Vegas (Nevada)', es: ' Las Vegas (Nevada)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Las Vegas słynie z kasyn, pokazów i nocnego życia. Odwiedzający mogą cieszyć się światowej klasy rozrywką, zwiedzać tematyczne hotele lub zobaczyć słynny Las Vegas Strip.', en: 'Las Vegas is famous for its casinos, shows, and nightlife. Visitors can enjoy world-class entertainment, visit themed hotels, or see the famous Las Vegas Strip.', es: 'Las Vegas es famosa por sus casinos, espectáculos y vida nocturna. Los visitantes pueden disfrutar de entretenimiento de clase mundial, visitar hoteles temáticos o ver la famosa franja de Las Vegas.' }

        },

        {

            id: 443,

            country: { pl: 'Sri Lanka', en: 'Sri Lanka', es: 'Sri Lanka' },

            place: { pl: 'Sigirija', en: 'Sigiriya', es: ' Sigiriya' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Sigiriya to starożytna forteca skalna z oszałamiającymi widokami. Słynie z fresków, ogrodów i Bramy Lwa, która prowadzi na szczyt. Jest to miejsce wpisane na Listę Światowego Dziedzictwa UNESCO.', en: 'Sigiriya is an ancient rock fortress with stunning views. It is famous for its frescoes, gardens, and the Lion\’s Gate that leads to the top. It\’s a UNESCO World Heritage site.', es: 'Sigiriya es una antigua fortaleza de roca con vistas impresionantes. Es famosa por sus frescos, jardines y la Puerta del León que lleva a la cima. Es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO.' }

        },

        {

            id: 444,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Shirakawa-go', en: 'Shirakawa-go', es: ' Shirakawa-go' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Shirakawa-go to górska wioska z unikalnymi domami o stromych, strzechowych dachach. Zostały zaprojektowane tak, by wytrzymać obfite opady śniegu zimą. W pokrytej śniegiem scenerii wygląda jak z bajki.', en: 'Shirakawa-go is a mountain village with unique thatched-roof houses. These houses are designed for heavy snow in winter. It looks like a fairy tale, especially when covered in snow.', es: 'Shirakawa-go es un pueblo de montaña con casas únicas de techo de paja. Estas casas están diseñadas para soportar la nieve pesada en invierno. Se ve como un cuento de hadas, especialmente cuando está cubierto de nieve.' }

        },

        {

            id: 445,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Wyspa Komodo', en: 'Komodo Island', es: ' Isla Komodo' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta wyspa jest domem dla największych jaszczurek na świecie – smoków z Komodo. Odwiedzający mogą wziąć udział w wycieczkach z przewodnikiem, by zobaczyć je w naturalnym środowisku.', en: 'This island is home to the world\’s largest lizards, the Komodo dragons. Visitors can take guided tours to see them in their natural habitat.', es: 'Esta isla es hogar de los lagartos más grandes del mundo, los dragones de Komodo. Los visitantes pueden hacer recorridos guiados para verlos en su hábitat natural.' }

        },

        {

            id: 446,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Safari na pustyni (Dubaj)', en: 'Desert Safari (Dubai)', es: ' Safari por el Desierto (Dubái)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Safari na pustyni to ekscytująca przygoda na pustyni arabskiej. Można tu jeździć po wydmach, jeździć na wielbłądach i delektować się tradycyjną kolacją beduińską pod gwiazdami.', en: 'A desert safari is an exciting adventure in the Arabian desert. You can go dune bashing, ride camels, and enjoy a traditional Bedouin-style dinner under the stars.', es: 'Un safari por el desierto es una emocionante aventura en el desierto árabe. Puedes hacer dune bashing, montar camellos y disfrutar de una cena tradicional al estilo beduino bajo las estrellas.' }

        },

        {

            id: 447,

            country: { pl: 'Kambodża', en: 'Cambodia', es: 'Camboya' },

            place: { pl: 'Angkor Wat', en: 'Angkor Wat', es: ' Angkor Wat' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Angkor Wat to największy religijny kompleks na świecie. Starożytne świątynie pokryte są rzeźbieniami i otoczone dżunglą. Najlepiej odwiedzać je o wschodzie słońca, gdy świątynie lśnią złotem.', en: 'Angkor Wat is the largest religious monument in the world. This ancient temple complex is covered in carvings and surrounded by jungle. It is best visited at sunrise when the temples glow golden.', es: 'Angkor Wat es el monumento religioso más grande del mundo. Este antiguo complejo de templos está cubierto de tallas y rodeado de jungla. Es mejor visitarlo al amanecer, cuando los templos brillan en dorado.' }

        },

        {

            id: 448,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Twierdza San Felipe', en: 'Fortress of San Felipe', es: ' Fortaleza de San Felipe' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ta ogromna twierdza w Cartagena została zbudowana, by chronić miasto przed piratami. Odwiedzający mogą zwiedzać tunele, wspinać się na mury i podziwiać widoki na stare miasto. To jeden z najważniejszych zabytków Kolumbii.', en: 'This huge fortress in Cartagena was built to protect the city from pirates. Visitors can explore tunnels, climb the walls, and enjoy views of the old town. It is one of Colombia\’s top landmarks.', es: 'Esta enorme fortaleza en Cartagena fue construida para proteger la ciudad de los piratas. Los visitantes pueden explorar túneles, subir por las murallas y disfrutar de vistas de la ciudad vieja. Es uno de los principales puntos de referencia de Colombia.' }

        },

        {

            id: 449,

            country: { pl: 'Namibia', en: 'Namibia', es: 'Namibia' },

            place: { pl: 'Wybrzeże Szkieletów', en: 'Skeleton Coast', es: ' Costa de los Esqueletos' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'To odległe wybrzeże słynie z wraków statków, mgły i pustynnych krajobrazów. Mieszanka wydm i oceanu tworzy dramatyczny widok. To świetne miejsce dla podróżników szukających przygód.', en: 'This remote coastline is famous for shipwrecks, fog, and desert landscapes. The mix of sand dunes and ocean makes it a dramatic sight. It\’s a great place for adventurous travelers.', es: 'Esta remota costa es famosa por naufragios, niebla y paisajes desérticos. La mezcla de dunas de arena y océano la convierte en un espectáculo dramático. Es un lugar ideal para los viajeros aventureros.' }

        },

        {

            id: 450,

            country: { pl: 'Honduras', en: 'Honduras', es: 'Honduras' },

            place: { pl: 'Roatán', en: 'Roatán', es: ' Roatán' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Roatán to karaibska wyspa z rafami koralowymi i piaszczystymi plażami. Jest idealnym miejscem do nurkowania i snorkelingu. Zwiedzający mogą także zobaczyć leniwce i małpy w parkach przyrody.', en: 'Roatán is a Caribbean island with coral reefs and sandy beaches. It is great for snorkeling and diving. Visitors can also see sloths and monkeys in nature parks.', es: 'Roatán es una isla caribeña con arrecifes de coral y playas de arena. Es ideal para practicar esnórquel y buceo. Los visitantes también pueden ver perezosos y monos en parques naturales.' }

        },

        {

            id: 451,

            country: { pl: 'Trynidad i Tobago', en: 'Trinidad & Tobago', es: 'Trinidad y Tobago' },

            place: { pl: 'Port Hiszpanii', en: 'Port of Spain', es: ' Puerto de España' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Port of Spain to tętniąca życiem stolica Trynidadu i Tobago. Znana jest z żywiołowych rynków, pięknych plaż i corocznych obchodów Karnawału. Miasto pełne jest kultury i historii.', en: 'Port of Spain is the vibrant capital of Trinidad & Tobago. It is known for its lively markets, beautiful beaches, and annual Carnival celebrations. The city is full of culture and history.', es: 'Puerto de España es la vibrante capital de Trinidad y Tobago. Es conocida por sus mercados animados, hermosas playas y celebraciones anuales de Carnaval. La ciudad está llena de cultura e historia.' }

        },

        {

            id: 452,

            country: { pl: 'Malta', en: 'Malta', es: 'Malta' },

            place: { pl: 'Świątynie Ġgantija (Gozo)', en: 'Ġgantija Temples (Gozo)', es: ' Templos Ġgantija (Gozo)' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Te świątynie mają ponad 5 000 lat, są starsze niż piramidy w Egipcie. Zbudowane są z gigantycznych bloków kamiennych. Odwiedzający mogą spacerować po ruinach i wyobrażać sobie życie w czasach starożytnych.', en: 'These temples are over 5,000 years old, older than the pyramids of Egypt. They are made of giant stone blocks. Visitors can walk through the ruins and imagine life in ancient times.', es: 'Estos templos tienen más de 5,000 años, más antiguos que las pirámides de Egipto. Están hechos de enormes bloques de piedra. Los visitantes pueden caminar por las ruinas e imaginar la vida en tiempos antiguos.' }

        },

        {

            id: 453,

            country: { pl: 'Czarnogóra', en: 'Montenegro', es: 'Montenegro' },

            place: { pl: 'Klasztor Ostrog', en: 'Ostrog Monastery', es: ' Monasterio de Ostrog' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten wspaniały klasztor zbudowany jest na zboczu klifu wysoko nad doliną. Jest ważnym miejscem pielgrzymkowym i oferuje niesamowite widoki. Odwiedzający mogą podziwiać unikalną architekturę i spokojną okolicę.', en: 'This stunning monastery is built into a cliffside high above the valley. It is an important pilgrimage site and offers incredible views. Visitors can admire the unique architecture and peaceful setting.', es: 'Este impresionante monasterio está construido en un acantilado, a gran altura sobre el valle. Es un importante sitio de peregrinaje y ofrece vistas increíbles. Los visitantes pueden admirar su arquitectura única y su entorno tranquilo.' }

        },

        {

            id: 454,

            country: { pl: 'Kanada', en: 'Canada', es: 'Canadá' },

            place: { pl: 'Jezioro Peyto (Alberta)', en: 'Peyto Lake (Alberta)', es: ' Lago Peyto (Alberta)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Jezioro Peyto słynie z jasnoturkusowej wody. Najlepszy widok jest z wysokiego punktu, gdzie jezioro przypomina głowę wilka. To jedno z najczęściej fotografowanych miejsc w Kanadzie.', en: 'Peyto Lake is famous for its bright turquoise water. The best view is from a high lookout, where the lake looks like a wolf\’s head. It is one of the most photographed places in Canada.', es: 'El Lago Peyto es famoso por su agua turquesa brillante. La mejor vista es desde un mirador alto, donde el lago parece la cabeza de un lobo. Es uno de los lugares más fotografiados de Canadá.' }

        },

        {

            id: 455,

            country: { pl: 'Barbados', en: 'Barbados', es: 'Barbados' },

            place: { pl: 'Bathsheba', en: 'Bathsheba', es: ' Bathsheba' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Bathsheba to mała wioska znana z dzikich plaż i dużych fal. To ulubione miejsce surferów, a odwiedzający mogą podziwiać dramatyczne formacje skalne i relaksujące widoki na ocean.', en: 'Bathsheba is a small village known for its wild beaches and big waves. Surfers love this spot, and visitors can enjoy dramatic rock formations and relaxing ocean views.', es: 'Bathsheba es un pequeño pueblo conocido por sus playas salvajes y grandes olas. A los surfistas les encanta este lugar, y los visitantes pueden disfrutar de formaciones rocosas dramáticas y relajantes vistas al océano.' }

        },

        {

            id: 456,

            country: { pl: 'Korea Południowa', en: 'South Korea', es: 'Corea del Sur' },

            place: { pl: 'Świątynia Haedong Yonggungsa', en: 'Haedong Yonggungsa Temple', es: ' Templo Haedong Yonggungsa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Haedong Yonggungsa to piękna nadmorska świątynia w Busan. Znana jest z malowniczego położenia przy oceanie, gdzie zwiedzający mogą cieszyć się widokiem morza, zwiedzając struktury świątyni.', en: 'Haedong Yonggungsa is a beautiful seaside temple in Busan. It\’s known for its scenic location by the ocean, where visitors can enjoy views of the sea while exploring the temple\’s structures.', es: 'Haedong Yonggungsa es un hermoso templo junto al mar en Busan. Es conocido por su ubicación pintoresca junto al océano, donde los visitantes pueden disfrutar de vistas del mar mientras exploran las estructuras del templo.' }

        },

        {

            id: 457,

            country: { pl: 'Boliwia', en: 'Bolivia', es: 'Bolivia' },

            place: { pl: 'Park Narodowy Madidi', en: 'Madidi National Park', es: ' Parque Nacional Madidi' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ten las deszczowy jest domem dla jaguarów, małp i rzadkich ptaków. Odwiedzający mogą eksplorować rzeki, wędrować po dżungli i podziwiać niezwykłą dziką przyrodę.', en: 'This rainforest is home to jaguars, monkeys, and rare birds. Visitors can explore rivers, hike through the jungle, and experience incredible wildlife.', es: 'Esta selva tropical es hogar de jaguares, monos y aves raras. Los visitantes pueden explorar ríos, hacer senderismo por la jungla y experimentar una vida salvaje increíble.' }

        },

        {

            id: 458,

            country: { pl: 'Bahrajn', en: 'Bahrain', es: 'Baréin' },

            place: { pl: 'Bahrajn World Trade Center', en: 'Bahrain World Trade Center', es: ' Centro Mundial de Comercio de Baréin' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ten nowoczesny wieżowiec słynie z unikalnego designu z turbiną wiatrową. Bliźniacze wieże wznoszą się wysoko nad Manamą i są symbolem nowoczesnego rozwoju Bahrajnu.', en: 'This modern skyscraper is famous for its unique wind turbine design. The twin towers stand high above Manama and are a symbol of Bahrain\’s modern development.', es: 'Este rascacielos moderno es famoso por su diseño único de turbina eólica. Las torres gemelas se alzan por encima de Manama y son un símbolo del desarrollo moderno de Bahréin.' }

        },

        {

            id: 459,

            country: { pl: 'Dominikana', en: 'Dominican Republic', es: 'República Dominicana' },

            place: { pl: 'Wyspa Saona', en: 'Saona Island', es: ' Isla Saona' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Te świątynie zostały wykute w skale przez faraona Ramzesa II. Posągi przy wejściu są ogromne. Każdego roku promienie słońca oświetlają wewnętrzną komnatę świątyni w określone dni.', en: 'This island has crystal-clear waters, palm trees, and soft white sand. Many visitors come on boat tours to relax, swim, and enjoy fresh seafood on the beach.', es: 'Esta isla tiene aguas cristalinas, palmeras y suave arena blanca. Muchos visitantes llegan en tours en barco para relajarse, nadar y disfrutar de mariscos frescos en la playa.' }

        },

        {

            id: 460,

            country: { pl: 'Egipt', en: 'Egypt', es: 'Egipto' },

            place: { pl: 'Świątynia Karnaku', en: 'Temple of Karnak', es: ' Templo de Karnak' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Wieża Eiffla to najsłynniejszy zabytek Francji. Zwiedzający mogą wjechać windą na szczyt, by podziwiać zapierające dech w piersiach widoki Paryża. Nocą jest pięknie oświetlona.', en: 'This massive temple complex is one of the largest in the world. It has giant pillars, statues, and sacred lakes. Walking through its ancient halls feels like stepping back in time.', es: 'Este enorme complejo de templos es uno de los más grandes del mundo. Tiene pilares gigantes, estatuas y lagos sagrados. Caminar por sus antiguos pasillos es como retroceder en el tiempo.' }

        },

        {

            id: 461,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Fontanny Bellagio', en: 'Bellagio Fountains', es: ' Fuentes del Bellagio' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Pokaz Fontanny Bellagio to jedna z najsłynniejszych atrakcji w Las Vegas. Pokazuje wodotryski tańczące do muzyki i kolorowe światła, tworząc spektakularne widowisko. Pokaz odbywa się kilka razy dziennie, oferując magiczne przeżycie dla odwiedzających.', en: 'The Bellagio Fountain Show is one of the most famous attractions in Las Vegas. It features water jets that dance to music and colorful lights, creating a spectacular display. The show happens several times a day, offering a magical experience for visitors.', es: 'El espectáculo de la Fuente Bellagio es una de las atracciones más famosas de Las Vegas. Presenta chorros de agua que bailan al ritmo de la música y luces coloridas, creando un espectáculo espectacular. El show ocurre varias veces al día, ofreciendo una experiencia mágica para los visitantes.' }

        },

        {

            id: 462,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Góra Kinabalu', en: 'Mount Kinabalu', es: ' Monte Kinabalu' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Góra Kinabalu to najwyższy szczyt Malezji. Jej zdobycie to popularne wyzwanie dla miłośników przygód. Góra leży w parku narodowym pełnym rzadkich roślin i zwierząt.', en: 'Mount Kinabalu is the highest peak in Malaysia. Climbing it is a popular challenge for adventurers. The mountain is part of a national park filled with rare plants and animals.', es: 'El monte Kinabalu es el pico más alto de Malasia. Subirlo es un desafío popular para los aventureros. La montaña es parte de un parque nacional lleno de plantas y animales raros.' }

        },

        {

            id: 463,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Świątynia Uluwatu', en: 'Uluwatu Temple', es: ' Templo Uluwatu' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta świątynia znajduje się na wysokim klifie z widokiem na ocean. Słynie z zachodów słońca i tradycyjnych pokazów tańca.', en: 'This temple sits on a high cliff overlooking the ocean. It is famous for its sunset views and traditional dance performances.', es: 'Este templo se encuentra sobre un alto acantilado con vistas al océano. Es famoso por sus vistas al atardecer y sus danzas tradicionales.' }

        },

        {

            id: 464,

            country: { pl: 'Montserrat', en: 'Montserrat', es: 'Montserrat' },

            place: { pl: 'Plymouth (opuszczony)', en: 'Plymouth (Abandoned)', es: ' Plymouth (Abandonado)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Plymouth było niegdyś stolicą Montserratu, ale zostało porzucone po wybuchu wulkanu w 1997 roku. Miasto jest teraz pokryte popiołem, tworząc upiorną scenerię. To unikalne i tajemnicze miejsce do odwiedzenia.', en: 'Plymouth was once the capital of Montserrat but was abandoned after a volcanic eruption in 1997. The town is now covered in ash, creating a ghostly scene. It\’s a unique and eerie place to visit.', es: 'Plymouth fue una vez la capital de Montserrat, pero fue abandonada después de una erupción volcánica en 1997. La ciudad está ahora cubierta de ceniza, creando una escena fantasmal. Es un lugar único y espeluznante para visitar.' }

        },

        {

            id: 465,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Zakazane Miasto (Pekin)', en: 'Forbidden City (Beijing)', es: ' Ciudad Prohibida (Pekín)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Zakazane Miasto było przez wieki siedzibą chińskich cesarzy. Ma piękne pałace, dziedzińce i złote dachy. Odwiedzający mogą zgłębiać jego historię i podziwiać imponującą architekturę.', en: 'The Forbidden City was home to Chinese emperors for centuries. It has beautiful palaces, courtyards, and golden roofs. Visitors can explore its history and see the impressive architecture.', es: 'La Ciudad Prohibida fue el hogar de los emperadores chinos durante siglos. Tiene hermosos palacios, patios y techos dorados. Los visitantes pueden explorar su historia y admirar la impresionante arquitectura.' }

        },

        {

            id: 466,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Jezioro Tahoe', en: 'Lake Tahoe', es: ' Lago Tahoe' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Jezioro Tahoe to duże, czyste jezioro słodkowodne otoczone górami. To popularne miejsce na narciarstwo, wędrówki i sporty wodne.', en: 'Lake Tahoe is a large, clear freshwater lake surrounded by mountains. It\’s a popular destination for skiing, hiking, and water sports.', es: 'El Lago Tahoe es un gran lago de agua dulce y clara rodeado de montañas. Es un destino popular para esquiar, hacer senderismo y practicar deportes acuáticos.' }

        },

        {

            id: 467,

            country: { pl: 'Chiny', en: 'China', es: 'China' },

            place: { pl: 'Bund w Szanghaju', en: 'Shanghai Bund', es: ' Bund de Shanghái' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Bund to zabytkowa dzielnica nadbrzeżna w Szanghaju. Z jednej strony znajdują się piękne budynki z czasów kolonialnych, a z drugiej nowoczesne drapacze chmur. Spacer wzdłuż rzeki nocą jest niezapomniany.', en: 'The Bund is a historic waterfront area in Shanghai. It has beautiful colonial-era buildings on one side and modern skyscrapers on the other. A walk along the river at night is unforgettable.', es: 'El Bund es una zona histórica frente al mar en Shanghái. Tiene hermosos edificios de la época colonial de un lado y rascacielos modernos del otro. Un paseo a lo largo del río por la noche es inolvidable.' }

        },

        {

            id: 468,

            country: { pl: 'Wenezuela', en: 'Venezuela', es: 'Venezuela' },

            place: { pl: 'Wodospady Angel', en: 'Angel Falls', es: ' Salto Ángel' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Wodospad Angel Falls to najwyższy wodospad na świecie, położony w Wenezueli w Parku Narodowym Canaima. Wodospad spada o niesamowite 979 metrów, otoczony bujną dżunglą.', en: 'Angel Falls is the world\’s highest uninterrupted waterfall, located in Venezuela\’s Canaima National Park. The falls drop an incredible 979 meters, surrounded by lush jungle.', es: 'El Salto Ángel es la cascada más alta del mundo, ubicada en el Parque Nacional Canaima de Venezuela. Las cataratas caen a una impresionante altura de 979 metros, rodeadas de una exuberante selva.' }

        },

        {

            id: 469,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Znak Hollywood', en: 'Hollywood Sign', es: ' Cartel de Hollywood' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Znak Hollywood to ikona Los Angeles. Odwiedzający mogą wspiąć się na szczyt wzgórza, aby podziwiać wspaniałe widoki na miasto i słynny znak.', en: 'The Hollywood Sign is an iconic symbol of Los Angeles. Visitors can hike to the top of the hill for great views of the city and the famous sign.', es: 'El cartel de Hollywood es un símbolo icónico de Los Ángeles. Los visitantes pueden hacer una caminata hasta la cima de la colina para disfrutar de las vistas de la ciudad y del famoso cartel.' }

        },

        {

            id: 470,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Nashville', en: 'Nashville', es: ' Nashville' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Nashville jest znane jako "Miasto Muzyki" z powodu bogatej historii muzyki country. Miasto ma wiele słynnych zabytków, w tym Grand Ole Opry i Country Music Hall of Fame. Odwiedzający mogą cieszyć się występami na żywo i poznać historię muzyki country.', en: 'Nashville is known as the "Music City" because of its rich country music history. The city has many famous landmarks, including the Grand Ole Opry and the Country Music Hall of Fame. Visitors can enjoy live music performances and learn about the history of country music.', es: 'Nashville es conocida como la "Ciudad de la Música" debido a su rica historia de música country. La ciudad tiene muchos monumentos famosos, incluidos el Grand Ole Opry y el Salón de la Fama de la Música Country. Los visitantes pueden disfrutar de actuaciones en vivo y aprender sobre la historia de la música country.' }

        },

        {

            id: 471,

            country: { pl: 'Izrael', en: 'Israel', es: 'Israel' },

            place: { pl: 'Masada (twierdza)', en: 'Masada (Fortress)', es: ' Masada (Fortaleza)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Masada to starożytna forteca na wysokim klifie. Oferuje niesamowite widoki na Morze Martwe. Odwiedzający mogą wspiąć się pieszo lub wjechać kolejką linową, by zwiedzić ruiny.', en: 'Masada is an ancient fortress on a high cliff. It has amazing views of the Dead Sea. Visitors can hike up or take a cable car to explore the ruins.', es: 'Masada es una antigua fortaleza en un acantilado alto. Tiene vistas impresionantes al Mar Muerto. Los visitantes pueden subir a pie o tomar un teleférico para explorar las ruinas.' }

        },

        {

            id: 472,

            country: { pl: 'Tanzania', en: 'Tanzania', es: 'Tanzania' },

            place: { pl: 'Plaże Zanzibaru i Kamienne Miasto', en: 'Zanzibar Beaches & Stone Town', es: ' Playas de Zanzíbar y Stone Town' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Zanzibar oferuje piękne, białe piaszczyste plaże i krystalicznie czyste wody. Stone Town to zabytkowa dzielnica z wąskimi uliczkami i starymi budynkami. To doskonałe miejsce do relaksu i zwiedzania.', en: 'Zanzibar offers beautiful white sandy beaches and crystal-clear waters. Stone Town is a historic area with narrow streets and old buildings. It\’s a perfect place to relax and explore.', es: 'Zanzibar ofrece hermosas playas de arena blanca y aguas cristalinas. Stone Town es una zona histórica con calles estrechas y edificios antiguos. Es un lugar perfecto para relajarse y explorar.' }

        },

        {

            id: 473,

            country: { pl: 'Boliwia', en: 'Bolivia', es: 'Bolivia' },

            place: { pl: 'Salar de Uyuni', en: 'Salar de Uyuni', es: ' Salar de Uyuni' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Salar de Uyuni to największa solna pustynia na świecie. Po deszczu zamienia się w gigantyczne lustro. Zwiedzający mogą robić niesamowite zdjęcia i zobaczyć słynne hotele z soli.', en: 'Salar de Uyuni is the largest salt flat in the world. After rain, it turns into a giant mirror. Visitors can take amazing photos and see the famous salt hotels.', es: 'El Salar de Uyuni es el mayor salar del mundo. Después de la lluvia, se convierte en un gigantesco espejo. Los visitantes pueden tomar fotos increíbles y ver los famosos hoteles de sal.' }

        },

        {

            id: 474,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Sedona (Arizona)', en: 'Sedona (Arizona)', es: ' Sedona (Arizona)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Sedona to oszałamiające miasteczko na pustyni, otoczone czerwonymi formacjami skalnymi. Jest znane z szlaków turystycznych, duchowej energii i pięknych krajobrazów.', en: 'Sedona is a stunning desert town surrounded by red rock formations. It is known for its hiking trails, spiritual energy, and beautiful landscapes.', es: 'Sedona es una impresionante ciudad desértica rodeada de formaciones rocosas rojas. Es conocida por sus senderos de senderismo, energía espiritual y hermosos paisajes.' }

        },

        {

            id: 475,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Miami (Floryda)', en: 'Miami (Florida)', es: ' Miami (Florida)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Miami jest znane z słonecznej pogody, pięknych plaż i tętniącego życiem nocnego miasta. Odwiedzający mogą zwiedzać architekturę w stylu Art Deco, relaksować się na South Beach lub zwiedzać pobliskie Everglades.', en: 'Miami is known for its sunny weather, beautiful beaches, and vibrant nightlife. Visitors can explore Art Deco architecture, relax on South Beach, or visit the nearby Everglades.', es: 'Miami es conocida por su clima soleado, hermosas playas y vibrante vida nocturna. Los visitantes pueden explorar la arquitectura Art Deco, relajarse en South Beach o visitar los Everglades cercanos.' }

        },

        {

            id: 476,

            country: { pl: 'Indonezja', en: 'Indonesia', es: 'Indonesia' },

            place: { pl: 'Góra Bromo', en: 'Mount Bromo', es: ' Monte Bromo' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Góra Bromo to aktywny wulkan z zapierającymi dech w piersiach krajobrazami. Odwiedzający wstają wcześnie, by zobaczyć wschód słońca nad kraterem. Widok jest wart wysiłku!', en: 'Mount Bromo is an active volcano with stunning landscapes. Visitors wake up early to see the sunrise over the crater. The view is worth the effort!', es: 'El Monte Bromo es un volcán activo con paisajes impresionantes. Los visitantes se levantan temprano para ver el amanecer sobre el cráter. ¡Vale la pena el esfuerzo!' }

        },

        {

            id: 477,

            country: { pl: 'Singapur', en: 'Singapore', es: 'Singapur' },

            place: { pl: 'Wyspa Sentosa', en: 'Sentosa Island', es: ' Isla Sentosa' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wyspa Sentosa to kurort z pięknymi plażami, luksusowymi ośrodkami i atrakcjami, takimi jak Universal Studios Singapore. To świetne miejsce na wypoczynek i przygodę.', en: 'Sentosa Island is a resort destination with beautiful beaches, luxury resorts, and attractions like Universal Studios Singapore. It\’s a fun getaway for both adventure and relaxation.', es: 'La Isla Sentosa es un destino turístico con hermosas playas, complejos turísticos de lujo y atracciones como Universal Studios Singapur. Es una escapada divertida tanto para la aventura como para el relax.' }

        },

        {

            id: 478,

            country: { pl: 'Macedonia Północna', en: 'North Macedonia', es: 'Macedonia del Norte' },

            place: { pl: 'Kanion Matka', en: 'Matka Canyon', es: ' Cañón de Matka' },

            continent: { pl: 'Europa', en: 'Europe', es: 'Europa' },

            description: { pl: 'Ten kanion jest idealny dla miłośników przyrody. Turyści mogą płynąć kajakiem po rzece, wędrować po wzgórzach lub odwiedzać jaskinie. Spokojne otoczenie czyni to miejsce doskonałym na ucieczkę od miasta.', en: 'This canyon is perfect for nature lovers. Visitors can kayak on the river, hike in the hills, or visit caves. The peaceful environment makes it a great escape from the city.', es: 'Este cañón es perfecto para los amantes de la naturaleza. Los visitantes pueden hacer kayak en el río, hacer senderismo en las colinas o visitar cuevas. El ambiente tranquilo lo convierte en una gran escapatoria de la ciudad.' }

        },

        {

            id: 479,

            country: { pl: 'Izrael', en: 'Israel', es: 'Israel' },

            place: { pl: 'Kopuła na Skale', en: 'Dome of the Rock', es: ' Cúpula de la Roca' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Ta złota kopuła to jedno z najważniejszych miejsc religijnych w Jerozolimie. Stoi na Wzgórzu Świątynnym i jest oszałamiającym zabytkiem.', en: 'This golden-domed shrine is one of the most important religious sites in Jerusalem. It sits on the Temple Mount and is a stunning landmark.', es: 'Este santuario de cúpula dorada es uno de los sitios religiosos más importantes de Jerusalén. Se encuentra en el Monte del Templo y es un monumento impresionante.' }

        },

        {

            id: 480,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Memphis (Tennessee)', en: 'Memphis (Tennessee)', es: ' Memphis (Tennessee)' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: {
                pl: 'Memphis to miejsce narodzin bluesa i rock \'n\' rolla. Odwiedzający mogą zwiedzać Graceland, odwiedzać Narodowe Muzeum Praw Człowieka i delektować się pysznym jedzeniem z Południa.',
                en: 'Memphis is the birthplace of blues and rock ‘n’ roll. Visitors can explore Graceland, visit the National Civil Rights Museum, and enjoy delicious Southern food.',
                es: 'Memphis es la cuna del blues y el rock \'n\' roll. Los visitantes pueden explorar Graceland, visitar el Museo Nacional de los Derechos Civiles y disfrutar de deliciosa comida sureña.'
            },
        },

        {

            id: 481,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Palma Jumeirah (Dubaj)', en: 'Palm Jumeirah (Dubai)', es: ' Palm Jumeirah (Dubái)' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Palm Jumeirah to sztuczna wyspa w kształcie palmy. Znajdują się na niej luksusowe hotele, ośrodki wypoczynkowe i wykwintne restauracje. Turyści mogą odbyć wycieczkę łodzią lub odwiedzić ośrodek Atlantis, by przeżyć niezapomniane chwile.', en: 'Palm Jumeirah is a man-made island shaped like a palm tree. It has luxury hotels, resorts, and fine dining. Visitors can take a boat tour or visit the Atlantis resort for a fun experience.', es: 'Palm Jumeirah es una isla artificial con forma de palma. Tiene hoteles de lujo, resorts y restaurantes de alta gama. Los visitantes pueden hacer un recorrido en barco o visitar el resort Atlantis para vivir una experiencia divertida.' }

        },

        {

            id: 482,

            country: { pl: 'Kolumbia', en: 'Colombia', es: 'Colombia' },

            place: { pl: 'Cartagena', en: 'Cartagena', es: ' Cartagena' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Cartagena to nadmorskie miasto z piękną kolonialną architekturą. Otoczone murami Stare Miasto pełne jest tętniących życiem placów i balkonów pokrytych kwiatami. Plaże i pobliskie wyspy sprawiają, że jest to idealne miejsce na wypoczynek.', en: 'Cartagena is a coastal city with beautiful colonial architecture. The walled Old Town is full of lively squares and flower-covered balconies. The beaches and nearby islands make it a perfect getaway.', es: 'Cartagena es una ciudad costera con hermosa arquitectura colonial. El casco antiguo amurallado está lleno de plazas animadas y balcones cubiertos de flores. Las playas y las islas cercanas la convierten en un destino perfecto.' }

        },

        {

            id: 483,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Kauai', en: 'Kauai', es: ' Kauai' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: {
                pl: 'Kauai to najstarsza wyspa Hawajów, znana z bujnych krajobrazów, wodospadów i szlaków turystycznych. Odwiedzający mogą zwiedzać Wąwóz Waimea i Na Pali Coast.', en: 'Kauai is the oldest of Hawaii\'s islands, known for lush landscapes, waterfalls, and hiking trails.Visitors can explore Waimea Canyon and the Napali Coast.', es: 'Kauai es la isla más antigua de Hawái, conocida por sus paisajes exuberantes, cascadas y senderos para caminar.Los visitantes pueden explorar el Cañón Waimea y la Costa Napali.'
            }

        },

        {

            id: 484,

            country: { pl: 'Zjednoczone Emiraty Arabskie', en: 'United Arab Emirates', es: 'Emiratos Árabes Unidos' },

            place: { pl: 'Marina w Dubaju', en: 'Dubai Marina', es: ' Marina de Dubái' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Dubai Marina to tętniąca życiem dzielnica nadmorska pełna restauracji, sklepów i drapaczy chmur. Można tu popłynąć łodzią, spacerować po promenadzie lub zrelaksować się nad wodą.', en: 'Dubai Marina is a lively waterfront district full of restaurants, shops, and skyscrapers. You can take a boat ride, stroll along the promenade, or relax by the water.', es: 'Dubai Marina es un animado distrito junto al agua lleno de restaurantes, tiendas y rascacielos. Puedes dar un paseo en barco, caminar por el paseo marítimo o relajarte junto al agua.' }

        },

        {

            id: 485,

            country: { pl: 'Katar', en: 'Qatar', es: 'Catar' },

            place: { pl: 'Suk Waqif', en: 'Souq Waqif', es: ' Souq Waqif' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Souq Waqif to tradycyjny bazar w Dosze. Ma wąskie uliczki pełne przypraw, tkanin i pamiątek. Odwiedzający mogą skosztować lokalnych potraw i oglądać występy ulicznych artystów.', en: 'Souq Waqif is a traditional market in Doha. It has narrow streets filled with spices, fabrics, and souvenirs. Visitors can taste local food and see street performances.', es: 'Souq Waqif es un mercado tradicional en Doha. Tiene calles estrechas llenas de especias, telas y recuerdos. Los visitantes pueden probar comida local y ver actuaciones callejeras.' }

        },

        {

            id: 486,

            country: { pl: 'Brazylia', en: 'Brazil', es: 'Brasil' },

            place: { pl: 'Rio de Janeiro', en: 'Rio de Janeiro', es: ' Río de Janeiro' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Rio słynie z plaż, karnawału i posągu Chrystusa Odkupiciela. Zwiedzający mogą podziwiać Głowę Cukru, słuchać samby i cieszyć się niesamowitymi widokami miasta.', en: 'Rio is famous for its beaches, carnival, and Christ the Redeemer statue. Visitors can enjoy Sugarloaf Mountain, samba music, and amazing city views.', es: 'Río de Janeiro es famoso por sus playas, su carnaval y la estatua del Cristo Redentor. Los visitantes pueden disfrutar del Pan de Azúcar, la música samba y vistas increíbles de la ciudad.' }

        },

        {

            id: 487,

            country: { pl: 'Paragwaj', en: 'Paraguay', es: 'Paraguay' },

            place: { pl: 'Asuncion', en: 'Asunción', es: ' Asunción' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Asunción to stolica Paragwaju, pełna historii i kolonialnych budynków. Miasto ma tętniące życiem rynki, zielone parki i mieszankę nowoczesnej i starej architektury.', en: 'Asunción is Paraguay\’s capital, full of history and colonial buildings. The city has lively markets, green parks, and a mix of modern and old architecture.', es: 'Asunción es la capital de Paraguay, llena de historia y edificios coloniales. La ciudad tiene mercados animados, parques verdes y una mezcla de arquitectura moderna y antigua.' }

        },

        {

            id: 488,

            country: { pl: 'Jordania', en: 'Jordan', es: 'Jordania' },

            place: { pl: 'Pustynia Wadi Rum', en: 'Wadi Rum Desert', es: ' Desierto de Wadi Rum' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Wadi Rum to pustynia o czerwonym piasku i ogromnych formacjach skalnych. Przypomina Marsa! Zwiedzający mogą odbyć wycieczki jeepem, jeździć na wielbłądach i spać pod gwiazdami w beduińskich obozach.', en: 'Wadi Rum is a desert with red sand and huge rock formations. It looks like Mars! Visitors can take jeep tours, ride camels, and sleep under the stars in Bedouin camps.', es: 'Wadi Rum es un desierto con arena roja y enormes formaciones rocosas. ¡Parece Marte! Los visitantes pueden hacer recorridos en jeep, montar camellos y dormir bajo las estrellas en campamentos beduinos.' }

        },

        {

            id: 489,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Park Narodowy Everglades', en: 'Everglades National Park', es: ' Parque Nacional Everglades' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Everglades to unikalny park narodowy pełen bagien, dzikiej przyrody i aligatorów. Odwiedzający mogą wybrać się na wycieczki łodzią powietrzną, zwiedzać mokradła i podziwiać rzadkie gatunki ptaków.', en: 'The Everglades is a unique national park full of swamps, wildlife, and alligators. Visitors can take airboat tours, explore the wetlands, and see rare bird species.', es: 'Los Everglades son un parque nacional único lleno de pantanos, vida salvaje y caimanes. Los visitantes pueden hacer tours en aerodeslizador, explorar los humedales y ver especies raras de aves.' }

        },

        {

            id: 490,

            country: { pl: 'Japonia', en: 'Japan', es: 'Japón' },

            place: { pl: 'Przejście Shibuya w Tokio', en: 'Tokyo\’s Shibuya Crossing', es: ' Cruce de Shibuya en Tokio' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Shibuya Crossing to najbardziej ruchliwe przejście dla pieszych na świecie. Setki ludzi przechodzą przez nie jednocześnie. Nocą jasne neony sprawiają, że jest jeszcze bardziej ekscytujące.', en: 'Shibuya Crossing is the world\’s busiest pedestrian crossing. Hundreds of people walk across at the same time. At night, the bright neon lights make it even more exciting.', es: 'El Cruce de Shibuya es el cruce peatonal más concurrido del mundo. Cientos de personas cruzan al mismo tiempo. Por la noche, las luces brillantes de neón lo hacen aún más emocionante.' }

        },

        {

            id: 491,

            country: { pl: 'Uganda', en: 'Uganda', es: 'Uganda' },

            place: { pl: 'Nieprzenikniony las Bwindi (goryle górskie)', en: 'Bwindi Impenetrable Forest (Mountain Gorillas)', es: ' Bosque Impenetrable de Bwindi (Gorilas de Montaña)' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Bwindi to obszar wpisany na Listę Światowego Dziedzictwa UNESCO, znany z goryli górskich. Turyści mogą odbyć trekking przez las, by zobaczyć te wspaniałe stworzenia z bliska. Region ten jest również domem dla różnorodnej fauny i flory.', en: 'Bwindi is a UNESCO World Heritage Site known for its mountain gorillas. Visitors can trek through the forest to see these magnificent creatures up close. The area is also home to diverse wildlife and plants.', es: 'Bwindi es un sitio del Patrimonio Mundial de la Humanidad de la UNESCO conocido por sus gorilas de montaña. Los visitantes pueden hacer un trekking a través del bosque para ver de cerca a estas majestuosas criaturas. La zona también alberga una gran diversidad de vida silvestre y plantas.' }

        },

        {

            id: 492,

            country: { pl: 'USA', en: 'USA', es: 'EE.UU.' },

            place: { pl: 'Waszyngton, D.C.', en: 'Washington, D.C.', es: ' Washington D.C.' },

            continent: { pl: 'Ameryka Północna', en: 'North America', es: 'Norteamérica' },

            description: { pl: 'Waszyngton, D.C. to stolica USA, pełna zabytkowych pomników i muzeów. Odwiedzający mogą zwiedzać Biały Dom, odwiedzać Pomnik Lincolna lub zwiedzać muzea Smithsonian.', en: 'Washington, D.C. is the U.S. capital, home to historic monuments and museums. Visitors can tour the White House, visit the Lincoln Memorial, or explore the Smithsonian museums.', es: 'Washington, D.C. es la capital de los EE. UU., hogar de monumentos históricos y museos. Los visitantes pueden recorrer la Casa Blanca, visitar el Monumento a Lincoln o explorar los museos Smithsonian.' }

        },

        {

            id: 493,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Great Ocean Road', en: 'Great Ocean Road', es: ' Great Ocean Road' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'To jedna z najpiękniejszych tras nadmorskich na świecie. Droga prowadzi przez klify, plaże i lasy deszczowe. Zwiedzający mogą zatrzymać się w punktach widokowych i zobaczyć słynne Dwunastu Apostołów.', en: 'This is one of the most beautiful coastal drives in the world. The road passes cliffs, beaches, and rainforests. Visitors can stop at lookouts and see the famous Twelve Apostles.', es: 'Este es uno de los recorridos costeros más hermosos del mundo. El camino pasa por acantilados, playas y selvas tropicales. Los visitantes pueden detenerse en miradores y ver los famosos Doce Apóstoles.' }

        },

        {

            id: 494,

            country: { pl: 'Mjanma', en: 'Myanmar', es: 'Myanmar' },

            place: { pl: 'Bagan – tysiące starożytnych świątyń', en: 'Bagan – Thousands of ancient temples', es: ' Bagan – Miles de templos antiguos' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Bagan to miejsce, gdzie znajduje się ponad 2,000 starożytnych świątyń. Odwiedzający mogą je zwiedzać na rowerze, wozem konnym lub balonem na gorące powietrze. Wschód słońca nad świątyniami to niezapomniany widok.', en: 'Bagan is home to over 2,000 ancient temples. Visitors can explore them by bike, horse cart, or hot air balloon. The sunrise over the temples is an unforgettable sight.', es: 'Bagán es hogar de más de 2,000 templos antiguos. Los visitantes pueden explorarlos en bicicleta, en carro de caballos o en globo aerostático. El amanecer sobre los templos es una vista inolvidable.' }

        },

        {

            id: 495,

            country: { pl: 'Katar', en: 'Qatar', es: 'Catar' },

            place: { pl: 'Wioska Kulturalna Katara', en: 'Katara Cultural Village', es: ' Katara Village Cultural' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Katara to centrum kultury z teatrami, galeriami sztuki i restauracjami. Organizowane są tam koncerty, festiwale i wystawy. Odwiedzający mogą zwiedzać piękną architekturę i plażę.', en: 'Katara is a cultural center with theaters, art galleries, and restaurants. It hosts concerts, festivals, and exhibitions. Visitors can explore its beautiful architecture and beach.', es: 'Katara es un centro cultural con teatros, galerías de arte y restaurantes. Alberga conciertos, festivales y exposiciones. Los visitantes pueden explorar su hermosa arquitectura y su playa.' }

        },

        {

            id: 496,

            country: { pl: 'Malezja', en: 'Malaysia', es: 'Malasia' },

            place: { pl: 'Wieże Petronas', en: 'Petronas Towers', es: ' Torres Petronas' },

            continent: { pl: 'Azja', en: 'Asia', es: 'Asia' },

            description: { pl: 'Te bliźniacze wieżowce w Kuala Lumpur należą do najwyższych na świecie. Most łączący wieże oferuje wspaniałe widoki na miasto. Nocą budynki świecą się spektakularnie, tworząc zachwycający krajobraz.', en: 'These twin skyscrapers in Kuala Lumpur are among the tallest in the world. The sky bridge offers great city views. At night, the towers shine brightly and create a stunning skyline.', es: 'Estos rascacielos gemelos en Kuala Lumpur están entre los más altos del mundo. El puente del cielo ofrece excelentes vistas de la ciudad. Por la noche, las torres brillan intensamente y crean un horizonte impresionante.' }

        },

        {

            id: 497,

            country: { pl: 'Botswana', en: 'Botswana', es: 'Botsuana' },

            place: { pl: 'Delta Okawango', en: 'Okavango Delta', es: ' Delta del Okavango' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Delta Okawango to ogromny system rzek śródlądowych. Zwiedzający mogą eksplorować ją łodzią i obserwować słonie, lwy i hipopotamy w ich naturalnym środowisku.', en: 'The Okavango Delta is a huge inland river system. Visitors can explore by boat and see elephants, lions, and hippos in their natural habitat.', es: 'El Delta del Okavango es un enorme sistema de ríos interiores. Los visitantes pueden explorarlo en barco y ver elefantes, leones e hipopótamos en su hábitat natural.' }

        },

        {

            id: 498,

            country: { pl: 'Mozambik', en: 'Mozambique', es: 'Mozambique' },

            place: { pl: 'Archipelag Bazaruto', en: 'Bazaruto Archipelago', es: ' Archipiélago de Bazaruto' },

            continent: { pl: 'Afryka', en: 'Africa', es: 'África' },

            description: { pl: 'Ta grupa wysp jest znana z czystych wód i białych plaż. To popularne miejsce do nurkowania i snorkelingu. Odwiedzający mogą zobaczyć kolorowe rafy koralowe, delfiny, a nawet dugongi.', en: 'This group of islands is known for its clear waters and white beaches. It is a top spot for snorkeling and diving. Visitors can see colorful coral reefs, dolphins, and even dugongs.', es: 'Este grupo de islas es conocido por sus aguas claras y sus playas blancas. Es un lugar ideal para hacer snorkel y buceo. Los visitantes pueden ver coloridos arrecifes de coral, delfines e incluso dugongos.' }

        },

        {

            id: 499,

            country: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            place: { pl: 'Uluru (Ayers Rock)', en: 'Uluru (Ayers Rock)', es: ' Uluru (Ayers Rock)' },

            continent: { pl: 'Australia', en: 'Australia', es: 'Australia' },

            description: { pl: 'Uluru to gigantyczna czerwona skała w sercu Australii. Jest święta dla Aborygenów i zmienia kolor o zachodzie słońca. Można ją obejść dookoła i poznać jej kulturowe znaczenie.', en: 'Uluru is a giant red rock in the middle of Australia. It is sacred to Aboriginal people and changes color at sunset. Visitors can walk around it and learn about its cultural importance.', es: 'Uluru es una enorme roca roja en el centro de Australia. Es sagrada para los aborígenes y cambia de color al atardecer. Los visitantes pueden caminar alrededor de ella y aprender sobre su importancia cultural.' }

        },

        {

            id: 500,

            country: { pl: 'Argentyna', en: 'Argentina', es: 'Argentina' },

            place: { pl: 'Lodowiec Perito Moreno', en: 'Perito Moreno Glacier', es: ' Glaciar Perito Moreno' },

            continent: { pl: 'Ameryka Południowa', en: 'South America', es: 'América del Sur' },

            description: { pl: 'Ten ogromny lodowiec znajduje się w Patagonii. W przeciwieństwie do większości lodowców, wciąż rośnie! Można wybrać się na rejs łodzią lub spacer po lodzie. Dźwięk pękającego lodu to niezapomniane przeżycie.', en: 'This huge glacier is located in Patagonia. Unlike most glaciers, it is still growing! Visitors can take boat tours or even hike on the ice. The sound of ice breaking is an unforgettable experience.', es: 'Este enorme glaciar se encuentra en Patagonia. ¡A diferencia de la mayoría de los glaciares, sigue creciendo! Los visitantes pueden tomar excursiones en barco o incluso hacer caminatas sobre el hielo. El sonido del hielo rompiéndose es una experiencia inolvidable.' }

        },


    ];

    /**
     * Get a destination that matches the given level
     * @param level The level to find a destination for
     * @returns The destination with the closest ID to the given level
     */
    getDestinationForLevel(level: number): TravelDestination {
        // Try to find an exact match first
        const exactMatch = this.destinations.find(dest => dest.id === level);
        if (exactMatch) {
            return exactMatch;
        }

        // If no exact match, find the closest ID
        let closestDest = this.destinations[0];
        let minDiff = Math.abs(closestDest.id - level);

        for (const dest of this.destinations) {
            const diff = Math.abs(dest.id - level);
            if (diff < minDiff) {
                minDiff = diff;
                closestDest = dest;
            }
        }

        return closestDest;
    }

    /**
     * Alias for getDestinationForLevel for backward compatibility
     * @param level The level to find a destination for
     * @returns The destination with the closest ID to the given level
     */
    getDestinationByLevel(level: number): TravelDestination {
        return this.getDestinationForLevel(level);
    }

    getDestinations(): TravelDestination[] {
        return this.destinations;
    }
}