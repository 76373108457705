import { Injectable } from '@angular/core';
import { Client, Room } from 'colyseus.js';

@Injectable({
  providedIn: 'root'
})
export class LiveGameCommunicatorService {
  private client: Client;
  private room: Room;

  constructor() {
    this.client = new Client('wss://backend-dev.linget.it/'); // Use wss for secure WebSocket
    this.room?.onStateChange((state) => {

      for (let player of state.players) {

      }
    });

  }
  async createRoom(roomName: string, options: any) {

    this.room = await this.client.create(roomName, options);

    this.room.onMessage("message", (message) => {

    });

    this.room.onStateChange((state) => {

    });

    this.room.onError((code, message) => {
      console.error(`Error ${code}: ${message}`);
    });

    this.room.onLeave((code) => {

    });
  }



  async joinRoom(roomName: string, options: any) {
    this.room = await this.client.join(roomName, options);

    this.room.onMessage("message", (message) => {


    });

    this.room.onStateChange((state) => {

    });

    this.room.onError((code, message) => {
      console.error(`Error ${code}: ${message}`);
    });

    this.room.onLeave((code) => {

    });

  }

  sendMessage(message: any) {
    if (this.room) {
      this.room.send("message", message);
    }
  }
}