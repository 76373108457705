import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-student-dictionary-dialog',
  templateUrl: './student-dictionary-dialog.component.html',
  styleUrls: ['./student-dictionary-dialog.component.scss'],
})
export class StudentDictionaryDialogComponent implements OnInit {
  foundWords;
  sortedWords = [];
  index: number;
  keyboardHeight: number;
  searchedWord: string;
  lang: string;
  hasAiTranslation: boolean = false;
  aiTranslation: string = '';

  constructor(
    private modalCtrl: ModalController,
    private helpers: HelpersService
  ) { }

  ngOnInit() {
    this.sortMeanings();
    this.checkForAITranslation();
  }

  sortMeanings() {
    this.foundWords = this.helpers.sortArrayByProperty(
      this.foundWords,
      'cefr',
      1
    );
    this.foundWords.forEach((word: any) => {
      switch (word.cefr) {
        case 'a1':
          word.color = 'orange';
          break;
        case 'a2':
          word.color = 'red';
          break;
        case 'b1':
          word.color = 'green';
          break;
        case 'b2':
          word.color = 'blue';
          break;
        case 'c1':
          word.color = 'purple';
          break;
        case 'c2':
          word.color = 'black';
          break;
        default:
          word.color = 'grey';
          break;
      }
    });

    // Sort words into levels based on CEFR level
    this.index = 0;
    if (this.foundWords?.length > 0) {
      this.sortedWords = [
        this.foundWords.filter((word: any) => word.cefr === 'a1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'a2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c2').reverse(),
      ];

    }
  }

  // Check if there are any AI translation entries
  checkForAITranslation() {
    if (this.foundWords && this.foundWords.length > 0) {
      const aiTranslationEntry = this.foundWords.find(word =>
        word.id && word.id.toString().startsWith('chat-translation-')
      );

      if (aiTranslationEntry) {
        this.hasAiTranslation = true;
        this.aiTranslation = aiTranslationEntry.translation || 'Nie znaleziono tłumaczenia';

      }
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
