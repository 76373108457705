import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TravelDestinationsService, TravelDestination } from 'src/app/shared/services/travel-destinations.service';
import { UserDetailsService } from '../user-details.service';

@Component({
    selector: 'app-travel-destinations-modal',
    templateUrl: './travel-destinations-modal.component.html',
    styleUrls: ['./travel-destinations-modal.component.scss'],
})
export class TravelDestinationsModalComponent implements OnInit {
    destinations: TravelDestination[] = [];
    currentLevel: number = 1;
    currentLanguage: 'pl' | 'en' = 'pl'; // Default language is Polish

    constructor(
        private modalController: ModalController,
        private travelDestinationsService: TravelDestinationsService,
        private userDetailsService: UserDetailsService
    ) { }

    ngOnInit() {
        this.destinations = this.travelDestinationsService.getDestinations();
        this.currentLevel = this.getTravelLevel();
    }

    getTravelLevel(): number {
        const diamonds = this.userDetailsService.userDetails?.userData?.totalDiamonds || 0;
        return Math.floor(diamonds / 10) || 1; // Ensure minimum level 1
    }

    isLevelUnlocked(destinationId: number): boolean {
        return destinationId <= this.currentLevel;
    }

    toggleLanguage() {
        this.currentLanguage = this.currentLanguage === 'pl' ? 'en' : 'pl';
    }

    getLanguageButtonText(): string {
        return this.currentLanguage === 'pl' ? 'English' : 'Polski';
    }

    getDiamondsNeeded(destinationId: number): number {
        return destinationId * 10;
    }

    getDiamondsRemaining(destinationId: number): number {
        const currentDiamonds = this.userDetailsService.userDetails?.userData?.totalDiamonds || 0;
        const neededDiamonds = destinationId * 10;
        return Math.max(0, neededDiamonds - currentDiamonds);
    }

    /**
     * Returns the first few words of a text string followed by ellipsis
     * @param text The full text to truncate
     * @param wordCount Number of words to show (default: 5)
     * @returns Truncated text with first few words
     */
    getFirstFewWords(text: string, wordCount: number = 5): string {
        if (!text) return '';

        const words = text.split(' ');
        if (words.length <= wordCount) return text;

        return words.slice(0, wordCount).join(' ');
    }

    closeModal() {
        this.modalController.dismiss();
    }
} 