import { Component, OnInit, isDevMode } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserDetailsService } from '../user-details.service';

interface ProgressHistoryWeek {
    weekNumber: number;
    year: number;
    progress: number;
    progressInMinutes: number;
}

@Component({
    selector: 'app-progress-history-modal',
    templateUrl: './progress-history-modal.component.html',
    styleUrls: ['./progress-history-modal.component.scss'],
})
export class ProgressHistoryModalComponent implements OnInit {
    progressHistory: ProgressHistoryWeek[] = [];
    isDevelopmentMode = isDevMode();
    showDebugInfo = false;
    debugDataStructure = 'Unknown';
    Math = Math; // Expose Math to the template

    constructor(
        private modalController: ModalController,
        public userDetailsService: UserDetailsService
    ) { }

    ngOnInit() {
        // Get user details and process progress history
        this.userDetailsService.getUserDetails();

        // Process progress history after a short delay to ensure data is loaded
        setTimeout(() => {
            this.processProgressHistory();

            // If no history entries were found, ensure at least the current week is shown
            if (this.progressHistory.length === 0) {
                this.addEmptyCurrentWeek();
            }

            // Ensure the current week is always included
            this.ensureCurrentWeekExists();

            // Fill in gaps between weeks
            this.fillWeekGaps();
        }, 300);
    }

    /**
     * Debug method to log the structure of user data
     */
    debugUserData() {
        const userData = this.userDetailsService.userDetails?.userData;


        // Check for specific progress-related properties
        if (userData) {










            // Log a sample of the data if available
            if (userData.progressHistory && Array.isArray(userData.progressHistory) && userData.progressHistory.length > 0) {

                // Log the structure of the first few entries to understand the format
                for (let i = 0; i < Math.min(5, userData.progressHistory.length); i++) {

                }
            }

            if (userData.dailyProgress && typeof userData.dailyProgress === 'object') {
                const keys = Object.keys(userData.dailyProgress);
                if (keys.length > 0) {

                }
            }
        }
    }

    /**
     * Process the progress history data from user details
     */
    processProgressHistory() {
        try {
            const userData = this.userDetailsService.userDetails?.userData;
            if (!userData) {
                console.error('No user data available');
                return;
            }

            // Create a map to store progress by week and year
            const progressMap = new Map<string, ProgressHistoryWeek>();

            // Process different data sources
            this.processProgressHistoryArray(userData, progressMap);
            this.processDailyProgress(userData, progressMap);
            this.processLearningSessions(userData, progressMap);

            // Add current week's progress if available
            this.addCurrentWeekProgress(userData, progressMap);

            // Convert map to array and sort by year and week (descending)
            this.progressHistory = Array.from(progressMap.values())
                .sort((a, b) => {
                    if (a.year !== b.year) {
                        return b.year - a.year; // Sort by year descending
                    }
                    return b.weekNumber - a.weekNumber; // Then by week descending
                });

            // Set debug data structure info
            if (this.progressHistory.length > 0) {
                this.debugDataStructure = 'Progress history processed successfully';
            } else {
                this.debugDataStructure = 'No progress history entries found';
            }


        } catch (error) {
            console.error('Error processing progress history:', error);
            this.debugDataStructure = 'Error processing data: ' + error;
        }
    }

    /**
     * Add the current week's progress to the history
     * @param userData The user data
     * @param progressMap The map of progress by week and year
     */
    addCurrentWeekProgress(userData: any, progressMap: Map<string, ProgressHistoryWeek>) {
        try {
            // Get current week and year
            const today = new Date();
            const currentWeek = this.getWeekNumber(today);
            const currentYear = today.getFullYear();
            const key = `${currentYear}-${currentWeek}`;

            // Get weekly progress if available (in seconds)
            let progressSeconds = 0;
            if (userData.weeklyProgress !== undefined && typeof userData.weeklyProgress === 'number') {
                progressSeconds = userData.weeklyProgress;
            }

            // Convert seconds to minutes
            const progressMinutes = Math.round(progressSeconds / 60);

            // Always add the current week, even if there's no progress
            if (progressMap.has(key)) {
                // Update existing entry if it exists
                const existingEntry = progressMap.get(key)!;
                existingEntry.progressInMinutes = progressMinutes; // Override with current value
                existingEntry.progress = progressSeconds;
            } else {
                // Create new entry for current week
                progressMap.set(key, {
                    weekNumber: currentWeek,
                    year: currentYear,
                    progress: progressSeconds,
                    progressInMinutes: progressMinutes
                });
            }


        } catch (error) {
            console.error('Error adding current week progress:', error);
        }
    }

    /**
     * Process the progress history data from userData
     * Handles duplicate weeks by summing their progress
     */
    processProgressHistoryArray(userData: any, progressMap: Map<string, ProgressHistoryWeek>) {
        // Check for progressHistory array
        if (userData.progressHistory) {


            if (Array.isArray(userData.progressHistory)) {
                // Process each entry in the progress history array
                userData.progressHistory.forEach((entry, index) => {
                    try {
                        if (!entry) {

                            return;
                        }

                        // Log every 50th entry to see the structure throughout the array
                        if (index % 50 === 0) {

                        }

                        // Extract week and year information
                        let weekNumber: number | undefined;
                        let year: number | undefined;
                        let progress = 0;

                        // Check different possible structures
                        if (typeof entry === 'object') {
                            if ('weekNumber' in entry && 'year' in entry) {
                                weekNumber = (entry as any).weekNumber;
                                year = (entry as any).year;
                                progress = 'progress' in entry ? ((entry as any).progress as number) || 0 : 0;
                            } else if ('week' in entry && 'year' in entry) {
                                weekNumber = (entry as any).week;
                                year = (entry as any).year;
                                progress = 'progress' in entry ? ((entry as any).progress as number) || 0 : 0;
                            } else if ('date' in entry) {
                                // If entry has a date field, extract week and year from it
                                try {
                                    const date = new Date((entry as any).date);
                                    if (!isNaN(date.getTime())) {
                                        weekNumber = this.getWeekNumber(date);
                                        year = date.getFullYear();
                                        progress = 'progress' in entry ? ((entry as any).progress as number) || 0 :
                                            'duration' in entry ? ((entry as any).duration as number) || 0 : 0;
                                    }
                                } catch (e) {
                                    console.error('Error parsing date:', (entry as any).date, e);
                                }
                            }
                        }

                        if (weekNumber !== undefined && year !== undefined) {
                            const key = `${year}-${weekNumber}`;

                            if (progressMap.has(key)) {
                                // If week already exists, add the progress
                                const existingEntry = progressMap.get(key)!;
                                existingEntry.progress += progress;
                                existingEntry.progressInMinutes = Math.round(existingEntry.progress / 60);
                            } else {
                                // Create a new entry
                                progressMap.set(key, {
                                    weekNumber: weekNumber,
                                    year: year,
                                    progress: progress,
                                    progressInMinutes: Math.round(progress / 60)
                                });
                            }
                        } else {

                        }
                    } catch (e) {
                        console.error(`Error processing entry at index ${index}:`, e);
                    }
                });
            } else if (typeof userData.progressHistory === 'object') {
                // Handle case where progressHistory is an object with keys
                Object.entries(userData.progressHistory).forEach(([key, entry]) => {
                    if (entry && typeof entry === 'object') {
                        // Try to extract week and year information
                        let weekNumber: number | undefined;
                        let year: number | undefined;
                        let progress = 0;

                        // Check if entry has direct weekNumber and year properties
                        if ('weekNumber' in entry && 'year' in entry) {
                            weekNumber = (entry as any).weekNumber;
                            year = (entry as any).year;
                            progress = 'progress' in entry ? ((entry as any).progress as number) || 0 : 0;
                        }
                        // Check if the key contains week and year information (e.g., "2023-42")
                        else if (key.includes('-')) {
                            const [yearStr, weekStr] = key.split('-');
                            year = parseInt(yearStr);
                            weekNumber = parseInt(weekStr);
                            progress = typeof entry === 'number' ? entry as number : ('progress' in entry ? ((entry as any).progress as number) || 0 : 0);
                        }

                        if (weekNumber && year) {
                            const mapKey = `${year}-${weekNumber}`;

                            if (progressMap.has(mapKey)) {
                                const existingEntry = progressMap.get(mapKey)!;
                                existingEntry.progress += progress;
                                existingEntry.progressInMinutes = Math.round(existingEntry.progress / 60);
                            } else {
                                progressMap.set(mapKey, {
                                    weekNumber: weekNumber,
                                    year: year,
                                    progress: progress,
                                    progressInMinutes: Math.round(progress / 60)
                                });
                            }
                        }
                    }
                });
            }
        }
    }

    /**
     * Process learning sessions data for additional history information
     * @param userData The user data object
     * @param progressMap The map of progress history entries
     */
    processLearningSessions(userData: any, progressMap: Map<string, ProgressHistoryWeek>) {
        if (!userData.learningSessions || !Array.isArray(userData.learningSessions)) {
            return;
        }



        // Process each learning session
        userData.learningSessions.forEach(session => {
            if (session && session.date) {
                try {
                    const date = new Date(session.date);
                    if (!isNaN(date.getTime())) {
                        const weekNumber = this.getWeekNumber(date);
                        const year = date.getFullYear();
                        const key = `${year}-${weekNumber}`;

                        // Calculate progress from session
                        let sessionProgress = 0;

                        // Check if session has duration
                        if (session.duration) {
                            sessionProgress = session.duration;
                        }
                        // Check if session has timeSpent
                        else if (session.timeSpent) {
                            sessionProgress = session.timeSpent;
                        }
                        // Check if session has progress
                        else if (session.progress) {
                            sessionProgress = session.progress;
                        }

                        if (sessionProgress > 0) {
                            if (progressMap.has(key)) {
                                const existingEntry = progressMap.get(key)!;
                                existingEntry.progress += sessionProgress;
                                existingEntry.progressInMinutes = Math.round(existingEntry.progress / 60);
                            } else {
                                progressMap.set(key, {
                                    weekNumber: weekNumber,
                                    year: year,
                                    progress: sessionProgress,
                                    progressInMinutes: Math.round(sessionProgress / 60)
                                });
                            }
                        }
                    }
                } catch (e) {
                    console.error('Error processing session date:', session.date, e);
                }
            }
        });
    }

    /**
     * Get the ISO week number for a given date
     * @param date The date to get the week number for
     * @returns The ISO week number (1-53)
     */
    getWeekNumber(date: Date): number {
        // Create a copy of the date to avoid modifying the input
        const targetDate = new Date(date.getTime());

        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        targetDate.setHours(0, 0, 0, 0);
        const dayNumber = targetDate.getDay() || 7;
        targetDate.setDate(targetDate.getDate() + 4 - dayNumber);

        // Get first day of year
        const yearStart = new Date(targetDate.getFullYear(), 0, 1);

        // Calculate full weeks to nearest Thursday
        const weekNumber = Math.ceil((((targetDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        // Check if we're actually in week 1 of the next year
        if (weekNumber > 52) {
            const nextYearStart = new Date(targetDate.getFullYear() + 1, 0, 1);
            const nextYearStartDay = nextYearStart.getDay();

            // If next year starts with a Thursday or earlier (Monday, Tuesday, Wednesday, Thursday),
            // then the current week is actually week 1 of next year
            if (nextYearStartDay >= 1 && nextYearStartDay <= 4) {
                return 1;
            }
        }

        // Check if we're actually in the last week of the previous year
        if (weekNumber < 1) {
            const prevYearLastDay = new Date(targetDate.getFullYear() - 1, 11, 31);
            return this.getWeekNumber(prevYearLastDay);
        }

        return weekNumber;
    }

    /**
     * Get a formatted date range string for a given week
     * @param week The week to get the date range for
     * @returns A formatted date range string
     */
    getWeekDateRange(week: ProgressHistoryWeek): string {
        try {
            // Calculate the dates using the ISO week date standard
            // Get the first day of the week (Monday)
            const firstDayOfWeek = this.getDateOfISOWeek(week.weekNumber, week.year);

            // Calculate the last day of the week (Sunday)
            const lastDayOfWeek = new Date(firstDayOfWeek);
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

            // Format the dates
            const options: Intl.DateTimeFormatOptions = {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            };

            // If both dates are in the same year, only show the year once
            if (firstDayOfWeek.getFullYear() === lastDayOfWeek.getFullYear()) {
                return `${firstDayOfWeek.toLocaleDateString('pl-PL', { day: 'numeric', month: 'short' })} - ${lastDayOfWeek.toLocaleDateString('pl-PL', options)}`;
            } else {
                return `${firstDayOfWeek.toLocaleDateString('pl-PL', options)} - ${lastDayOfWeek.toLocaleDateString('pl-PL', options)}`;
            }
        } catch (e) {
            console.error('Error calculating week date range:', e);
            return `Tydzień ${week.weekNumber}, ${week.year}`;
        }
    }

    /**
     * Get the Monday date of a specific ISO week number in a year
     * @param week The ISO week number (1-53)
     * @param year The year
     * @returns The Date object representing the Monday of the specified week
     */
    getDateOfISOWeek(week: number, year: number): Date {
        // Find the first day of the year
        const firstDay = new Date(year, 0, 1);

        // Get the day of the week for January 1st (0 = Sunday, 1 = Monday, etc.)
        const dayOfWeek = firstDay.getDay();

        // ISO weeks start on Monday, so we need to adjust
        // Find the first Monday of the year
        const firstMonday = new Date(year, 0, 1 + (dayOfWeek === 0 ? 1 : 8 - dayOfWeek));

        // If week 1 contains January 4th, then the first week might not start on the first Monday
        // If January 4th is before the first Monday, then the first partial week is considered week 1
        const jan4th = new Date(year, 0, 4);

        let startDate;
        if (jan4th < firstMonday) {
            // Week 1 is the partial week
            const weekOffset = (week - 1) * 7;
            startDate = new Date(year, 0, 1);

            // Adjust to Monday
            const startDayOfWeek = startDate.getDay();
            if (startDayOfWeek !== 1) { // If not Monday
                const adjust = startDayOfWeek === 0 ? -6 : 2 - startDayOfWeek;
                startDate.setDate(startDate.getDate() + adjust);
            }

            startDate.setDate(startDate.getDate() + weekOffset);
        } else {
            // Week 1 starts with the first Monday
            const weekOffset = (week - 1) * 7;
            startDate = new Date(firstMonday);
            startDate.setDate(firstMonday.getDate() + weekOffset);
        }

        return startDate;
    }

    /**
     * Calculate the progress percentage based on minutes
     * @param minutes The number of minutes
     * @returns The progress percentage
     */
    getProgressPercentage(minutes: number): number {
        // Calculate percentage based on 45 minutes goal
        const percentage = Math.round((minutes / 45) * 100);

        // Return the percentage (allowing values above 100%)
        return percentage;
    }

    /**
     * Close the modal
     */
    closeModal() {
        this.modalController.dismiss();
    }

    /**
     * Debug method to display progress data information in the UI
     */
    debugProgressData() {
        this.showDebugInfo = !this.showDebugInfo;

        const userData = this.userDetailsService.userDetails?.userData;
        if (userData) {
            if (userData.progressHistory) {
                if (Array.isArray(userData.progressHistory)) {
                    this.debugDataStructure = 'progressHistory (Array)';
                } else if (typeof userData.progressHistory === 'object') {
                    this.debugDataStructure = 'progressHistory (Object)';
                } else {
                    this.debugDataStructure = `progressHistory (${typeof userData.progressHistory})`;
                }
            } else if (userData.dailyProgress) {
                this.debugDataStructure = 'dailyProgress';
            } else if (userData.weeklyProgress !== undefined) {
                this.debugDataStructure = 'weeklyProgress only';
            } else {
                this.debugDataStructure = 'No progress data found';
            }

            // Force reprocess the data
            this.processProgressHistory();
        }
    }

    /**
     * Get information about the data type and structure for debugging
     * @param data The data to analyze
     * @returns A string describing the data type and structure
     */
    getDataTypeInfo(data: any): string {
        if (data === null) return 'null';
        if (data === undefined) return 'undefined';

        const type = typeof data;

        if (type === 'object') {
            if (Array.isArray(data)) {
                return `Array (${data.length} items)`;
            } else {
                const keys = Object.keys(data);
                return `Object (${keys.length} keys: ${keys.slice(0, 3).join(', ')}${keys.length > 3 ? '...' : ''})`;
            }
        }

        return type;
    }

    /**
     * Get a formatted string representation of a sample entry
     * @param index The index of the entry to display
     * @returns A string representation of the entry
     */
    getSampleEntryInfo(index: number): string {
        const progressHistory = this.userDetailsService.userDetails?.userData?.progressHistory;
        if (!progressHistory || !Array.isArray(progressHistory) || index >= progressHistory.length) {
            return 'Not available';
        }

        const entry = progressHistory[index];
        if (!entry) {
            return 'Undefined entry';
        }

        try {
            return JSON.stringify(entry, null, 2);
        } catch (e) {
            return `Error stringifying entry: ${e}`;
        }
    }

    /**
     * Refresh the data by getting the latest user details and reprocessing the history
     */
    refreshData() {
        this.userDetailsService.getUserDetails();
        this.progressHistory = [];

        // Process progress history after a short delay to ensure data is loaded
        setTimeout(() => {
            if (this.showDebugInfo) {
                this.debugUserData();
            }
            this.processProgressHistory();

            // If no history entries were found, ensure at least the current week is shown
            if (this.progressHistory.length === 0) {
                this.addEmptyCurrentWeek();
            }

            // Ensure the current week is always included
            this.ensureCurrentWeekExists();

            // Fill in gaps between weeks
            this.fillWeekGaps();
        }, 300);
    }

    /**
     * Process daily progress data from userData
     * @param userData The user data
     * @param progressMap The map of progress by week and year
     */
    processDailyProgress(userData: any, progressMap: Map<string, ProgressHistoryWeek>) {
        try {
            // Check for dailyProgress which might contain historical data
            if (userData.dailyProgress && typeof userData.dailyProgress === 'object') {


                // Process daily progress data which might contain history
                Object.entries(userData.dailyProgress).forEach(([dateStr, progress]) => {
                    try {
                        const date = new Date(dateStr);
                        if (!isNaN(date.getTime())) {
                            const weekNumber = this.getWeekNumber(date);
                            const year = date.getFullYear();
                            const key = `${year}-${weekNumber}`;
                            const progressValue = typeof progress === 'number' ? progress : 0;

                            // Convert seconds to minutes
                            const progressMinutes = Math.round(progressValue / 60);

                            if (progressMap.has(key)) {
                                const existingEntry = progressMap.get(key)!;
                                existingEntry.progress += progressValue;
                                existingEntry.progressInMinutes += progressMinutes;
                            } else {
                                progressMap.set(key, {
                                    weekNumber: weekNumber,
                                    year: year,
                                    progress: progressValue,
                                    progressInMinutes: progressMinutes
                                });
                            }
                        }
                    } catch (e) {
                        console.error('Error processing date:', dateStr, e);
                    }
                });
            }
        } catch (error) {
            console.error('Error processing daily progress:', error);
        }
    }

    /**
     * Check if the given week is the current week
     * @param week The week to check
     * @returns True if the week is the current week
     */
    isCurrentWeek(week: ProgressHistoryWeek): boolean {
        const today = new Date();
        const currentWeek = this.getWeekNumber(today);
        const currentYear = today.getFullYear();

        return week.weekNumber === currentWeek && week.year === currentYear;
    }

    /**
     * Add an empty current week entry if no history exists
     */
    addEmptyCurrentWeek() {
        const today = new Date();
        const currentWeek = this.getWeekNumber(today);
        const currentYear = today.getFullYear();

        this.progressHistory = [{
            weekNumber: currentWeek,
            year: currentYear,
            progress: 0,
            progressInMinutes: 0
        }];


    }

    /**
     * Ensure the current week exists in the progress history
     */
    ensureCurrentWeekExists() {
        const today = new Date();
        const currentWeek = this.getWeekNumber(today);
        const currentYear = today.getFullYear();

        // Check if current week exists in the history
        const currentWeekExists = this.progressHistory.some(
            week => week.weekNumber === currentWeek && week.year === currentYear
        );

        // If not, add it
        if (!currentWeekExists) {
            // Get weekly progress if available
            let progressMinutes = 0;
            const userData = this.userDetailsService.userDetails?.userData;
            if (userData && userData.weeklyProgress !== undefined) {
                progressMinutes = Math.round(userData.weeklyProgress / 60);
            }

            // Add current week at the beginning of the array (newest first)
            this.progressHistory.unshift({
                weekNumber: currentWeek,
                year: currentYear,
                progress: progressMinutes * 60, // Convert back to seconds for consistency
                progressInMinutes: progressMinutes
            });


        }
    }

    /**
     * Fill in gaps between weeks with zero-minute entries
     * This ensures a continuous timeline even when the user hasn't logged in
     */
    fillWeekGaps() {
        if (this.progressHistory.length <= 1) {
            return; // No gaps to fill if there's only one entry or none
        }

        // Sort by year and week (descending)
        this.progressHistory.sort((a, b) => {
            if (a.year !== b.year) {
                return b.year - a.year; // Sort by year descending
            }
            return b.weekNumber - a.weekNumber; // Then by week descending
        });

        const filledHistory: ProgressHistoryWeek[] = [];

        // Process each entry and fill gaps
        for (let i = 0; i < this.progressHistory.length - 1; i++) {
            const currentWeek = this.progressHistory[i];
            const nextWeek = this.progressHistory[i + 1];

            // Add the current week
            filledHistory.push(currentWeek);

            // Calculate the gap between current and next week
            let gapWeeks = this.calculateWeekGap(currentWeek, nextWeek);

            // If there's a gap, fill it with zero-minute entries
            if (gapWeeks > 1) {
                // Start from the week after the current week
                let tempWeek = { ...currentWeek };

                for (let j = 1; j < gapWeeks; j++) {
                    // Calculate the next week in the gap
                    tempWeek = this.getPreviousWeek(tempWeek);

                    // Skip if we've already passed the next week
                    if (tempWeek.year < nextWeek.year ||
                        (tempWeek.year === nextWeek.year && tempWeek.weekNumber <= nextWeek.weekNumber)) {
                        break;
                    }

                    // Add a zero-minute entry for the gap week
                    filledHistory.push({
                        weekNumber: tempWeek.weekNumber,
                        year: tempWeek.year,
                        progress: 0,
                        progressInMinutes: 0
                    });
                }
            }
        }

        // Add the last entry
        filledHistory.push(this.progressHistory[this.progressHistory.length - 1]);

        // Update the progress history with filled gaps
        this.progressHistory = filledHistory;


    }

    /**
     * Calculate the gap between two weeks in total weeks
     * @param laterWeek The later week
     * @param earlierWeek The earlier week
     * @returns The number of weeks between the two weeks (including both weeks)
     */
    calculateWeekGap(laterWeek: ProgressHistoryWeek, earlierWeek: ProgressHistoryWeek): number {
        if (laterWeek.year === earlierWeek.year) {
            // Same year, just calculate week difference
            return laterWeek.weekNumber - earlierWeek.weekNumber;
        } else {
            // Different years
            let totalWeeks = 0;

            // Add weeks from the later year
            totalWeeks += laterWeek.weekNumber;

            // Add weeks from years in between (if any)
            for (let year = laterWeek.year - 1; year > earlierWeek.year; year--) {
                totalWeeks += this.getWeeksInYear(year);
            }

            // Add remaining weeks from the earlier year
            totalWeeks += this.getWeeksInYear(earlierWeek.year) - earlierWeek.weekNumber;

            return totalWeeks;
        }
    }

    /**
     * Get the number of weeks in a year
     * @param year The year
     * @returns The number of weeks in the year (52 or 53)
     */
    getWeeksInYear(year: number): number {
        // A year has 52 or 53 weeks depending on the day of the week of January 1
        // and whether it's a leap year
        const lastDayOfYear = new Date(year, 11, 31);
        const weekOfLastDay = this.getWeekNumber(lastDayOfYear);
        return weekOfLastDay === 1 ? 52 : weekOfLastDay;
    }

    /**
     * Get the previous week
     * @param week The current week
     * @returns The previous week
     */
    getPreviousWeek(week: ProgressHistoryWeek): ProgressHistoryWeek {
        if (week.weekNumber > 1) {
            // Simple case: just decrement the week number
            return {
                weekNumber: week.weekNumber - 1,
                year: week.year,
                progress: 0,
                progressInMinutes: 0
            };
        } else {
            // We need to go to the last week of the previous year
            return {
                weekNumber: this.getWeeksInYear(week.year - 1),
                year: week.year - 1,
                progress: 0,
                progressInMinutes: 0
            };
        }
    }

    /**
     * Check if the given week was added to fill a gap
     * @param week The week to check
     * @returns True if the week was added to fill a gap
     */
    isGapWeek(week: ProgressHistoryWeek): boolean {
        // We mark gap weeks during the fillWeekGaps process
        // For simplicity, we'll consider any week with exactly 0 progress as a gap week
        // except for the current week
        return week.progressInMinutes === 0 && !this.isCurrentWeek(week);
    }
} 