import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsConversationsTopicsService } from './chatbots-conversations-topics.service';


@Component({
  selector: 'app-chatbots-conversations-topics',
  templateUrl: './chatbots-conversations-topics.component.html',
  styleUrls: ['./chatbots-conversations-topics.component.css']
})
export class ChatbotsConversationsTopicsComponent implements OnInit {
  @Input() topics
  @Output() topicClick = new EventEmitter()

  @Input() categoryFinished = false
  constructor(
    private chatbotsConversationsTopicsService: ChatbotsConversationsTopicsService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }
  ngOnInit() {
    this.topics = this.chatbotsConversationsTopicsService.topics

    setTimeout(() => {
      this.categoryFinished = this.topics.every(topic => topic.topics.every(topic => topic.finished))
      this.topics.forEach(category => {
        let stars = 0
        category.topics.forEach(topic => {
          if (topic.finished) {
            stars++
          }
        })
        category.stars = stars

      }
      )
    }, 1200)

  }

  checkIfTopicDisabled(topic) {
    let result = true
    const topicId = topic.id
    if (topicId === 1) {
      result = false
    } else {
      if (topicId - 1 > 0) {
        const previousTopic = this.topics.find(topic => topic.id === topicId - 1)
        if (previousTopic.nextCategoryUnlocked) {
          result = false
        }
      }
    }
    return result
  }
  checkIfCategoryFinished(category) {
    return category.topics.every(topic => topic.finished)
  }
  onTopicClick(topic) {
    this.topicClick.emit(topic)
  }

}
