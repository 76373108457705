import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatbotsConversationsTopicsService {

  constructor(
  ) { }
  topics = [
    {
      level: 1,
      id: 1,
      title: "Żółtodziób",
      titleEn: "Newbie",
      topics: [
        { id: 11, type: "conversation", topic: "Rodzina", topicEn: "Family", emoji: "👨‍👩‍👧‍👦" },
        { id: 12, type: "conversation", topic: "Mój dom lub mieszkanie", topicEn: "My house or apartment", emoji: "🏠" },
        { id: 13, type: "situation", topic: "Kupowanie owoców na targu", topicEn: "Buying fruits at the market", emoji: "🍎" },
        { id: 14, type: "conversation", topic: "Moje hobby", topicEn: "My hobby", emoji: "🎨" },
        { id: 15, type: "conversation", topic: "Moja ulubiona pora roku", topicEn: "My favorite season", emoji: "🍂" },
        { id: 16, type: "conversation", topic: "Codzienna rutyna", topicEn: "Daily routine", emoji: "⏰" }
      ]
    },
    {
      level: 2,
      id: 2,
      title: "Zielony w temacie",
      titleEn: "Green in the subject",
      topics: [
        { id: 21, type: "conversation", topic: "Ulubiona muzyka", topicEn: "Favorite music", emoji: "🎵" },
        { id: 22, type: "conversation", topic: "Zakupy w supermarkecie", topicEn: "Shopping in the supermarket", emoji: "🛒" },
        { id: 23, type: "situation", topic: "Kupowanie biletów do kina", topicEn: "Buying tickets at the cinema", emoji: "🎟️" },
        { id: 24, type: "conversation", topic: "Moje miasto lub wieś", topicEn: "My town or village", emoji: "🏙️" },
        { id: 25, type: "situation", topic: "Kupowanie biletu na autobus", topicEn: "Buying a bus ticket", emoji: "🚌" },
        { id: 26, type: "conversation", topic: "Opis pokoju", topicEn: "Description of a room", emoji: "🛋️" }
      ]
    },
    {
      level: 3,
      id: 3,
      title: "Ciekawski świata",
      titleEn: "Curious about the world",
      topics: [
        { id: 31, type: "conversation", topic: "Co jest typowe w moim kraju", topicEn: "What is typical for my country", emoji: "📅" },
        { id: 32, type: "situation", topic: "W sklepie spożywczym – codzienne zakupy", topicEn: "At the grocery store – daily shopping", emoji: "🥖" },
        { id: 33, type: "conversation", topic: "Aktualna pogoda", topicEn: "Current weather", emoji: "🌦️" },
        { id: 34, type: "conversation", topic: "Plany na weekend", topicEn: "Weekend plans", emoji: "📆" },
        { id: 35, type: "conversation", topic: "Ulubione gry i zabawy", topicEn: "Favorite games and activities", emoji: "🎲" },
        { id: 36, type: "situation", topic: "Kupowanie biletu wstępu do zoo", topicEn: "Buying an entrance ticket to the zoo", emoji: "🦁" }
      ]
    },
    {
      level: 4,
      id: 4,
      title: "Świeży Myśliciel",
      titleEn: "Fresh Thinker",
      topics: [
        { id: 41, type: "situation", topic: "Pytanie o trasę autobusu", topicEn: "Asking about a bus route", emoji: "🗺️" },
        { id: 42, type: "conversation", topic: "Moja praca lub inne zajęcie", topicEn: "My job or other occupation", emoji: "💼" },
        { id: 43, type: "conversation", topic: "Ulubiony film lub serial", topicEn: "Favorite movie or TV series", emoji: "🎬" },
        { id: 44, type: "conversation", topic: "Ulubione jedzenie", topicEn: "Favorite food", emoji: "🍔" },
        { id: 45, type: "conversation", topic: "Jak minął wczorajszy dzień?", topicEn: "How was yesterday?", emoji: "🕰️" },
        { id: 46, type: "situation", topic: "Pytanie o opcje śniadania wegetariańskiego", topicEn: "Asking about vegetarian breakfast options", emoji: "🥗" }
      ]
    },
    {
      level: 5,
      id: 5,
      title: "Zainteresowany, ale Niepewny",
      titleEn: "Interested but Uncertain",
      topics: [
        { id: 51, type: "conversation", topic: "Przygotowania do świąt", topicEn: "Holiday preparations", emoji: "🎄" },
        { id: 52, type: "situation", topic: "W restauracji – pytanie o menu dla dzieci", topicEn: "At a restaurant – asking about the kids' menu", emoji: "🍽️" },
        { id: 53, type: "conversation", topic: "Relaksujące miejsca", topicEn: "Relaxing places", emoji: "🌴" },
        { id: 54, type: "situation", topic: "Pytanie o składniki w daniu i alergię", topicEn: "Asking about dish ingredients and allergies", emoji: "🥘" },
        { id: 55, type: "conversation", topic: "Ważna osoba w moim życiu", topicEn: "An important person in my life", emoji: "❤️" },
        { id: 56, type: "conversation", topic: "Jakie są moje ulubione sposoby na relaks?", topicEn: "What are my favorite ways to relax?", emoji: "🛀" }
      ]
    },
    {
      level: 6,
      id: 6,
      title: "Ostrożny Odkrywca",
      titleEn: "Cautious Explorer",
      topics: [
        { id: 61, type: "situation", topic: "W aptece – kupowanie leku na przeziębienie", topicEn: "At the pharmacy – buying cold medicine", emoji: "💊" },
        { id: 62, type: "conversation", topic: "Ulubione ubrania", topicEn: "Favorite clothes", emoji: "👕" },
        { id: 63, type: "conversation", topic: "Ciekawa historia rodzinna", topicEn: "Interesting family story", emoji: "📖" },
        { id: 64, type: "situation", topic: "W aptece – pytanie o lek bez recepty", topicEn: "At the pharmacy – asking for over-the-counter medicine", emoji: "🩺" },
        { id: 65, type: "conversation", topic: "Osoby, które podziwiam", topicEn: "People I admire", emoji: "⭐" },
        { id: 66, type: "situation", topic: "Na lotnisku – nadawanie bagażu", topicEn: "At the airport – checking in luggage", emoji: "✈️" }
      ]
    },
    {
      level: 7,
      id: 7,
      title: "Sprytny Nowicjusz",
      titleEn: "Clever Novice",
      topics: [
        { id: 71, type: "conversation", topic: "Ulubiony sport", topicEn: "Favorite sport", emoji: "⚽" },
        { id: 72, type: "situation", topic: "Na stacji kolejowej – pytanie o opóźniony pociąg", topicEn: "At the train station – asking about a delayed train", emoji: "🚂" },
        { id: 73, type: "situation", topic: "Pytanie mieszkańca o lokalne restauracje", topicEn: "Asking a local about restaurants", emoji: "🍴" },
        { id: 74, type: "conversation", topic: "Ulubiona pora dnia", topicEn: "Favorite time of day", emoji: "🌅" },
        { id: 75, type: "situation", topic: "Rozmowa z taksówkarzem – podanie adresu", topicEn: "Talking to a taxi driver – giving an address", emoji: "🚖" },
        { id: 76, type: "situation", topic: "Zgubiłeś się - prośba o pomoc", topicEn: "Lost – asking for help", emoji: "❓" }
      ]
    },
    {
      level: 8,
      id: 8,
      title: "Wielki Początkujący",
      titleEn: "Great Beginner",
      topics: [
        { id: 81, type: "situation", topic: "W hotelu – zameldowanie", topicEn: "At the hotel – checking in", emoji: "🏨" },
        { id: 82, type: "situation", topic: "W biurze informacji turystycznej", topicEn: "At the tourist information office", emoji: "📍" },
        { id: 83, type: "situation", topic: "Wynajęcie roweru", topicEn: "Renting a bike", emoji: "🚲" },
        { id: 84, type: "conversation", topic: "Aktywności w weekendy", topicEn: "Weekend activities", emoji: "🎉" },
        { id: 85, type: "situation", topic: "W supermarkecie – pytanie o konkretny produkt", topicEn: "At the supermarket – asking about a specific product", emoji: "🛍️" },
        { id: 86, type: "conversation", topic: "Poranna rutyna", topicEn: "Morning routine", emoji: "🌅" }
      ]
    },
    {
      level: 9,
      id: 9,
      title: "Skrzat Wiedzy",
      titleEn: "Knowledge Elf",
      topics: [
        { id: 91, type: "situation", topic: "Pytanie o dodatkowy ręcznik lub kosmetyki w hotelu", topicEn: "Asking for an extra towel or toiletries at the hotel", emoji: "🧴" },
        { id: 92, type: "conversation", topic: "Pogoda w moim kraju", topicEn: "Weather in my country", emoji: "🌍" },
        { id: 93, type: "conversation", topic: "Ulubiony dzień tygodnia", topicEn: "Favorite day of the week", emoji: "📆" },
        { id: 94, type: "situation", topic: "Zgłaszanie błędu w rachunku w supermarkecie", topicEn: "Reporting an error in the bill at the supermarket", emoji: "🧾" },
        { id: 95, type: "conversation", topic: "Jedzenie na mieście", topicEn: "Eating out", emoji: "🍣" },
        { id: 96, type: "conversation", topic: "Dlaczego warto mieć zwierzęta?", topicEn: "Why it's worth having pets?", emoji: "🐶" }
      ]
    },

    {
      level: 10,
      id: 10,
      title: "Obiecujący Uczeń",
      titleEn: "Promising Student",
      topics: [
        { id: 101, type: "conversation", topic: "Małe przyjemności", topicEn: "Small pleasures", emoji: "☕" },
        { id: 102, type: "conversation", topic: "Święta w moim kraju", topicEn: "Holidays in my country", emoji: "🎁" },
        { id: 103, type: "conversation", topic: "Wady i zalety różnych pór roku", topicEn: "Pros and cons of different seasons", emoji: "🌤️" },
        { id: 104, type: "conversation", topic: "Ulubione jedzenie na śniadanie", topicEn: "Favorite breakfast food", emoji: "🥞" },
        { id: 105, type: "conversation", topic: "Znaczenie humoru w życiu", topicEn: "The importance of humor in life", emoji: "😂" },
        { id: 106, type: "situation", topic: "Prośba o rachunek rozdzielony na kilka osób", topicEn: "Asking for a split bill", emoji: "💳" }
      ]
    },
    {
      level: 11,
      id: 11,
      title: "Zaawansowany Nowicjusz",
      titleEn: "Advanced Novice",
      topics: [
        { id: 111, type: "conversation", topic: "Moi przyjaciele", topicEn: "My friends", emoji: "👫" },
        { id: 112, type: "conversation", topic: "Ważne wydarzenie w moim życiu", topicEn: "Important event in my life", emoji: "🎉" },
        { id: 113, type: "conversation", topic: "Ulubione miejsce na spacer", topicEn: "Favorite place for a walk", emoji: "🌳" },
        { id: 114, type: "conversation", topic: "Sposoby na poprawę nastroju", topicEn: "Ways to improve mood", emoji: "😊" },
        { id: 115, type: "conversation", topic: "Ulubione święta", topicEn: "Favorite holidays", emoji: "🎄" },
        { id: 116, type: "situation", topic: "Pytanie o drogę do muzeum", topicEn: "Asking for directions to the museum", emoji: "🗺️" }
      ]
    },
    {
      level: 12,
      id: 12,
      title: "Zbieracz Informacji",
      titleEn: "Information Gatherer",
      topics: [
        { id: 121, type: "situation", topic: "Rezerwacja stolika w restauracji", topicEn: "Booking a table at a restaurant", emoji: "🍽️" },
        { id: 122, type: "conversation", topic: "Dlaczego warto słuchać muzyki?", topicEn: "Why it's worth listening to music?", emoji: "🎵" },
        { id: 123, type: "situation", topic: "Na targu – negocjowanie ceny", topicEn: "At the market – negotiating the price", emoji: "🛍️" },
        { id: 124, type: "situation", topic: "Prośba o zwrot pieniędzy za wadliwy produkt", topicEn: "Asking for a refund for a faulty product", emoji: "💸" },
        { id: 125, type: "situation", topic: "W restauracji – pytanie o danie dnia", topicEn: "At the restaurant – asking about the dish of the day", emoji: "🥗" },
        { id: 126, type: "situation", topic: "Zgłoszenie zagubienia klucza do pokoju", topicEn: "Reporting a lost room key", emoji: "🔑" }
      ]
    },
    {
      level: 13,
      id: 13,
      title: "Kolekcjoner Pytań",
      titleEn: "Question Collector",
      topics: [
        { id: 131, type: "conversation", topic: "Plany na wakacje", topicEn: "Holiday plans", emoji: "✈️" },
        { id: 132, type: "conversation", topic: "Ulubione miejsca w naturze", topicEn: "Favorite places in nature", emoji: "🏞️" },
        { id: 133, type: "situation", topic: "Pytanie o miejsce parkingowe na płatnym parkingu", topicEn: "Asking about parking space in a paid parking lot", emoji: "🅿️" },
        { id: 134, type: "conversation", topic: "Prezenty", topicEn: "Gifts", emoji: "🎁" },
        { id: 135, type: "conversation", topic: "Cechy dobrego przyjaciela", topicEn: "Qualities of a good friend", emoji: "🤝" },
        { id: 136, type: "situation", topic: "Rozmowa o pracę", topicEn: "Job interview", emoji: "💼" }
      ]
    },
    {
      level: 14,
      id: 14,
      title: "Łowca Wiedzy",
      titleEn: "Knowledge Hunter",
      topics: [
        { id: 141, type: "conversation", topic: "Formy spędzania czasu z przyjaciółmi", topicEn: "Ways to spend time with friends", emoji: "🥳" },
        { id: 142, type: "situation", topic: "Tankowanie samochodu na stacji benzynowej", topicEn: "Refueling the car at a gas station", emoji: "⛽" },
        { id: 143, type: "conversation", topic: "Formy transportu", topicEn: "Forms of transportation", emoji: "🚗" },
        { id: 144, type: "conversation", topic: "Moja przyszłość za 10 lat", topicEn: "My future in 10 years", emoji: "🔮" },
        { id: 145, type: "situation", topic: "W księgarni – pytanie o książki w promocyjnej cenie", topicEn: "At the bookstore – asking about books on sale", emoji: "📚" },
        { id: 146, type: "conversation", topic: "Czas wolny", topicEn: "Free time", emoji: "⏳" }
      ]
    },
    {
      level: 15,
      id: 15,
      title: "Zwinny Myśliciel",
      titleEn: "Agile Thinker",
      topics: [
        { id: 151, type: "conversation", topic: "Cele na najbliższy rok", topicEn: "Goals for the next year", emoji: "🎯" },
        { id: 152, type: "conversation", topic: "Zdrowy styl życia", topicEn: "Healthy lifestyle", emoji: "🍎" },
        { id: 153, type: "conversation", topic: "Rola przyrody w życiu codziennym", topicEn: "The role of nature in daily life", emoji: "🌱" },
        { id: 154, type: "conversation", topic: "Wieczorna rutyna", topicEn: "Evening routine", emoji: "🌙" },
        { id: 155, type: "conversation", topic: "Co najbardziej lubię w swoim kraju?", topicEn: "What I like most about my country?", emoji: "🌍" },
        { id: 156, type: "situation", topic: "W sklepie odzieżowym – pytanie o dostępność innego rozmiaru", topicEn: "At the clothing store – asking about availability of another size", emoji: "👗" }
      ]
    },
    {
      level: 16,
      id: 16,
      title: "Mistrz Drobnych Kroków",
      titleEn: "Master of Small Steps",
      topics: [
        { id: 161, type: "situation", topic: "Pytanie o możliwość zwrotu", topicEn: "Asking about return policy", emoji: "↩️" },
        { id: 162, type: "conversation", topic: "Ulubiony artysta/muzyk", topicEn: "Favorite artist/musician", emoji: "🎤" },
        { id: 163, type: "conversation", topic: "Gotowanie", topicEn: "Cooking", emoji: "🍳" },
        { id: 164, type: "conversation", topic: "Plany nauczenia się czegoś w przyszłości", topicEn: "Plans to learn something in the future", emoji: "📖" },
        { id: 165, type: "conversation", topic: "Najciekawsze miejsca, które odwiedziłem", topicEn: "Most interesting places I've visited", emoji: "🗺️" },
        { id: 166, type: "conversation", topic: "Wspomnienia ze szkoły", topicEn: "School memories", emoji: "🏫" }
      ]
    },
    {
      level: 17,
      id: 17,
      title: "Władca Notatek",
      titleEn: "Master of Notes",
      topics: [
        { id: 171, type: "situation", topic: "W hotelu – negocjacje w sprawie zmiany pokoju", topicEn: "At the hotel – negotiating a room change", emoji: "🏨" },
        { id: 172, type: "situation", topic: "Rozmowa z obsługą o dostępnych atrakcjach w okolicy", topicEn: "Talking to staff about local attractions", emoji: "📌" },
        { id: 173, type: "conversation", topic: "Czy warto oszczędzać pieniądze?", topicEn: "Is it worth saving money?", emoji: "💰" },
        { id: 174, type: "situation", topic: "Kupowanie dodatkowego bagażu w ostatniej chwili", topicEn: "Buying extra luggage at the last minute", emoji: "🧳" },
        { id: 175, type: "situation", topic: "W centrum handlowym – prośba o odnalezienie zagubionego dziecka", topicEn: "At the mall – asking to find a lost child", emoji: "👶" },
        { id: 176, type: "conversation", topic: "Podejmowanie ryzyka", topicEn: "Taking risks", emoji: "🎲" }
      ]
    },
    {
      level: 18,
      id: 18,
      title: "Odkrywca Odpowiedzi",
      titleEn: "Explorer of Answers",
      topics: [
        { id: 181, type: "conversation", topic: "Ulubione miejsce w domu", topicEn: "Favorite place at home", emoji: "🏠" },
        { id: 182, type: "situation", topic: "Rozmowa z urzędnikiem imigracyjnym – pytania o wizę", topicEn: "Talking to an immigration officer – visa questions", emoji: "📜" },
        { id: 183, type: "conversation", topic: "Sposoby walki ze stresem", topicEn: "Ways to deal with stress", emoji: "🧘" },
        { id: 184, type: "conversation", topic: "Ulubiony przedmiot w szkole", topicEn: "Favorite subject in school", emoji: "📘" },
        { id: 185, type: "situation", topic: "Restauracja - specjalne okazje (np. tort urodzinowy)", topicEn: "Restaurant - special occasions (e.g., birthday cake)", emoji: "🎂" },
        { id: 186, type: "situation", topic: "Na lotnisku – przebookowanie lotu", topicEn: "At the airport – rebooking a flight", emoji: "✈️" }
      ]
    },
    {
      level: 19,
      id: 19,
      title: "Poszukiwacz Rozwiązań",
      titleEn: "Solution Seeker",
      topics: [
        { id: 191, type: "conversation", topic: "Co bym zrobił, gdyby mogły się spełnić moje trzy życzenia?", topicEn: "What would I do if my three wishes could come true?", emoji: "✨" },
        { id: 192, type: "situation", topic: "W muzeum – pytanie o przewodnika w języku obcym", topicEn: "At the museum – asking for a guide in a foreign language", emoji: "🎧" },
        { id: 193, type: "situation", topic: "Rozmowa z recepcjonistą o wycieczce fakultatywnej", topicEn: "Talking to the receptionist about an optional tour", emoji: "🚌" },
        { id: 194, type: "conversation", topic: "Ulubiony nauczyciel", topicEn: "Favorite teacher", emoji: "👩‍🏫" },
        { id: 195, type: "conversation", topic: "Dlaczego ludzie marzą?", topicEn: "Why do people dream?", emoji: "💭" },
        { id: 196, type: "conversation", topic: "Najważniejsze wartości w życiu", topicEn: "Most important values in life", emoji: "⚖️" }
      ]
    },
    {
      level: 20,
      id: 20,
      title: "Zapalony Orator",
      titleEn: "Eager Orator",
      topics: [
        { id: 201, type: "conversation", topic: "Sposoby na oszczędzanie", topicEn: "Ways to save money", emoji: "🏦" },
        { id: 202, type: "conversation", topic: "Ulubione miejsce w moim mieście", topicEn: "Favorite place in my city", emoji: "🏙️" },
        { id: 203, type: "situation", topic: "W muzeum – pytanie o dostępność audioprzewodników", topicEn: "At the museum – asking about availability of audio guides", emoji: "🎧" },
        { id: 204, type: "conversation", topic: "Radzenie sobie z porażkami", topicEn: "Dealing with failures", emoji: "📉" },
        { id: 205, type: "situation", topic: "Pytanie o dodatkowe usługi, np. pralnię hotelową", topicEn: "Asking about additional services, e.g., hotel laundry", emoji: "🧺" },
        { id: 206, type: "conversation", topic: "Cele na przyszłość", topicEn: "Goals for the future", emoji: "🚀" }
      ]
    },
    {
      level: 21,
      id: 21,
      title: "Rzemieślnik Słowa",
      titleEn: "Word Craftsman",
      topics: [
        { id: 211, type: "conversation", topic: "Rozwój osobisty", topicEn: "Personal development", emoji: "💡" },
        { id: 212, type: "situation", topic: "Rozmowa o lokalnych specjałach", topicEn: "Talking about local specialties", emoji: "🍴" },
        { id: 213, type: "situation", topic: "Na lotnisku – skarga na opóźnienie lotu", topicEn: "At the airport – complaining about a flight delay", emoji: "✈️" },
        { id: 214, type: "situation", topic: "Na policji – zgłaszanie zagubienia paszportu", topicEn: "At the police – reporting a lost passport", emoji: "👮‍♂️" },
        { id: 215, type: "situation", topic: "W restauracji – składanie skargi na zimne jedzenie", topicEn: "At the restaurant – complaining about cold food", emoji: "🍽️" },
        { id: 216, type: "situation", topic: "Na poczcie – wysyłanie paczki za granicę", topicEn: "At the post office – sending a package abroad", emoji: "📦" }
      ]
    },
    {
      level: 22,
      id: 22,
      title: "Biegły w Przedbiegach",
      titleEn: "Proficient in the Preliminaries",
      topics: [
        { id: 221, type: "situation", topic: "Pytanie o alternatywne loty podczas opóźnienia", topicEn: "Asking about alternative flights during a delay", emoji: "🛫" },
        { id: 222, type: "situation", topic: "W banku – otwieranie konta osobistego", topicEn: "At the bank – opening a personal account", emoji: "🏦" },
        { id: 223, type: "conversation", topic: "Zabawne wspomnienia", topicEn: "Funny memories", emoji: "😂" },
        { id: 224, type: "conversation", topic: "Moje pasje i zainteresowania", topicEn: "My passions and interests", emoji: "🎨" },
        { id: 225, type: "situation", topic: "Na lotnisku – zgłaszanie zgubionego bagażu", topicEn: "At the airport – reporting lost luggage", emoji: "🧳" },
        { id: 226, type: "conversation", topic: "Ulubiona zabawa z dzieciństwa", topicEn: "Favorite childhood game", emoji: "🧸" }
      ]
    },
    {
      level: 23,
      id: 23,
      title: "Edukacyjny Ninja",
      titleEn: "Educational Ninja",
      topics: [
        { id: 231, type: "situation", topic: "W hotelu – zgłoszenie problemu z klimatyzacją", topicEn: "At the hotel – reporting an air conditioning issue", emoji: "❄️" },
        { id: 232, type: "situation", topic: "U mechanika – opisanie problemu z samochodem", topicEn: "At the mechanic – describing a car problem", emoji: "🚗" },
        { id: 233, type: "situation", topic: "Wypożyczanie sprzętu sportowego w górach", topicEn: "Renting sports equipment in the mountains", emoji: "⛷️" },
        { id: 234, type: "situation", topic: "Wynajem apartamentu – pytanie o koszty", topicEn: "Renting an apartment – asking about costs", emoji: "🏘️" },
        { id: 235, type: "conversation", topic: "Diety i odchudzanie", topicEn: "Diets and weight loss", emoji: "🥗" },
        { id: 236, type: "situation", topic: "Rozmowa o problemach z daniem", topicEn: "Talking about problems with a dish", emoji: "🍲" }
      ]
    },
    {
      level: 24,
      id: 24,
      title: "Ekspert w Ekspresie",
      titleEn: "Express Expert",
      topics: [
        { id: 241, type: "conversation", topic: "Marzenia dotyczące podróży", topicEn: "Travel dreams", emoji: "🌍" },
        { id: 242, type: "conversation", topic: "Szczerość", topicEn: "Honesty", emoji: "💬" },
        { id: 243, type: "conversation", topic: "Co to znaczy być dobrym człowiekiem?", topicEn: "What does it mean to be a good person?", emoji: "🤝" },
        { id: 244, type: "conversation", topic: "Dlaczego warto dbać o środowisko?", topicEn: "Why is it important to care for the environment?", emoji: "🌱" },
        { id: 245, type: "conversation", topic: "Podróżowanie samotnie vs z grupą", topicEn: "Traveling alone vs with a group", emoji: "🌏" },
        { id: 246, type: "conversation", topic: "Dbanie o zdrowie", topicEn: "Taking care of health", emoji: "💪" }
      ]
    },
    {
      level: 25,
      id: 25,
      title: "Geniusz w Treningu",
      titleEn: "Genius in Training",
      topics: [
        { id: 251, type: "conversation", topic: "Mój idealny dzień", topicEn: "My ideal day", emoji: "☀️" },
        { id: 252, type: "situation", topic: "W szpitalu – opisywanie objawów lekarzowi", topicEn: "At the hospital – describing symptoms to a doctor", emoji: "🏥" },
        { id: 253, type: "conversation", topic: "Ciekawe wydarzenia kulturalne", topicEn: "Interesting cultural events", emoji: "🎭" },
        { id: 254, type: "conversation", topic: "Co najbardziej cenię w swojej kulturze?", topicEn: "What I value most in my culture", emoji: "🌸" },
        { id: 255, type: "situation", topic: "Zgłaszanie awarii w wynajmowanym apartamencie", topicEn: "Reporting a malfunction in a rented apartment", emoji: "🔧" },
        { id: 256, type: "situation", topic: "Organizacja wycieczki przez recepcję", topicEn: "Organizing a trip through the reception", emoji: "📋" }
      ]
    },
    {
      level: 26,
      id: 26,
      title: "Skrupulatny Znawca",
      titleEn: "Meticulous Expert",
      topics: [
        { id: 261, type: "situation", topic: "Rozmowa z sąsiadem o hałasie", topicEn: "Talking to a neighbor about noise", emoji: "🔊" },
        { id: 262, type: "situation", topic: "Wynajmowanie przewodnika turystycznego", topicEn: "Hiring a tour guide", emoji: "🗺️" },
        { id: 263, type: "situation", topic: "Pytanie o najlepsze wino do dania", topicEn: "Asking about the best wine for a dish", emoji: "🍷" },
        { id: 264, type: "conversation", topic: "Co chciałbym w sobie zmienić?", topicEn: "What I would like to change about myself", emoji: "🔄" },
        { id: 265, type: "situation", topic: "W kwiaciarni – zamawianie kwiatów na specjalną okazję", topicEn: "At the florist – ordering flowers for a special occasion", emoji: "💐" },
        { id: 266, type: "conversation", topic: "Czy technologia ułatwia życie?", topicEn: "Does technology make life easier?", emoji: "📱" }
      ]
    },
    {
      level: 27,
      id: 27,
      title: "Przyszły Profesjonalista",
      titleEn: "Future Professional",
      topics: [
        { id: 271, type: "situation", topic: "Prośba o zmianę miejsca w samolocie", topicEn: "Requesting a seat change on a plane", emoji: "💺" },
        { id: 272, type: "conversation", topic: "Zeszły weekend", topicEn: "Last weekend", emoji: "🗓️" },
        { id: 273, type: "situation", topic: "Wynajęcie samochodu na lotnisku", topicEn: "Renting a car at the airport", emoji: "🚙" },
        { id: 274, type: "situation", topic: "Zgłaszanie problemów z obsługą kelnerską", topicEn: "Reporting issues with waiter service", emoji: "🤵" },
        { id: 275, type: "conversation", topic: "Wspomnienie z dzieciństwa", topicEn: "Childhood memory", emoji: "📖" },
        { id: 276, type: "conversation", topic: "Samochody vs komunikacja publiczna", topicEn: "Cars vs public transportation", emoji: "🚉" }
      ]
    },
    {
      level: 28,
      id: 28,
      title: "Magister konwersacji",
      titleEn: "Master of Conversation",
      topics: [
        { id: 281, type: "conversation", topic: "Dlaczego warto podróżować?", topicEn: "Why is it worth traveling?", emoji: "🗺️" },
        { id: 282, type: "conversation", topic: "Ulubione tradycje", topicEn: "Favorite traditions", emoji: "🎉" },
        { id: 283, type: "situation", topic: "Pytanie o możliwość późniejszego wymeldowania", topicEn: "Asking about the possibility of late check-out", emoji: "⏰" },
        { id: 284, type: "conversation", topic: "Siła nawyku", topicEn: "The power of habit", emoji: "🔁" },
        { id: 285, type: "situation", topic: "Zgłaszanie problemów z zamkiem w pokoju hotelowym", topicEn: "Reporting issues with the lock in a hotel room", emoji: "🔒" },
        { id: 286, type: "conversation", topic: "Co byś zrobił, gdybyś wygrał na loterii?", topicEn: "What would you do if you won the lottery?", emoji: "💰" }
      ]
    },
    {
      level: 29,
      id: 29,
      title: "Kreator Odpowiedzi",
      titleEn: "Answer Creator",
      topics: [
        { id: 291, type: "conversation", topic: "Ulubione miejsca na wakacje", topicEn: "Favorite vacation spots", emoji: "🏖️" },
        { id: 292, type: "conversation", topic: "Wspomnienia z podróży", topicEn: "Travel memories", emoji: "📸" },
        { id: 293, type: "conversation", topic: "Jak pandemia wpłynęła na nasze życie?", topicEn: "How has the pandemic affected our lives?", emoji: "🦠" },
        { id: 294, type: "situation", topic: "Rozmowa z ubezpieczycielem o wypadku samochodowym", topicEn: "Talking to an insurer about a car accident", emoji: "📄" },
        { id: 295, type: "conversation", topic: "Praca w dużej vs małej firmie", topicEn: "Working in a large vs small company", emoji: "🏢" },
        { id: 296, type: "conversation", topic: "Inteligencja vs mądrość", topicEn: "Intelligence vs wisdom", emoji: "🧠" }
      ]
    },
    {
      level: 30,
      id: 30,
      title: "Licencjonowany Obieżyświat",
      titleEn: "Licensed Globetrotter",
      topics: [
        { id: 301, type: "conversation", topic: "Ulubione danie narodowe", topicEn: "Favorite national dish", emoji: "🍛" },
        { id: 302, type: "conversation", topic: "Życie studenckie w moim kraju", topicEn: "Student life in my country", emoji: "🎓" },
        { id: 303, type: "conversation", topic: "Miejsca, które warto odwiedzić w moim kraju", topicEn: "Places worth visiting in my country", emoji: "🏞️" },
        { id: 304, type: "conversation", topic: "Rozwój technologii: korzystny czy szkodliwy?", topicEn: "Technological development: beneficial or harmful?", emoji: "🤖" },
        { id: 305, type: "conversation", topic: "Pieniądze vs szczęście", topicEn: "Money vs happiness", emoji: "💸" },
        { id: 306, type: "conversation", topic: "Media społecznościowe a dezinformacja", topicEn: "Social media and misinformation", emoji: "🌐" }
      ]
    },
    {
      level: 31,
      id: 31,
      title: "Zawodowy Poszukiwacz",
      titleEn: "Professional Seeker",
      topics: [
        { id: 311, type: "conversation", topic: "Zalety i wady mieszkania w dużym mieście", topicEn: "Advantages and disadvantages of living in a big city", emoji: "🏙️" },
        { id: 312, type: "conversation", topic: "Czy technologia może zastąpić ludzką kreatywność?", topicEn: "Can technology replace human creativity?", emoji: "🤖" },
        { id: 313, type: "situation", topic: "Opisywanie objawów i sytuacji, w której doszło do urazu", topicEn: "Describing symptoms and the situation in which the injury occurred", emoji: "🤕" },
        { id: 314, type: "conversation", topic: "Ciekawe doświadczenia z podróży", topicEn: "Interesting travel experiences", emoji: "✈️" },
        { id: 315, type: "conversation", topic: "Marzenia", topicEn: "Dreams", emoji: "🌟" },
        { id: 316, type: "conversation", topic: "Wartości ważne w przyjaźni", topicEn: "Values important in friendship", emoji: "🤝" }
      ]
    },
    {
      level: 32,
      id: 32,
      title: "Specjalista Bez Świadomości",
      titleEn: "Unconscious Specialist",
      topics: [
        { id: 321, type: "situation", topic: "Rozmowa z policjantem – zgłaszanie kradzieży", topicEn: "Talking to a policeman - reporting a theft", emoji: "👮‍♂️" },
        { id: 322, type: "conversation", topic: "Osiągnięcia", topicEn: "Achievements", emoji: "🏆" },
        { id: 323, type: "conversation", topic: "Myślenie logiczne vs kreatywność", topicEn: "Logical thinking vs creativity", emoji: "🧠" },
        { id: 324, type: "conversation", topic: "Zalety mojego kraju", topicEn: "Advantages of my country", emoji: "💪" },
        { id: 325, type: "conversation", topic: "Moja ulubiona książka", topicEn: "My favorite book", emoji: "📚" },
        { id: 326, type: "conversation", topic: "Sprawiedliwość", topicEn: "Justice", emoji: "⚖️" }
      ]
    },
    {
      level: 33,
      id: 33,
      title: "Odkrywca Nowych Światów",
      titleEn: "Explorer of New Worlds",
      topics: [
        { id: 331, type: "conversation", topic: "Praca zdalna", topicEn: "Remote work", emoji: "💻" },
        { id: 332, type: "conversation", topic: "Rola muzyki w życiu człowieka", topicEn: "The role of music in human life", emoji: "🎵" },
        { id: 333, type: "conversation", topic: "Ostatnie wakacje lub wyjazd", topicEn: "Last vacation or trip", emoji: "🏖️" },
        { id: 334, type: "conversation", topic: "Doświadczenia związane z nauką nowej umiejętności", topicEn: "Experiences related to learning a new skill", emoji: "🎓" },
        { id: 335, type: "conversation", topic: "Życie bez internetu", topicEn: "Life without the internet", emoji: "🌐❌" },
        { id: 336, type: "conversation", topic: "Ulubione wspomnienia z wakacji", topicEn: "Favorite vacation memories", emoji: "🌅" }
      ]
    },
    {
      level: 34,
      id: 34,
      title: "Wiedzo-maniak",
      titleEn: "Knowledge Maniac",
      topics: [
        { id: 341, type: "conversation", topic: "Formy spędzania czasu z rodziną", topicEn: "Ways to spend time with family", emoji: "👨‍👩‍👧‍👦" },
        { id: 342, type: "situation", topic: "Na policji – składanie zeznań jako świadek", topicEn: "At the police - giving testimony as a witness", emoji: "👮‍♀️" },
        { id: 343, type: "conversation", topic: "Znaczenie codziennych rytuałów", topicEn: "The importance of daily rituals", emoji: "☕" },
        { id: 344, type: "situation", topic: "Negocjowanie ceny za wycieczkę z przewodnikiem", topicEn: "Negotiating the price for a guided tour", emoji: "💰" },
        { id: 345, type: "conversation", topic: "Zwierzęta w zoo", topicEn: "Animals in the zoo", emoji: "🦁" },
        { id: 346, type: "situation", topic: "Wizyta w ambasadzie – ubieganie się o nowy paszport", topicEn: "Visit to the embassy - applying for a new passport", emoji: "🛂" }
      ]
    },
    {
      level: 35,
      id: 35,
      title: "Językowy Mag",
      titleEn: "Language Mage",
      topics: [
        { id: 351, type: "conversation", topic: "Ulubione tradycje rodzinne", topicEn: "Favorite family traditions", emoji: "🎄" },
        { id: 352, type: "conversation", topic: "Wyzwania na przyszłość", topicEn: "Challenges for the future", emoji: "🚀" },
        { id: 353, type: "conversation", topic: "Ulubione przepisy kulinarne", topicEn: "Favorite recipes", emoji: "🍲" },
        { id: 354, type: "conversation", topic: "Motywacja do nauki języków", topicEn: "Motivation to learn languages", emoji: "🗣️" },
        { id: 355, type: "situation", topic: "Negocjacje dotyczące przedłużenia pobytu", topicEn: "Negotiations for extending the stay", emoji: "⏳" },
        { id: 356, type: "conversation", topic: "Doświadczenia z nauką w szkole", topicEn: "Experiences with learning at school", emoji: "🏫" }
      ]
    },
    {
      level: 36,
      id: 36,
      title: "Waćpan Wiedzy",
      titleEn: "Lord of Knowledge",
      topics: [
        { id: 361, type: "conversation", topic: "Różnice między życiem w mieście a na wsi", topicEn: "Differences between city and country life", emoji: "🌆🌳" },
        { id: 362, type: "conversation", topic: "Moja dieta", topicEn: "My diet", emoji: "🥗" },
        { id: 363, type: "conversation", topic: "Zimowe wieczory", topicEn: "Winter evenings", emoji: "❄️" },
        { id: 364, type: "situation", topic: "Wynajem mieszkania – rozmowa z właścicielem", topicEn: "Renting an apartment - conversation with the owner", emoji: "🏠" },
        { id: 365, type: "conversation", topic: "Kariera vs życie osobiste", topicEn: "Career vs personal life", emoji: "⚖️" },
        { id: 366, type: "conversation", topic: "Wyznaczniki sukcesu", topicEn: "Indicators of success", emoji: "⭐" }
      ]
    },
    {
      level: 37,
      id: 37,
      title: "Władca słów",
      titleEn: "Master of Words",
      topics: [
        { id: 371, type: "conversation", topic: "Wizja świata za 50 lat", topicEn: "Vision of the world in 50 years", emoji: "🌍🔮" },
        { id: 372, type: "situation", topic: "Rozmowa o zmianie warunków kontraktu", topicEn: "Conversation about changing contract terms", emoji: "📜" },
        { id: 373, type: "conversation", topic: "Największe wyzwania współczesnego świata", topicEn: "The biggest challenges of the modern world", emoji: "🌎🔥" },
        { id: 374, type: "conversation", topic: "Znaczenie znajomości języków", topicEn: "The importance of knowing languages", emoji: "🌏🗨️" },
        { id: 375, type: "conversation", topic: "Zmiany na rynku pracy w ostatnich dekadach", topicEn: "Changes in the job market in recent decades", emoji: "📈" },
        { id: 376, type: "conversation", topic: "Wirtualna rzeczywistość", topicEn: "Virtual reality", emoji: "🕶️" }
      ]
    },
    {
      level: 38,
      id: 38,
      title: "Konwersacyjna Legenda",
      titleEn: "Conversational Legend",
      topics: [
        { id: 381, type: "conversation", topic: "Pojęcie szczęścia", topicEn: "The concept of happiness", emoji: "😊" },
        { id: 382, type: "conversation", topic: "Wyzwania związane z ochroną środowiska", topicEn: "Challenges related to environmental protection", emoji: "🌱" },
        { id: 383, type: "conversation", topic: "Sztuczna inteligencja", topicEn: "Artificial intelligence", emoji: "🤖" },
        { id: 384, type: "conversation", topic: "Wartości społeczne kiedyś i dziś", topicEn: "Social values then and now", emoji: "🏛️" },
        { id: 385, type: "conversation", topic: "Różnice między pokoleniami", topicEn: "Differences between generations", emoji: "👶👴" },
        { id: 386, type: "conversation", topic: "Kultura mojego kraju", topicEn: "Culture of my country", emoji: "🎨" }
      ]
    },
    {
      level: 39,
      id: 39,
      title: "Językowy Arcymistrz",
      titleEn: "Language Grandmaster",
      topics: [
        { id: 391, type: "conversation", topic: "Źródła motywacji", topicEn: "Sources of motivation", emoji: "🔥" },
        { id: 392, type: "conversation", topic: "Rola edukacji w życiu człowieka", topicEn: "The role of education in human life", emoji: "📖" },
        { id: 393, type: "conversation", topic: "Wpływ technologii na nasze życie", topicEn: "The impact of technology on our lives", emoji: "📱" },
        { id: 394, type: "conversation", topic: "Konsumpcja mięsa", topicEn: "Meat consumption", emoji: "🥩" },
        { id: 395, type: "conversation", topic: "Mieszkanie za granicą", topicEn: "Living abroad", emoji: "✈️🌏" },
        { id: 396, type: "conversation", topic: "Rola sztuki w życiu człowieka", topicEn: "The role of art in human life", emoji: "🎭" }
      ]
    },
    {
      level: 40,
      id: 40,
      title: "Wirtuoz rozmowy",
      titleEn: "Virtuoso of Conversation",
      topics: [
        { id: 401, type: "situation", topic: "Prowadzenie rozmów o zakupie nieruchomości", topicEn: "Conducting conversations about buying real estate", emoji: "🏡" },
        { id: 402, type: "conversation", topic: "Zalety i wady bycia sławnym", topicEn: "Advantages and disadvantages of being famous", emoji: "🌟" },
        { id: 403, type: "conversation", topic: "Zalety i wady technologii", topicEn: "Advantages and disadvantages of technology", emoji: "📡" },
        { id: 404, type: "conversation", topic: "Zalety i wady globalizacji", topicEn: "Advantages and disadvantages of globalization", emoji: "🌍" },
        { id: 405, type: "conversation", topic: "Idealne miejsce do życia", topicEn: "Ideal place to live", emoji: "🏞️" },
        { id: 406, type: "conversation", topic: "Plusy i minusy mediów społecznościowych", topicEn: "Pros and cons of social media", emoji: "📱🌐" }
      ]
    }
  ]
}