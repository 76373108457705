import { Injectable } from '@angular/core';
import uniqid from 'uniqid';
import clone from 'clone-deep';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  usersSets = [];
  colors400 = [
    '#ef5350',
    '#ec407a',
    '#ab47bc',
    '#5c6bc0',
    '#42a5f5',
    '#29b6f6',
    '#26c6da',
    '#26a69a',
    '#66bb6a',
    '#9ccc65',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#78909c',
  ];
  accentMap = {
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    ç: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
  };
  constructor() { }
  removeSpecialCharsAndSpaces(str) {
    return this.removeAccentFromString(str)
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '');
  }
  generateRandomString(length: number): string {
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const values = crypto.getRandomValues(new Uint32Array(length));
    values.forEach((value) => {
      result += charset[value % charset.length];
    });
    return result;
  }

  getLongestString(array) {
    let longestString = '';
    for (const item of array) {
      if (item.length > longestString.length) {
        longestString = item;
      }
    }
    return longestString;
  }

  translateObjectFromBackend(object) {
    let result;
    result = object.data;
    result.id = object._id;
    return result;
  }
  removeAccentFromString(string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  convertGoogleDriveLink(link) {
    let id = link.replace('https://drive.google.com/file/d/', '');
    id = id
      .replace('/view?usp=share_link', '')
      .replace('/view?usp=sharing', '')
      .replace('/view?usp=drivesdk', '');

    const directLink =
      'https://drive.google.com/uc?id=' + id + '&export=download';

    return directLink;
  }
  getUniqueRandomItemsFromArray(array, numberOfItems) {
    let result = [];
    let tempArray = [...array]; // clone the original array

    for (let i = 0; i < numberOfItems; i++) {
      if (tempArray.length === 0) {
        break; // break if we've exhausted all elements
      }

      let randomIndex = Math.floor(Math.random() * tempArray.length); // get a random index
      result.push(tempArray[randomIndex]); // push the random item from tempArray to result
      tempArray.splice(randomIndex, 1); // remove the used item from tempArray
    }

    return result;
  }
  getRandomObjectFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }
  clone(item) {
    return clone(item);
  }
  removeDuplicatesObjbyId(array) {
    let uniqueItems = [];
    array.forEach((itemToCheck) => {
      if (!uniqueItems.some((item) => item.id == itemToCheck.id)) {
        uniqueItems.push(itemToCheck);
      }
    });
    return uniqueItems;
  }
  calculateTextSize(text: string): string {
    const deviceHeight = window.innerHeight;
    const textLength = text.toString().length;
    // Calculate the ideal font size based on the device height and text length
    let heightFactor = 1.4;
    let lengthFactor = 3.8;
    if (deviceHeight > 700) {
      heightFactor = 1.1;
      lengthFactor = 3.7;
    }

    const idealFontSize =
      (deviceHeight * heightFactor) / (textLength * lengthFactor);

    // Limit the font size to a maximum of 3vh and a minimum of 2vh
    const fontSize = Math.max(Math.min(idealFontSize, 3), 1.5);

    // Convert the font size to a string with "vh" units

    return fontSize.toFixed(2) + 'vh';
  }
  sortArrayByProperty(array, property, direction = 1) {
    array.sort(function compare(a, b) {
      // Check for nullish values
      const aIsNullish = a[property] === null || a[property] === undefined;
      const bIsNullish = b[property] === null || b[property] === undefined;

      // If both are nullish, they are equal in terms of sorting
      if (aIsNullish && bIsNullish) return 0;

      // If a is nullish but b is not, a should come first
      if (aIsNullish && !bIsNullish) return -1;

      // If b is nullish but a is not, b should come first
      if (!aIsNullish && bIsNullish) return 1;

      // Regular comparison
      let comparison = 0;
      if (a[property] > b[property]) {
        comparison = 1 * direction;
      } else if (a[property] < b[property]) {
        comparison = -1 * direction;
      }
      return comparison;
    });

    return array; // Chainable
  }

  get nativeWindow(): Window {
    return window;
  }

  fixJSONFormat(json: string): string {
    let originalJson = this.clone(json);



    return originalJson

  }









  chunk(arr, chunkSize) {
    if (chunkSize <= 0) {
      throw new Error('Invalid chunk size');
    }
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
  shuffleArrayOfObjects(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  makeid(length?) {
    return uniqid();
  }
  getRandomNumber(min, max) {
    let result;
    result = Math.random() * (max - min) + min;
    return Math.round(result);
  }

  /**
   * Determines if a given text is written in Polish
   * Uses a combination of Polish-specific characters and common Polish words
   * @param text - The text to analyze
   * @returns boolean - true if the text is likely Polish, false otherwise
   */
  isPolishText(text: string): boolean {
    if (!text || typeof text !== 'string' || text.trim().length === 0) {
      return false;
    }

    // Normalize and lowercase the text
    const normalizedText = text.toLowerCase().trim();

    // Polish-specific characters pattern
    const polishCharPattern = /[ąćęłńóśźż]/i;

    // Common Polish words (articles, prepositions, conjunctions)
    const commonPolishWords = [
      'i', 'w', 'na', 'z', 'do', 'to', 'że', 'się', 'nie', 'jest',
      'dla', 'przez', 'ale', 'jak', 'od', 'co', 'tak', 'po', 'lub',
      'o', 'tylko', 'już', 'może', 'być', 'przy', 'są', 'czy', 'jako',
      'kiedy', 'bardzo', 'więc', 'jeszcze', 'przed', 'również', 'jeśli',
      'gdyż', 'ponieważ', 'między', 'jednak', 'oraz', 'nawet'
    ];

    // Common Polish character pair patterns (even without diacritics)
    const polishCharPairs = ['cz', 'sz', 'rz', 'dz', 'ch', 'ci', 'ni', 'si', 'zi', 'dzi'];

    // Common Polish word endings
    const polishEndings = ['ować', 'emy', 'amy', 'cie', 'ać', 'ski', 'cki', 'dzki', 'owy', 'owa', 'owe',
      'ego', 'emu', 'iej', 'om', 'ach', 'ami', 'owi', 'em', 'imi', 'ymi', 'ich', 'ych'];

    // Split text into words
    const words = normalizedText
      .replace(/[.,!?;:()"""'']/g, ' ')
      .split(/\s+/)
      .filter(word => word.length > 0);

    if (words.length === 0) {
      return false;
    }

    // Check for Polish characters
    const hasPolishChars = polishCharPattern.test(normalizedText);

    // Count common Polish words
    let polishWordCount = 0;
    words.forEach(word => {
      if (commonPolishWords.includes(word)) {
        polishWordCount++;
      }
    });

    // Calculate Polish word ratio
    const polishWordRatio = polishWordCount / words.length;

    // Additional detection mechanisms when Polish characters and common words are absent
    if (!hasPolishChars && polishWordRatio < 0.15) {
      // Check for Polish character pairs count
      let pairCount = 0;
      polishCharPairs.forEach(pair => {
        const regex = new RegExp(pair, 'gi');
        const matches = normalizedText.match(regex) || [];
        pairCount += matches.length;
      });
      const pairRatio = pairCount / (normalizedText.length / 2);

      // Check for Polish word endings
      let endingCount = 0;
      words.forEach(word => {
        if (word.length >= 3) {
          for (const ending of polishEndings) {
            if (word.endsWith(ending)) {
              endingCount++;
              break;
            }
          }
        }
      });
      const endingRatio = endingCount / words.length;

      // Frequency of specific consonant patterns (like 'w' at the beginning of words)
      const wPrefixWords = words.filter(word => word.startsWith('w') && word.length > 1).length;
      const wPrefixRatio = wPrefixWords / words.length;

      // Vowel/consonant distribution check - Polish has many consonants
      const vowels = 'aeiouy';
      let consonantCount = 0;
      let vowelCount = 0;
      normalizedText.split('').forEach(char => {
        if (/[a-z]/.test(char)) {
          if (vowels.includes(char)) {
            vowelCount++;
          } else {
            consonantCount++;
          }
        }
      });
      const consonantRatio = consonantCount / (consonantCount + vowelCount || 1);

      // Combined analysis of multiple factors
      const combinedScore = (pairRatio * 3) + (endingRatio * 4) + (wPrefixRatio * 2) +
        (consonantRatio > 0.55 ? 0.5 : 0);

      if (combinedScore >= 0.6) {
        return true;
      }

      // Additional check for long sequences of consonants (common in Polish)
      const consonantClusterPattern = /[bcdfghjklmnprstwzś]{3,}/i;
      if (consonantClusterPattern.test(normalizedText) && consonantRatio > 0.52) {
        return true;
      }
    }

    // Use a combination of metrics to determine if it's Polish
    // Strong signal if there are Polish characters
    if (hasPolishChars && words.length >= 3) {
      return true;
    }

    // If no Polish characters but has a significant ratio of common Polish words
    if (words.length >= 5 && polishWordRatio >= 0.25) {
      return true;
    }

    // If text is shorter but has a very high ratio of Polish words
    if (words.length >= 2 && words.length < 5 && polishWordRatio >= 0.5) {
      return true;
    }

    return false;
  }
}
