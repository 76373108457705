import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatbotsConversationsTopicsService } from '../chatbots-conversations-topics/chatbots-conversations-topics.service';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';

@Component({
    selector: 'app-conversations-level-modal',
    templateUrl: './conversations-level-modal.component.html',
    styleUrls: ['./conversations-level-modal.component.scss'],
})
export class ConversationsLevelModalComponent implements OnInit {
    levels: any[] = [];
    currentLevel: number = 1;
    currentLanguage: 'pl' | 'en' = 'pl'; // Default language is Polish
    completedTopics: any[] = [];

    constructor(
        private modalController: ModalController,
        private chatbotsConversationsTopicsService: ChatbotsConversationsTopicsService,
        private userDetailsService: UserDetailsService
    ) { }

    ngOnInit() {
        this.loadCompletedTopics();
        this.currentLevel = this.getUserLevel();

        // Use the topics from the service
        this.levels = this.chatbotsConversationsTopicsService.topics;
    }

    getUserLevel(): number {
        // Get the user's data
        const userData = this.userDetailsService.getUserDetails()?.userData;

        if (userData && userData.conversationsStats) {
            // Get the latest conversation stats entry
            const stats = userData.conversationsStats;
            if (stats.length > 0) {
                // The level is stored in the badge path or directly in the stats
                const latestStat = stats[stats.length - 1];
                if (latestStat.badge) {
                    // Extract level from badge path (e.g., 'assets/kowersacjebadges/3.png' -> 3)
                    const levelMatch = latestStat.badge.match(/(\d+)\.png$/);
                    if (levelMatch && levelMatch[1]) {
                        return parseInt(levelMatch[1], 10);
                    }
                }

                // If no badge or couldn't extract level, use the level property if available
                if (latestStat.level) {
                    return latestStat.level;
                }
            }
        }

        // Default to level 1 if no data is available
        return 1;
    }

    loadCompletedTopics() {
        // Get the completed topics from the user's data
        const userData = this.userDetailsService.getUserDetails()?.userData;
        if (userData && userData.conversationsStats) {
            // Extract completed topic IDs from the user's conversation stats
            // We need to look at the nested conversations within each category
            this.completedTopics = [];

            userData.conversationsStats.forEach(category => {
                if (category.conversations && Array.isArray(category.conversations)) {
                    // Add IDs of finished conversations to the completedTopics array
                    const finishedTopics = category.conversations
                        .filter(conversation => conversation.finished)
                        .map(conversation => conversation.id);

                    this.completedTopics.push(...finishedTopics);
                }
            });
        } else {
            // Fallback to empty array if no data is available
            this.completedTopics = [];
        }
    }

    // This method is now only used as a fallback
    getCompletedTopics() {
        // Sample completed topics for testing
        return [11, 12, 13, 21, 31]; // Example of completed topics
    }

    isLevelUnlocked(levelId: number): boolean {
        // A level is unlocked if it's less than or equal to the user's current level
        return levelId <= this.currentLevel;
    }

    isTopicCompleted(topicId: number): boolean {
        return this.completedTopics.includes(topicId);
    }

    getCompletedTopicsCount(topics: any[]): number {
        return topics.filter(topic => this.isTopicCompleted(topic.id)).length;
    }

    toggleLanguage() {
        this.currentLanguage = this.currentLanguage === 'pl' ? 'en' : 'pl';
    }

    getLanguageButtonText(): string {
        return this.currentLanguage === 'pl' ? 'English' : 'Polski';
    }

    closeModal() {
        this.modalController.dismiss();
    }

    onTopicClick(topic: any) {
        // Get the level ID from the topic or from its parent level
        const levelId = topic.levelId || topic.level;

        if (!this.isLevelUnlocked(levelId)) {
            // Show alert or message that level is locked
            return;
        }

        // Close modal and pass the selected topic
        this.modalController.dismiss({
            topic: topic
        });
    }
} 