import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gapHighlight'
})
export class GapHighlightPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }

        // Replace any sequence of underscores with a styled gap
        // This handles both single underscores and multiple consecutive underscores
        return value.replace(/_{1,}/g, '<span class="gap">_____</span>');
    }
} 