import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReadTextService } from 'src/app/shared/services/read-text.service';
import { SpinnerService } from '../../services/spinner.service'; // @ts-ignore
import { SetsService } from '../../services/sets.service';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/naming-convention
const LanguageDetect = require('languagedetect');

@Component({
  selector: 'app-set-content-card',
  templateUrl: './set-content-card.component.html',
  styleUrls: ['./set-content-card.component.scss'],
})
export class SetContentCardComponent implements OnInit {
  @Input() setItem;
  @Input() setId
  @Input() usersSet = false;
  @Input() dictionaryMode = true;
  freeSetItem = false;
  imgLoaded = false;
  @Input() questionLang = '';
  @Input() answerLang = '';
  url: any;
  userIsAuthor = false;
  constructor(
    private spinner: SpinnerService,
    private readTextService: ReadTextService,
    private router: Router,
    private setsService: SetsService
  ) { }

  ngOnInit() {

    this.url = this.router.url;

    this.questionLang;
    const lngDetector = new LanguageDetect();
    if (this.setItem.question) {
      this.freeSetItem = true;
    } else {
      this.freeSetItem = false;
    }
  }

  imgWillLoad() {
    this.imgLoaded = true;
  }

  imgDidLoad() {
    this.spinner.hideSpinner();
  }
  addItemToSet(setItem) {

    this.setsService.addItemsToSet({ wordId: setItem.wordId, meaningId: setItem.id, item: {} });
  }
  removeItemFromSet(setItem) {
    const confirm = window.confirm('Czy na pewno chcesz usunąć to słowo?');
    if (!confirm) {
      return;
    }
    this.setsService.removeItemFromSet(setItem, this.setId);
  }
  readTerm(setItem) {
    const audio = new Audio();
    const url = setItem.wordAudio
      ? setItem.wordAudio
      : setItem.termAndDefinitionAudioUS
        ? setItem.termAndDefinitionAudioUS
        : setItem.termAudioUS;
    const textToRead = setItem.wordAudio
      ? setItem.word1 + ' ' + setItem.word2
      : setItem.termAndDefinitionAudioUS
        ? setItem.termAndDefinition
        : setItem.term;
    // this.readTextService.windowRead(textToRead, false);
    this.readTextService.readTextFromRepo(url, textToRead, setItem.id);
    // this.readTextService.readTextFromMs(textToRead);
  }

  readDefinition(setItem) {
    const audio = new Audio();
    const url = setItem.wordAudio
      ? setItem.polishAudio
      : setItem.definitionAudioUS;

    const textToRead = setItem.wordAudio
      ? setItem.translation
      : setItem.definitionAudioUS;

    // this.readTextService.windowRead(textToRead, false);
    this.readTextService.readTextFromRepo(url, textToRead, setItem.id);
    // this.readTextService.readTextFromMs(textToRead);
  }

  readDisassembledSentence(setItem) {
    const url = setItem.sentenceAudio;

    const textToRead = setItem.sentence;

    // this.readTextService.windowRead(textToRead, false);
    this.readTextService.readTextFromRepo(url, textToRead, setItem.id);
    // this.readTextService.readTextFromMs(textToRead);
  }
}
