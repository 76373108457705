import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { AuthenticationService } from '../authentication.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss'],
})
export class SetNewPasswordComponent implements OnInit {
  profileSub;
  showIntro = false;
  userDetails = null;
  newPassword;
  enteredNewPassword;
  confirmedNewPassword;
  messagesSub;
  message;
  passwordsError = false;
  hide = true;
  setPasswordForm;
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.setPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmNewPassword: ['', [Validators.required]],
    });

    // this.spinner.showSpinner();
    // setTimeout(() => {
    //   this.spinnerParams.changeParams("Ładowanie trwa dłużej niż zwykle...");
    // }, 5000);
    setTimeout(() => {
      this.messagesSub = this.auth
        .userBackendMessagesListener()
        .subscribe((message) => {

          this.message = message;
          switch (message) {
            case 'passwordChanged':
              this.showIntro = false;
              this.redirect();
              break;

            default:
              break;
          }
        });
    });
  }

  changePassword(details) {
    this.passwordsError = false;
    if (this.passwordMatchValidator()) {
      this.auth.setPassword(
        this.setPasswordForm.value.newPassword,
        this.userDetails._id
      );
    } else {
      this.passwordsError = true;
    }
  }
  passwordMatchValidator() {
    let result = false;
    if (
      this.setPasswordForm.value.newPassword ===
      this.setPasswordForm.value.confirmNewPassword &&
      this.setPasswordForm.value.newPassword?.length > 0 &&
      this.setPasswordForm.value.confirmNewPassword?.length > 0
    ) {
      result = true;
    } else {
      result = false;
      if (
        this.setPasswordForm.value.newPassword?.length === 0 ||
        this.setPasswordForm.value.confirmNewPassword?.length === 0
      ) {
        alert('Wypełnij wszystkie pola!');
      } else {
        alert('Hasła nie są takie same!');
      }
    }
    return result;
  }
  updateFormControl(event: any, controlName: string) {
    this.setPasswordForm.controls[controlName].setValue(event.target.value);
  }

  redirect() {


    this.router.navigateByUrl('/home/profile');
  }

  ngOnDestroy(): void {
    this.profileSub.unsubscribe();
    this.messagesSub.unsubscribe();
  }
}
