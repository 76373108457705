import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ChatbotsService } from '../chatbots.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';


@Component({
  selector: 'app-chatbots-translate-message',
  templateUrl: './chatbots-translate-message.component.html',
  styleUrls: ['./chatbots-translate-message.component.css']
})
export class ChatbotsTranslateMessageComponent implements OnInit {
  @Input() text: string;
  @Input() translatedText: string = ''
  @Output() translatedTextChange = new EventEmitter()
  selectedChatbot
  translating = false
  translationCount = 0
  constructor(
    private aiService: ChatbotsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes?.text?.currentValue && changes?.text?.firstChange) {
      this.selectedChatbot = this.aiService.chatbots.find(chatbot => chatbot.name === 'Trey')
      if (this.translatedText === '') {
        this.sendMessage()
      }
    }
  }
  ngOnInit() {

  }
  refreshTranslation() {
    if (this.translationCount < 3) {
      this.sendMessage()
      this.translationCount++
    }
  }

  sendMessage() {

    this.translating = true
    const data = {
      messages: [{
        "role": "user",
        "content": this.text
      }],
      aiProvider: this.selectedChatbot.aiProvider,
      model: this.selectedChatbot.model,
      maxTokens: this.selectedChatbot.maxTokens,
      maxMessages: this.selectedChatbot.maxMessages,
      systemMsg: this.selectedChatbot.systemMsg,
      files: null
    };

    this.aiService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        try {
          // Fixing the invalid JSON key to a valid structure
          const validJsonString = res.res.content.replace('{translation:', '{"translation":');
          const translationObject = JSON.parse(validJsonString);
          this.translatedText = translationObject.translation;
          this.translating = false
          this.translatedTextChange.emit({ originalText: this.text, translatedText: this.translatedText });
        } catch (parseError) {
          console.error("Invalid JSON structure in response", parseError);
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

  }
}
