import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { AnswerSummaryModalComponent } from './questions-quiz-elements/answer-summary-modal/answer-summary-modal.component';
import { SpeechToTextService } from 'src/app/shared/services/speech-recognition.service';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  currentQuestion;
  flashcardsMode = false;
  url;
  lang: 'en' | 'es' = 'en';
  quizFinished = false;
  divideCount = 0;
  quizItems = [];
  itemNumber = 0;
  multiModeNumber = 0;
  startThinking = 0;
  streakStartTime = 0;
  _nextQuestion = new Subject();
  _nextFlashcard = new Subject();
  flipSpeaking = new Subject();
  questionChanged = new Subject<any>();
  private _isUpdatingStats = false;
  constructor(
    private actionSheetCtrl: ActionSheetController,
    private helpers: HelpersService,
    private userDetailsService: UserDetailsService,
    private http: HttpClient,
    private platformService: PlatformService,
    private modalCtrl: ModalController
  ) // private speechToTextService: SpeechToTextService
  {
    this.url = this.platformService.url;
    this.startThinking = Date.now();
    this.streakStartTime = Date.now();
  }
  nextFlashcard() {
    this.bareUpdateStats();
    this.itemNumber++;
    this.currentQuestion = this.quizItems[this.itemNumber];
    this._nextFlashcard.next(this.currentQuestion);
    this.startThinking = Date.now();
    this.streakStartTime = Date.now();
  }
  public checkAnswer(
    answer: 'correct' | 'wrong',
    ratio?: number,
    feedbackMessage?: string | boolean,
    recognizedText?: string,
    selectedWrongAnswer?: string,
    correctAnswer?: string,
    modalOptions?: {
      fromSpeaking?: boolean;
      preventAutoDismiss?: boolean;
      [key: string]: any;
    }
  ) {
    if (answer === 'correct') {
      this.openAnswerSummaryModal(
        'correct',
        ratio,
        false,
        recognizedText,
        selectedWrongAnswer,
        correctAnswer,
        modalOptions
      );
    } else {
      this.openAnswerSummaryModal(
        'wrong',
        ratio,
        false,
        recognizedText,
        selectedWrongAnswer,
        correctAnswer,
        modalOptions
      );
    }
  }
  flipSpeakingAndSentence() {
    this.flipSpeaking.next('flip')
  }
  presentActionSheet(
    answer: 'correct' | 'wrong',
    ratio?: number,
    feedbackMessage?: string | boolean,
    recognizedText?: string,
    selectedWrongAnswer?: string,
    correctAnswer?: string,
    modalOptions?: {
      fromSpeaking?: boolean;
      preventAutoDismiss?: boolean;
      [key: string]: any;
    }
  ) {
    this.openAnswerSummaryModal(
      answer,
      ratio,
      false,
      recognizedText,
      selectedWrongAnswer,
      correctAnswer,
      modalOptions
    );
  }

  async openAnswerSummaryModal(
    answer: 'correct' | 'wrong',
    ratio?: number,
    autoDismiss?: boolean,
    recognizedText?: string,
    selectedWrongAnswer?: string,
    correctAnswer?: string,
    modalOptions?: {
      fromSpeaking?: boolean;
      preventAutoDismiss?: boolean;
      [key: string]: any;
    }
  ) {
    const dataToSend = {
      question: this.currentQuestion,
      answer: answer,
      ratio: ratio,
      autoDismiss: autoDismiss && !modalOptions?.preventAutoDismiss,
      fromSpeaking: modalOptions?.fromSpeaking || ratio > 0,
      recognizedText: recognizedText,
      selectedWrongAnswer: selectedWrongAnswer,
    };

    const modal = await this.modalCtrl.create({
      component: AnswerSummaryModalComponent,
      cssClass: 'bottom-modal',
      backdropDismiss: false,
      componentProps: {
        data: dataToSend,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'again') {

    }
    if (role === 'next') {

      this.nextQuestion(answer, correctAnswer);
    }
  }

  updateStats(
    userDetails,
    studentId,
    setId,
    weeklyProgress,
    finished,
    currentSet,
    quizParams
  ) {
    const statsId = this.helpers.makeid(8);
    const setToUpdate = this.userDetailsService.currentSet;
    if (setToUpdate) {
      if (!setToUpdate.stats && currentSet) {
        setToUpdate.stats = {
          lastLearned: 0,
          lastQuestionTime: 0,
          numberOfRuns: 0,
          startTime: Date.now(),
          totalItems: 0,
          weeklyProgress: 0,
          quizParams,
        };
        setToUpdate.stats.weeklyProgress = weeklyProgress ? weeklyProgress : 0;
      } else if (setToUpdate.stats) {

        setToUpdate.stats.quizParams = quizParams;
        setToUpdate.stats.weeklyProgress = weeklyProgress;
        setToUpdate.stats.lastQuestionTime = Date.now();
        setToUpdate.stats.quizParams.multiQuizParams =
          quizParams.multiQuizParams;
        if (finished) {
          setToUpdate.stats.items = [];
          setToUpdate.stats.knownItems = [];
          if (setToUpdate.stats.quizParams?.multiQuizParams) {
            setToUpdate.stats.quizParams.multiQuizParams.multiModeNumber = 0;
            setToUpdate.stats.quizParams.multiQuizParams.divideCount = 0;
            setToUpdate.stats.quizParams.multiQuizParams.itemNumber = 0;
            setToUpdate?.stats?.numberOfRuns
              ? setToUpdate.stats.numberOfRuns++
              : (setToUpdate.stats.numberOfRuns = 1);
          }
          setToUpdate.stats.weeklyProgress = weeklyProgress;
          setToUpdate.stats.lastQuestionTime = Date.now();
        }
      }
      let _weeklyProgress = 0;
      userDetails.userData.sets.forEach((set) => {
        if (set?.stats?.weeklyProgress > 0) {
          _weeklyProgress += set.stats?.weeklyProgress;
        }
      });
      userDetails.userData.weeklyProgress = _weeklyProgress;
      currentSet.stats = setToUpdate.stats;
      this.userDetailsService.storeUserDetails(userDetails);
      this.userDetailsService.storeCurrentSet(setToUpdate);
      this.userDetailsService.storeCurrentSetsStats(setToUpdate.stats);
    }
    quizParams.lastLearned = Date.now();
    this.http
      .put(this.url + '/api/customers/update-stats/' + studentId, {
        stats: 'no',
        setId: setId,
        statsItemUpdate: {},
        statsId: statsId,

        knownItemsUpdate: {},
        weeklyProgress: weeklyProgress,
        finished: finished,
        quizParams: quizParams,
      })
      .subscribe((response: any) => {


        // Update local state with the response from the server
        if (response && response.updatedSet) {
          // Update the current set with the server response
          const updatedSet = response.updatedSet;

          // Find and update the set in the user's sets
          if (userDetails && userDetails.userData && userDetails.userData.sets) {
            const setIndex = userDetails.userData.sets.findIndex(
              set => set.id === updatedSet.id || set.parentSet === updatedSet.parentSet
            );

            if (setIndex !== -1) {
              // Update the set with the server response
              userDetails.userData.sets[setIndex] = updatedSet;

              // Update weekly progress
              if (response.totalWeeklyProgress) {
                userDetails.userData.weeklyProgress = response.totalWeeklyProgress;
              }

              // Store the updated user details
              this.userDetailsService.storeUserDetails(userDetails);

              // Update current set
              if (this.userDetailsService.currentSet &&
                (this.userDetailsService.currentSet.id === updatedSet.id ||
                  this.userDetailsService.currentSet.parentSet === updatedSet.parentSet)) {
                this.userDetailsService.storeCurrentSet(updatedSet);
                this.userDetailsService.storeCurrentSetsStats(updatedSet.stats);
              }
            }
          }
        }
      }, error => {
        console.error('Error updating stats:', error);
      });
  }
  bareUpdateStats(finished?) {
    // Prevent multiple calls in quick succession
    if (this._isUpdatingStats) {

      return;
    }

    this._isUpdatingStats = true;

    try {
      const thinkingTime = (Date.now() - this.streakStartTime) / 1000;


      // Update learning streak
      this.userDetailsService.updateLearningStreak(thinkingTime);

      // Get current stats
      const stats = this.userDetailsService.getCurrentSetsStats();
      const previousWeeklyProgress = stats?.weeklyProgress || 0;
      let weeklyProgressToSend = previousWeeklyProgress;



      // Only add thinking time if it's reasonable (less than 2 minutes)
      if (thinkingTime < 120) {
        weeklyProgressToSend = thinkingTime + previousWeeklyProgress;
      }

      // Get the latest user details
      const userDetails = this.userDetailsService.getUserDetails();
      if (!userDetails || !this.userDetailsService.currentSet) {
        console.error('User details or current set not available');
        this._isUpdatingStats = false;
        return;
      }

      // Update stats with the server
      this.updateStats(
        userDetails,
        userDetails._id,
        this.userDetailsService.currentSet.id,
        weeklyProgressToSend,
        finished,
        this.userDetailsService.currentSet,
        {
          multiQuizParams: {
            divideCount: this.divideCount,
            itemNumber: this.itemNumber + 1,
            multiModeNumber: this.multiModeNumber,
            // push real params here
            multiParams: [
              {
                readFull: true,
                multi: true,
                divide: true,
                write: false,
                onlyWrite: false,
                makeSentence: false,
                gif: false,
                translation: false,
                buttonClues: true,
                answerMode: 'sentence',
                writeClue: true,
              },
              {
                readFull: true,
                multi: true,
                divide: true,
                write: false,
                onlyWrite: true,
                answerMode: 'sentence',
                makeSentence: false,
                gif: false,
                translation: false,
                buttonClues: true,
                writeClue: true,
                speakSentence: false,
              },
              {
                readFull: false,
                multi: true,
                divide: true,
                write: false,
                onlyWrite: true,
                makeSentence: false,
                gif: true,
                answerMode: 'sentence',
                translation: false,
                buttonClues: true,
                writeClue: true,
              },
            ],
          },
        }
      );

      // Reset the update flag after a short delay to prevent race conditions
      setTimeout(() => {
        this._isUpdatingStats = false;
      }, 500);
    } catch (error) {
      console.error('Error in bareUpdateStats:', error);
      this._isUpdatingStats = false;
    }
  }
  async showActionSheet(answer) {
    const selection = await this.presentActionSheet(answer);
  }
  nextQuestion(answer, correctAnswer?) {
    // this.speechToTextService.speakingTry = 0;
    this._nextQuestion.next({ answer: answer, correctAnswer: correctAnswer });
    this.startThinking = Date.now();
    this.streakStartTime = Date.now();

    // Notify components that we're changing questions
    setTimeout(() => {
      if (this.currentQuestion) {
        this.questionChanged.next(this.currentQuestion);
      }
    }, 100);
  }
  nextQuestionUpdatedListener() {
    return this._nextQuestion.asObservable();
  }
  nextFlashcardUpdatedListener() {
    return this._nextFlashcard.asObservable();
  }
  flipSpeakingUpdatedListener() {
    return this.flipSpeaking.asObservable();
  }
  questionChangedListener() {
    return this.questionChanged.asObservable();
  }
}
