import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { PrizesService } from 'src/app/shared/services/prizes.service';

@Component({
  selector: 'app-choose-prize',
  templateUrl: './choose-prize.component.html',
  styleUrls: ['./choose-prize.component.css'],
})
export class ChoosePrizeComponent implements OnInit {
  prizes = [];

  @Input() isTestMode: boolean = false; // Flag to indicate if opened from test button

  selectedBox = null;
  boxes = [
    {
      id: 1,
      boxPicture: '/assets/boxes/box_b.svg',
      topPicture: '/assets/boxes/top_b.svg',
      itemInTheBox: {
        img: '/assets/collectibles/3.svg',
        name: '',
        description: '',
        id: 0,
        extraDiamonds: 0
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
    {
      id: 2,
      boxPicture: '/assets/boxes/box_p_1.svg',
      topPicture: '/assets/boxes/top_p.svg',
      itemInTheBox: {
        img: '/assets/collectibles/2.svg',
        name: '',
        description: '',
        id: 0,
        extraDiamonds: 0
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
    {
      id: 3,
      boxPicture: '/assets/boxes/box_v.svg',
      topPicture: '/assets/boxes/top_v.svg',
      itemInTheBox: {
        img: '/assets/collectibles/1.svg',
        name: '',
        description: '',
        id: 0,
        extraDiamonds: 0
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
  ];
  picturesSub: any;
  chosenPrizes = [];
  data;
  isAdultUser = false;
  extraDiamondsRange = { min: 3, max: 10 };
  constructor(
    private helpers: HelpersService,
    public userDetailsService: UserDetailsService,
    private prizesService: PrizesService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit(): void {
    // Check if user is an adult or if in test mode
    const userDetails = this.userDetailsService.getUserDetails();
    this.isAdultUser = this.isTestMode || userDetails?.userData?.adult === true || userDetails?.userData?.alsoStudent === true;

    setTimeout(() => {
      this.prizes = this.prizesService.prizes;
      this.prizes.forEach((prize) => {
        const prizeNumberedLevel = this.prizesService.checkCollectablesLevel(
          prize.id
        ).numberedLevel;
        for (let index = 0; index < 3 - prizeNumberedLevel; index++) {
          this.chosenPrizes.push(prize);
        }
      });
      const rarePrizes = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Rare'
      );
      const commonPrizes = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Common'
      );
      const commonPrizes2 = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Common'
      );
      this.chosenPrizes = this.chosenPrizes.concat(rarePrizes);
      this.chosenPrizes = this.chosenPrizes.concat(commonPrizes2);
      this.chosenPrizes = this.chosenPrizes.concat(commonPrizes);


      // For adult users, prepare boxes with diamond information instead of collectibles
      if (this.isAdultUser) {
        this.boxes.forEach((box) => {
          const extraDiamonds = this.helpers.getRandomNumber(
            this.extraDiamondsRange.min,
            this.extraDiamondsRange.max
          );
          box.itemInTheBox = {
            img: '/assets/emojis/1f48e.svg',
            name: `${extraDiamonds} Diamentów`,
            description: `Otrzymujesz ${extraDiamonds} dodatkowych diamentów!`,
            extraDiamonds: extraDiamonds,
            id: 0
          };
        });
      } else {
        // Original code for non-adult users
        this.boxes.forEach((box) => {
          box.itemInTheBox =
            this.chosenPrizes[
            this.helpers.getRandomNumber(0, this.chosenPrizes.length - 1)
            ];
        });
      }
    }, 1000);
  }
  checkOpenedBoxes() {
    return this.boxes.some((box) => box.opened);
  }
  openBox(box) {
    setTimeout(() => {
      box.opened = true;
      setTimeout(() => {
        box.makeTada = true;
        setTimeout(() => {
          box.showCard = true;
          box.makeTada = false;
        }, 1500);
      });
    }, 1000);
    box.openTop = true;
    setTimeout(() => {
      box.showTop = false;
      box.openTop = false;
      this.selectedBox = box;
      box.makeTada = false;
    }, 1500);
  }
  getPrize(item) {
    if (this.isAdultUser && item.extraDiamonds) {
      // For adult users, give extra diamonds
      this.giveExtraDiamonds(item.extraDiamonds);
    } else {
      // Original code for non-adult users
      let previousLevel = this.prizesService.checkCollectablesLevel(item.id);
      if (previousLevel.numberedLevel == 0) {
        item.numberedLevel = 1;
        item.level = ['*', '', ''];
      } else if (previousLevel.numberedLevel == 1) {
        item.numberedLevel = 2;
        item.level = ['*', '*', ''];
      } else if (previousLevel.numberedLevel == 2) {
        item.numberedLevel = 3;
        item.level = ['*', '*', '*'];
      }
      item.fromSet = this.data?.setId;
      this.userDetailsService.addusersCollectableItems(
        this.userDetailsService.getSimpleUserDetails().id,
        item
      );
      let userDetails = this.userDetailsService.getUserDetails();
      if (!userDetails.userData.collectables) {
        userDetails.userData.collectables = [];
      }
      userDetails.userData.collectables.push(item);
      this.userDetailsService.storeUserDetails(userDetails);

      // Dismiss the modal with updated data
      this.modalCtrl.dismiss({
        itemAdded: item,
        updated: true
      });
    }
  }

  giveExtraDiamonds(amount: number) {
    const userId = this.userDetailsService.getSimpleUserDetails().id;
    const userDetails = this.userDetailsService.getUserDetails();




    // Initialize extraDiamonds if it doesn't exist
    if (!userDetails.userData.extraDiamonds) {
      userDetails.userData.extraDiamonds = 0;
    }

    // Initialize totalDiamonds if it doesn't exist
    if (!userDetails.userData.totalDiamonds) {
      userDetails.userData.totalDiamonds = 0;
    }

    // Add the new diamonds to both extraDiamonds and totalDiamonds
    userDetails.userData.extraDiamonds += amount;
    userDetails.userData.totalDiamonds += amount;



    // Update extraDiamonds in the database
    this.userDetailsService.setUsersProp(userId, 'extraDiamonds', userDetails.userData.extraDiamonds);

    // Update totalDiamonds in the database
    this.userDetailsService.setUsersProp(userId, 'totalDiamonds', userDetails.userData.totalDiamonds);

    // Update local user details
    this.userDetailsService.storeUserDetails(userDetails);

    // Dismiss the modal with updated data


    this.modalCtrl.dismiss({
      diamondsAdded: amount,
      totalDiamonds: userDetails.userData.totalDiamonds,
      updated: true
    });
  }
  // showCollectables() {
  //   const dialogRef = this.dialog.open(CollectablesViewComponent, {
  //     minHeight: "100vh",
  //     height: "100vh",
  //     minWidth: "100vw",
  //     width: "100vw",
  //     panelClass: "full-screen-dialog-no-wrap",
  //     data: this.data.userId.userData.collectables,
  //   });
  // }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
