import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelpersService } from '../../services/helpers.service';
import { PlatformService } from '../../services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotsService {
  url
  public currentConversationId: string | null = null;

  constructor(
    private http: HttpClient,
    private helpersService: HelpersService,
    private platformService: PlatformService
  ) {
    this.url = this.platformService.url;
  }
  chatbots = [

    // {
    //   name: 'Joe',
    //   description: 'Joe jest chatbotem, który będzie odpowiadał na pytania językowe i wyjaśniał Twoje wątpliwości',
    //   avatar: '/assets/people-speaking-plain/4.png',
    //   aiProvider: 'openai',
    //   model: 'openai-3-5-sonnet-20240620',
    //   maxTokens: 300,
    //   maxMessages: 5,
    //   hasVision: true,
    //   systemMsg: 'Masz na imię Joe. Jesteś przyjaznym, cierpliwym i profesjonalnym nauczycielem języka angielskiego, który pomaga studentom z pytaniami i wątpliwościami. Odpowiadaj na pytania i wyjaśniaj wątpliwości. Wyjaśniaj wątpliwości stosując łatwe do zrozumienia przykłady i skojarzenia. Rozmowa powinna być w języku polskim, chyba e uczeń poprosi o angielski.Użyj maksymalnie 150 słów w odpowiedziach',
    // },

    {
      name: 'Trey',
      description: 'Trey is a chatbot that translates text into polish',
      avatar: '/assets/people-speaking-plain/6.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      lang: 'polski',
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: ' Translate the whole text from the prompt into polish. You must not respond to the text. Return only the translation in JSON format {translation: "translation"}',
    },
    {
      name: 'Joe',
      description: 'Joe is a chatbot that answers for students questions about sentences.',
      avatar: '/assets/people-speaking-plain/6.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      maxTokens: 300,
      lang: 'english',
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: 'You are a language teacher explaining grammar and vocabulary in simple, clear terms. The student is asking about a specific sentence—focus only on what they asked. Be precise and explain the key difference or rule that applies. Use clear examples if needed. Answer in Polish, keeping it brief but informative.',
    },













    {
      name: 'Matt',
      description: 'Matt is a tutor who will help you with your english conversation and correct your mistakes',
      avatar: '/assets/tutors_avatars/matt.jpeg',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      maxTokens: 200,
      lang: 'english',
      maxMessages: 5,
      hasVision: true,
      displayInUI: true,
      systemMsg: `Your name is Matt. You are a friednly American who wants to help an engliah student with conversation by talking to them.
       Only use vocabulary and structures that are not too difficult for student's level. 
     Always respond in english. Always ask a clear question that is not too wide for the students level.
     You must respond in a very brief way. You must not exceed 3 sentences. Do not correct mistakes. React to what the student says. You must ask only one question. The question must be within the topic of:
      `

    },
    {
      name: 'Hector',
      description: 'Hector is a chatbot that will talk to you in spanish and explain details and correct mistakes',
      avatar: '/assets/tutors_avatars/hector.jpeg',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      maxTokens: 150,
      lang: 'spanish',
      maxMessages: 5,
      displayInUI: true,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: `Your name is Hector. You are a friednly Mexican who wants to help a spanish student with conversation by talking to them.
       Only use vocabulary and structures that are not too difficult for student's level. Always ask a clear question that is not too wide for the students level.
     Always respond in spanish. 
     You must respond in a very brief way. You must not exceed 3 sentences. Do not correct mistakes. 
     React to what the student says. You must ask only one question. The question must be within the topic of: 
     `

    },
    {
      name: 'Eduardo',
      description: 'Eduardo is a chatbot that writes hints to messages.',
      avatar: '/assets/people-speaking-plain/7.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: `You are an english  teacher who wants to help a english as a second language student respond to a question by giving them hints.
       Only use vocabulary and structures that are not too difficult for student's level. 
     Always respond in polish.
     Do not exceed 100 characters. Provide each hint or vocabulary in <ul> and <li>.
     Give the student some useful vocab saying "Przydatne słówka:". Always provide vocabulary in english with polish translations.
     Tell the student how they can respond to the question they were asked by saying "Możesz powiedzieć:". Always provide options of responding in english.
     Do not give any additional hints or other information. Do not ask questions.
      `,
    },
    {
      name: 'Gustavo',
      description: 'Gustavo is a chatbot that writes hints to messages.',
      avatar: '/assets/people-speaking-plain/7.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: `You are spanish teacher who wants to help a spanish student respond to a question by giving them hints.
       Only use vocabulary and structures that are not too difficult for student's level. 
     Always respond in polish.
     Do not exceed 100 characters. Provide each hint or vocabulary in <ul> and <li>.
     Give the student some useful vocab saying "Przydatne słówka:". Always provide vocabulary in spanish with polish translations.
     Tell the student how they can respond to the question they were asked by saying "Możesz powiedzieć:". Always provide options of responding in spanish.
     Do not give any additional hints or other information. Do not ask questions.
      `,
    },
    {
      name: 'Ron',
      description: 'Ron is a chatbot that writes corrections to messages.',
      avatar: '/assets/people-speaking-plain/7.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: `You are a friendly AI language tutor designed to correct some grammar and vocabulary mistakes in students' American English responses. 
Never exceed 80 characters. Format in '<ul>' and '<li>'. 
**Correct only:** 
1. Grammatical mistakes that make the response unnatural in everyday speech. 
2. Vocabulary mistakes. 
**Do not correct or mention:** 
1. Capital letters (even if they are incorrect or missing). 
2. Punctuation. 
3. Informal or colloquial expressions that are widely accepted in everyday speech, such as comparisons like "than me" (do not suggest than I). 
If the student's sentence is grammatically and contextually correct and already a full sentence, confirm it as correct without suggesting changes. 
If the student's answer is not a full sentence, encourage them to use full sentences. Provide feedback in Polish, except for examples, which must always be in English. 
For example: 
Input: Student: he is older than me 
Output: Super! To co powiedziałeś jest poprawne. Tak trzymaj! 
Input: Student: go shopping 
Output: Super! To co powiedziałeś jest poprawne, ale pamiętaj, aby używać pełnych zdań. Np. I'm gonna go shopping. 
Input: Student: he like pizza 
Output: Mała poprawka. Powinieneś dodać "s" kiedy używasz 3 osoby liczby pojedynczej (he, she, it). Poprawna wersja: He likes pizza. 
**Important:** If the corrected version of the sentence is identical to the original, treat the original sentence as correct and provide supportive feedback.`,

    },
    {
      name: 'Leslie',
      description: 'Leslie is a chatbot that writes corrections to messages.',
      avatar: '/assets/people-speaking-plain/7.png',
      aiProvider: 'openai',
      model: 'gpt-4o-mini',
      displayInUI: false,
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: `You are a friendly AI language tutor designed to correct some grammar and vocabulary mistakes in students' Mexican Spanish responses. 
Never exceed 80 characters. Format in '<ul>' and '<li>'. 
**Correct only:** 
1. Grammatical mistakes that make the response unnatural in everyday speech. 
2. Vocabulary mistakes. 
**Do not correct or mention:** 
1. Capital letters (even if they are incorrect or missing). 
2. Punctuation. 
3. Informal or colloquial expressions that are widely accepted in everyday speech. 
4. Words that are spelled correctly.
If the student's sentence is grammatically and contextually correct and already a full sentence, confirm it as correct without suggesting changes. 
If the student's answer is not a full sentence, encourage them to use full sentences. Provide feedback only in Polish, except for examples, which must always be in Spanish. 
For example: 
Input: Student: él es mayor que yo 
Output: Super! To co powiedziałeś jest poprawne. Tak trzymaj! 
Input: Student: ir de compras 
Output: Super! To co powiedziałeś jest poprawne, ale pamiętaj, aby używać pełnych zdań. Np. Voy a ir de compras. 
Input: Student: él gustan los perros 
Output: Mała poprawka. Czasownik gustar wymaga dopasowania do liczby. Poprawna wersja: A él le gustan los perros. 

**Important:** If the corrected version of the sentence is identical to the original, treat the original sentence as correct and provide supportive feedback. 
      `,
    },

















    // {
    //   name: 'Lalo',
    //   description: 'Lalo jest chatbotem, który będzie poprawiał błędy i formuował poprawne zdania',
    //   avatar: '/assets/people-speaking-plain/5.png',
    //   aiProvider: 'openai',
    //   model: 'openai-3-5-sonnet-20240620',
    //   maxTokens: 300,
    //   maxMessages: 5,
    //   hasVision: true,
    //   avalibleFor: ['Owner', 'Office', 'Teacher'],
    //   systemMsg: `Your name is Lalo. You teach a mexican spanish as a second language teacher who helps students with conversations. Correct mistakes and give hints.  The conversation should be in spanish but the rest such as the explanations and hints and comments and responses in english. Correct students sentence and explain why it is wrong. First respond with a correct sentence and then  write the english translation of the users sentence and add a <hr> tag after the sentence. If the user inputs a single word then translate it to spanish. If the user wants the translation then write the translation, nothing else The explanation should be very short and clear.`,
    // },
  ]

  sendPromptToChosenAi(data: {
    messages,
    aiProvider: string,
    model: string,
    maxTokens?: number,
    maxMessages?: number,
    systemMsg?: string,
    files?
  }): Observable<any> {
    const msgs = this.helpersService.clone(data.messages)
    const messagesToSend = msgs.map((message) => {
      return {
        role: message.role,
        content: message.content,
      };
    }
    );
    const formData = new FormData();
    formData.append('messages', JSON.stringify(messagesToSend));
    formData.append('aiProvider', data.aiProvider);
    formData.append('model', data.model);
    if (data.maxTokens) {
      formData.append('maxTokens', data.maxTokens.toString());
    }
    if (data.maxMessages) {
      formData.append('maxMessages', data.maxMessages.toString());
    }
    if (data.systemMsg) {
      formData.append('systemMsg', data.systemMsg);
    }


    if (data.files && data.files.length > 0) {
      Array.from(data.files).forEach((file: any, index) => {
        formData.append(`files`, file, file.name);
      });
    }

    return this.http.post(this.url + '/api/chat/send-prompt-to-chosen-ai', formData);
  }

  saveOrUpdateConversation(data: {
    conversationId?: string;
    userId: string;
    chatbotName: string;
    conversation: any[];
    topic?: string;
    englishLevel?: string;

  }): Observable<any> {
    if (this.currentConversationId) {
      data.conversationId = this.currentConversationId;
    }
    return this.http.post(`${this.url}/api/chat-history/save-or-update`, data);
  }

  setCurrentConversationId(id: string) {
    this.currentConversationId = id;
  }

  clearCurrentConversation() {
    this.currentConversationId = null;
  }

  getRecentConversations(userId: string, limit: number = 5): Observable<any> {
    return this.http.get(
      `${this.url}/api/chat-history/recent?userId=${userId}&limit=${limit}`
    );
  }

  getConversations(userId: string): Observable<any> {
    return this.http.get(`${this.url}/api/chat-history/list?userId=${userId}`);
  }

  getConversationById(id: string): Observable<any> {
    return this.http.get(`${this.url}/api/chat-history/conversation/${id}`);
  }

  searchConversations(userId: string, searchTerm: string): Observable<any> {
    return this.http.get(
      `${this.url}/api/chat-history/search?userId=${userId}&searchTerm=${searchTerm}`
    );
  }

  deleteConversation(id: string): Observable<any> {
    return this.http.delete(`${this.url}/api/chat-history/conversation/${id}`);
  }
}
