import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatbotsService } from 'src/app/shared/components/chatbots/chatbots.service';

interface ChatMessage {
    role: 'user' | 'assistant';
    content: string;
}

@Component({
    selector: 'app-chat-question',
    templateUrl: './chat-question.component.html',
    styleUrls: ['./chat-question.component.scss']
})
export class ChatQuestionComponent implements OnInit {
    selectedChatbot: any;
    checking = false;

    @Input() sentence: string = '';
    @Input() question: string = '';
    @Input() answer: string = '';
    @Input() showFeedback: boolean = false;
    @Input() extended: boolean = false;

    usersQuestion = '';
    aiResponse: string = '';
    conversationHistory: ChatMessage[] = [];

    constructor(
        private chatbotsService: ChatbotsService,
        private modalController: ModalController
    ) { }

    ngOnInit() {
        this.selectedChatbot = this.chatbotsService.chatbots.find(chatbot => chatbot.name == 'Joe');

        // Initialize conversation with context message
        this.conversationHistory.push({
            role: 'assistant',
            content: `Kontekst: "${this.sentence}". Zadaj pytanie o to zdanie.`
        });
    }

    // Scroll to bottom of chat when new messages are added
    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    private scrollToBottom() {
        try {
            const chatMessages = document.querySelector('.chat-messages');
            if (chatMessages) {
                chatMessages.scrollTop = chatMessages.scrollHeight;
            }
        } catch (err) {
            console.error('Error scrolling to bottom:', err);
        }
    }

    sendMessage(checkOnly: boolean = false) {
        if (!this.usersQuestion.trim() || (this.extended && !checkOnly)) return;

        // Add user's question to conversation history
        this.conversationHistory.push({
            role: 'user',
            content: this.usersQuestion
        });

        this.checking = true;
        const data = {
            messages: [
                // Include initial context about the sentence
                {
                    "role": "system",
                    "content": `The context is a sentence: "${this.sentence}". Help the user explore and understand this sentence.`
                },
                // Include full conversation history
                ...this.conversationHistory.map(msg => ({
                    "role": msg.role,
                    "content": msg.content
                }))
            ],
            aiProvider: this.selectedChatbot.aiProvider,
            model: this.selectedChatbot.model,
            maxTokens: this.selectedChatbot.maxTokens,
            maxMessages: this.selectedChatbot.maxMessages,
            systemMsg: this.selectedChatbot.systemMsg,
            files: null
        };

        this.chatbotsService.sendPromptToChosenAi(data).subscribe(
            (res) => {
                console.log("Response from AI:", res);
                this.checking = false;

                // Add AI's response to conversation history
                const aiResponse = res.res.content;
                this.conversationHistory.push({
                    role: 'assistant',
                    content: aiResponse
                });

                // Clear user's input
                this.usersQuestion = '';
            },
            (error) => {
                console.error('Error:', error);
                this.checking = false;

                // Add error message to conversation history
                this.conversationHistory.push({
                    role: 'assistant',
                    content: 'Sorry, there was an error processing your question.'
                });
            }
        );
    }

    closeModal() {
        this.modalController.dismiss();
    }

    onKeyDown(event: KeyboardEvent) {
        event.stopPropagation();
    }
}
